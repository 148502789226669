import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Slider,
  TextField,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import Cropper, { Area, Point } from "react-easy-crop";
// import "./styles.css";
const defaultRatio = 16 / 9;

export type CropperData = {
  crop: Point;
  zoom: number;
  aspect: number;
  croppedAreaPixels?: Area;
};
export enum CropperType {
  FixAll = "fix_all",
  FixWidthOnly = "fix_width_only",
  FixHeightOnly = "fix_height_only",
}

type Props = {
  onClose?: () => void;
  onSave?: (data: CropperData) => void;
  image: string;
  aspectRatio?: number;
  isLoading?: boolean;
  extra?: ReactNode;
  crop_type?: `${CropperType}`;
  height?: number;
  width?: number;
} & Omit<DialogProps, "onSubmit">;

const getImageDimension = (
  imgSrc: string
): Promise<{ height: number; width: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      resolve({ height: img.height, width: img.width });
    };
  });
};
const Copper = ({
  image,
  aspectRatio,
  onClose,
  isLoading,
  onSave: handleSave,
  extra,
  crop_type,
  width: pwidth = 1,
  height: pheight = 1,
  ...dialogProps
}: Props) => {
  const height = Number(pheight);
  const width = Number(pwidth);
  const [cropper, setCropper] = useState<CropperData>({
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: aspectRatio || defaultRatio,
    croppedAreaPixels: undefined,
  });

  const [dynamicDimension, setDynamicDimension] = useState<number>(1);

  const [imageDimension, setImageDimension] =
    useState<{ height: number; width: number } | null>();

  useEffect(() => {
    getImageDimension(image).then((res) => {
      setImageDimension(res);
      switch (crop_type) {
        case CropperType.FixAll:
          const fixedAspectAll = width / height;
          setCropper((state) => ({
            ...state,
            aspect: fixedAspectAll,
          }));
          break;
        case CropperType.FixWidthOnly: {
          setDynamicDimension(res.height);
          const asptr = width / res.height;
          setCropper((state) => ({
            ...state,
            aspect: asptr,
          }));
          break;
        }
        case CropperType.FixHeightOnly: {
          setDynamicDimension(res.width);
          const asptr = res.width / height;
          setCropper((state) => ({
            ...state,
            aspect: asptr,
          }));
          break;
        }
        default:
          setCropper((state) => ({
            ...state,
            aspect: aspectRatio || defaultRatio,
          }));
          break;
      }
    });
  }, [crop_type, image, height, width, aspectRatio]);

  const handleDyamicDimensionChange = (n: number) => {
    setDynamicDimension(n);
    if (CropperType.FixHeightOnly === crop_type) {
      setCropper((state) => ({
        ...state,
        aspect: n / height,
      }));
    } else {
      setCropper((state) => ({
        ...state,
        aspect: width / n,
      }));
    }
  };

  const onCropChange = (crop: any) => {
    setCropper((state) => {
      return {
        ...state,
        crop,
      };
    });
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCropper((state) => {
      return {
        ...state,
        croppedAreaPixels,
      };
    });
  };

  const onZoomChange = (zoom: any) => {
    setCropper((state) => {
      return {
        ...state,
        zoom,
      };
    });
  };

  const onSave = () => {
    handleSave?.(cropper);
  };
  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      {/* <DialogWrap> */}
      <DialogTitle zIndex={2} position={"relative"}>
        Image Cropper
      </DialogTitle>
      <DialogContent sx={{ height: 500, position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: "80px",
            overflow: "hidden",
          }}
        >
          <Cropper
            image={image}
            crop={cropper.crop}
            showGrid={false}
            zoom={cropper.zoom}
            aspect={cropper.aspect}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
            rotation={0}
            onRotationChange={() => {}}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            // left: "50%",
            width: "80%",
            // transform: "translateX(-50%)",
            height: "40px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex" }}>
              <label style={{ marginRight: "10px" }}>Zoom</label>
              <Slider
                value={cropper.zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, newValue) => {
                  if (typeof newValue === "number") onZoomChange(newValue);
                }}
                className="zoom-range"
                sx={{ width: "500px" }}
              />
            </Box>
            {(crop_type === CropperType.FixHeightOnly ||
              crop_type === CropperType.FixWidthOnly) && (
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex" }}>
                  <label style={{ marginRight: "10px" }}>
                    {crop_type === CropperType.FixHeightOnly
                      ? "Width"
                      : "Height"}
                  </label>
                  <Slider
                    value={dynamicDimension}
                    min={1}
                    max={
                      crop_type === CropperType.FixHeightOnly
                        ? imageDimension?.width
                        : imageDimension?.height
                    }
                    step={0.1}
                    onChange={(e, newValue) => {
                      if (typeof newValue === "number")
                        handleDyamicDimensionChange(newValue);
                    }}
                    sx={{ width: "500px" }}
                  />
                </Box>
                <TextField
                  type="number"
                  value={dynamicDimension}
                  hiddenLabel
                  size="small"
                  variant="filled"
                  sx={{ width: "60px", marginLeft: "60px" }}
                  onChange={(e) => {
                    handleDyamicDimensionChange(Number(e?.target.value));
                  }}
                />
              </Box>
            )}
            <Box>{extra}</Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} loading={isLoading}>
          Cancel
        </LoadingButton>
        <LoadingButton onClick={onSave} variant="contained" loading={isLoading}>
          Save
        </LoadingButton>
      </DialogActions>
      {/* </DialogWrap> */}
    </Dialog>
  );
};

export default Copper;
