import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import InvitationModal from "models/Invitation";
import { useParams } from "react-router-dom";
import InvitationCard from "./InvitationCard";

type Props = {};

export default function Invitation({}: Props) {
  const { accountSlug } = useParams<{ accountSlug: string }>();

  const { data, isLoading, isFetchedAfterMount } = useQuery({
    queryKey: ["inviation", { accountSlug }],
    queryFn: async () => {
      const res = await InvitationModal.get(accountSlug!);
      return res.data;
    },
    enabled: !!accountSlug,
  });

  if (!isFetchedAfterMount || isLoading) return <Box>loading...</Box>;
  if (!accountSlug || !data) return <Box>Not Found</Box>;

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <InvitationCard
        title={data.account_title}
        description="You have been invited to join the workspace of:"
      />
    </Box>
  );
}
