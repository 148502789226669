import { Box, Button } from "@mui/material";
import React from "react";
import FusionActions, {
  FusionActionsProps,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/ExtendedFields/FusionActions";

export type RecordListFieldsProps = {
  datasetActions: FusionActionsProps["actions"];
  onAddActionClick: FusionActionsProps["onAddClick"];
  onEditActionClick: FusionActionsProps["onEditClick"];
  onDeleteActionClick: FusionActionsProps["onDeleteClick"];
  onSortAction: FusionActionsProps["onSort"];
  onDatalistSettingsClick(): void;
};

const RecordListFields: React.FC<RecordListFieldsProps> = (props) => {
  const {
    datasetActions,
    onDatalistSettingsClick,
    onAddActionClick,
    onDeleteActionClick,
    onEditActionClick,
    onSortAction,
  } = props;

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <FusionActions
          actions={datasetActions}
          onAddClick={onAddActionClick}
          onEditClick={onEditActionClick}
          onDeleteClick={onDeleteActionClick}
          onSort={onSortAction}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{
          borderColor: "#fff",
          boxShadow: "none",
          mb: 2,
        }}
        fullWidth
        onClick={onDatalistSettingsClick}
      >
        Edit Datalist Settings
      </Button>
    </Box>
  );
};

export default RecordListFields;
