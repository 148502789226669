import { useMutation } from "@tanstack/react-query";
import UserModel from "models/User";
import { useNavigate } from "react-router-dom";

export default function useSignUp() {
  const navigate = useNavigate();
  return useMutation(
    async (data: {
      first_name: string;
      last_name: string;
      email: string;
      password: string;
      // company_name: string;
      // account_package_id: string;
    }) => {
      const userRes = await UserModel.createAccount(data);
      return userRes;
    },
    {
      onSuccess: () => {
        navigate("/login");
      },
    }
  );
}
