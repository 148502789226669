import TextField, { TextFieldProps } from "@mui/material/TextField";
import {
  AuBankAccountElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  FpxBankElement,
  IbanElement,
  IdealBankElement,
} from "@stripe/react-stripe-js";
import FormField from "components/FormField";
import * as React from "react";
import StripeInput from "./StripeInput";

type StripeElement =
  | typeof AuBankAccountElement
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement
  | typeof FpxBankElement
  | typeof IbanElement
  | typeof IdealBankElement;

type ReactComponentProps<
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> = T extends React.JSXElementConstructor<infer P>
  ? P
  : T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T]
  : {};

interface StripeTextFieldProps<
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> extends Omit<TextFieldProps, "onChange" | "inputComponent" | "inputProps"> {
  inputProps?: ReactComponentProps<T>;
  labelErrorMessage?: string | null;
  onChange?: "onChange" extends keyof ReactComponentProps<T>
    ? ReactComponentProps<T>["onChange"]
    : never;
  stripeElement?: T;
}

export const StripeTextField = <
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
>(
  props: StripeTextFieldProps<T>
) => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    label,
    ...other
  } = props;

  return (
    <FormField label={label as any}>
      <TextField
        //   InputLabelProps={{
        //     ...InputLabelProps,
        //     shrink: true,
        //   }}

        InputProps={{
          ...InputProps,
          inputProps: {
            ...inputProps,
            ...InputProps.inputProps,
            component: stripeElement,
          },

          inputComponent: StripeInput,
        }}
        error={error}
        helperText={error ? labelErrorMessage : helperText}
        margin="dense"
        type="text"
        fullWidth
        hiddenLabel
        size="small"
        variant="filled"
        {...(other as any)}
      />
    </FormField>
  );
};

export function StripeTextFieldNumber(
  props: StripeTextFieldProps<typeof CardNumberElement>
) {
  return (
    <StripeTextField
      label="Credit Card Number"
      stripeElement={CardNumberElement}
      {...props}
    />
  );
}

export function StripeTextFieldExpiry(
  props: StripeTextFieldProps<typeof CardExpiryElement>
) {
  return (
    <StripeTextField
      label="Expires"
      stripeElement={CardExpiryElement}
      {...props}
    />
  );
}

export function StripeTextFieldCVC(
  props: StripeTextFieldProps<typeof CardCvcElement>
) {
  return (
    <StripeTextField
      label="CVC Code"
      stripeElement={CardCvcElement}
      {...props}
    />
  );
}
