import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";

const useFusion = (fusionSlug?: string) => {
  return useGetItem({
    modelName: ApiModels.Fusion,
    slug: fusionSlug,
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    },
  });
};

export default useFusion;
