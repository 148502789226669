import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  type CognitoUserSession,
} from "amazon-cognito-identity-js";
import { CognitoConfig } from "configs/AppConfig";
import { removeLocalStorage } from "utils";

export class Auth {
  private static userPool: CognitoUserPool;

  private static getUserPool() {
    if (!this.userPool) {
      this.userPool = new CognitoUserPool({
        UserPoolId: CognitoConfig.USER_POOL_ID!,
        ClientId: CognitoConfig.APP_CLIENT_ID!,
      });
    }
    return this.userPool;
  }
  static async signIn(email: string, password: string) {
    const user = new CognitoUser({ Username: email, Pool: this.getUserPool() });
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    return new Promise((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => reject(err),
      })
    );
  }

  static async checkIfUserExist(email: string) {
    return Auth.signIn(email.toLowerCase(), "0  ")
      .then((res) => {
        return false;
      })
      .catch((error) => {
        const code = error.code;
        console.log(error);
        //  return error;
        switch (code) {
          case "UserNotFoundException":
            return false;
          default:
            return true;
        }
      });
  }

  static async signUp(email: string, password: string) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
    ];

    return new Promise((resolve, reject) =>
      this.getUserPool().signUp(
        email,
        password,
        attributeList,
        [],
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        }
      )
    );
  }

  static getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  static signOut() {
    const currentUser = this.getCurrentUser();

    if (currentUser !== null) {
      currentUser.signOut();
      removeLocalStorage("token");
      removeLocalStorage("account-id");
    }
  }

  static async authenticate() {
    const currentUser = this.getCurrentUser();
    if (!currentUser) {
      throw new Error("Can't authenticate without a current user");
    }

    return new Promise<CognitoUserSession>((resolve, reject) => {
      currentUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          if (!session.isValid()) {
            reject(new Error("Session is invalid"));
          } else {
            resolve(session);
          }
        }
      });
    });
  }

  static async getCognitoUser(username: string) {
    const userData = {
      Username: username,
      Pool: this.getUserPool(),
    };
    const cognitoUser = new CognitoUser(userData);

    return cognitoUser;
  }

  static async forgotPassword(
    username: string,
    code: string,
    password: string
  ) {
    return new Promise(async function (resolve, reject) {
      const cognitoUser = await Auth.getCognitoUser(username);

      if (!cognitoUser) {
        reject(`could not find ${username}`);
        return;
      }

      cognitoUser.confirmPassword(code, password, {
        onSuccess: function () {
          resolve("password updated");
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  }

  static async changePassword(oldPassword: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      const currentUser = this.getCurrentUser();
      if (!currentUser) {
        reject("User is not available");
        return;
      }

      // Optional: Reauthenticate the user here

      currentUser.getSession((err: Error, session: CognitoUserSession|null) => {
        if (err || !session?.isValid()) {
          reject("Session is not valid");
          return;
        }

        currentUser.changePassword(
          oldPassword,
          newPassword,
          (err: any, res: any) => {
            if (err) {
              reject(err);
            } else {
              resolve(res);
            }
          }
        );
      });
    });
  }

  static async verifyCode(username: string, code: string) {
    return new Promise(async function (resolve, reject) {
      const cognitoUser = await Auth.getCognitoUser(username);

      cognitoUser.confirmRegistration(code, true, function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }
  static async sendCode(username: string) {
    return new Promise(async function (resolve, reject) {
      const cognitoUser = await Auth.getCognitoUser(username);

      if (!cognitoUser) {
        reject(`could not find ${username}`);
        return;
      }

      cognitoUser.forgotPassword({
        onSuccess: function (res) {
          resolve(res);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    }).catch((err) => {
      throw err;
    });
  }
}
