import Model from "models";
import qs from "qs";

class InvitationModal {
  private model = new Model<Invitation>("/", "public-1");

  async get(slug: string) {
    return await this.model.sendRequest<Invitation>(
      `invitation/${slug}`,
      "GET"
    );
  }
  async send(data: { email: string; seat_type_id: string }) {
    return await this.model.sendRequest<Invitation>(
      `send-invitation`,
      "POST",
      data
    );
  }
  async list({ query }: { query: Record<string, string> }) {
    return await this.model.sendRequest<Invitation[]>(
      `invitation?${qs.stringify(query)}`,
      "GET"
    );
  }
  async updateStatus(accountSlug: string, status: "accept" | "reject") {
    return await this.model.sendRequest<Invitation>(
      `update-invitation-status/${accountSlug}?status=${status}`,
      "PUT"
    );
  }

  async delete(slug: string) {
    return await this.model.sendRequest<Invitation>(
      `invitation/${slug}`,
      "DELETE"
    );
  }
}

export default new InvitationModal();
