// ----------------------------------------------------------------------

export default function Badge() {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {},
      },
    },
  };
}
