import { Divider, Grid, Stack, styled } from "@mui/material";
import ColorPicker from "components/ColorPicker";
import ToolTipInput from "components/Form/TooltipFields/Input";
import FormField from "components/FormField";
import IconPickerField from "components/IconPicker";
import { AllIconPickerIcons } from "constants/index";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";

type Props = {
  register: UseFormRegister<Partial<GfGui>>;
  errors: FieldErrors<Partial<GfGui>>;
  control: Control<Partial<GfGui>, any>;
};

export const LabelHolder = styled(Grid)(({ theme }) => ({
  ".MuiFormLabel-root": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    color: theme.palette.text.primary,
  },

  [`${theme.breakpoints.down("sm")}`]: {
    marginBottom: "20px",
  },
}));

export const RDivider = styled(Divider)(({ theme }) => ({
  margin: "30px 0",

  [`${theme.breakpoints.down("sm")}`]: {
    margin: "20px 0",
  },
}));

export const StackHolder = styled(Stack)(({ theme }) => ({
  // maxWidth: "400px",
  padding: "20px",

  ".MuiFormLabel-root ": {
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.text.primary,
    margin: "0 0 8px",
  },
}));

const GuiEditForm = ({ register, errors, control }: Props) => {
  return (
    <StackHolder spacing={2.5}>
      <ToolTipInput
        name={"name"}
        label={"Workspace Title"}
        tooltipProps={{ placement: "left" }}
      />

      <ToolTipInput
        name={"description"}
        baseElementProps={{ multiline: true, rows: 2 }}
        label={"Workspace Description"}
        tooltipProps={{ placement: "left" }}
        tooltipInlineElementProps={{ multiline: true, rows: 2 }}
      />

      <FormField label="Color" error={errors.icon}>
        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorPicker {...field} color={field.value} placement="left" />
          )}
        />
      </FormField>
      <FormField label="Icon" error={errors.icon}>
        <Controller
          name="icon"
          control={control}
          render={({ field }) => (
            <IconPickerField
              {...field}
              icons={AllIconPickerIcons}
              compact={true}
              placement="left"
            />
          )}
        />
      </FormField>
    </StackHolder>
  );
};

export default GuiEditForm;
