import { Stack, Typography, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IOSSwitch from "components/IOSSwitch";
import useAppConfig from "hooks/useAppConfig";
import React from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { FormType } from "./MiddleComponent";

interface Props {
  title?: string;
  description?: string;
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
  value?: boolean;
}

const PermissionWrap = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  border: `1px solid ${theme.palette.background.GF20}`,
  borderRadius: "6px",
  padding: "11px 24px",
  transition: "all 0.4s ease",

  "&.permission-active, &:hover": {
    borderColor: theme.palette.background.GF60,
  },

  ".MuiTypography-subtitle1": {
    fontSize: "18px",
    lineHeight: "20px",
  },
  ".MuiTypography-body2": {
    fontSize: "15px",
    lineHeight: "16px",
  },
}));

const Item: React.FC<Props> = (props) => {
  const { title = "", description = "", onChange, value } = props;

  const theme = useTheme();
  return (
    <PermissionWrap>
      <Stack direction="row" alignItems="center" spacing={3.375}>
        <IOSSwitch
          onChange={onChange}
          size="small"
          color="primary"
          checked={value}
          value={value}
        />
        <Stack gap={0.5}>
          <Typography gutterBottom variant="subtitle1" component="div" mb="0">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </PermissionWrap>
  );
};

function Permission({
  register,
  control,
}: {
  register: UseFormRegister<Partial<FormType>>;
  control: Control<Partial<FormType>, any>;
}) {
  const {
    co_pilot_access,
    jobs_access,
    dataset_access,
    workflow_access,
    import_template_access,
    dashboards_access,
    media_access,
    icon_access,
    user_access,
    sylar_access,
    apps_access,
    vector_embeds_access,
    fine_tune_models_access,
    skills_access,
    adventure_access,
    billing_module,
  } = useAppConfig();
  return (
    <Stack gap="16px">
      {co_pilot_access && (
        <Controller
          name="permissions.co_pilot_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Co-Pilot"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {jobs_access && (
        <Controller
          name="permissions.jobs_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Jobs"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {dataset_access && (
        <Controller
          name="permissions.dataset_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Datasets"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {workflow_access && (
        <Controller
          name="permissions.workflow_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Workflows"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {import_template_access && (
        <Controller
          name="permissions.import_template_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Import Templates"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {dashboards_access && (
        <Controller
          name="permissions.dashboards_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Dashboards"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {media_access && (
        <Controller
          name="permissions.media_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Media"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {icon_access && (
        <Controller
          name="permissions.icon_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Icons"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {user_access && (
        <Controller
          name="permissions.user_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Users"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {sylar_access && (
        <Controller
          name="permissions.sylar_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Sylar"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {apps_access && (
        <Controller
          name="permissions.apps_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Apps"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {vector_embeds_access && (
        <Controller
          name="permissions.vector_embeds_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Vector Embeds"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {fine_tune_models_access && (
        <Controller
          name="permissions.fine_tune_models_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Fine Tune Models"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {skills_access && (
        <Controller
          name="permissions.skills_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Skills"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {adventure_access && (
        <Controller
          name="permissions.adventure_access"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Adventure Access"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
      {billing_module && (
        <Controller
          name="permissions.billing_module"
          control={control}
          render={({ field }) => {
            return (
              <Item
                title="Billing"
                description="Lorem ipsum dolor sit amet, consectetur , sed do eiusmod tempor incididunt ut labore."
                {...field}
              />
            );
          }}
        />
      )}
    </Stack>
  );
}

export default Permission;
