import { zodResolver } from "@hookform/resolvers/zod";
import {
  DeleteOutline,
  EditOutlined,
  SecurityOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import TagEditor from "components/Form/TagEditor";
import ProfileUpload from "components/ProfileUploader/ProfileUploader";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import useAppNavigate from "hooks/useAppNavigate";
import { debounce, uniqueId } from "lodash";
import AccountModal from 'models/Account';
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import { useCallback, useEffect, useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";
import { confirmDelete } from "utils/confirmDelete";
import { z } from "zod";

const SidebarSectionBlock = styled(Box)(({ theme }) => {
  return {
    marginTop: "20px",
    ".MuiCard-root:hover": {
      background: `${theme.palette.background.GFRightNavForeground} !important`,

      ".edit-icon": {
        opacity: "1",
        visibility: "visible",
      },
    },

    ".record-item": {
      transition: "all 0.4s ease",

      "&:hover ": {
        background: theme.palette.background.GF20,

        ".edit-icon": {
          opacity: "1",
          visibility: "visible",
        },
      },
    },

    ".edit-icon": {
      width: "16px",
      height: "16px",
      color: theme.palette.background.GF60,
      transition: "all 0.4s ease",
      opacity: "0",
      visibility: "hidden",
      cursor: "pointer",

      "&:hover": {
        color: theme.palette.text.primary,
      },

      svg: {
        width: "100%",
        height: "auto",
        display: "block",
        color: "currentColor",
      },
    },
  };
});

const formSchema = z.object({
  image: z.any(),
  tags: z
    .array(
      z.object({ label: z.string(), value: z.string(), color: z.string() })
    )
    .optional(),
});

const BoxIcon = styled(Box)(({ theme }) => {
  return {
    width: "20px",
    color: theme.palette.background.GF70,

    svg: {
      maxWidth: "100%",
      height: "20px",
      width: "auto",
      display: "block",
      margin: "auto",

      path: {
        fill: "currentColor",
      },
    },
  };
});

const AccountUserSetting = ({ onMenuEdit }: { onMenuEdit: () => void }) => {
  const { slug: accountUserSlug } = useParams<{ slug: string }>();

  const { data: accountUser } = useGetItem({
    modelName: ApiModels.AccountUser,
    slug: accountUserSlug,
  });
  const form = useForm<Partial<User>>({
    defaultValues: accountUser,
    resolver: zodResolver(formSchema),
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    reset,
    watch,
  } = form;
  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);
  const appNavigate = useAppNavigate();
  const { mutate: removeUser, isLoading: isRemoving } = useMutation({
    mutationFn: async (id: string) => {
      return await AccountModal.removeUser(id);
    },
    mutationKey: [ApiModels.AccountUser],
  });
  const { mutate: updateAccountUser } = useUpdateItem({
    modelName: ApiModels.AccountUser,
    mutationOptions: {
      mutationKey: [ApiModels.AccountUser],
    },
  });

  useEffect(() => {
    initialValueSet.current = false;
  }, [accountUserSlug]);

  useEffect(() => {
    if (accountUser && !initialValueSet.current) {
      // console.log("accountUser", accountUser);
      reset({ ...accountUser, tags: accountUser?.tags || [] });
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 1000);
    }
  }, [reset, accountUser]);

  const submitHandler = useCallback(
    (data: Partial<User>) => {
      if (accountUserSlug && allowNetworkRequest.current) {
        updateAccountUser(
          { slug: accountUserSlug, data },
          {
            onSuccess: () => {
              console.log("AccountUser edit success");
            },
          }
        );
      }
    },
    [accountUserSlug, updateAccountUser]
  );

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 600);
    const subscription = watch((_) => {
      submitDeb();
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  const theme = useTheme();

  const handleRemoveUser = () => {
    if (!accountUserSlug) return;

    confirmDelete().then((result) => {
      if (!result.isConfirmed) return;
      removeUser(accountUserSlug, {
        onSuccess: () => {
          queryClient.invalidateQueries([ApiModels.AccountUser]);
          appNavigate("/account-user-module");
        },
      });
    });
  };
  return (
    <Box>
      <FormProvider {...form}>
        <Box sx={{ marginTop: 2.5 }}>
          <Controller
            control={control}
            name="image"
            render={({ field }) => {
              return (
                <ProfileUpload
                  image={field.value?.url}
                  onChange={(url, file) => {
                    field.onChange({
                      uid: uniqueId(),
                      url: url,
                      name: file?.name,
                      size: file?.size,
                      type: file?.type,
                    });
                  }}
                />
              );
            }}
          />
        </Box>
        <SidebarSectionWrap
          rightIcon={false}
          leftIcon={false}
          title="Menu Settings"
        >
          <ProfileCard
            AvatarImage={
              <BoxIcon>
                <SecurityOutlined />
              </BoxIcon>
            }
            options={{
              draggable: false,
              switcher: false,
            }}
            rightIcon={
              <Box className="edit-icon">
                <EditOutlined sx={{ color: "grey.500" }} />
              </Box>
            }
            title={"User Menu"}
            sx={{
              background: theme.palette.background.GFRightNavForeground,
              height: 48,
              ".MuiTypography-subtitle1": {
                margin: "0 0 2px",
              },

              ".card-inner-content": {
                gap: "2px",
              },
            }}
            onClick={onMenuEdit}
          />
        </SidebarSectionWrap>
        <SidebarSectionBlock>
          <TagEditor name="tags" />
        </SidebarSectionBlock>

        <LoadingButton
          color="error"
          variant="contained"
          fullWidth
          sx={{ marginTop: 4 }}
          onClick={() => handleRemoveUser()}
          loading={isRemoving}
        >
          <DeleteOutline /> <Box sx={{ ml: 1 }}>Remove User</Box>
        </LoadingButton>
      </FormProvider>
    </Box>
  );
};

export default AccountUserSetting;
