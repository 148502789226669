import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import PaymentForm, { PaymentFormRef } from "components/stripe/PaymentForm";
import StripeHoc from "components/stripe/StripeHoc";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import React, { RefObject, useRef, useState } from "react";
type Props = {
  onSubmit?: (data: any) => void;
  onClose?: () => void;
} & Omit<DialogProps, "onSubmit">;

const DialogContentWrapper = ({
  onCancel,
}: {
  onCancel?: React.MouseEventHandler<HTMLButtonElement | undefined>;
}) => {
  const [loading, setLoading] = useState(false);
  const paymentFormRef = useRef() as RefObject<PaymentFormRef>;
  const { mutate: createCard } = useCreateItem({ modelName: ApiModels.Card });
  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setLoading(true);
    if (paymentFormRef?.current?.validate()) {
      setLoading(false);
      return;
    }
    try {
      /// type TokenResult but not working
      const result: any = await paymentFormRef?.current?.getToken();

      await createCard({ card_token: result.token.id } as Partial<Card>, {
        onSuccess: (res) => {
          console.log(res);
          (onCancel as any)?.();
          paymentFormRef?.current?.clear();
        },
      });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  return (
    <React.Fragment>
      <DialogContent>
        <PaymentForm ref={paymentFormRef} />
      </DialogContent>

      <DialogActions>
        <LoadingButton onClick={onCancel}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          type={"submit"}
          loading={loading}
          disabled={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </React.Fragment>
  );
};

const AddCard: React.FC<Props> = (props) => {
  const { onClose, onSubmit, ...dialogProps } = props;

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add New Card</DialogTitle>
      <StripeHoc>
        <DialogContentWrapper onCancel={onClose} />
      </StripeHoc>
    </Dialog>
  );
};

export default AddCard;
