import { Box, Button, Stack, styled } from "@mui/material";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import Icon from "components/util-components/Icon";
import useOpenClose from "hooks/useOpenClose";
import React, { useState } from "react";
import { ImporterUploadsInternal } from "views/app-view/upload-design/ImporterUploads";
import AddDatasetModel from "../AddDatasetModal";
import DatasetWidget from "./DatasetWidgets";

const DatasetSidebarContainerWrap = styled(Box)(({ theme }) => {
  return {
    paddingTop: 20,
    marginBottom: 20,

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const DesignUploaderWrapper = styled(Box)(({ theme }) => ({
  "&>div": {
    padding: "16px",
    background: "#2F2F36",
    borderRadius: "8px",
    h4: { fontSize: "18px" },
    ".heading-card p p": { fontSize: "14px" },
    ".uploader>div": { padding: "5px" },
  },
}));
// first and 4th column to show array;
const columnsToShow = [true, false, false, true];
const GuiSidenavMain: React.FC<{
  onEditDataset?: (_: Dataset, w: IncludeTabs) => void;
  datasetDesignSlug: string;
  datasetSlug: string;
  includedTabs?: IncludeTabs[];
  showActions?: boolean;
  actions?: FusionAction[];
  onNextClick?(): void;
  onPreviousClick?(): void;
  onActionClick?(action: FusionAction): void;
  selectedImport?: string;
}> = ({
  onEditDataset,
  datasetDesignSlug,
  datasetSlug,
  includedTabs = [],
  actions,
  onActionClick,
  onNextClick,
  onPreviousClick,
  showActions,
  selectedImport,
}) => {
  const [open, onOpen, onClose] = useOpenClose();
  const [selectedDatasetDesignProps, setSelectedDatasetDesignProps] =
    useState<IncludeTabs>();

  return (
    <React.Fragment>
      {selectedImport && (
        <SidebarSectionWrap title={"Design Importer"} rightIcon={false}>
          <DesignUploaderWrapper>
            <ImporterUploadsInternal
              slug={selectedImport}
              options={{ showColumnIndex: columnsToShow }}
            />
          </DesignUploaderWrapper>
        </SidebarSectionWrap>
      )}
      {includedTabs?.map((tab) => (
        <SidebarSectionWrap
          title={tab.name!}
          // rightIcon={false}
          key={tab.id}
          onRightIconClick={() => {
            onOpen();
            setSelectedDatasetDesignProps(tab);
          }}
        >
          <DatasetWidget
            datasetDesignSlug={datasetDesignSlug}
            datasetSlug={datasetSlug}
            selectedTabData={tab}
            onEdit={(d) => {
              onEditDataset?.(d, tab);
            }}
          />
        </SidebarSectionWrap>
      ))}
      {showActions && (
        <DatasetSidebarContainerWrap>
          <Stack direction="column" spacing="20px">
            <Stack
              direction="row"
              spacing={2}
              sx={{ p: 2, background: "#2F2F36", borderRadius: "6px" }}
            >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onPreviousClick}
              >
                Previous
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onNextClick}
              >
                Next
              </Button>
            </Stack>
            {!!actions?.length && (
              <Stack
                direction="column"
                spacing={2}
                sx={{ p: 2, background: "#2F2F36", borderRadius: "6px" }}
              >
                {actions?.map((action) => (
                  <Button
                    key={action.id}
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<Icon iconName={action.action_icon} />}
                    onClick={() => onActionClick?.(action)}
                  >
                    {action.action_title}
                  </Button>
                ))}
              </Stack>
            )}
          </Stack>
        </DatasetSidebarContainerWrap>
      )}
      <AddDatasetModel
        // gui={gui!}
        includedFieldIds={selectedDatasetDesignProps?.included_fields || []}
        datasetDesignSlug={selectedDatasetDesignProps?.dataset_to_include!}
        onClose={onClose}
        open={open}
        linkedField={{
          slug: selectedDatasetDesignProps?.linking_field!,
          value: datasetSlug!,
        }}
        tabMetadata={selectedDatasetDesignProps!}
      />
    </React.Fragment>
  );
};

export default GuiSidenavMain;
