import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";

type MessageItemProps = PropsWithChildren<{
  senderName?: string;
  invert: boolean;
}>;

const MessageItem: FC<MessageItemProps> = (props) => {
  const { senderName, children, invert } = props;
  return (
    <Box
      sx={{ minHeight: "72px", background: !invert ? "#FFFFFF0D" : undefined }}
    >
      <Stack
        sx={{
          height: "100%",
          width: "100%",
          minHeight: "72px",
          padding: "16px",
        }}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap={2}
      >
        <Avatar
          sx={{ bgcolor: "#0085FF", color: "white", borderRadius: "4px" }}
          variant="square"
        >
          {senderName?.charAt(0)}
        </Avatar>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          gap={1}
          sx={{ flexGrow: 1 }}
        >
          <Typography variant="body1" sx={{ color: "#0085FF" }}>
            {senderName}
          </Typography>
          {children}
        </Stack>
        <Button variant="text" sx={{ color: "#ffffff" }}>
          <MoreHoriz />
        </Button>
      </Stack>
    </Box>
  );
};

export default MessageItem;
