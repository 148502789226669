import Model from "models";

class Account {
  private model = new Model<AppAccount>("/account", "gui-fusion");

  async get(slug: string) {
    return await this.model.get(slug);
  }

  async create(
    data: Partial<{
      company_name: string;
      pass_phrase?: string;
      account_package_id: string;
    }>
  ) {
    return await this.model.sendRequest<AppAccount>(`/create`, "POST", data);
  }
  async changePackage(data: Partial<{ account_package_id: string }>) {
    return await this.model.sendRequest<AppAccount>(
      `/change-package`,
      "PUT",
      data
    );
  }
  async requestCredit(
    data: Partial<{ credit_type_id: string; requested_credit: number }>
  ) {
    return await this.model.sendRequest<{ payment_intent: any }>(
      `/request-credit`,
      "PUT",
      data
    );
  }

  async requestCreditConfirm(
    data: Partial<{
      credit_type_id: string;
      requested_credit: number;
      payment_intent_id: string;
      package_id: string;
    }>
  ) {
    return await this.model.sendRequest<AppAccount>(
      `/request-credit-confirm`,
      "PUT",
      data
    );
  }
  async removeUser(userId: string) {
    return await this.model.sendRequest(`/remove-user`, "PUT", {
      user_id: userId,
    });
  }
}

export default new Account();
