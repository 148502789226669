import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import ColorPicker from "components/ColorPicker";
import FormField from "components/FormField";
import SidebarSection from "components/RightSidebar";
import Spin from "components/Spin";
import DatasetModel from "models/Dataset";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  label: z.string(),
  status: z.string(),
  color: z.string(),
});

export type AddStatusFormType = z.infer<typeof formSchema>;

type AddStatusToIncludeProps = {
  datasetDesignSlug: string;
  statusFieldSlug: string;
  status?: WorkflowStatus;
  onBackClick(): void;
  onSubmit(data: AddStatusFormType): void;
};

const AddStatusToInclude: React.FC<AddStatusToIncludeProps> = (props) => {
  const { datasetDesignSlug, statusFieldSlug, status, onSubmit, onBackClick } =
    props;

  const [statuses, setStatuses] = useState<LabeledValue[]>([]);

  const [statusLoading, setStatusLoading] = useState(false);

  const {
    register,
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
  } = useForm<AddStatusFormType>({
    defaultValues: {
      color: "#000000",
    },
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    setStatusLoading(true);
    DatasetModel.getWorkflowStatusOptions(datasetDesignSlug, statusFieldSlug)
      .then((res) => {
        setStatuses(res.data);
        setStatusLoading(false);
      })
      .catch(() => {
        setStatusLoading(false);
      });

    if (status) {
      reset({
        label: status.label,
        status: status.status,
        color: status.color,
      });
    }

    return () => {
      reset();
    };
  }, []);

  const isEditMode = !!status;

  return (
    <Box sx={{ px: 2 }}>
      <Spin spinning={statusLoading}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SidebarSection
            title="Add Status to Include"
            leftIcon={<ArrowBack />}
            rightIcon={false}
            onLeftIconClick={onBackClick}
          >
            <FormField
              label="Status"
              error={dirtyFields.status ? errors.status : undefined}
            >
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      value={field.value || ""}
                      margin="dense"
                      variant="filled"
                      id="status"
                      type="text"
                      fullWidth
                      hiddenLabel
                      select
                      disabled={isEditMode}
                      size="small"
                    >
                      {statuses.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                }}
              />
            </FormField>
            <FormField
              label="Label"
              error={!!dirtyFields.label ? errors.label : undefined}
            >
              <TextField
                {...register("label")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                hiddenLabel
                size="small"
                variant="filled"
                fullWidth
              />
            </FormField>
            <FormField
              label="Color"
              fullWidth
              error={!!dirtyFields.status ? errors.status : undefined}
            >
              <Controller
                name="color"
                control={control}
                render={({ field }) => (
                  <ColorPicker {...field} arrow color={field.value} />
                )}
              />
            </FormField>
          </SidebarSection>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1.5}
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                border: "none",
                color: "#fff",
                background: (theme) => theme.palette.background.GF10,
              }}
              onClick={onBackClick}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                borderColor: "#fff",
                boxShadow: "none",
              }}
              fullWidth
            >
              Save Changes
            </Button>
          </Stack>
        </form>
      </Spin>
    </Box>
  );
};

export default AddStatusToInclude;
