import { zodResolver } from "@hookform/resolvers/zod";
import { EditOutlined, Logout, PasswordOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import ProfileUpload from "components/ProfileUploader/ProfileUploader";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import useCurrentUser from "hooks/useCurrentUser";
import useOpenClose from "hooks/useOpenClose";
import { debounce, uniqueId } from "lodash";
import { ApiModels } from "queries/apiModelMapping";
import useSignOut from "queries/auth/useSignOut";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect, useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import InfoList from "stories/CompoundComponent/AccountInfoCard/AccountInfoCard/AccountInfoCard";
import { z } from "zod";
import ChangePasswordModal from "./ChangePasswordModal";
import UpdateUserModal from "./UpdateUserModal";
const InfoListWrap = styled(InfoList)(({ theme }) => {
  return {
    ".MuiList-root": {
      padding: "12px 0 8px",
    },
    "li div": {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "4px",
      ".row-data-element": {
        marginLeft: "-12px",
      },
    },
  };
});

const formSchema = z.object({
  image: z.any(),
});

const RightSection = ({ onMenuEdit }: { onMenuEdit?: () => void }) => {
  const currentUser = useCurrentUser();
  const [userFormIsOpen, onUserFormOpen, onUseFormClose] = useOpenClose();
  const [
    openChangePasswordModal,
    onChangePasswordModalOpen,
    onChangePasswordModalClose,
  ] = useOpenClose();

  const { mutate: handleSignOut, isLoading } = useSignOut();
  const form = useForm<Partial<User>>({
    defaultValues: currentUser,
    resolver: zodResolver(formSchema),
  });
  const {
    // register,
    handleSubmit,
    control,
    // formState: { errors, dirtyFields },
    reset,
    watch,
  } = form;
  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);

  const { mutate: updateAccountUser } = useUpdateItem({
    modelName: ApiModels.AccountUser,
    mutationOptions: {
      mutationKey: [ApiModels.AccountUser],
    },
  });

  useEffect(() => {
    initialValueSet.current = false;
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && !initialValueSet.current) {
      // console.log("accountUser", accountUser);
      reset({ ...currentUser, tags: currentUser?.tags || [] });
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 1000);
    }
  }, [reset, currentUser]);

  const submitHandler = useCallback(
    (data: Partial<User>) => {
      if (currentUser && allowNetworkRequest.current) {
        updateAccountUser(
          { slug: currentUser.slug, data },
          {
            onSuccess: () => {
              console.log("AccountUser edit success");
            },
          }
        );
      }
    },
    [currentUser, updateAccountUser]
  );

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 600);
    const subscription = watch((_) => {
      submitDeb();
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  return (
    <React.Fragment>
      <Box>
        <FormProvider {...form}>
          <Box sx={{ marginTop: 2.5 }}>
            <Controller
              control={control}
              name="image"
              render={({ field }) => {
                return (
                  <ProfileUpload
                    image={field.value?.url}
                    onChange={(url, file) => {
                      field.onChange({
                        uid: uniqueId(),
                        url: url,
                        name: file?.name,
                        size: file?.size,
                        type: file?.type,
                      });
                    }}
                  />
                );
              }}
            />
          </Box>
          <SidebarSectionWrap
            leftIcon={false}
            rightIcon={<EditOutlined sx={{ width: "20px", height: "20px" }} />}
            onRightIconClick={onUserFormOpen}
            title={"General Information"}
          >
            <InfoListWrap
              title={currentUser?.first_name || ""}
              data={[
                // { icon: "first name", title: currentUser.first_name },
                { icon: "email", title: currentUser.email },
                { icon: "phone", title: currentUser.phone },
              ]}
            />
          </SidebarSectionWrap>
          <SidebarSectionWrap rightIcon={false} leftIcon={false}>
            <Box
              sx={{
                backgroundColor: "#2a292e",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                borderRadius: "5px",
              }}
              onClick={onChangePasswordModalOpen}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <PasswordOutlined /> Change Password
              </Box>
              <EditOutlined sx={{ width: "20px", height: "20px" }} />
            </Box>
          </SidebarSectionWrap>

          <LoadingButton
            color="error"
            variant="contained"
            fullWidth
            sx={{ marginTop: 4 }}
            onClick={() => handleSignOut()}
            loading={isLoading}
          >
            <Logout /> Logout
          </LoadingButton>
        </FormProvider>
      </Box>
      <UpdateUserModal open={userFormIsOpen} onClose={onUseFormClose} />
      <ChangePasswordModal
        open={openChangePasswordModal}
        onClose={onChangePasswordModalClose}
      />
    </React.Fragment>
  );
};

export default RightSection;
