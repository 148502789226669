import { zodResolver } from "@hookform/resolvers/zod";
import { Box, MenuItem, TextField, debounce } from "@mui/material";
import FormField from "components/FormField";
import { ApiModels } from "queries/apiModelMapping";
import React, { useCallback, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";

import { ArrowBackOutlined } from "@mui/icons-material";
import IconPickerField from "components/IconPicker";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { NativeMaterialIconNames } from "constants/index";
import { queryClient } from "queries";
import useListItems from "queries/useListItems";
import useUpdateItem from "queries/useUpdateItem";
import { z } from "zod";
const formSchema = z.object({
  label: z.string().min(1, "title is required"),
  icon: z.string(),
  gui_to_link_id: z.string().min(1, "gui is required"),
});

type AddAccountUserFormType = z.infer<typeof formSchema>;

type Props = { menuItem?: UserMenuItem; goBack?: () => void };
const icons = NativeMaterialIconNames.map((icon, idx) => ({
  id: "string",
  slug: icon,
  title: icon,
  svg: "string",
  native_ref: icon,
  tags: [],
  icon_type: "native",
  category_name: `native`,
  created_by: "string",
  created_at: "string",
  updated_at: "string",
  is_deleted: 0,
}));
const MenuItemEditor: React.FC<Props> = (props) => {
  const { goBack, menuItem } = props;
  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);

  const { data: guis } = useListItems({
    modelName: ApiModels.Gui,
    queryOptions: { enabled: false },
  });
  // const { data: menuItem } = useGetItem({
  //   modelName: ApiModels.UserMenuItem,
  //   slug: menuItemId as unknown as string,
  // });

  const form = useForm<AddAccountUserFormType>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: { label: "", gui_to_link_id: "", icon: "" },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    setValue,
    reset,
    watch,
  } = form;
  const { mutate: updateUserMenu, isLoading } = useUpdateItem({
    modelName: ApiModels.UserMenuItem,
    mutationOptions: { onSuccess: () => {} },
  });

  const submitHandler = useCallback(
    (data: AddAccountUserFormType) => {
      updateUserMenu(
        { slug: menuItem?.id as unknown as string, data: data },
        {
          onSuccess: () => {
            console.log("inner success");
            queryClient.invalidateQueries([
              menuItem?.parent_menu,
              ApiModels.UserMenuItem,
            ]);
          },
        }
      );
    },
    [menuItem, updateUserMenu]
  );

  useEffect(() => {
    initialValueSet.current = false;
  }, [menuItem?.id]);
  useEffect(() => {
    if (menuItem && !initialValueSet.current) {
      reset(menuItem);
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 1000);
    }
  }, [reset, menuItem]);

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 600);
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "gui_to_link_id":
        case "label":
        case "icon":
          submitDeb();
          return;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);
  console.log({ errors });
  return (
    <SidebarSectionWrap
      leftIcon={<ArrowBackOutlined />}
      onLeftIconClick={goBack}
      rightIcon={false}
    >
      <Box component="form">
        <FormField
          label="Title"
          error={dirtyFields.label ? errors.label : undefined}
        >
          <TextField
            {...register("label")}
            autoFocus
            margin="dense"
            fullWidth
            placeholder="0"
            hiddenLabel
            size="small"
            variant="filled"
            id="label"
          />
        </FormField>
        <FormField
          label="Dashboard Item"
          error={dirtyFields.gui_to_link_id ? errors.gui_to_link_id : undefined}
        >
          <Controller
            name="gui_to_link_id"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  margin="dense"
                  fullWidth
                  placeholder="0"
                  hiddenLabel
                  size="small"
                  variant="filled"
                  select
                  onChange={
                    ((e: any, { props }: any) => {
                      field.onChange(e);
                      setValue("icon", props?.itemProp);
                    }) as any
                  }
                >
                  {guis?.map((gui) => {
                    return (
                      <MenuItem
                        value={gui.slug}
                        key={gui.slug}
                        itemProp={gui.icon}
                      >
                        {gui.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              );
            }}
          />
        </FormField>
        <FormField label="Icon" error={errors.icon}>
          <Controller
            name="icon"
            control={control}
            render={({ field }) => (
              <IconPickerField {...field} icons={icons} compact={true} />
            )}
          />
        </FormField>
      </Box>
    </SidebarSectionWrap>
  );
};

export default MenuItemEditor;
