import { Settings } from "@mui/icons-material";
import { Box, DrawerProps, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import GfDrawer from "components/GfDrawer";
import Scrollbar from "components/Scrollbar";
import { GuiType } from "enums/gui";
import AnimationLayout, { Config } from "layouts/AnimationLayout";
import GuiModel from "models/Gui";
import { ApiModels } from "queries/apiModelMapping";
import React, { useCallback, useState } from "react";
import { v4 } from "uuid";
import FieldsSelection from "views/app-view/gui-dataset/components/FieldsSelection";
import RecordFilterRules from "views/app-view/gui-dataset/components/WidgetRules";
import AddStatusToInclude, {
  AddStatusFormType,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/AddStatusToInclude";
import FilterRuleEditor, {
  AddFilterRuleFormType,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/FilterRuleEditor";
import FusionActionEditor, {
  FusionActionForm,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/FusionActionEditor";
import TabEditorFields from "views/app-view/gui-workspace/components/TabEditorDrawer/TabEditorFields";

const DrawerBox = styled(GfDrawer)(({ theme }) => ({
  ".MuiDrawer-paper ": {
    ".MuiPaper-root ": {
      background: `${theme.palette.background.GFTopNav} !important`,
      boxShadow: "none",

      ".MuiCard-root": {
        background: `${theme.palette.background.GFRightNavForeground} !important`,
        transition: "all 0.4s ease",

        "&:hover": {
          background: `${theme.palette.background.GF20} !important`,
        },
      },
    },

    ".drawer-head": {
      background: theme.palette.background.GFTopNav,
      padding: "15px 20px",
      borderBottom: `1px solid ${theme.palette.other?.divider}`,

      ".drawer-icon-holder": {
        width: "24px",
        height: "24px",
        background: "none",
      },

      ".drawer-title-holder": {
        padding: "0 0 0 11px",
      },
    },

    ".draggable-handle": {
      padding: "0",
    },
  },
}));

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",
  };
});

type TabEditDrawerProps = {
  tab?: GuiTab;
  gui?: GfGui;
  onSubmit(tab: Partial<GuiTab>): Promise<void>;
  onEditDatalistSettings(): void;
} & Omit<DrawerProps, "onSubmit">;

const TabEditDrawer: React.FC<TabEditDrawerProps> = (props) => {
  const { tab, gui, open, onClose, onSubmit, onEditDatalistSettings } = props;

  const queryClient = useQueryClient();
  const [selectedStatus, setSelectedStatus] = useState<WorkflowStatus>();
  const [selectedFilterRule, setSelectedFilterRule] = useState<FilterRule>();
  const [selectedFusionAction, setSelectedFusionAction] =
    useState<FusionAction>();

  const onUpdateTabStatus = useCallback(
    (status: AddStatusFormType) => {
      if (tab?.tab_type === "workflow_board") {
        if (!selectedStatus) {
          onSubmit({
            ...tab,
            statuses_to_include: [
              ...(tab.statuses_to_include || []),
              { ...status, id: v4() },
            ],
          });
        } else {
          onSubmit({
            ...tab,
            statuses_to_include:
              tab.statuses_to_include?.map((s) =>
                s.id === selectedStatus.id ? { ...s, ...status } : s
              ) || [],
          });
          setSelectedStatus(undefined);
        }
      }
    },
    [onSubmit, selectedStatus, tab]
  );

  const onUpdateTabFilterRule = useCallback(
    (rule: AddFilterRuleFormType) => {
      if (tab?.tab_type === "workflow_board") {
        if (!selectedFilterRule) {
          onSubmit({
            ...tab,
            filter_rules: [...(tab.filter_rules || []), { ...rule, id: v4() }],
          });
        } else {
          onSubmit({
            ...tab,
            filter_rules:
              tab.filter_rules?.map((r) =>
                r.id === selectedFilterRule.id ? { ...r, ...rule } : r
              ) || [],
          });
          setSelectedStatus(undefined);
        }
      }
    },
    [onSubmit, selectedFilterRule, tab]
  );

  const handleFusionActionSubmit = useCallback(
    async (values: FusionActionForm) => {
      return new Promise<void>((resolve, reject) => {
        if (tab?.tab_type === GuiType.Reviewer) {
          if (!selectedFusionAction) {
            if (gui?.slug) {
              GuiModel.createReviewerAction(gui?.slug, {
                action_title: values.action_title,
                action_icon: values.action_icon,
                tab_name: tab.tab_name,
              })
                .then(() => {
                  queryClient.refetchQueries([ApiModels.Gui, gui.slug]);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          } else {
            onSubmit({
              ...tab,
              associated_actions:
                tab.associated_actions?.map((action) =>
                  action.id === selectedFusionAction.id
                    ? { ...action, ...values }
                    : action
                ) || [],
            })
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          }
        } else if (tab?.tab_type === GuiType.RecordList) {
          if (!selectedFusionAction) {
            if (gui?.slug) {
              GuiModel.createDatasetAction(gui?.slug, {
                action_title: values.action_title,
                action_icon: values.action_icon,
                tab_name: tab.tab_name,
              })
                .then(() => {
                  queryClient.refetchQueries([ApiModels.Gui, gui.slug]);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          } else {
            onSubmit({
              ...tab,
              associated_actions:
                tab.associated_actions?.map((action) =>
                  action.id === selectedFusionAction.id
                    ? { ...action, ...values }
                    : action
                ) || [],
            })
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          }
        }
      });
    },
    [gui?.slug, onSubmit, queryClient, selectedFusionAction, tab]
  );

  const getComponents: Config["getComponents"] = useCallback(
    (gotoComponent, goBack) => {
      return {
        main: (
          <TabEditorFields
            tab={tab!}
            viewFilters={gui?.filter_settings.view_filters?.[tab!.id] ?? []}
            onClose={() => onClose?.({}, "backdropClick")}
            onSubmit={(values) => {
              onSubmit(values);
              onClose?.({}, "backdropClick");
            }}
            onEditDatalistSettings={onEditDatalistSettings}
            onAddStatusClick={() => {
              gotoComponent({
                id: "status-to-include-editor",
                name: "status-to-include-editor",
              });
            }}
            onEditStatusClick={(item) => {
              setSelectedStatus(item);
              gotoComponent({
                id: item.id,
                name: "status-to-include-editor",
              });
            }}
            onAddFilterRuleClick={() => {
              gotoComponent({
                id: "filter-rule-editor",
                name: "filter-rule-editor",
              });
            }}
            onEditFilterRuleClick={(item) => {
              setSelectedFilterRule(item);
              gotoComponent({
                id: item.id,
                name: "filter-rule-editor",
              });
            }}
            onEditFieldToInclude={() => {
              gotoComponent({
                name: "field-selection-form",
                id: "field-selection-form",
              });
            }}
            onAddActionClick={() => {
              setSelectedFusionAction(undefined);
              gotoComponent({
                name: "reviewer-action-editor",
                id: "reviewer-action-editor",
              });
            }}
            onEditActionClick={(action) => {
              setSelectedFusionAction(action);
              gotoComponent({
                id: action.id,
                name: "reviewer-action-editor",
              });
            }}
            onEditFilters={() => {
              gotoComponent({
                name: "rules-editor",
                id: "rules-editor",
              });
            }}
            // onAddParam={() => {
            //   gotoComponent({
            //     id: "add-param",
            //     name: "add-param",
            //   });
            // }}
            // onEditParam={(props: GuiParams) => {
            //   searchParams.set("paramSlug", props.slug);
            //   setSearchParams(searchParams);
            //   setTimeout(() => {
            //     gotoComponent({
            //       id: "edit-param",
            //       name: "edit-param",
            //     });
            //   }, 0);
            // }}
          />
        ),
        "status-to-include-editor": (
          <AddStatusToInclude
            datasetDesignSlug={
              tab?.tab_type === "workflow_board" ? tab?.dataset_design_slug : ""
            }
            statusFieldSlug={
              tab?.tab_type === "workflow_board" ? tab?.status_field : ""
            }
            onBackClick={() => {
              goBack();
              setSelectedStatus(undefined);
            }}
            status={selectedStatus}
            onSubmit={(values) => {
              goBack();
              onUpdateTabStatus(values);
            }}
          />
        ),
        "filter-rule-editor": (
          <FilterRuleEditor
            onBackClick={() => {
              goBack();
              setSelectedFilterRule(undefined);
            }}
            rule={selectedFilterRule}
            onSubmit={(values) => {
              goBack();
              onUpdateTabFilterRule(values);
            }}
          />
        ),
        "field-selection-form": gui ? (
          <Box sx={{ p: 2 }}>
            <FieldsSelection
              gui={gui}
              indexKey="form_fields"
              onClickBack={() => goBack()}
            />
          </Box>
        ) : (
          <></>
        ),
        "reviewer-action-editor": (
          <FusionActionEditor
            action={selectedFusionAction}
            onSubmit={async (values) => {
              await handleFusionActionSubmit(values).then(() => {
                goBack();
                setSelectedFusionAction(undefined);
              });
            }}
            onCancel={() => {
              goBack();
              setSelectedFusionAction(undefined);
            }}
          />
        ),
        "rules-editor": (
          <Box sx={{ p: 2 }}>
            <RecordFilterRules
              gui={gui!}
              guiTab={tab!}
              onClickBack={() => goBack()}
            />
          </Box>
        ),
      };
    },
    [
      gui,
      handleFusionActionSubmit,
      onClose,
      onEditDatalistSettings,
      onSubmit,
      onUpdateTabFilterRule,
      onUpdateTabStatus,
      selectedFilterRule,
      selectedFusionAction,
      selectedStatus,
      tab,
    ]
  );
  return (
    <DrawerBox
      anchor={"right"}
      open={open}
      width="420px"
      title="Tab Settings"
      icon={<Settings />}
      onClose={onClose}
      closeIcon={false}
    >
      <ScrollbarParent>
        <Scrollbar>
          {tab && (
            <AnimationLayout
              urlQueryKey="s"
              enableScrollbar={false}
              config={{
                initialComponent: "main",
                getComponents: getComponents,
              }}
            />
          )}
        </Scrollbar>
      </ScrollbarParent>
    </DrawerBox>
  );
};

export default TabEditDrawer;
