import { useQuery } from "@tanstack/react-query";
import UserMenuItem from "models/UserMenuItem";
import { ApiModels } from "queries/apiModelMapping";
import useAccountSlug from "./useAccountSlug";

const useCurrentUserMenu = () => {
  const slug = useAccountSlug();
  const { data: userMenu } = useQuery({
    queryKey: [ApiModels, "current-user", slug],
    queryFn: async () => {
      const res = await UserMenuItem.getUseMenu();
      return res.data;
    },
    onSuccess: (res) => {
      // console.log("data-re", res);
    },
  });
  return userMenu;
};

export default useCurrentUserMenu;
