import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";

function BaseRadioList({
  options = [],
  disabled,
  ...rest
}: RadioGroupProps & {
  options?: { label: string; value: string }[];
  disabled?: boolean;
}) {
  return (
    <RadioGroup {...rest}>
      {options.map((opt) => {
        return (
          <FormControlLabel
            value={opt.value}
            control={<Radio disabled={disabled} />}
            label={opt.label}
          />
        );
      })}
    </RadioGroup>
  );
}

export default BaseRadioList;
