import React from "react";
import { useLocation } from "react-router-dom";
import { pathToModule } from "utils/gaurd";

const useRouteModule = () => {
  const location = useLocation();

  const routeModule = React.useMemo(() => {
    return pathToModule(location.pathname);
  }, [location.pathname]);

  return routeModule;
};

export default useRouteModule;
