import { Close, Search } from "@mui/icons-material";
import { Chip, Stack, TextField, styled } from "@mui/material";
import useAppNavigate from "hooks/useAppNavigate";
import React, { useEffect, useState } from "react";
import { useListingLayout } from "store/stores/listingLayout";
import { getSearchParams } from "utils";

const TagsArea = styled(Stack)(({ theme }) => ({
  alignItems: "center",

  ".MuiChip-root": {
    background: theme.palette.background.cardsBorder,
    padding: "0 9px",
    borderRadius: "4px",
    margin: "2px 8px 2px 0",
    height: "24px",

    ".MuiChip-label": {
      fontSize: "12px",
      lineHeight: "24px",
      padding: "0 12px 0 0",
    },

    ".MuiChip-deleteIcon": {
      width: "16px",
      height: "auto",
      margin: "0",
      color: theme.palette.text.primary,
    },
  },

  ".result-counter": {
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.palette.background.GF80,
    padding: "0 0 0 11px",
  },
}));

type SearchBarProps = {};

const SearchBar: React.FC<SearchBarProps> = () => {
  const navigate = useAppNavigate();

  const count = useListingLayout.useCount();
  const searchTags = useListingLayout.useSearchTags();
  const setSearchTags = useListingLayout.useSetSearchTags();
  const addSearchTag = useListingLayout.useAddSearchTag();
  const removeSearchTag = useListingLayout.useRemoveSearchTag();

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const searchParams = getSearchParams();
    const tags = searchParams.get("q");
    if (tags) {
      setSearchTags(tags.split(",").map((tag) => tag.trim()));
    }
  }, []);

  const handleAddTag = (tag: string) => {
    if (searchTags.includes(tag)) {
      return;
    }

    setSearchInput("");
    addSearchTag(tag);
    const searchParams = getSearchParams();
    searchParams.set("q", searchTags.concat(tag.trim()).join(","));

    navigate({
      search: `?${searchParams.toString()}`,
    });
  };

  const handleRemoveTag = (tag: string) => {
    removeSearchTag(tag);
    const searchParams = getSearchParams();
    const filteredKeywords = searchTags.filter((keyword) => keyword !== tag);

    if (filteredKeywords.length === 0) {
      searchParams.delete("q");
    } else {
      searchParams.set("q", filteredKeywords.join(","));
    }

    navigate({
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <>
      <TextField
        margin="dense"
        type="text"
        hiddenLabel
        size="small"
        variant="filled"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        InputProps={{
          endAdornment: <Search fontSize="small" />,
        }}
        sx={{
          margin: 0,
          ".MuiInputBase-root": {
            minHeight: "11px",
          },
          ".MuiInputBase-input": { height: "11px" },
        }}
        placeholder="Search"
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleAddTag(searchInput);
          }
        }}
      />
      <TagsArea direction={"row"}>
        {searchTags.map((v) => (
          <Chip
            key={v}
            label={v}
            onDelete={() => handleRemoveTag(v)}
            deleteIcon={<Close />}
          />
        ))}
        {searchTags.length > 0 ? (
          <span className="result-counter">Found {count}</span>
        ) : null}
      </TagsArea>
    </>
  );
};

export default SearchBar;
