import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import FusionModel from "models/Fusion";

const fetchFusionsByWebhook = async (context: QueryFunctionContext) => {
  const [, webhookSlug] = context.queryKey;
  const res = await FusionModel.listFusionByWebhook(webhookSlug as string);
  return res.data;
};

const useListFusionsByWebhook = (webhookSlug?: string) => {
  return useQuery(["fusions-by-webhook", webhookSlug], fetchFusionsByWebhook, {
    enabled: !!webhookSlug,
  });
};

export default useListFusionsByWebhook;
