import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Copyright } from "layouts/SplashLayout";
import useSignIn from "queries/auth/useSignIn";
import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function SignInSide() {
  const { mutate: handleSign, isLoading } = useSignIn();
  const [error, setError] = React.useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    handleSign(
      {
        email: data.get("email") as string,
        password: data.get("password") as string,
      },
      {
        onError: (err: any) => {
          if (err.code === "UserNotConfirmedException") {
            navigate("/verify");
          } else {
            setError(err.message);
          }
        },
        onSuccess: () => {
          setTimeout(() => {
            navigate(from || "/", { replace: true });
          }, 100);
        },
      }
    );
  };

  return (
    <Box>
      <Box textAlign={"center"}>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main", ml: "45%" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {/* <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        /> */}
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={isLoading}
        >
          Sign In
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link
              to="/forget-password"
              style={{ textDecorationColor: "white", color: "white" }}
            >
              <Typography>Forgot password?</Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link
              to="/signup"
              style={{ textDecorationColor: "white", color: "white" }}
              state={{ from }}
            >
              <Typography>{"Don't have an account? Sign Up"}</Typography>
            </Link>
          </Grid>
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Box>
  );
}
