import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import useRouteToApiModel from "hooks/useRouteToApiModel";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

type AddFolderModelProps = {
  open: boolean;
  parentFolderId?: string;
  onClose(): void;
};

const AddFolderModel: React.FC<AddFolderModelProps> = (props) => {
  const { parentFolderId, open, onClose } = props;

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<Folder>({
    mode: "onBlur",
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, "Name is required"),
      })
    ),
    defaultValues: {},
  });

  const routeModel = useRouteToApiModel();
  const { mutate: createFolder } = useCreateItem({
    modelName: ApiModels.Folder,
    queryKey: [ApiModels.Folder, routeModel],
  });

  const closeHandler = () => {
    onClose();
    reset();
  };
  const submitHandler = (data: Folder) => {
    createFolder(
      {
        ...data,
        resource: routeModel,
        sort_order: 10000000000,
        parent_folder_id: parentFolderId || null,
      },
      {
        onSuccess: () => {
          closeHandler();
        },
      }
    );
  };
  return (
    <Dialog open={open} onClose={onClose} disableEscapeKeyDown scroll="body">
      <DialogTitle>Add Folder</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <Box component="form">
            <FormField
              label="Folder Name"
              error={dirtyFields.name ? errors.name : undefined}
            >
              <TextField
                {...register("name")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
              />
            </FormField>
          </Box>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <Button onClick={closeHandler}>Cancel</Button>
        <Button onClick={handleSubmit(submitHandler)} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFolderModel;
