import Model from "models";
import qs from "qs";

class DatasetModel extends Model<Dataset> {
  constructor() {
    super("/dataset", "data-mng");
  }
  async list<ReturnType = Dataset>(options?: {
    query?: Record<string, any>;
    path?: string;
  }) {
    const cursor = options?.query?.cursor;
    if (options?.query?.cursor && typeof cursor === "object") {
      options.query.cursor = JSON.stringify(options.query.cursor);
    }
    return await this.sendRequest<ReturnType[]>(
      `${options?.path ? `/${options.path}` : ""}${
        options?.query ? `?${qs.stringify(options.query)}` : ``
      }`,
      "GET"
    ).then((res) => {
      (res.data as unknown as Dataset[])?.forEach((dataset) => {
        dataset.slug = `${dataset.id}`;
      });
      return res;
    });
  }

  async get<ReturnType = Dataset>(
    slug: string,
    options?: { query?: Record<string, any>; path?: string }
  ) {
    return await this.sendRequest<ReturnType>(
      `${options?.path ? `/${options.path}` : ""}/${slug}${
        options?.query ? `?${qs.stringify(options.query)}` : ``
      }`,
      "GET"
    ).then((res) => {
      if ((res.data as Dataset).id != null)
        (res.data as Dataset).slug = `${(res.data as Dataset).id}`;
      return res;
    });
  }

  async getWorkflowStatusOptions(
    datasetDesignSlug: string,
    statusFieldSlug: string
  ) {
    return await this.sendRequest<LabeledValue[]>(
      `/workflow-status-options/${datasetDesignSlug}/${statusFieldSlug}`,
      "GET"
    );
  }

  async processImage(data: any) {
    return await this.sendRequest<LabeledValue[]>(
      `/process/image`,
      "POST",
      data
    );
  }
}

const model = new DatasetModel();
export default model;
