import {
  Box,
  BoxProps,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  SxProps,
} from "@mui/material";
import React, { PropsWithChildren, useState } from "react";

type ActionMenuProps = {
  menuItems?: {
    label: React.ReactNode;
    value: string;
    icon?: React.ReactNode;
    iconContainerProps?: BoxProps;
  }[];
  containerSx?: SxProps;
  onItemClick?(value: string): void;
} & Omit<MenuProps, "open">;

const ActionMenu: React.FC<PropsWithChildren<ActionMenuProps>> = (props) => {
  const {
    menuItems = [],
    children,
    onItemClick,
    containerSx,
    ...menuProps
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMoreClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box
      className="action-menu"
      onClick={(e) => {
        e.stopPropagation();
        handleMoreClick(e);
      }}
      sx={containerSx}
    >
      {children}
      {menuItems.length > 0 ? (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          {...menuProps}
        >
          {menuItems.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={(e) => {
                onItemClick?.(item.value);
                e.stopPropagation();
                setAnchorEl(null);
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={"13px"}
                className="action-menu__item"
              >
                <Box className="action-menu__icon" {...item.iconContainerProps}>
                  {item.icon}
                </Box>
                <Box className="action-menu__label">{item.label}</Box>
              </Stack>
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </Box>
  );
};

export default ActionMenu;
