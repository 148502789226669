import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import React from "react";
import { useForm } from "react-hook-form";

import { queryClient } from "queries";
import useListItems from "queries/useListItems";
import { useParams } from "react-router-dom";
import { z } from "zod";

const formSchema = z.object({
  template_name: z.string().min(1, "title is required"),
});

type AddAccountUserFormType = z.infer<typeof formSchema>;

type Props = { templateId: string } & Omit<DialogProps, "onSubmit">;

const SaveAsMenuTemplate: React.FC<Props> = (props) => {
  const { onClose, templateId, ...dialogProps } = props;
  const { slug: accountUserSlug } = useParams<{ slug: string }>();

  const { data: guis } = useListItems({
    modelName: ApiModels.Gui,
    queryOptions: { enabled: false },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    reset,
  } = useForm<AddAccountUserFormType>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const { mutate: createUserMenuTemplate, isLoading } = useCreateItem({
    modelName: ApiModels.UserMenuTemplate,
  });

  const submitHandler = (data: AddAccountUserFormType) => {
    createUserMenuTemplate({ template_id: templateId, ...data } as any, {
      onSuccess: () => {
        console.log("inner success");
        queryClient.refetchQueries([
          ApiModels.UserMenuTemplate,
          accountUserSlug,
        ]);
        (onClose as any)?.();
        reset();
      },
    });
  };

  return (
    <Dialog
      onClose={onClose}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Save As Template</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <Box component="form">
            <FormField
              label="Title"
              error={
                dirtyFields.template_name ? errors.template_name : undefined
              }
            >
              <TextField
                {...register("template_name")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
              />
            </FormField>
          </Box>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <LoadingButton onClick={onClose as any}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler)}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SaveAsMenuTemplate;
