import { Box, Stack, styled, Typography } from "@mui/material";
import { DynamicEditFormProps } from "components/Form/DynamicEditForm";
import InnerPageLayout from "layouts/inner-app-layout";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import React, { useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDatasetStore } from "store/stores/dataset";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import ChatView from "views/app-view/gui-dataset/components/ChatView";
import IFrameView from "./components/IFrameView";
import EditDataset from "./EditDateset";
import EditDatasetExtended from "./EditDatesetExtended";

type Props = {
  onFormEvent?: DynamicEditFormProps["onFormEvent"];
  data?: any;
};

const InnerAppLayout = styled(Box)(({ theme }) => {
  return {
    ".heading-card": {
      padding: "0",
    },
  };
});

const EditBox = styled(Box)(({ theme }) => {
  return {
    height: "100%",

    [`${theme.breakpoints.down("sm")}`]: {
      padding: "11px 15px",
    },

    ".MuiTabs-root": {
      position: "fixed",
      left: "269px",
      top: "60px",
      right: "0",
      padding: "0 32px",
      background: "#1F2125",
      borderBottom: `1px solid rgba(53, 55, 58, 1)`,
      height: "60px",
      zIndex: "9",

      ".MuiTabs-flexContainer": {
        height: "100%",
      },

      ".MuiTabs-indicator": {
        height: "2px",
        background: "#fff",
      },
    },
  };
});

export const BoxHeader = styled(Stack)(({ theme }) => ({
  gap: "24px",
  marginBottom: "48px",

  [`${theme.breakpoints.down("sm")}`]: {
    gap: "8px",
    margin: "-11px -15px 20px",
    padding: "11px 15px 11px",
    background: theme.palette.common.blackshades["30p"],
    borderBottom: `1px solid ${theme.palette.background.GFOutlineNav}`,
  },
}));

const TabStyle = ({ title }: { title: string }) => {
  // const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      <Typography className="tab-text" sx={{ color: "#fff" }}>
        {title}
      </Typography>
      {/* <Typography
        sx={{ color: theme.palette.background.GF40 }}
        className="counter"
      >
        4
      </Typography> */}
    </Stack>
  );
};

const MiddleComponent: React.FC<Props> = (props) => {
  const { onFormEvent, data } = props;
  const [searchParams] = useSearchParams();

  const { datasetSlug, slug: guiSlug } =
    useParams<{
      slug: string;
      datasetSlug?: string;
    }>();

  const { data: gui } = useGetItem({
    modelName: ApiModels.Gui,
    slug: guiSlug,
    queryOptions: { enabled: !!guiSlug },
  });

  const selectedGuiTab = useGuiDashboardStore.useSelectedTab();

  const guiTab = useMemo(() => {
    if (selectedGuiTab != null && gui?.tabs) {
      return gui?.tabs?.[selectedGuiTab];
    }
  }, [gui?.tabs, selectedGuiTab]);
  const hideGeneralTab = guiTab?.hide_general_tab;
  const isGuiTabRecordList = guiTab?.tab_type === "record_list";
  const datasetDesignSlug = isGuiTabRecordList
    ? guiTab.dataset_design_slug
    : undefined;

  const [value, setValue] = useState(Number(searchParams.get("t")) || 0);
  const popDatasetDraft = useDatasetStore.usePopDatasetDraft();

  const additionTabs = useMemo(
    () =>
      guiTab?.tab_type === "record_list" ? guiTab?.included_tabs : undefined,
    [guiTab]
  );
  const tabLists = useMemo(() => {
    const tabs = [];

    if (!hideGeneralTab) {
      tabs.push({
        label: <TabStyle title="General" />,
        value: 0,
      });
    }
    if (additionTabs)
      additionTabs.forEach((tab, index) => {
        tabs.push({
          label: <TabStyle title={tab.name} />,
          value: index + Number(!hideGeneralTab),
        });
      });
    return tabs;
  }, [additionTabs, hideGeneralTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    popDatasetDraft();
  };

  const includedFieldIds = isGuiTabRecordList ? guiTab?.form_fields || [] : [];
  const selectedTab = additionTabs?.[value - Number(!hideGeneralTab)];
  const tabKey = (!hideGeneralTab && value !== 0) || hideGeneralTab;
  return (
    <EditBox
      id="middle-component"
      sx={{
        padding:
          selectedTab?.tab_type === "iframe"
            ? "64px 0px 0px 0px"
            : "64px 29px 20px 29px",
      }}
    >
      <InnerAppLayout
        sx={{
          height: "100%",
        }}
      >
        <InnerPageLayout
          sx={{
            height: "100%",
          }}
          centerBoxSx={{
            paddingTop: selectedTab?.tab_type === "iframe" ? "0px" : undefined,
          }}
          hideHeader={true}
          onChange={handleChange}
          tabList={tabLists}
          value={value}
        >
          {!hideGeneralTab && value === 0 && (
            <EditDataset
              onFormEvent={onFormEvent}
              data={data}
              datasetDesignSlug={datasetDesignSlug}
              datasetSlug={datasetSlug}
              guiSlug={guiSlug!}
              includedFieldIds={includedFieldIds}
              formMetadata={{
                field_metadata:
                  guiTab?.tab_type === "record_list"
                    ? guiTab.field_metadata
                    : {},
              }}
            />
          )}
          {tabKey && selectedTab?.tab_type === "iframe" && (
            <IFrameView
              selectedTabData={selectedTab}
              datasetSlug={datasetSlug}
              datasetDesignSlug={datasetDesignSlug}
            />
          )}
          {tabKey && selectedTab?.record_type === "chat_view" && (
            <ChatView
              selectedTabData={selectedTab}
              datasetSlug={datasetSlug}
              datasetDesignSlug={datasetDesignSlug}
            />
          )}
          {tabKey &&
            selectedTab?.record_type !== "chat_view" &&
            selectedTab?.tab_type !== "iframe" && (
              <EditDatasetExtended
                onFormEvent={onFormEvent}
                data={data}
                datasetDesignSlug={datasetDesignSlug}
                datasetSlug={datasetSlug}
                guiSlug={guiSlug!}
                selectedTabData={selectedTab}
                key={value}
              />
            )}
        </InnerPageLayout>
      </InnerAppLayout>
    </EditBox>
  );
};

export default MiddleComponent;
