import IFrame from "components/IFrame";
import Spin from "components/Spin";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import { FC, useMemo, useState } from "react";
import { replaceShortCodes } from "utils/index";

type IFrameViewProps = {
  selectedTabData?: IncludeTabs;
  datasetSlug?: string;
  datasetDesignSlug?: string;
};

const IFrameView: FC<IFrameViewProps> = (props) => {
  const { datasetSlug, datasetDesignSlug, selectedTabData } = props;

  const [loading, setLoading] = useState(true);

  const { data: dataset } = useGetItem({
    modelName: ApiModels.Dataset,
    slug: datasetSlug,
    requestOptions: { path: datasetDesignSlug },
    queryKey: [ApiModels.Dataset, { datasetDesignSlug, datasetSlug }],
  });

  const url = useMemo(() => {
    if (!selectedTabData?.iframe_url) {
      return "";
    }

    const shortCodeData = { ...dataset, ...(dataset?.fields ?? {}) };

    return replaceShortCodes(selectedTabData.iframe_url, shortCodeData);
  }, [selectedTabData?.iframe_url, dataset]);

  return (
    <Spin sx={{ height: "100%" }} spinning={loading}>
      <IFrame
        url={url}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </Spin>
  );
};

export default IFrameView;
