import { Box, DialogProps } from "@mui/material";

import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { PhoneNumberRegex } from "constants/index";
import useCurrentUser from "hooks/useCurrentUser";
import AccountUser from "models/AccountUser";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useStore } from "store";
import { z } from "zod";
import UserForm from "./UserForm";

const formSchema = z.object({
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  //   email: z.string().email("Please enter valid email"),
  phone: z
    .string()
    .regex(PhoneNumberRegex, "Please enter a valid phone number"),
  image: z.any().optional(),
  mailing_address: z
    .object({
      address1: z.string().optional(),
      address2: z.string().optional(),
      city: z.string().optional(),
      state: z.string().optional(),
      zip: z.string().optional(),
    })
    .optional(),
});
export type FormType = z.infer<typeof formSchema>;
export type Props = {} & DialogProps;

const UpdateUserModal = ({ onClose, ...dialogProps }: Props) => {
  const currentUser = useCurrentUser();
  const setCurrentUser = useStore.useSetUser();

  const { mutate: updateMe, isLoading } = useMutation({
    mutationFn: async (data: Partial<FormType>) => {
      await AccountUser.updateMe(data as User);
    },
    onSuccess: (_) => {
      console.log("success");
    },
  });

  const form = useForm<Partial<FormType>>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: currentUser,
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const submitHandler = useCallback(
    (data: Partial<FormType>) => {
      updateMe(data, {
        onSuccess: () => {
          const updatedUser = { ...currentUser, ...data };
          setCurrentUser(updatedUser);
          handleClose();
        },
      });
    },
    [updateMe]
  );

  const handleClose = () => {
    onClose?.({}, "backdropClick");
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Invite User</DialogTitle>
      <Box component="form" sx={{ padding: "40px" }}>
        <UserForm register={register} errors={errors} control={control} />
      </Box>
      <DialogActions>
        <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler)}
          variant="contained"
          loading={isLoading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserModal;
