import { Box, Typography, styled } from "@mui/material";
import { Stack } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import Scrollbar from "components/Scrollbar";
import SimpleTable, { Column } from "components/SimpleTable";
import Spin from "components/Spin";
import dayjs from "dayjs";
import InnerPageLayout from "layouts/inner-app-layout";
import TransationHistroyModal from "models/TransationHistroy";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useStore } from "store";
import Rightbar from "./Rightbar";
const RightSideBox = styled(Box)(({ theme }) => ({
  width: "420px",
  height: "calc(100vh - 60px)",
  background: theme.palette.background.GFRightNavBackground,

  ".side-menu-active &": {
    height: "100vh",
  },
}));
const CenterBox = styled(Box)(({ theme }) => ({
  flexGrow: "1",
  flexBasis: "0",
  minWidth: "0",
  height: "calc(100vh - 60px)",

  ".side-menu-active &": {
    height: "100vh",
  },
}));

type Props = {};

const headCells: Column<TransactionHistory>[] = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Date / Time",
    render: (value) => {
      if (value) return dayjs(value).format("MMM DD, YYYY h:mma");
      return <div>invalid date</div>;
    },
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "Credit / Debit",
    render: (_, record) => {
      return record.credited ? `+ ${record.credited}` : record.debited;
    },
  },
];

const TabStyle = ({ title }: { title: string }) => {
  // const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      <Typography className="tab-text" sx={{ color: "#fff" }}>
        {title}
      </Typography>
    </Stack>
  );
};
const Index = (props: Props) => {
  const selectedAccount = useStore.useSelectedAccount?.();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get("page")) || 1;
  const order = searchParams.get("order");
  const orderBy = searchParams.get("orderBy");

  const [totalPages, setTotalPages] = useState(1);
  const { data: creditTypes, isLoading: isCreditLoading } = useListItems({
    modelName: ApiModels.CreditType,
    requestOptions: {
      query: {
        package_slug: selectedAccount?.account_package_id,
      },
    },
    queryOptions: {
      enabled: !!selectedAccount?.account_package_id,
    },
  });
  const [selectedTab, setSeletecTab] = useState("all");
  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const res = await TransationHistroyModal.list({
        query: {
          credit_type_id: selectedTab !== "all" ? selectedTab : undefined,
          limit: 10,
          offset: 10 * currentPage,
          sorted_by: orderBy,
          sorted_order: order,
        },
      });

      return res as unknown as {
        data: TransactionHistory[];
        totalPages: number;
      };
    },
    onSuccess(data) {
      setTotalPages(data.totalPages);
      return data.data;
    },
    queryKey: [
      ApiModels.TransationHistory,
      selectedTab,
      currentPage,
      orderBy,
      order,
    ],
  });

  const tabList = useMemo(() => {
    return [
      {
        label: <TabStyle title="Transaction History" />,
        value: "all",
      },
    ].concat(
      creditTypes?.map((item) => {
        return {
          label: <TabStyle title={item.name} />,
          value: item.slug,
        };
      }) || []
    );
  }, [creditTypes]);
  return (
    <Stack direction="row">
      <CenterBox>
        <Scrollbar height={500}>
          <InnerPageLayout
            sx={{ padding: "40px" }}
            tabList={tabList as any}
            title={"Billing Settings"}
            onChange={(_, value) => {
              setSeletecTab(value);
            }}
            value={selectedTab}
          >
            <Spin
              spinning={isLoading}
              sx={{
                ".MuiStack-root": { background: "transparent !important" },
              }}
            >
              <SimpleTable
                columns={headCells}
                data={data?.data || []}
                compact
                // order={order as any}
                // orderBy={orderBy as any}
                pagination={{
                  count: totalPages || 1,
                  page: currentPage,
                  onChange: (e, page) => {
                    setSearchParams({ page: page?.toString() });
                  },
                }}
              />
            </Spin>
          </InnerPageLayout>
        </Scrollbar>
      </CenterBox>
      {/* {xlScreen && ( */}
      <RightSideBox>
        <Rightbar />
      </RightSideBox>
      {/* )} */}
    </Stack>
  );
};

export default Index;
