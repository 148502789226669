import { GuiType } from "enums/gui";
import React from "react";
import GuiDashboard from "views/app-view/gui-dashboard";
import GuiDataset from "views/app-view/gui-dataset";
import GuiReviewer from "views/app-view/gui-reviewer";
import WorkflowBoard from "views/app-view/gui-workflow-board";

type GuiTabContentProps = {
  tab: GuiTab;
  publicAccess?: boolean;
};

const GuiTabContent: React.FC<GuiTabContentProps> = (props) => {
  const { tab, publicAccess } = props;

  switch (tab.tab_type) {
    case GuiType.Dashboard:
      return <GuiDashboard guiTab={tab} editable={!publicAccess} />;
    case GuiType.RecordList:
      return <GuiDataset publicAccess={publicAccess} />;
    case GuiType.WorkflowBoard:
      return (
        <WorkflowBoard
          datasetDesignSlug={tab.dataset_design_slug}
          statuses={tab.statuses_to_include || []}
          statusField={tab.status_field}
        />
      );
    case GuiType.Reviewer:
      return <GuiReviewer publicAccess={publicAccess} />;
    default:
      return null;
  }
};

export default GuiTabContent;
