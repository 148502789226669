import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";

import SecureLayout from "layouts/SecureLayout";
import Layout from "layouts/SimpleLayout";

import AppLayout from "layouts/AppLayout";
import SystemLayout from "layouts/SystemLayout";
import AccountUser from "views/app-view/account-user";
import DatacardDesign from "views/app-view/datacard-design";
import FlowDesigner from "views/app-view/flow-designer";
import OauthCallback from "views/app-view/flow-designer/oauth-callback";
import FusionAction from "views/app-view/fusion-actions";
import Fusions from "views/app-view/fusions";
import Gui from "views/app-view/gui";

import Icons from "views/app-view/icons";
import WorkSpace from "views/app-view/WorkSpace";
import LoginPage from "views/auth-view/Login";

import Database from "assets/icons/nav-route/Database";
import DeveloperIcon from "assets/icons/nav-route/Developer";
import FusionIcon from "assets/icons/nav-route/Fusion";
import GuiIcon from "assets/icons/nav-route/Gui";
import MediaIcon from "assets/icons/nav-route/Media";
import UserIcon from "assets/icons/nav-route/User";
import SplashLayout from "layouts/SplashLayout";
import Billing from "views/app-view/billing";
import Card from "views/app-view/card";
import ChatBoart from "views/app-view/ChatBoart";
import FinetuneKnowledgebase from "views/app-view/finetune-knowledgebase";
import FusionLogs from "views/app-view/fusions/FusionLogs";
import GuiWorkspace from "views/app-view/gui-workspace";
import MediaMAnager from "views/app-view/media-manager";
import Skills from "views/app-view/Skills";
// import TranscriptionEditor from "views/app-view/TranscriptionEditor";
import WorkspaceGaurd from 'layouts/WorkspaceGaurd';
import FusionConnections from "views/app-view/fusion-connections";
import FusionWebhooks from "views/app-view/fusion-webhooks";
import Invitation from "views/app-view/invitation/Invitation";
import UploadDesign from "views/app-view/upload-design";
import VectorKnowledgebase from "views/app-view/vector-knowledgebase";
import ForgetPassword from "views/auth-view/ForgetPassword";
import ResetPassword from "views/auth-view/ResetPassword";
import SignUp from "views/auth-view/SignUp";
import Verify from "views/auth-view/Verify";
import Home from "views/dashboard/Home";
const Loadable =
  (Component: React.ComponentType<any>) => (props: JSX.IntrinsicAttributes) =>
    (
      <Suspense fallback={<div>Suspense Loading..</div>}>
        <Component {...props} />
      </Suspense>
    );

// const RegisterPage = Loadable(lazy(() => import('../pages/register.page')));
const UnauthorizePage = Loadable(
  lazy(() => import("views/auth-view/UnauthorizePage"))
);
const Profile = Loadable(lazy(() => import("views/app-view/profile")));
// const Presentation = Loadable(
//   lazy(() => import("views/app-view/presentation"))
// );
// const Presentations = Loadable(
//   lazy(() => import("views/app-view/presentation/Presentations"))
// );
// const FusionAction = Loadable(
//   lazy(() => import("views/app-view/fusion-actions"))
// );

const authRoutes: RouteObject = {
  path: "*",
  element: <SplashLayout />,
  children: [
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "signup",
      element: <SignUp />,
    },
    {
      path: "verify",
      element: <Verify />,
    },
    {
      path: "forget-password",
      element: <ForgetPassword />,
    },
    {
      path: "reset-password",
      element: <ResetPassword />,
    },
  ],
};

const normalRoutes: RouteObject = {
  path: "",
  element: <Layout />,
  children: [
    {
      path: "",
      element: <SecureLayout />,
      children: [
        {
          path: "",
          element: <AppLayout />,
          children: [
            {
              index: true,
              element: <Home />,
            },
            {
              path: "invitation/:accountSlug",
              element: <Invitation />,
            },
          ],
        },
      ],
    },
    {
      path: "unauthorized",
      element: <UnauthorizePage />,
    },
  ],
};

const systemRoutes: RouteObject = {
  path: "/:accountSlug",
  element: <SecureLayout />,
  children: [
    {
      path: "",
      element: <WorkspaceGaurd />,
      children: [
        {
          path: "",
          element: <SystemLayout />,
          children: [
            {
              path: "dataset-design",
              element: <Profile />,
            },
            {
              path: "dataset-design/:slug",
              element: <Profile />,
            },
            {
              path: "dataset-design/:slug/:datasetSlug",
              element: <Profile />,
            },
            {
              path: "icons",
              element: <Icons />,
            },
            {
              path: "fusion-action-module",
              element: <FusionAction />,
            },
            {
              path: "fusion-action-module/:slug",
              element: <FusionAction />,
            },
            {
              path: "fusion-action-module/:slug/connection/:connectionSlug",
              element: <FusionAction />,
            },
            {
              path: "fusion-action-module/:slug/module/:moduleSlug",
              element: <FusionAction />,
            },
            {
              path: "fusion-action-module/:slug/webhook/:webhookSlug",
              element: <FusionAction />,
            },
            {
              path: "fusion-action-module/:slug/remote-procedure/:remoteProcedureSlug",
              element: <FusionAction />,
            },
            {
              path: "datacard-design-module",
              element: <DatacardDesign />,
            },
            {
              path: "datacard-design-module/:slug",
              element: <DatacardDesign />,
            },
            // {
            //   path: "gui-module/:slug",
            //   element: <Gui />,
            // },
            {
              path: "gui-module/:slug/note/:noteSlug",
              element: <Gui />,
            },
            {
              path: "account-user-module",
              element: <AccountUser />,
            },
            {
              path: "account-user-module/:slug",
              element: <AccountUser />,
            },
            {
              path: "vector-knowledgebase-module",
              element: <VectorKnowledgebase />,
            },
            {
              path: "vector-knowledgebase-module/:slug",
              element: <VectorKnowledgebase />,
            },
            {
              path: "finetune-knowledgebase-module",
              element: <FinetuneKnowledgebase />,
            },
            {
              path: "finetune-knowledgebase-module/:slug",
              element: <FinetuneKnowledgebase />,
            },
            {
              path: "import-module",
              element: <UploadDesign />,
            },
            {
              path: "import-module/:slug",
              element: <UploadDesign />,
            },
            {
              path: "fusion-webhooks-module",
              element: <FusionWebhooks />,
            },
            {
              path: "fusion-webhooks-module/:webhookSlug",
              element: <FusionWebhooks />,
            },
            {
              path: "fusion-connections-module",
              element: <FusionConnections />,
            },
            {
              path: "fusion-connections-module/:connectionSlug",
              element: <FusionConnections />,
            },
          ],
        },
        {
          path: "",
          element: <SystemLayout sideNav={false} />,
          children: [
            {
              path: "",
              children: [
                {
                  index: true,
                  element: <WorkSpace />,
                },
              ],
            },
            {
              path: "fusion/flow-designer/:fusionSlug",
              element: <FlowDesigner />,
            },
            {
              path: "skills/:slug",
              element: <Skills />,
            },
            {
              path: "fusion/logs",
              element: <FusionLogs />,
            },
            {
              path: "media",
              element: <MediaMAnager />,
            },
            {
              path: "fusion",
              element: <Fusions type="fusion" />,
            },
            {
              path: "fusion/:fusionSlug",
              element: <Fusions type="fusion" />,
            },
            {
              path: "skill-design-module",
              element: <Fusions type="skill_design" />,
            },
            {
              path: "skill-design-module/:fusionSlug",
              element: <Fusions type="skill_design" />,
            },
            {
              path: "adventure-module",
              element: <Fusions type="adventure" />,
            },
            {
              path: "adventure-module/:fusionSlug",
              element: <Fusions type="adventure" />,
            },
            {
              path: "double-talk-module",
              element: <Fusions type="double_talk" />,
            },
            {
              path: "double-talk-module/:fusionSlug",
              element: <Fusions type="double_talk" />,
            },
            {
              path: "saline-chat-module",
              element: <Fusions type="saline_chat" />,
            },
            {
              path: "saline-chat-module/:fusionSlug",
              element: <Fusions type="saline_chat" />,
            },
            {
              path: "gui-module",
              element: <Gui />,
            },
            {
              path: "gui-module/:slug/:tab?",
              element: <GuiWorkspace />,
            },
            {
              path: "gui-module/:slug/:tab/:datasetSlug?",
              element: <GuiWorkspace />,
            },
            {
              path: "gui-module-public/:slug/:tab?",
              element: <GuiWorkspace publicAccess={true} />,
            },
            {
              path: "gui-module-public/:slug/:tab/:datasetSlug?",
              element: <GuiWorkspace publicAccess={true} />,
            },
            {
              path: "billing-module",
              element: <Billing />,
            },
            {
              path: "settings",
              element: <MediaMAnager />,
            },
            {
              path: "chat",
              element: <ChatBoart />,
            },
            {
              path: "skills",
              element: <Skills />,
            },
          ],
        },
        // {
        //   path: "",
        //   element: <SystemLayout sideNav={false} RcAccountMenu={false} />,
        //   children: [
        //     {
        //       path: "presentations",
        //       element: <Presentations />,
        //     },
        //     {
        //       path: "presentations/:slug",
        //       element: <Presentation />,
        //     },
        //   ],
        // },
        // {
        //   path: "transcript",
        //   element: <SystemLayout sideNav={false} RcAccountMenu={false} />,
        //   children: [
        //     {
        //       path: "",
        //       element: <TranscriptionEditor />,
        //     },
        //   ],
        // },
      ],
    },
    {
      path: "account-setup/add-card",
      children: [
        {
          element: <Card />,
          index: true,
        },
      ],
    },
    {
      path: "unauthorized",
      element: <UnauthorizePage />,
    },
    {
      path: "oauth_callback",
      element: <OauthCallback />,
    },
  ],
};

const routes: RouteObject[] = [authRoutes, systemRoutes, normalRoutes];

export const SystemNavRoutes = [
  {
    name: "Data",
    path: "/dataset-design",
    description:
      "Manage your team member permissions, user experiences and access levels.",
    icon: <Database />,
    children: [
      {
        name: "Dataset Designs",
        path: "/dataset-design",
      },
      {
        name: "Vector Knowledgebase",
        path: "/vector-knowledgebase-module",
      },
      {
        name: "Finetune Knowledgebase",
        path: "/finetune-knowledgebase-module",
      },
      {
        name: "Importer",
        path: "/import-module",
      },
    ],
  },
  {
    name: "Gui's",
    path: "/gui-module",
    icon: <GuiIcon />,
    description:
      "Manage custom workspaces that you and your team can use to manage content, projects and data.",
    children: [
      {
        name: "Gui's",
        path: "/gui-module",
      },
    ],
  },
  {
    name: "Fusions's",
    path: "/fusion",
    icon: <FusionIcon />,
    description:
      "Create custom workflows and automatons for contacts, projects or any other business process.",
    children: [
      {
        name: "Fusions",
        path: "/fusion",
      },
      {
        name: "Skill Designs",
        path: "/skill-design-module",
      },
    ],
  },
  {
    name: "Media",
    path: "/media",
    icon: <MediaIcon />,
    description:
      "Design custom data structures for your contacts, documents, analytics and projects.",
    children: [
      {
        name: "Presentation",
        path: "/presentation",
      },
      {
        name: "Icons",
        path: "/icons",
      },
    ],
  },
  {
    name: "Developer",
    path: "/fusion-action-module",
    icon: <DeveloperIcon />,
    description:
      "Manage your team member permissions, user experiences and access levels.",
    children: [
      {
        name: "Fusion Actions",
        path: "/fusion-action-module",
      },
    ],
  },
  {
    name: "Users",
    path: "/account-user-module",
    icon: <UserIcon />,
    type: "simple",
    description:
      "Manage your team member permissions, user experiences and access levels.",
  },
  {
    name: "Sylar",
    path: "/settings",
    description:
      "Manage your team member permissions, user experiences and access levels.",
    children: [],
  },
];

export default routes;
