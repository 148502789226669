import VerifiedIcon from "@mui/icons-material/Verified";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useQuery from "hooks/useQuery";
import { Copyright } from "layouts/SplashLayout";
import * as React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "services/Auth";
import Swal from "sweetalert2";

export default function SignUp() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [minutes, setMinutes] = React.useState(1);
  const [seconds, setSeconds] = React.useState(30);
  const { email } = useQuery<{ email: string }>();
  const [code, setCode] = React.useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;

  const resendOTP = async () => {
    await Auth.sendCode(email);
    setMinutes(1);
    setSeconds(30);
  };

  const verifyEmail = async () => {
    if (!code?.length) {
      setError("Invalide Code");
      return;
    }
    setLoading(true);
    try {
      await Auth.verifyCode(email, code);
      Swal.fire("Good job!", "Email Verify", "success").then(() => {
        navigate("/login", { state: { from } });
      });
    } catch {
      setError("Invalide Code");
    }
    setLoading(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <Box textAlign={"center"}>
      <Avatar sx={{ m: 1, bgcolor: "secondary.main", marginLeft: "45%" }}>
        <VerifiedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Verify Email
      </Typography>
      <Typography textAlign={"center"}>
        {`We sent an email with a verification code to your email. Please check your inbox.`}
      </Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="code"
          label="Code"
          name="code"
          autoComplete="code"
          autoFocus
          onChange={(e) => setCode(e.target.value)}
          value={code}
          error={Boolean(error)}
          helperText={Boolean(error) ? error : ""}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {seconds > 0 || minutes > 0 ? (
            <Typography>
              Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </Typography>
          ) : (
            <Typography>Didn't recieve code?</Typography>
          )}

          <Button
            disabled={seconds > 0 || minutes > 0}
            style={{
              color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "color.primary",
            }}
            onClick={resendOTP}
            variant="text"
          >
            Resend OTP
          </Button>
        </Box>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
          onClick={() => verifyEmail()}
        >
          Done
        </LoadingButton>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Box>
  );
}
