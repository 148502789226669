import { NorthEast } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { List } from "components/List";
import Spin from "components/Spin";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import InnerPageLayout from "layouts/inner-app-layout";
import useFusionConnections from "queries/fusion/useFusionConnections";
import useListFusionsByConnection from "queries/fusion/useListFusionsByConnection";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSystemLayoutStore from "store";

const FusionConnections: React.FC = () => {
  const { connectionSlug } = useParams<{ connectionSlug: string }>();
  const appNavigate = useAppNavigate();
  const accountSlug = useAccountSlug();

  const { data: connections } = useFusionConnections("no-module", "no-app", {
    return: "all",
  });
  const { data: fusions, isFetching } =
    useListFusionsByConnection(connectionSlug);

  const setMenu = useSystemLayoutStore.useSetMenu();
  const menuItemProps = useSystemLayoutStore.useSetItemProps();
  const setDisableFolders = useSystemLayoutStore.useSetDisableFolders();
  const setShowActionButtons = useSystemLayoutStore.useSetShowActionButtons();
  const setShowSideNavTopSelector =
    useSystemLayoutStore.useSetShowSideNavTopSelector();

  useEffect(() => {
    setDisableFolders(true);
    setShowSideNavTopSelector(false);
    setShowActionButtons(false);
  }, [setDisableFolders, setShowActionButtons, setShowSideNavTopSelector]);

  useEffect(() => {
    menuItemProps({
      onClick: (item) => {
        appNavigate(`/fusion-connections-module/${item.key}?t=0`);
      },
      isActive: (item) => item.key === connectionSlug,
    });
  }, [connectionSlug, accountSlug, menuItemProps, appNavigate]);

  useEffect(() => {
    setMenu(
      connections?.map((connection) => ({
        key: connection.slug,
        title: connection.connection_name,
      })) || []
    );

    if (!connectionSlug && connections && connections?.length > 0) {
      appNavigate(`/fusion-connections-module/${connections[0].slug}?t=0`);
    }
  }, [appNavigate, setMenu, connectionSlug, connections]);

  const connectionTitle = useMemo(
    () =>
      connections?.find((connection) => connection.slug === connectionSlug)
        ?.connection_name,
    [connectionSlug, connections]
  );

  return (
    <InnerPageLayout
      title={connectionTitle}
      subtitle={`List of fusions associated with connection "${connectionTitle}"`}
      loading={isFetching}
    >
      <Spin spinning={isFetching}>
        {!!fusions?.length ? (
          <List
            data={fusions}
            hideSearch
            keyBinding={{
              title: "fusion_title",
              createdAt: "created_at",
              updatedAt: "updated_at",
              description: "fusion_type",
              icon: "fusion_icon",
            }}
            showAddButton={false}
            showLoadMoreButton={false}
            onItemClick={(item) => {
              appNavigate(`/fusion/flow-designer/${item.slug}`);
            }}
            rowActions={() => <NorthEast />}
          />
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <Typography>No Fusions</Typography>
          </Stack>
        )}
      </Spin>
    </InnerPageLayout>
  );
};

export default FusionConnections;
