import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import FormField from "components/FormField";
import InvitationModal from "models/Invitation";
import useGetAccountSeats from "queries/useGetAccountSeats";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  email: z
    .string()
    .min(1, "email name is required")
    .email("This is not a valid email."),
  seat_type_id: z.string().min(1, "user type is required"),
});

type AddAccountUserFormType = z.infer<typeof formSchema>;

type Props = {
  onClose: () => void;
} & Omit<DialogProps, "onSubmit">;

const InviteUserModal: React.FC<Props> = (props) => {
  const { onClose, ...dialogProps } = props;
  const { data: seatTypes, isLoading: isSeatLoading } = useGetAccountSeats();
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = useForm<AddAccountUserFormType>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const { mutate: sendInvitation, isLoading } = useMutation(
    ["send_invitation"],
    async (data: AddAccountUserFormType) => {
      const res = InvitationModal.send(data);
      return res;
    }
  );

  const submitHandler = (data: AddAccountUserFormType) => {
    sendInvitation(data, {
      onSuccess: () => {
        console.log("inner success");

        onClose();
        reset();
      },
    });
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Invite User</DialogTitle>
      <Box component="form" sx={{ padding: "40px" }}>
        <FormField
          label="Email"
          error={dirtyFields.email ? errors.email : undefined}
        >
          <TextField
            {...register("email")}
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            placeholder="user@email.com"
          />
        </FormField>
        <FormField
          label={isSeatLoading ? "loading..." : "User Type"}
          error={dirtyFields.seat_type_id ? errors.seat_type_id : undefined}
        >
          <TextField
            {...register("seat_type_id")}
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            select
          >
            {seatTypes?.map((ut) => (
              <MenuItem value={ut.slug}>{ut.name}</MenuItem>
            ))}
          </TextField>
        </FormField>
      </Box>
      <DialogActions>
        <LoadingButton onClick={onClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler)}
          variant="contained"
          loading={isLoading}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserModal;
