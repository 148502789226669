import SvgIcon from "@mui/material/SvgIcon";

const SVGComp = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33984 8C7.23984 8 6.29818 7.60833 5.51484 6.825C4.73151 6.04167 4.33984 5.1 4.33984 4C4.33984 2.9 4.73151 1.95833 5.51484 1.175C6.29818 0.391667 7.23984 0 8.33984 0C9.43984 0 10.3815 0.391667 11.1648 1.175C11.9482 1.95833 12.3398 2.9 12.3398 4C12.3398 5.1 11.9482 6.04167 11.1648 6.825C10.3815 7.60833 9.43984 8 8.33984 8ZM0.339844 16V13.2C0.339844 12.6333 0.485677 12.1125 0.777344 11.6375C1.06901 11.1625 1.45651 10.8 1.93984 10.55C2.97318 10.0333 4.02318 9.64583 5.08984 9.3875C6.15651 9.12917 7.23984 9 8.33984 9C9.43984 9 10.5232 9.12917 11.5898 9.3875C12.6565 9.64583 13.7065 10.0333 14.7398 10.55C15.2232 10.8 15.6107 11.1625 15.9023 11.6375C16.194 12.1125 16.3398 12.6333 16.3398 13.2V16H0.339844ZM2.33984 14H14.3398V13.2C14.3398 13.0167 14.294 12.85 14.2023 12.7C14.1107 12.55 13.9898 12.4333 13.8398 12.35C12.9398 11.9 12.0315 11.5625 11.1148 11.3375C10.1982 11.1125 9.27318 11 8.33984 11C7.40651 11 6.48151 11.1125 5.56484 11.3375C4.64818 11.5625 3.73984 11.9 2.83984 12.35C2.68984 12.4333 2.56901 12.55 2.47734 12.7C2.38568 12.85 2.33984 13.0167 2.33984 13.2V14ZM8.33984 6C8.88984 6 9.36068 5.80417 9.75234 5.4125C10.144 5.02083 10.3398 4.55 10.3398 4C10.3398 3.45 10.144 2.97917 9.75234 2.5875C9.36068 2.19583 8.88984 2 8.33984 2C7.78984 2 7.31901 2.19583 6.92734 2.5875C6.53568 2.97917 6.33984 3.45 6.33984 4C6.33984 4.55 6.53568 5.02083 6.92734 5.4125C7.31901 5.80417 7.78984 6 8.33984 6Z"
        fill="currentColor"
      />
    </svg>
  );
};

const UserIcon: React.FC<React.ComponentProps<typeof SvgIcon>> = (props) => {
  return (
    <SvgIcon
      inheritViewBox
      {...props}
      component={SVGComp}
      className="plus-icon"
    />
  );
};

export default UserIcon;
