import {
  ClientRect,
  CollisionDescriptor,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  Modifier,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Coordinates } from "@dnd-kit/core/dist/types";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Box, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import ActionBar from "components/layout-components/listing-layout/ActionBar";
import Listing, {
  ListingProps,
} from "components/layout-components/listing-layout/Listing";
import TopBar from "components/layout-components/listing-layout/TopBar";
import React, { useMemo, useState } from "react";
import { useListingLayout } from "store/stores/listingLayout";

/**
 * Sort collisions from smallest to greatest value
 */
export function sortCollisionsAsc(
  { data: { value: a } }: CollisionDescriptor,
  { data: { value: b } }: CollisionDescriptor
) {
  return a - b;
}

/**
 * Returns the distance between two points
 */
export function distanceBetween(p1: Coordinates, p2: Coordinates) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

/**
 * Returns the coordinates of the center of a given ClientRect
 */
function centerOfRectangle(
  rect: ClientRect,
  left = rect.left,
  top = rect.top
): Coordinates {
  return {
    x: left + rect.width * 0.5,
    y: top + rect.height * 0.5,
  };
}

const MAX_DISTACE = 100;
/**
 * Returns the closest rectangles from an array of rectangles to the center of a given
 * rectangle.
 */
export const customClosestCenter: CollisionDetection = ({
  collisionRect,
  droppableRects,
  droppableContainers,
}) => {
  const centerRect = centerOfRectangle(
    collisionRect,
    collisionRect.left,
    collisionRect.top
  );
  const collisions: CollisionDescriptor[] = [];

  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer;
    const rect = droppableRects.get(id);

    if (rect) {
      const distBetween = distanceBetween(centerOfRectangle(rect), centerRect);

      if (distBetween < MAX_DISTACE) {
        collisions.push({
          id,
          data: { droppableContainer, value: distBetween },
        });
      }
    }
  }

  return collisions.sort(sortCollisionsAsc);
};

const PageContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  background: theme.palette.background.ContentArea,
  overflow: "hidden",
}));

const ListContainer = styled(Box)(({ theme }) => ({
  flexGrow: "1",
  flexBasis: "0",
  minHeight: "0",
  width: "100%",
  height: "100%",
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  maxWidth: "1260px",
  margin: "0 auto",
  padding: "43px 20px",
  overflow: "hidden",
  width: "100%",
}));

type ListingLayoutProps = {
  topBarProps: {
    extra?: React.ReactNode;
  };
} & ListingProps;

const ListingLayout: React.FC<ListingLayoutProps> = (props) => {
  const { topBarProps, ...listingProps } = props;

  const view = useListingLayout.useView();

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 2 },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: { distance: 2 },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    const dragerId = active.data.current?.id;
    const overId = over?.data.current?.id;
    if (dragerId && overId && dragerId !== overId) {
      if ((active.id as string)?.startsWith("grid-card")) {
        if (dragerId != null && overId != null) {
          listingProps?.onItemDrop?.(overId, dragerId);
        }
      } else {
        // Folder was drop on an other fodler
        listingProps?.onFolderDrop?.(dragerId, overId);
      }
    }
    setActiveId(null);
  };

  const modifiers: Modifier[] = useMemo(() => {
    if (view === "list") {
      return [restrictToVerticalAxis];
    }

    return [];
  }, [view]);

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={modifiers}
      onDragStart={({ active }) => {
        setActiveId(active.id);
      }}
      collisionDetection={customClosestCenter}
    >
      <PageContainer>
        <TopBar {...topBarProps} />
        <ListContainer>
          <Scrollbar>
            <BoxContainer>
              <ActionBar />
              <Listing {...listingProps} view={view} activeKey={activeId} />
            </BoxContainer>
          </Scrollbar>
        </ListContainer>
      </PageContainer>
    </DndContext>
  );
};

export default ListingLayout;
