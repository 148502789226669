import {
  ExpandMore,
  GridViewOutlined,
  ReorderOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useListingLayout } from "store/stores/listingLayout";
import SearchBar from "./SearchBar";

const FiltersArea = styled(Stack)(({ theme }) => ({
  margin: "0 0 40px",

  ".search-area": {
    ".MuiFormControl-root": {
      maxWidth: "200px",

      ".MuiInputBase-root ": {
        paddingRight: "8px",
      },

      ".MuiSvgIcon-root": {
        width: "14px",
        height: "auto",
      },
    },
  },
}));

const SelectHolder = styled(Stack)(({ theme }) => ({
  ".MuiInputBase-root": {
    minHeight: "inherit !important",
    background: "none !important",

    ".MuiSelect-select": {
      padding: "0 18px 0 0 !important",
      fontSize: "12px",
      lineHeight: "17px",
      background: "none !important",
    },
  },

  ".MuiTypography-root": {
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.palette.background.GF50,
    margin: "0 10px 0 0",
  },

  ".MuiSelect-icon": {
    width: "14px",
    height: "auto",
    right: "0 !important",
    color: theme.palette.background.GF80,
    top: "calc(50% - 7px)",
  },
}));

const ButtonsHolder = styled(Stack)(({ theme }) => ({
  padding: "0 0 0 8px",
  gap: "4px",
}));

const ButtonBox = styled(IconButton)(({ theme }) => ({
  width: "26px",
  minWidth: "26px",
  height: "26px",
  borderRadius: "4px",
  background: theme.palette.background.GF10,
  fontSize: "12px",
  lineHeight: "15px",
  color: theme.palette.background.GF40,

  "&:hover": {
    color: theme.palette.text.primary,
    background: theme.palette.background.GF20,
  },

  svg: {
    width: "16px",
    height: "auto",
    display: "block",
  },
}));

type ActionBarProps = {};

const ActionBar: React.FC<ActionBarProps> = () => {
  const view = useListingLayout.useView();
  const setView = useListingLayout.useSetView();
  const sortValue = useListingLayout.useSortValue();
  const setSortValue = useListingLayout.useSetSortValue();
  const sortOptions = useListingLayout.useSortOptions();

  return (
    <FiltersArea
      justifyContent="space-between"
      alignItems="center"
      direction={"row"}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        className="search-area"
      >
        <SearchBar />
      </Stack>
      <Stack direction={"row"} alignItems="center" spacing={1}>
        <SelectHolder direction={"row"} alignItems="center">
          <Typography variant="subtitle1">Sort :</Typography>
          <Select
            value={sortValue}
            onChange={(e) => {
              setSortValue(e.target.value);
            }}
            IconComponent={ExpandMore}
            size="small"
            variant="filled"
            defaultValue={sortOptions[0].value}
          >
            {sortOptions.map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </SelectHolder>
        <Stack direction={"row"} alignItems="center" spacing={4}>
          <ButtonsHolder direction={"row"} alignItems="center">
            <ButtonBox
              aria-label="upload picture"
              onClick={() => setView("grid")}
            >
              <GridViewOutlined
                sx={{
                  color: (theme) =>
                    view === "grid" ? "#fff" : theme.palette.background.GF40,
                }}
              />
            </ButtonBox>
            <ButtonBox
              aria-label="upload picture"
              onClick={() => setView("list")}
            >
              <ReorderOutlined
                sx={{
                  color: (theme) =>
                    view === "list" ? "#fff" : theme.palette.background.GF40,
                }}
              />
            </ButtonBox>
          </ButtonsHolder>
        </Stack>
      </Stack>
    </FiltersArea>
  );
};

export default ActionBar;
