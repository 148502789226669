import { Button, ButtonProps, styled } from "@mui/material";
import { FC } from "react";

const ButtonStyled = styled(Button)(({ theme }) => ({
  border: "none",
  color: "#fff",
  background: theme.palette.background.GF10,
}));

const GFButton: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyled
      size="small"
      variant="contained"
      disableElevation
      {...props}
    />
  );
};

export default GFButton;
