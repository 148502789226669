import {
  DeleteOutlined,
  DriveFileRenameOutline,
  EditOutlined,
} from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Avatar, Stack, Typography } from "@mui/material";
import AddFolder from "assets/icons/AddFolder";
import FusionIcon from "assets/icons/nav-route/Fusion";
import AddFolderModel from "components/AddFolderModal";
import GFButton from "components/GFButton";
import IconButtonWithTooltip from "components/IconButtonWithTooltip";
import SimpleInputWrapper from "components/SimpleInputWrapper";
import { ListingItem } from "components/layout-components/listing-layout/Listing";
import RenameModel from "components/share-components/RenameModel";
import Icon from "components/util-components/Icon";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import useFusions from "hooks/useFusions";
import useOpenClose from "hooks/useOpenClose";
import useQuery from "hooks/useQuery";
import useRouteToApiModel from "hooks/useRouteToApiModel";
import ListingLayout from "layouts/ListingLayout";
import FusionModel from "models/Fusion";
import moment from "moment";
import { useSnackbar } from "notistack";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import useFolders from "queries/useFolders";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FusionDraft, useFusionFlowStore } from "store/stores/fusion-flow";
import { useListingLayout } from "store/stores/listingLayout";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import { exportData, getSearchParams, readFile, sortItems } from "utils";
import FusionEditDrawer from "views/app-view/fusions/components/FusionEditDrawer";
import AddFusionModal from "./AddFusionModal";
type Props = {
  type?: FusionPageType;
};

const Fusions: React.FC<Props> = (props) => {
  const { type = "fusion" } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { folder_id: folderId = "root" } = useQuery<{ folder_id?: string }>();

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [folderModalOpen, setFolderModalOpen] = useState(false);

  const routeModule = useRouteToApiModel();
  const navigate = useAppNavigate();
  const selectedAccount = useAccountSlug();

  const fusionList = useFusions({ type, folderId });
  const [fusions, setFusions] = useState(fusionList);
  const { mutate: deleteFusion } = useDeleteItem({
    modelName: ApiModels.Fusion,
  });
  const { mutate: updateFusion } = useUpdateItem({
    modelName: ApiModels.Fusion,
    listQueryKey: [ApiModels.Fusion, type, folderId ?? "root"],
  });
  const { mutate: updateFolder } = useUpdateItem({
    modelName: ApiModels.Folder,
  });
  const { mutate: deleteFolder } = useDeleteItem({
    modelName: ApiModels.Folder,
  });
  const { data: foldersList } = useFolders();
  const [folders, setFolders] = useState(foldersList);
  const [isOpen, open, close] = useOpenClose();

  const setFusionDraft = useFusionFlowStore.useSetFusionDraft();
  const onRename = useSystemLayoutStore.useOnRename?.();
  const setBreadcrumbs = useListingLayout.useSetBreadcrumbs();
  const searchTags = useListingLayout.useSearchTags();
  const sortValue = useListingLayout.useSortValue();
  const setSortValue = useListingLayout.useSetSortValue();
  const setSortOptions = useListingLayout.useSetSortOptions();
  const setCount = useListingLayout.useSetCount();

  useEffect(() => {
    setFolders(foldersList);
  }, [foldersList]);

  useEffect(() => {
    setFusions(fusionList);
  }, [fusionList]);

  useEffect(() => {
    return () => {
      setFusionDraft(null);
    };
  }, []);

  useEffect(() => {
    setSortOptions([
      {
        label: "Last Updated",
        value: "last_updated",
      },
      {
        label: "Last Created",
        value: "last_created",
      },
      {
        label: "Name",
        value: "name",
      },
    ]);

    setSortValue("last_updated");
  }, []);

  useEffect(() => {
    let breadcrumbs = [];
    const rootTitle = "Fusions";
    const typeRouteMap = {
      skill_design: "skill-design-module",
      adventure: "adventure-module",
      double_talk: "double-talk-module",
      saline_chat: "saline-chat-module",
    };

    const getTypeRoute = (type: string) =>
      typeRouteMap[type as keyof typeof typeRouteMap] || "fusion";

    if (folders?.length && folderId) {
      const folder = folders.find((f) => f.slug === folderId);
      if (folder) {
        const folderHierarchy = [];
        let currentFolder: Folder | undefined = folder;
        while (currentFolder) {
          folderHierarchy.unshift(currentFolder);
          currentFolder = folders.find(
            // eslint-disable-next-line no-loop-func
            (f) => f.slug === currentFolder?.parent_folder_id
          );
        }
        breadcrumbs.push({
          id: "root",
          name: rootTitle,
          url: `/${getTypeRoute(type)}`,
        });
        folderHierarchy.forEach((folder, index) => {
          if (index !== folderHierarchy.length - 1) {
            breadcrumbs.push({
              id: folder.slug,
              name: folder.name,
              url: `/${getTypeRoute(type)}?folder_id=${folder.slug}`,
            });
          }
          //pushing root
          else if (
            index === folderHierarchy.length - 1 &&
            searchTags.length > 0
          ) {
            breadcrumbs.push({
              id: "search_results",
              name: "Search Results",
            });
          } else {
            breadcrumbs.push({
              id: folder.slug,
              name: folder.name,
            });
          }
        });
      }
    } else {
      breadcrumbs.push({
        id: "root",
        name: rootTitle,
      });
      if (searchTags.length > 0) {
        breadcrumbs.push({
          id: "search_results",
          name: "Search Results",
        });
      }
    }

    setBreadcrumbs(breadcrumbs);
  }, [folderId, folders, searchTags.length, setBreadcrumbs, type]);

  const sortData = useCallback(
    (fusionItems: Fusion[], folderItems: Folder[]) => {
      let sortedFusionItems = fusionItems;
      let sortedFolderItems = folderItems;
      const sortOptions = {
        key: "",
        isDateType: false,
      };
      if (sortValue === "last_updated") {
        sortOptions.key = "updated_at";
        sortOptions.isDateType = true;
      } else if (sortValue === "last_created") {
        sortOptions.key = "created_at";
        sortOptions.isDateType = true;
      } else if (sortValue === "name") {
        sortOptions.key = "fusion_title";
        sortOptions.isDateType = false;
      }

      if (sortOptions.key) {
        sortedFusionItems = sortItems(
          sortedFusionItems,
          sortOptions.key as keyof Fusion,
          true,
          sortOptions.isDateType
        );
        sortedFolderItems = sortItems(
          sortedFolderItems,
          sortOptions.key as keyof Folder,
          true,
          sortOptions.isDateType
        );
      }

      return { fusionItems: sortedFusionItems, folderItems: sortedFolderItems };
    },
    [sortValue]
  );

  const data = useMemo(() => {
    let filtered: Fusion[] = fusions || [];

    let filteredFolders =
      folders?.filter((f) => {
        if (folderId === "root" || !folderId)
          return f.parent_folder_id === "root" || !f.parent_folder_id;

        return f.parent_folder_id === folderId;
      }) || [];

    const sortedData = sortData(filtered, filteredFolders);
    filtered = sortedData.fusionItems;
    filteredFolders = sortedData.folderItems;

    let finalList: ListingItem[] =
      filtered.map((fusion) => ({
        type: "grid-item",
        item: {
          ...fusion,
          iconComp: <Icon iconName={fusion.fusion_icon || "InsertDriveFile"} />,
          imageUrl: "/assets/images/fusion-flow.png",
          lastUpdated: moment
            .utc(fusion.updated_at || fusion.created_at)
            .fromNow(),
          link: `/${selectedAccount}/fusion/flow-designer/${fusion.slug}`,
          title: (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                variant="rounded"
                sx={{
                  width: "32px",
                  height: "32px",
                  color: "#fff",
                  background: (theme) =>
                    !fusion.fusion_icon
                      ? "transparent"
                      : theme.palette.background.GF10,
                }}
              >
                <Icon iconName={fusion.fusion_icon!} fontSize="small" />
              </Avatar>
              <Typography>{fusion.fusion_title}</Typography>
            </Stack>
          ),
        },
      })) || [];

    const folderList: ListingItem[] =
      filteredFolders.map((folder) => ({
        type: "folder",
        folder: {
          ...folder,
          link: `/${selectedAccount}/fusion?folder_id=${folder.slug}`,
          title: (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                variant="rounded"
                sx={{
                  width: "32px",
                  height: "32px",
                  color: "#fff",
                  background: (theme) => theme.palette.background.GF10,
                }}
              >
                <Icon iconName="Folder" fontSize="small" />
              </Avatar>
              <Typography>{folder.name}</Typography>
            </Stack>
          ),
          lastUpdated: moment
            .utc(folder.updated_at || folder.created_at)
            .fromNow(),
        },
      })) || [];

    finalList.unshift(...folderList);

    if (searchTags.length > 0) {
      finalList = finalList.filter((item) => {
        if (item.type === "folder") {
          return searchTags.some(
            (keyword) =>
              item.folder?.name?.toLowerCase().indexOf(keyword.toLowerCase()) >
              -1
          );
        } else if (item.type === "grid-item") {
          return searchTags.some(
            (keyword) =>
              (item.item?.fusion_title as string)
                ?.toLowerCase()
                ?.indexOf(keyword.toLowerCase()) > -1
          );
        }

        return false;
      });
    }

    return finalList;
  }, [folderId, folders, sortData, searchTags, fusions]);

  useEffect(() => {
    setCount(data.length);
  }, [data]);

  const handleFolderClick = (folder: Folder) => {
    const searchParams = getSearchParams();
    searchParams.set("folder_id", `${folder.slug}`);
    navigate({ search: `?${searchParams.toString()}` });
  };

  const handleFusionLocationChange = (folderSlug: string, itemSlug: string) => {
    // queryClient.setQueryData<Fusion[]>(
    //   [ApiModels.Fusion, type, folderId ?? "root"],
    //   (fusions) => {
    //     return fusions?.map((f) => {
    //       if (f.fusion_slug === itemSlug) {
    //         return { ...f, folder_id: folderSlug };
    //       }
    //       return f;
    //     });
    //   }
    // );
    setFusions((fusions) => {
      return fusions?.map((f) => {
        if (f.fusion_slug === itemSlug) {
          return { ...f, folder_id: folderSlug };
        }
        return f;
      });
    });
    updateFusion(
      { slug: itemSlug, data: { folder_id: folderSlug } },
      {
        onSuccess() {
          queryClient.refetchQueries([ApiModels.Folder, routeModule]);
          queryClient.refetchQueries([
            ApiModels.Fusion,
            type,
            folderId ?? "root",
          ]);
        },
      }
    );
  };
  const handleUpdateFolderLocationChange = (
    folderSlug: string,
    parentFolder: string
  ) => {
    setFolders((folder) => {
      return folder?.map((f) => {
        if (f.slug === folderSlug) {
          return { ...f, parent_folder_id: parentFolder };
        }
        return f;
      });
    });
    updateFolder(
      { slug: folderSlug, data: { parent_folder_id: parentFolder } },
      {
        onSuccess() {
          queryClient.refetchQueries([ApiModels.Folder, routeModule]);
        },
      }
    );
  };

  const handleClose = () => {
    close();
    setFusionDraft(null);
  };

  const handleEditFusionClick = (fusion: Fusion) => {
    setFusionDraft(fusion as FusionDraft);
    open();
  };

  const exportFusion = async (slug: string) => {
    enqueueSnackbar("Exporting fusion", { variant: "info" });
    const fusionData = (await FusionModel.export(slug)).data;
    exportData(
      JSON.stringify(fusionData),
      `${fusionData.fusion.fusion_title ?? fusionData.fusion.slug}.json`
    );
  };
  const exportAllFolderFusion = async (Id: string) => {
    enqueueSnackbar("Exporting fusions", { variant: "info" });
    const fusion = (await FusionModel.exportFusions(Id)).data;
    exportData(JSON.stringify(fusion));
  };

  const importFusion = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files || e.target?.files?.length === 0) return;

    enqueueSnackbar("Importing fusion", { variant: "info" });

    try {
      const data = await readFile(e.target.files[0]);
      await FusionModel.importFusion(
        JSON.parse(data) as FusionExportData,
        folderId
      );

      queryClient.refetchQueries([ApiModels.Fusion, type, folderId || "root"]);

      enqueueSnackbar("fusion successfully imported", { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("failed importing", { variant: "error" });
    }
  };

  const removeAllFusionSessions = () => {
    FusionModel.purgeSessions("all")
      .then((response) => {
        enqueueSnackbar({
          message: response.message || "purge started",
          variant: "success",
        });
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar({ message: (e as Error).message, variant: "error" });
      });
  };

  return (
    <>
      <ListingLayout
        data={data}
        idKey="slug"
        tableColumns={[
          {
            label: "Fusion Name",
            fieldKey: "title",
            folderKey: "title",
          },
          {
            label: "Last Updated",
            fieldKey: "lastUpdated",
            folderKey: "lastUpdated",
          },
        ]}
        topBarProps={{
          extra: (
            <Stack direction="row" alignItems="center" spacing={1}>
              <GFButton
                endIcon={<DeleteOutlined />}
                onClick={removeAllFusionSessions}
              >
                Delete All Sessions
              </GFButton>
              <IconButtonWithTooltip
                size="small"
                tooltipProps={{ title: "Import Fusion" }}
              >
                <SimpleInputWrapper
                  onChange={importFusion}
                  accept="json"
                  multiple={false}
                >
                  <CloudUploadIcon />
                </SimpleInputWrapper>
              </IconButtonWithTooltip>
              <IconButtonWithTooltip
                size="small"
                tooltipProps={{ title: "Add Fusion" }}
                onClick={() => setAddModalOpen(true)}
              >
                <FusionIcon />
              </IconButtonWithTooltip>
              <IconButtonWithTooltip
                size="small"
                tooltipProps={{ title: "Add Folder" }}
                onClick={() => setFolderModalOpen(true)}
              >
                <AddFolder />
              </IconButtonWithTooltip>
            </Stack>
          ),
        }}
        actions={[
          {
            title: "Open",
            icon: <EditOutlined />,
            onClick(id) {
              const fusion = fusions?.find((fusion) => fusion.slug === id);
              if (fusion) {
                navigate(`/fusion/flow-designer/${fusion.slug}`);
              }
            },
          },
          {
            title: "Rename",
            icon: <DriveFileRenameOutline />,
            onClick(id) {
              const fusion = fusions?.find((fusion) => fusion.slug === id);
              if (fusion) {
                onRename?.({
                  title: fusion.fusion_title,
                  key: fusion.slug,
                  model: ApiModels.Fusion,
                  nameKey: "fusion_title",
                });
              }
            },
          },
          {
            title: "Edit",
            icon: <EditOutlined />,
            onClick(id) {
              const fusion = fusions?.find((fusion) => fusion.slug === id);
              if (fusion) {
                handleEditFusionClick(fusion);
              }
            },
          },
          {
            title: "Delete",
            icon: <DeleteOutlined />,
            onClick(id) {
              deleteFusion({ slug: id });
            },
          },
          {
            title: "Export",
            icon: <FileDownloadIcon />,
            onClick(id) {
              exportFusion(id);
            },
          },
        ]}
        folderActions={[
          {
            title: "Open",
            icon: <EditOutlined />,
            onClick(folder) {
              handleFolderClick(folder);
            },
          },
          {
            title: "Rename",
            icon: <DriveFileRenameOutline />,
            onClick(folder) {
              onRename?.({
                title: folder.name,
                key: folder.slug,
                model: ApiModels.Folder,
                nameKey: "name",
                updateItemOptions: {
                  listQueryKey: [ApiModels.Folder, routeModule],
                  mutationOptions: {
                    mutationKey: [ApiModels.Folder, routeModule],
                  },
                },
              });
            },
          },
          {
            title: "Delete",
            icon: <DeleteOutlined />,
            onClick(folder) {
              deleteFolder({ slug: folder.slug });
            },
          },
          {
            title: "Export",
            icon: <FileDownloadIcon />,
            onClick(folder) {
              exportAllFolderFusion(folder.slug);
            },
          },
        ]}
        gridKeyMap={{
          id: "slug",
          icon: "iconComp",
          imageUrl: "imageUrl",
          title: "fusion_title",
          subtitle: "lastUpdated",
          link: "link",
        }}
        onFolderClick={handleFolderClick}
        onOpenClick={(id) => {
          const fusion = fusions?.find((fusion) => fusion.slug === id);
          if (fusion) {
            navigate(`/fusion/flow-designer/${fusion.slug}`);
          }
        }}
        onMediaClick={(id) => {
          const fusion = fusions?.find((fusion) => fusion.slug === id);
          if (fusion) {
            navigate(`/fusion/flow-designer/${fusion.slug}`);
          }
        }}
        onItemDrop={handleFusionLocationChange}
        onFolderDrop={handleUpdateFolderLocationChange}
      />
      <AddFusionModal
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        type={type}
      />
      <AddFolderModel
        open={folderModalOpen}
        parentFolderId={folderId}
        onClose={() => setFolderModalOpen(false)}
      />
      <RenameModel module={ApiModels.Folder} title="Rename" />
      <FusionEditDrawer open={isOpen} onClose={handleClose} type={type} />
    </>
  );
};

export default Fusions;
