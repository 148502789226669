import { useStore } from "store";
import { ApiModels } from "./apiModelMapping";
import useListItems from "./useListItems";

const useGetAccountSeats = () => {
  const pkgId = useStore.useSelectedAccount?.()?.account_package_id;
  return useListItems({
    modelName: ApiModels.SeatType,
    requestOptions: {
      query: {
        package_slug: pkgId,
      },
    },
    queryOptions: {
      enabled: !!pkgId,
    },
    queryKey: [ApiModels.SeatType, pkgId],
  });
};

export default useGetAccountSeats;
