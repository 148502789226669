import { UniqueIdentifier } from "@dnd-kit/core/dist/types";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { ArrowBack } from "@mui/icons-material";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import FormField from "components/FormField";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { MultipleContainers } from "components/drag-drop/MultipleContainer";
import { DraggableItemProps } from "components/drag-drop/components/Item/Item";
import { DocumentElementType } from "enums";
import { ListSource } from "enums/Form";
import useAppNavigate from "hooks/useAppNavigate";
import useQuery from "hooks/useQuery";
import { cloneDeep } from "lodash";
import get from "lodash/get";
import set from "lodash/set";
import GuiModal from "models/Gui";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import InfoList from "stories/CompoundComponent/AccountInfoCard/AccountInfoCard/AccountInfoCard";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";
import { v4 } from "uuid";
import FusionActions from "views/app-view/gui-workspace/components/TabEditorDrawer/ExtendedFields/FusionActions";
import DragFieldItem from "../DragFieldItem";
import ChatViewFields from "./ChatViewFields";

const ListItemStyle = styled(Box)(() => ({
  marginBottom: 10,
}));

const FieldsWrapper = styled(Box)(({ theme }) => {
  return {
    margin: "0 0 6px",

    ".title": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
      margin: "0 0 16px",
      textTransform: "capitalize",
    },
  };
});

const DataSetFields = styled(Stack)(({ theme }) => {
  return {
    gap: "16px",
    marginBottom: "16px",

    ".MuiFormLabel-root": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
      color: theme.palette.text.primary,
      margin: "0 0 8px",
    },
  };
});

const FieldsWrap = styled(Stack)(({ theme }) => {
  return {
    ".draggable-handle": {
      color: theme.palette.background.GF20,

      "&:hover": {
        background: "none",
        color: theme.palette.background.GF80,
      },

      svg: {
        fill: "currentColor",
      },
    },
  };
});

const InfoListWrap = styled(InfoList)(({ theme }) => {
  return {
    ".MuiList-root": {
      padding: "12px 0 8px",
    },
  };
});

const CheckboxStyled = styled(Checkbox)({
  display: "block",
  width: "fit-content",
  height: "42px",
});

const defaultFields: DataField[] = [
  {
    id: "id",
    slug: "id",
    title: "id",
    type: DocumentElementType.TextField,
  },
];

type TabCreatorProps = {
  gui: GfGui;
  indexKey: "included_tabs" | "included_sidebar_widgets";
  allowAdditionalTabs?: boolean;
  path?: string[];
  datasetDesigns?: DatasetDesign[];
  datasetDesignsMap?: Map<string, DatasetDesign>;
  allowTabActions?: boolean;
  type?: "middle_tab" | "right_widget";
  onClickBack: () => void;
  onAddWidget?: () => void;
  onEditWidget?: (_: IncludeTabs, index: number) => void;
  onEditRules?(): void;
  onEditFilterRules?: (fieldId: DataField) => void;
  onEditImageSetting?: (fieldId: DataField) => void;
  onAddActionClick?(): void;
  onEditActionClick?(action: FusionAction): void;
};

const TabCreator: React.FC<TabCreatorProps> = (props) => {
  const {
    gui,
    onClickBack,
    indexKey = "included_tabs",
    onAddWidget,
    onEditWidget,
    allowAdditionalTabs = false,
    path = [],
    datasetDesigns,
    datasetDesignsMap,
    onEditRules,
    onEditFilterRules,
    onEditImageSetting,
    onAddActionClick,
    onEditActionClick,
    allowTabActions,
    type = "right_widget",
  } = props;

  const { selectedTabId } = useQuery<{ selectedTabId: string }>();
  const isNewTab = selectedTabId === "new-tab";
  const [includedFields, setIncludedFields] = useState<string[]>([]);
  const [listIncludedFields, setListIncludeFields] = useState<string[]>([]);
  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);
  const appNavigate = useAppNavigate();
  const selectedGuiTab = useGuiDashboardStore.useSelectedTab();

  const guiTab = useMemo(() => {
    if (selectedGuiTab != null && gui?.tabs) {
      return gui?.tabs?.[selectedGuiTab];
    }
  }, [gui?.tabs, selectedGuiTab]);

  const { selectedTab, parentTab } = useMemo(() => {
    if (guiTab?.tab_type === "record_list") {
      let parentTab = guiTab[indexKey];

      if (isNewTab) {
        return {
          selectedTab: null,
          parentTab: get(parentTab, path.slice(0, -1)),
        };
      }
      const selectedTab = get(parentTab, path.slice(0, -1));
      setIncludedFields(selectedTab?.included_fields || []);
      setListIncludeFields(selectedTab?.list_included_fields || []);
      return {
        selectedTab,
        parentTab:
          path.length > 2 ? get(parentTab, path.slice(0, -3)) : undefined,
      } as { selectedTab?: IncludeTabs; parentTab?: IncludeTabs };
    } else {
      return {};
    }
  }, [guiTab, indexKey, isNewTab, path, selectedTabId]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<Partial<IncludeTabs>>({
    mode: "onBlur",
    // resolver: zodResolver(formSchema),
    defaultValues: {
      // Provide default values for the form data here
      // For example:
      id: "",
      included_fields: [],
      list_included_fields: [],
      name: "",
      tab_type: "dataset",
      dataset_to_include: "",
      association_type: "",
      linking_field: "",
      record_type: "list",
      additional_tabs: [],
      show_full_record: false,
      use_fusion: false,
      fusion_id: "",
      sender_id_field: "",
      message_field: "",
      sender_name_field: "",
      iframe_url: "",
      allow_delete_record: true,
    },
  });

  const theme = useTheme();
  const { slug: guiSlug } =
    useParams<{
      slug: string;
    }>();

  const datasetDesignSlug =
    guiTab?.tab_type === "record_list" ? guiTab.dataset_design_slug : undefined;

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: parentTab?.dataset_to_include ?? datasetDesignSlug,
  });
  const { mutate: updateGui, isLoading } = useUpdateItem({
    modelName: ApiModels.Gui,
  });

  const [tabActions, setTabActions] = useState<FusionAction[]>([]);
  const [filterRules, setFilterRules] = useState<Condition[]>([]);

  useEffect(() => {
    initialValueSet.current = false;
    allowNetworkRequest.current = false;
  }, [selectedTabId]);

  useEffect(() => {
    if (selectedTabId && selectedTab && !initialValueSet.current) {
      reset({ ...selectedTab, tab_type: selectedTab.tab_type ?? "dataset" });
      setTabActions(selectedTab?.tab_actions || []);
      setFilterRules(selectedTab?.filter_rules || []);
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 500);
    }
  }, [reset, selectedTab, selectedTabId]);

  const submitHandler = useCallback(
    async (data: Partial<IncludeTabs>) => {
      if (
        guiSlug &&
        selectedTabId &&
        (allowNetworkRequest.current || isNewTab) &&
        guiTab?.tab_type === "record_list"
      ) {
        const datasetListSetting = cloneDeep(guiTab);
        if (isNewTab) {
          const newTabArray = [
            ...get(datasetListSetting, [indexKey, ...path], []),
            data,
          ];

          set(datasetListSetting, [indexKey, ...path], newTabArray);
        } else {
          set(datasetListSetting, [indexKey, ...path.slice(0, -1)], {
            ...selectedTab,
            ...data,
            tab_actions: tabActions,
          });
        }

        await new Promise<void>((resolve, reject) => {
          updateGui(
            {
              slug: guiSlug,
              data: {
                tabs:
                  gui.tabs?.map((t) =>
                    t.id === guiTab.id
                      ? { ...guiTab, ...datasetListSetting }
                      : t
                  ) ?? [],
              },
            },
            {
              onSuccess: () => {
                console.log("gui edit success");
                resolve();
              },
            }
          );
        });
      }
    },
    [
      guiSlug,
      selectedTabId,
      isNewTab,
      guiTab,
      indexKey,
      path,
      selectedTab,
      tabActions,
      updateGui,
      gui.tabs,
    ]
  );
  const onCreateNew = async (data: Partial<IncludeTabs>) => {
    set(data, "id", v4());
    if (!data.use_fusion && viewType !== "chat_view") {
      await submitHandler(data).then(() => {
        onClickBack();
      });
    } else {
      await GuiModal.createFusion({
        gui_slug: gui.slug,
        main_tab_id: guiTab?.id! as unknown as string,
        sub_tab_id: data?.id!,
      }).then(async (res) => {
        const fusionSlug = res.data.fusion_slug;
        set(data, "fusion_id", fusionSlug);
        set(data, "use_fusion", true);
        await submitHandler(data).then(() => {
          onClickBack();
        });
      });
    }
  };
  useEffect(() => {
    if (!isNewTab) {
      const submitDeb = debounce(() => {
        handleSubmit(submitHandler)();
      }, 1200);
      const subscription = watch((_) => {
        submitDeb();
      });
      return () => subscription.unsubscribe();
    }
  }, [watch, submitHandler, handleSubmit]);

  const onSortEndHandler = (
    _: UniqueIdentifier[],
    items: Record<UniqueIdentifier, UniqueIdentifier[]>
  ) => {
    setValue("included_fields", items.included as string[]);
  };
  const onListFieldSortEndHandler = (
    _: UniqueIdentifier[],
    items: Record<UniqueIdentifier, UniqueIdentifier[]>
  ) => {
    setValue("list_included_fields", items.included as string[]);
  };
  const includedDatasetDesignSlug = watch("dataset_to_include");
  const associationType = watch("association_type");
  const additionalTabs = watch("additional_tabs");
  const useFusion = watch("use_fusion");
  const showFullRecord = watch("show_full_record");
  const viewType = watch("record_type");
  const tabType = watch("tab_type");
  console.log("🚀 ~ tabType:", tabType);

  const includedDatasetDesign = useMemo(
    () => datasetDesigns?.find((dd) => dd.slug === includedDatasetDesignSlug),
    [datasetDesigns, includedDatasetDesignSlug]
  );

  const { fieldsMap, items } = useMemo(() => {
    const excludedFields: string[] = [];
    const fieldsMap = new Map<string, DataField>();
    includedDatasetDesign?.fields?.fields?.forEach((field) => {
      fieldsMap.set(field.id, field);
      if (!includedFields.includes(field.id)) {
        excludedFields.push(field.id);
      }
    });

    return {
      items: { included: includedFields, excluded: excludedFields },
      fieldsMap,
    };
  }, [includedDatasetDesign, includedFields]);

  const { fieldsMap: listfieldsMap, items: listFields } = useMemo(() => {
    const excludedFields: string[] = [];
    const fieldsMap = new Map<string, DataField>();
    includedDatasetDesign?.fields?.fields?.forEach((field) => {
      fieldsMap.set(field.id, field);
      if (!listIncludedFields?.includes(field.id)) {
        excludedFields.push(field.id);
      }
    });

    return {
      items: { included: listIncludedFields, excluded: excludedFields },
      fieldsMap,
    };
  }, [includedDatasetDesign?.fields?.fields, listIncludedFields]);

  const showEditOnSelect = useCallback(
    (item: DataField) => {
      return (
        !isNewTab &&
        ((item.type === DocumentElementType.Select &&
          item.list_source === ListSource.RecordAssociation) ||
          item.type === DocumentElementType.Image)
      );
    },
    [isNewTab]
  );

  const handleClick = () => {
    appNavigate(`/fusion/flow-designer/${selectedTab?.fusion_id}`);
  };

  const linkFields = useMemo(() => {
    if (associationType !== "reference_table") {
      return [
        ...defaultFields,
        ...(includedDatasetDesign?.fields?.fields ?? []),
      ];
    }
    return [...defaultFields, ...(datasetDesign?.fields?.fields ?? [])];
  }, [
    associationType,
    datasetDesign?.fields?.fields,
    includedDatasetDesign?.fields?.fields,
  ]);

  const handleFieldEdit = (d: DataField) => {
    // if (d.type === DocumentElementType.Select) {
    // }
    if (d.type === DocumentElementType.Image) {
      onEditImageSetting?.(d);
    } else {
      onEditFilterRules?.(d);
    }
  };

  const viewTypeOptions = useMemo(() => {
    const options = [
      {
        label: "Single",
        value: "single",
      },
      {
        label: "List",
        value: "list",
      },
    ];

    if (type === "middle_tab") {
      options.push({
        label: "Chat View",
        value: "chat_view",
      });
    }

    return options;
  }, [type]);

  return (
    <React.Fragment>
      <SidebarSectionWrap
        title="Back to Dataset"
        rightIcon={false}
        leftIcon={<ArrowBack />}
        onLeftIconClick={onClickBack}
      >
        <DataSetFields>
          <FormField label="Name" error={errors.name}>
            <TextField
              {...register("name")}
              autoFocus
              type="text"
              fullWidth
              variant="filled"
              size="small"
            />
          </FormField>
          <FormField label="Tab Type" error={errors.tab_type}>
            <Controller
              name="tab_type"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  select
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}
                >
                  <MenuItem value="dataset">Dataset</MenuItem>
                  <MenuItem value="iframe">IFrame</MenuItem>
                </TextField>
              )}
            />
          </FormField>
          {tabType === "iframe" ? (
            <>
              <FormField label="IFrame URL" error={errors.iframe_url}>
                <TextField
                  {...register("iframe_url")}
                  type="text"
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </FormField>
            </>
          ) : (
            <>
              <FormField
                label="Dataset to Include"
                error={errors.dataset_to_include}
              >
                <Controller
                  name="dataset_to_include"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      fullWidth
                      variant="filled"
                      size="small"
                      select
                      onChange={(value) => {
                        setIncludedFields([]);
                        setValue("linking_field", "");
                        field.onChange(value);
                      }}
                    >
                      {datasetDesigns
                        ?.sort((a, b) =>
                          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                        )
                        ?.map((dd) => (
                          <MenuItem key={dd.id} value={dd.slug}>
                            {dd.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </FormField>
              <FormField
                label="Foreign key location"
                error={errors.association_type}
              >
                <Controller
                  name="association_type"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      fullWidth
                      variant="filled"
                      size="small"
                      select
                      {...field}
                      onChange={(value) => {
                        setIncludedFields([]);
                        field.onChange(value);
                      }}
                    >
                      {[
                        { label: "Parent Table", value: "reference_table" },
                        // { label: "Junction Table", value: "junction_table" },
                        { label: "This Table", value: "this_table" },
                      ]?.map((dd) => (
                        <MenuItem key={dd.value} value={dd.value}>
                          {dd.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormField>
              <FormField label="Linking field" error={errors.linking_field}>
                <Controller
                  name="linking_field"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value ?? ""}
                      type="text"
                      fullWidth
                      variant="filled"
                      size="small"
                      select
                    >
                      {linkFields?.map((f) => (
                        <MenuItem key={f.id} value={f.slug}>
                          {f.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormField>
              <FormField label="View Type" error={errors.record_type}>
                <Controller
                  name="record_type"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      fullWidth
                      variant="filled"
                      size="small"
                      select
                    >
                      {viewTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormField>
              {viewType === "chat_view" && (
                <ChatViewFields
                  control={control}
                  errors={errors}
                  linkFields={linkFields}
                />
              )}
              {viewType === "list" && (
                <FormField
                  label="Allow Delete Record"
                  error={errors.use_fusion}
                >
                  <Controller
                    name="allow_delete_record"
                    control={control}
                    render={({ field }) => (
                      <CheckboxStyled
                        {...field}
                        checked={field.value ?? true}
                        onChange={(_, checked) => field.onChange(checked)}
                      />
                    )}
                  />
                </FormField>
              )}
              <FormField
                label="Show Full Dataset"
                error={errors.show_full_record}
              >
                <Controller
                  name="show_full_record"
                  control={control}
                  render={({ field }) => (
                    <CheckboxStyled {...field} checked={field.value} />
                  )}
                />
              </FormField>
              <FormField label="Use Fusion" error={errors.use_fusion}>
                <Controller
                  name="use_fusion"
                  control={control}
                  render={({ field }) => (
                    <CheckboxStyled
                      {...field}
                      checked={field.value || viewType === "chat_view"}
                      disabled={!isNewTab || viewType === "chat_view"}
                    />
                  )}
                />
              </FormField>
              {useFusion && !isNewTab && (
                <FormField label="Fusion" error={errors.fusion_id}>
                  <Controller
                    name="fusion_id"
                    control={control}
                    render={({ field }) => (
                      <Box>
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="filled"
                          size="small"
                          hiddenLabel
                          disabled
                        />
                        <Link onClick={handleClick}>link to fusion</Link>
                      </Box>
                    )}
                  />
                </FormField>
              )}
            </>
          )}
        </DataSetFields>

        {tabType === "dataset" && (
          <>
            <MultipleContainers
              items={items}
              minimal
              vertical={true}
              modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              style={{ padding: 0 }}
              renderContainer={(props: any) => {
                return (
                  <FieldsWrapper>
                    <Box className="title">{props.id}</Box>
                    <FieldsWrap ref={props.ref} sx={{ minHeight: 60 }}>
                      {props.children}
                    </FieldsWrap>
                  </FieldsWrapper>
                );
              }}
              renderItem={(props: DraggableItemProps & { ref: any }) => {
                const item = fieldsMap.get(props.value as unknown as string);
                if (!item) return null;

                const {
                  transition,
                  handleProps,
                  listeners,
                  transform,
                  dragging,
                  ref,
                } = props;

                const styles: CSSProperties = {
                  opacity: dragging ? 0.4 : undefined,
                  transform: CSS.Translate.toString(transform as any),
                  transition: transition as any,
                };
                return (
                  <ListItemStyle style={styles} id={item.id} ref={ref}>
                    <DragFieldItem
                      item={item}
                      rightIcon={
                        // showEditOnSelect(item) && (
                        // )
                        <Box
                          className="edit-icon"
                          onClick={() => handleFieldEdit?.(item)}
                        >
                          <EditOutlined sx={{ color: "grey.500" }} />
                        </Box>
                      }
                      listeners={listeners}
                      handleProps={handleProps}
                    />
                  </ListItemStyle>
                );
              }}
              onSortEnd={onSortEndHandler as any}
            />

            {showFullRecord && (
              <React.Fragment>
                <Typography>Record Fields</Typography>
                <MultipleContainers
                  items={listFields}
                  minimal
                  vertical={true}
                  modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                  style={{ padding: 0 }}
                  renderContainer={(props: any) => {
                    return (
                      <FieldsWrapper>
                        <Box className="title">{props.id}</Box>
                        <FieldsWrap ref={props.ref} sx={{ minHeight: 60 }}>
                          {props.children}
                        </FieldsWrap>
                      </FieldsWrapper>
                    );
                  }}
                  renderItem={(props: DraggableItemProps & { ref: any }) => {
                    const item = listfieldsMap.get(
                      props.value as unknown as string
                    );
                    if (!item) return null;

                    const {
                      transition,
                      handleProps,
                      listeners,
                      transform,
                      dragging,
                      ref,
                    } = props;

                    const styles: CSSProperties = {
                      opacity: dragging ? 0.4 : undefined,
                      transform: CSS.Translate.toString(transform as any),
                      transition: transition as any,
                    };
                    return (
                      <ListItemStyle style={styles} id={item.id} ref={ref}>
                        <DragFieldItem
                          item={item}
                          listeners={listeners}
                          handleProps={handleProps}
                        />
                      </ListItemStyle>
                    );
                  }}
                  onSortEnd={onListFieldSortEndHandler as any}
                />
              </React.Fragment>
            )}
          </>
        )}
      </SidebarSectionWrap>
      {tabType === "dataset" && (
        <>
          {!isNewTab && (
            <SidebarSectionWrap title="Data View Filters" rightIcon={false}>
              <ProfileCard
                options={{
                  draggable: false,
                  switcher: false,
                }}
                rightIcon={
                  <Stack direction="row" spacing={1}>
                    <Box className="edit-icon">
                      <EditOutlined sx={{ color: "grey.500" }} />
                    </Box>
                  </Stack>
                }
                subTitle={`${filterRules?.length} Inactive`}
                title={`${filterRules?.length} Active Rules`}
                sx={{
                  background: theme.palette.background.GFRightNavForeground,
                  height: 48,
                  ".MuiTypography-subtitle1": {
                    margin: "0 0 2px",
                  },

                  ".card-inner-content": {
                    gap: "2px",
                  },
                }}
                onClick={() => onEditRules?.()}
              />
            </SidebarSectionWrap>
          )}
          {!isNewTab && allowTabActions && (
            <FusionActions
              title="Tab Actions"
              actions={tabActions}
              onAddClick={() => onAddActionClick?.()}
              onEditClick={(action) => onEditActionClick?.(action)}
              onDeleteClick={(action) => {
                setTabActions((actions) =>
                  actions.filter((a) => a.id !== action.id)
                );
              }}
              onSort={(actions) => {
                setTabActions(actions);
              }}
            />
          )}
          {allowAdditionalTabs && !isNewTab && (
            <SidebarSectionWrap
              title="Additional Widget"
              onRightIconClick={onAddWidget}
            >
              {additionalTabs?.map((tab, index) => {
                const infoList = [
                  {
                    title: tab.name,
                    icon: "Name :",
                  },
                  {
                    title: datasetDesignsMap?.get(tab.dataset_to_include)?.name,
                    icon: "Included Dataset :",
                  },
                  {
                    title: tab.association_type,
                    icon: "Association Type :",
                  },
                ];
                if (tab.association_type !== "parent") {
                  infoList.push({
                    title: tab.record_type,
                    icon: "Record Type :",
                  });
                }
                return (
                  <InfoListWrap
                    key={tab.id}
                    data={infoList}
                    headerRightIcon={
                      <Box className="edit-icon">
                        <EditOutlined
                          onClick={() => onEditWidget?.(tab, index)}
                          sx={{ color: "grey.500" }}
                        />
                      </Box>
                    }
                    title={"Included Fields"}
                    sx={{
                      marginBottom: "10px",
                      "&:hover": {
                        background:
                          theme.palette.background.GFRightNavForeground,
                      },
                    }}
                  />
                );
              })}
            </SidebarSectionWrap>
          )}
        </>
      )}

      {isNewTab && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
          mb={2}
        >
          <LoadingButton
            variant="outlined"
            onClick={onClickBack}
            loading={isLoading}
            disabled={isLoading}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit(onCreateNew)}
            loading={isLoading}
            disabled={isLoading}
          >
            Save
          </LoadingButton>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default TabCreator;
