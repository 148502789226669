import Model from "models";
import qs from "query-string";

class GFMLFunctionModel extends Model<GFMLFunction> {
  constructor() {
    super("/global-gfml-function", "dev-sett");
  }

  async getGlobalFunctionGroups(query?: Record<string, any>) {
    return await this.sendRequest<GFMLFunctionGroup[]>(
      `/groups?${qs.stringify(query || {})}`,
      "GET"
    );
  }
}

export default new GFMLFunctionModel();
