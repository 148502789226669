import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import useRouteToApiModel from "hooks/useRouteToApiModel";
import { ApiModels } from "./apiModelMapping";
import useListItems from "./useListItems";

const useFolders = (
  args?: Partial<UseQueryOptions<Folder[], unknown, Folder[], QueryKey>>
) => {
  const routeModule = useRouteToApiModel();

  return useListItems({
    modelName: ApiModels.Folder,
    queryKey: [ApiModels.Folder, routeModule],
    requestOptions: { query: { resource: routeModule } },
    queryOptions: { ...args },
  });
};

export default useFolders;
