import { UniqueIdentifier } from "@dnd-kit/core";
import { CSS, Transform } from "@dnd-kit/utilities";
import { zodResolver } from "@hookform/resolvers/zod";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import AddFolder from "assets/icons/AddFolder";
import classNames from "classnames";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import SearchInput from "components/SearchInput";
import SimpleInputWrapper from "components/SimpleInputWrapper";
import { SortableList } from "components/SortableList";
import Spin from "components/Spin";
import { ContainerProps, Handle } from "components/drag-drop/components";
import { DraggableItemProps } from "components/drag-drop/components/Item/Item";
import GenericIcon from "components/util-components/Icon";
import { NAV_SELECTOR_TYPE } from "configs/AppConfig";
import { Icons } from "constants/index";
import Fuse from "fuse.js";
import useOpenClose from "hooks/useOpenClose";
import useRouteToApiModel from "hooks/useRouteToApiModel";
import { throttle } from "lodash";
import debounce from "lodash/debounce";
import isArray from "lodash/isArray";
import DatasetDesignModel from "models/DatasetDesign";
import FolderModel from "models/Folder";
import { useSnackbar } from "notistack";
import { queryClient } from "queries";
import { ApiModelDataTypes, ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useDeleteItem from "queries/useDeleteItem";
import useFolders from "queries/useFolders";
import useUpdateItem from "queries/useUpdateItem";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import Swal from "sweetalert2";
import { exportData, readFile } from "utils";
import { z } from "zod";
import {
  AddButton,
  BottomSection,
  BoxWrap,
  DividerWrap,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  ListItemButtonWrap,
  ListItemStyle,
  MenuContainer,
} from "./index.styles.";
type MenuInfo = {
  title: string;
  key: string;
  icon: Icons;
  children?: MenuInfo[];
};

export const CustomListItem = React.memo(
  React.forwardRef<
    HTMLLIElement,
    {
      hideDragHanlder?: boolean;
      item: { title: string; key: string; icon: Icons };
      onClick?: (_: MenuInfo) => void;
      [key: string]: any;
      handleContextMenu: (
        _: React.MouseEvent<HTMLDivElement>,
        extra?: MenuInfo
      ) => void;
    } & DraggableItemProps
  >(
    (
      {
        item,
        onClick,
        handleProps,
        index,
        color,
        dragging,
        handleContextMenu,
        hideDragHanlder = false,
        ...rest
      },
      ref
    ) => {
      const { onClick: onItemClick, isActive } =
        useSystemLayoutStore.useItemProps?.() || {};

      const handleClick = () => {
        onItemClick?.(item);
      };
      const onContextMenuHandler = (
        e: React.MouseEvent<HTMLLIElement, MouseEvent>
      ) => {
        handleContextMenu?.(e as any, item);
      };
      return (
        <ListItemStyle
          disablePadding
          onClick={handleClick}
          sx={{ zIndex: 10000000000 }}
          ref={ref}
          isActive={isActive?.(item)}
          // {...rest}
          id={item.key}
          onContextMenu={onContextMenuHandler}
        >
          {!hideDragHanlder && (
            <SortableList.DragHandle>
              <Handle />
            </SortableList.DragHandle>
          )}
          <ListItemButtonWrap
            sx={{
              minHeight: 40,
              justifyContent: open ? "initial" : "center",
              px: 2,
            }}
            disableRipple={true}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                // mr: open ? 1.5 : "auto",
                mr: 1.5,
                justifyContent: "center",
              }}
            >
              <GenericIcon
                iconName={item.icon || "Menu"}
                sx={{ width: "16px", height: "auto" }}
                key={item.icon}
              />
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              sx={{ opacity: open ? 1 : 0, m: 0 }}
            />
          </ListItemButtonWrap>
          {/* </AccountLink> */}
        </ListItemStyle>
      );
    }
  )
);

const MenuListContainer: React.FC<
  React.PropsWithChildren<
    {
      title: string;
      handleContextMenu: (
        _: React.MouseEvent<HTMLDivElement>,
        id: string
      ) => void;
    } & ContainerProps
  >
> = React.memo(
  React.forwardRef(
    (
      {
        children,
        title,
        style,
        transform,
        transition,
        id,
        isDragging,
        handleContextMenu,
      },
      ref
    ) => {
      const theme = useTheme();
      const [isExpended, setIsExpended] = React.useState(false);
      const onContextMenuHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        handleContextMenu?.(e, id as string);
      };
      return (
        <Stack
          gap={1.25}
          sx={{ minWidth: "0" }}
          id={id as string}
          onContextMenu={onContextMenuHandler}
        >
          <Box ref={ref} className="box-holder">
            <Accordion
              sx={{
                boxShadow: "none  !important",
                background: "none !important",
                borderRadius: "0 !important",
              }}
              expanded={isExpended && !isDragging}
              onChange={() => setIsExpended((ex) => !ex)}
              style={{
                ...style,
                transition,
                transform: CSS.Translate.toString(transform as Transform),
                opacity: isDragging ? 0.5 : undefined,
              }}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  minHeight: "40px",
                  boxShadow: "none",
                  // padding: "0 12px",
                  "&:hover": {
                    ".MuiTypography-root ": {
                      color: theme.palette.background.GF60,
                    },

                    ".MuiAccordionSummary-expandIconWrapper": {
                      color: theme.palette.background.GF60,
                    },
                  },

                  ".draggable-handle ": {
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    // right: "50px",
                    width: "auto",
                    opacity: 0,
                  },

                  "&.Mui-expanded": {
                    minHeight: "40px",

                    ".MuiAccordionSummary-content": {
                      margin: "5px 0",

                      [`${theme.breakpoints.only(
                        "sm"
                      )},${theme.breakpoints.only("md")}`]: {
                        margin: "0",
                      },
                    },
                  },

                  ".MuiAccordionSummary-content": {
                    margin: "5px 0",
                    alignItems: "center",

                    [`${theme.breakpoints.only("sm")},${theme.breakpoints.only(
                      "md"
                    )}`]: {
                      margin: "0",
                    },
                  },

                  ".MuiPaper-root": {
                    boxShadow: "none",
                  },

                  ".MuiAccordionSummary-expandIconWrapper": {
                    width: "18px",
                    transform: "rotate(90deg)",
                    color: theme.palette.background.Groups,

                    "&.Mui-expanded": {
                      transform: "rotate(0)",
                    },

                    svg: {
                      width: "100%",
                      height: "auto",
                    },
                  },
                }}
              >
                <SortableList.DragHandle>
                  <Handle />
                </SortableList.DragHandle>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: theme.palette.background.Groups,
                    textTransform: "uppercase",
                    transition: "all 0.4s ease",
                  }}
                >
                  {title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  p: 0,
                }}
              >
                {<List sx={{ p: 0 }}>{children}</List>}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Stack>
      );
    }
  )
);

const open = true;

const ButtonAdd = () => {
  const addButtonProps = useSystemLayoutStore.useAddButtonProps?.();
  const theme = useTheme();
  return (
    <React.Fragment>
      <AddButton
        fullWidth
        variant="outlined"
        color="inherit"
        sx={{ borderColor: theme.palette.background.GF10 }}
        {...addButtonProps}
      >
        <PersonAddAltIcon />
      </AddButton>
    </React.Fragment>
  );
};
const ButtonAddFolder = () => {
  const theme = useTheme();
  const [isOpen, onOpen, onClose] = useOpenClose();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<Folder>({
    mode: "onBlur",
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, "Name is required"),
      })
    ),
    defaultValues: {},
  });

  const routeModel = useRouteToApiModel();
  const { mutate: createFolder } = useCreateItem({
    modelName: ApiModels.Folder,
    queryKey: [ApiModels.Folder, routeModel],
  });

  const closeHandler = () => {
    onClose();
    reset();
  };
  const submitHandler = (data: Folder) => {
    createFolder(
      { ...data, resource: routeModel, sort_order: 10000000000 },
      {
        onSuccess: () => {
          closeHandler();
        },
      }
    );
  };
  return (
    <React.Fragment>
      <AddButton
        fullWidth
        variant="outlined"
        color="inherit"
        sx={{ borderColor: theme.palette.background.GF10 }}
        onClick={onOpen}
      >
        <AddFolder />
      </AddButton>
      <Dialog open={isOpen} disableEscapeKeyDown scroll="body">
        <DialogTitle>Add Folder</DialogTitle>
        <Scrollbar className="form-scroller">
          <DialogContent>
            <Box component="form">
              <FormField
                label="Folder Name"
                error={dirtyFields.name ? errors.name : undefined}
              >
                <TextField
                  {...register("name")}
                  autoFocus
                  margin="dense"
                  id="name"
                  type="text"
                  fullWidth
                />
              </FormField>
            </Box>
          </DialogContent>
        </Scrollbar>
        <DialogActions>
          <Button onClick={closeHandler}>Cancel</Button>
          <Button onClick={handleSubmit(submitHandler)} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const FolderRenameModel = ({
  folder,
  isOpen,
  onClose,
}: {
  folder: Folder | undefined;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<Folder>({
    mode: "onBlur",
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, "Name is required"),
      })
    ),
    defaultValues: {},
  });

  const { mutate: updateFolder, isLoading } = useUpdateItem({
    modelName: ApiModels.Folder,
    queryKey: [ApiModels.Folder, ApiModels.DatasetDesign],
    mutationOptions: {
      onSuccess: (_, { slug, data }) => {
        const defaultQueryKey = [ApiModels.Folder, ApiModels.DatasetDesign];
        queryClient.setQueriesData(
          [defaultQueryKey],
          (oldData: ApiModelDataTypes[typeof ApiModels.Folder][] = []) => {
            if (isArray(oldData)) {
              return oldData.map((item) =>
                item.slug === slug ? { ...item, ...data } : item
              );
            }
            return oldData;
          }
        );
      },
    },
  });

  const closeHandler = () => {
    onClose();
  };
  const submitHandler = (data: Folder) => {
    if (folder?.slug)
      updateFolder(
        { slug: folder.slug, data },
        {
          onSuccess: () => {
            closeHandler();
          },
        }
      );
  };

  React.useEffect(() => {
    reset(folder);
  }, [folder, reset]);

  return (
    <React.Fragment>
      <Dialog open={isOpen} disableEscapeKeyDown scroll="body">
        <DialogTitle>Rename</DialogTitle>
        <Scrollbar className="form-scroller">
          <DialogContent>
            <Box component="form">
              <FormField
                label="Folder Name"
                error={dirtyFields.name ? errors.name : undefined}
              >
                <TextField
                  {...register("name")}
                  autoFocus
                  margin="dense"
                  id="name"
                  type="text"
                  fullWidth
                />
              </FormField>
            </Box>
          </DialogContent>
        </Scrollbar>
        <DialogActions>
          <Button onClick={closeHandler} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit(submitHandler)}
            variant="contained"
            loading={isLoading}
            type="submit"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const SideNavTopSelector: React.FC = () => {
  const selectedValue = useSystemLayoutStore.useSideNavSelectorValue();
  const setValue = useSystemLayoutStore.useSetSideNavSelectorValue();
  const options = useSystemLayoutStore.useSideNavSelectorOptions();
  const props = useSystemLayoutStore.useSideNavSelectorProps();
  const type = useSystemLayoutStore.useSideNavSelectorType();

  return (
    <DrawerHeader>
      {type === NAV_SELECTOR_TYPE.SELECT ? (
        <Select
          onChange={(e) => {
            setValue(options.find((op) => op.key === e.target.value));
            props.onSelect?.(options.find((op) => op.key === e.target.value));
          }}
          value={selectedValue.key}
          size="small"
          className="header-select"
          IconComponent={ExpandMoreIcon}
          sx={{
            width: "100%",
            ".MuiSelect-select": {
              minHeight: "48px !important",
              padding: "6px 12px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
          }}
        >
          {options.map((item) => (
            <MenuItem value={item.key} key={item.key} sx={{ gap: "6px" }}>
              {item.icon && (
                <GenericIcon
                  className="header-select-icon"
                  key={item.icon}
                  iconName={item.icon as any}
                  color={"primary"}
                />
              )}{" "}
              <Box className="header-title">{item.title}</Box>
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Box
          sx={{
            width: "100%",
            minHeight: "48px !important",
            padding: "6px 12px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
          }}
        >
          <GenericIcon
            iconName={selectedValue.icon}
            sx={{ marginTop: "5px" }}
          />
          <Typography>{selectedValue.title}</Typography>
        </Box>
      )}
    </DrawerHeader>
  );
};

type SideNavProps = {
  disableDrawer?: boolean;
};

type SortItemType = {
  id: UniqueIdentifier;
  children: { id: UniqueIdentifier; data: { id: string; slug: string } }[];
}[];
const SideNav: React.FC<SideNavProps> = (props) => {
  const itemsLoading = useSystemLayoutStore.useSideNavItemsLoading();
  const rawMenu = useSystemLayoutStore.useMenu();
  const layout = useSystemLayoutStore.useLayout();
  const disableFolders = useSystemLayoutStore.useDisableFolders();
  const showActionButtons = useSystemLayoutStore.useShowActionButtons();
  const buttonOptions = useSystemLayoutStore.useButtonOptions();
  const showSideNavTopSelector =
    useSystemLayoutStore.useShowSideNavTopSelector();
  const enableSideNavSearch = useSystemLayoutStore.useEnableSideNavSearch();
  const menuListSearchOptions = useSystemLayoutStore.useMenuListSearchOptions();

  const onRename = useSystemLayoutStore.useOnRename?.();

  const { enqueueSnackbar } = useSnackbar();
  const [isDragging, setDragging] = React.useState(false);
  const [itemContextMenu, setItemContextMenu] =
    React.useState<{
      mouseX: number;
      mouseY: number;
      item: AppItem;
    } | null>(null);
  const [containerContextMenu, setContainerContextMenu] =
    React.useState<{
      mouseX: number;
      mouseY: number;
      item: Folder;
    } | null>(null);
  const [isRenameModelOpen, setRenameModelOpen] = React.useState(false);

  const [selectedFolder, setSelectedFolder] = React.useState<Folder | null>();
  const routeModule = useRouteToApiModel();
  const { data: folders } = useFolders();
  const fuseRef = React.useRef(new Fuse(rawMenu, menuListSearchOptions));
  const searchValue = useSystemLayoutStore.useSearchValue?.();
  const setSearchValue = useSystemLayoutStore.useSetSearch?.();
  const useCustomSearch = useSystemLayoutStore.useCustomSearch?.();

  const [sortItems, setSortItems] = React.useState<SortItemType>([]);

  const { mutate: updateItemSort } = useMutation({
    mutationFn: async ({ data }: any) => {
      await FolderModel.sort(data);
    },
    onSuccess: (_) => {
      console.log("success");
    },
  });

  // console.log("🚀 ~ file: index.tsx:662 ~ SideNav ~ routeModule:", routeModule);
  const { mutate: deleteItem, isLoading: isDeleting } = useDeleteItem({
    modelName: routeModule! as any,
  });

  React.useEffect(() => {
    fuseRef.current = new Fuse(rawMenu, menuListSearchOptions);
  }, [rawMenu, menuListSearchOptions]);

  const menu = React.useMemo(() => {
    if (!searchValue?.length) return rawMenu;
    if (useCustomSearch) return rawMenu;
    return fuseRef.current.search(searchValue)?.map((i) => i.item);
  }, [searchValue, rawMenu]);

  const { sortItemFolderMap, sortItemMap } = React.useMemo(() => {
    const sortItems: SortItemType = [];

    const sortItemFolderMap = new Map();
    const sortItemMap = new Map();
    /// When in Search view no need to drag and drop
    if (layout === "search" || !searchValue.length) {
      menu.forEach((m) => {
        sortItemMap.set(m.key, m);
      });
      folders
        ?.sort((a, b) => a.sort_order - b.sort_order)
        .forEach((folder) => {
          const children: typeof sortItems[0]["children"] = [];

          folder.childs.forEach((child) => {
            if (child?.slug && sortItemMap.get(child.slug))
              children.push({
                id: child.slug,
                data: { id: child.id, slug: child.slug },
              });
          });

          sortItems.push({
            id: folder.slug,
            children,
          });
          sortItemFolderMap.set(folder.slug, folder);
        });
    }
    setSortItems(sortItems);
    return { sortItemFolderMap, sortItemMap };
  }, [layout, searchValue.length, folders, menu]);

  const handleSeach = (value: string) => {
    setSearchValue(value);
  };

  const containerSortingHanlder = (containers: SortItemType) => {
    setSortItems(containers);
    const data = containers.map((ctr) => {
      return {
        id: ctr.id,
      };
    });

    updateItemSort({ data: { data, resource: routeModule } });
  };
  // Interesting problem. if someone else creates a item and someone else sorted it. it's gonna make it disable in folder listing
  const itemSortingHanlder = (
    items: typeof sortItems[0]["children"],
    containerId: UniqueIdentifier
  ) => {
    setSortItems((prev) =>
      prev.map((ct) => {
        if (ct.id === containerId) {
          return { ...ct, children: items };
        }
        return ct;
      })
    );
    const data = [
      {
        id: containerId,
        childs: items.map((itkey) => {
          return {
            id: itkey.data.id,
            slug: itkey.id,
          };
        }),
      },
    ];

    updateItemSort({ data: { data, resource: routeModule } });
  };

  const handleItemContextMenu = React.useCallback(
    (event: React.MouseEvent, extra: AppItem) => {
      event.preventDefault();
      event.stopPropagation();
      setItemContextMenu(
        itemContextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
              item: extra,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    },
    [itemContextMenu]
  );
  const handleContainerContextMenu = React.useCallback(
    (event: React.MouseEvent, id: string) => {
      event.preventDefault();
      setContainerContextMenu(
        containerContextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
              item: sortItemFolderMap.get(id),
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    },
    [containerContextMenu, sortItemFolderMap]
  );
  const handleItemContextMenuClose = () => {
    setItemContextMenu(null);
  };
  const handleRenameItem = () => {
    onRename?.({
      title: itemContextMenu?.item.title!,
      key: itemContextMenu?.item.key!,
    });
    handleItemContextMenuClose();
  };

  const handleContainerContextMenuClose = () => {
    setContainerContextMenu(null);
  };
  const handleRenameContainer = () => {
    setSelectedFolder(containerContextMenu?.item);
    setRenameModelOpen(true);
    handleContainerContextMenuClose();
  };

  const handleRenameModelClose = () => {
    setSelectedFolder(null);
    setRenameModelOpen(false);
  };
  const ItemContextMenuOpen = itemContextMenu !== null;
  const containerContextMenuOpen = containerContextMenu !== null;

  const handleOnDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteItem({ slug: itemContextMenu?.item?.key! });
      }
    });

    handleItemContextMenuClose();
  };
  const handleScroll = throttle((event: any) => {
    const { scrollHeight, clientHeight, scrollTop } = event.target;

    // Detect if user has scrolled near the bottom, say 80% of the way
    const isNearBottom = scrollTop + clientHeight >= scrollHeight * 0.8;

    if (isNearBottom) {
      // Load more content here
      useSystemLayoutStore.getState().loadmore?.();
      // onRename?.({ title: "", key: "" });
    }
  }, 200);

  const content = (
    <>
      <BoxWrap>
        {showSideNavTopSelector && (
          <>
            <SideNavTopSelector />
            <DividerWrap />
          </>
        )}
        {enableSideNavSearch && (
          <Box sx={{ py: 1.375, px: 1.75 }}>
            <SearchInput
              id="outlined-adornment-password"
              placeholder="Search..."
              size="small"
              sx={{ m: 0 }}
              onChange={debounce((e) => {
                handleSeach(e.target.value);
              }, 300)}
            />
          </Box>
        )}
        <Scrollbar onScroll={handleScroll}>
          <MenuContainer sx={{ color: "black" }}>
            {layout === "search" ||
            searchValue?.length > 0 ||
            disableFolders ? (
              <Box>
                {menu.map((m) => {
                  return <CustomListItem item={m} hideDragHanlder />;
                })}
              </Box>
            ) : (
              <Spin spinning={itemsLoading}>
                <Box sx={{ ".SortableItem": { marign: 0 } }}>
                  {sortItems?.length > 0 && (
                    <SortableList
                      items={sortItems}
                      onChange={containerSortingHanlder}
                      renderItem={(item) => {
                        const folder = sortItemFolderMap.get(item.id);
                        if (!folder) return null;

                        return (
                          <SortableList.Item key={item.id} id={item.id} handle>
                            <MenuListContainer
                              title={folder.name}
                              id={item.id}
                              handleContextMenu={handleContainerContextMenu}
                            >
                              {item?.children?.length > 0 && (
                                <SortableList
                                  items={item?.children}
                                  onChange={(newitem) =>
                                    itemSortingHanlder(newitem, item.id)
                                  }
                                  renderItem={(folderItem) => {
                                    const fItem = sortItemMap.get(
                                      folderItem.id
                                    );
                                    if (!fItem) return null;

                                    return (
                                      <SortableList.Item
                                        key={folderItem.id}
                                        id={folderItem.id}
                                        handle
                                      >
                                        <CustomListItem
                                          item={fItem}
                                          handleContextMenu={
                                            handleItemContextMenu
                                          }
                                        />
                                      </SortableList.Item>
                                    );
                                  }}
                                />
                              )}
                            </MenuListContainer>
                          </SortableList.Item>
                        );
                      }}
                    />
                  )}
                </Box>
              </Spin>
            )}
          </MenuContainer>
        </Scrollbar>
      </BoxWrap>
      {showActionButtons && (
        <BottomSection className="bottom-btns">
          {buttonOptions.addFolder && <ButtonAddFolder />}
          {buttonOptions.addItem && <ButtonAdd />}
        </BottomSection>
      )}
    </>
  );
  const exportDataset = async () => {
    handleItemContextMenuClose();
    enqueueSnackbar("Exporting Dataset", { variant: "info" });
    const Data = (await DatasetDesignModel.get(itemContextMenu?.item?.key!))
      .data;
    exportData(JSON.stringify(Data, null, 2));
  };

  const importDataset: React.InputHTMLAttributes<HTMLInputElement>["onChange"] =
    async (e) => {
      handleContainerContextMenuClose();
      if (e.target?.files?.length === 0) return;
      enqueueSnackbar("Importing dataset", { variant: "info" });
      const data = await readFile(e.target.files?.[0]!);
      const dataset = JSON.parse(data as string) as unknown as
        | DatasetDesign
        | DatasetDesign[];
      try {
        if (Array.isArray(dataset)) {
          const res = await DatasetDesignModel.importBatch({
            data: dataset,
            folder: containerContextMenu?.item?.slug!,
          });
        } else if (dataset.slug) {
          const res = await DatasetDesignModel.import({
            data: dataset,
            folder: containerContextMenu?.item?.slug!,
          });
        }
        queryClient.refetchQueries([ApiModels.Folder, ApiModels.DatasetDesign]);
        queryClient.refetchQueries([ApiModels.DatasetDesign]);
        enqueueSnackbar("dataset successfully imported", {
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        enqueueSnackbar("failed importing", { variant: "error" });
      }
    };
  const exportDatasets = async () => {
    handleContainerContextMenuClose();
    enqueueSnackbar("Exporting Dataset", { variant: "info" });
    const Data = (
      await DatasetDesignModel.exportFolderDatasetDesign(
        containerContextMenu?.item?.slug!
      )
    ).data;
    exportData(JSON.stringify(Data, null, 2));
  };

  return (
    <Box>
      <FolderRenameModel
        folder={selectedFolder!}
        isOpen={isRenameModelOpen}
        onClose={handleRenameModelClose}
      />

      {/*Start Menu Item Context Menu */}
      <Menu
        open={ItemContextMenuOpen}
        onClose={handleItemContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          ItemContextMenuOpen
            ? { top: itemContextMenu.mouseY, left: itemContextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleRenameItem}>Rename</MenuItem>
        <MenuItem onClick={handleOnDelete}>Delete</MenuItem>
        {routeModule === "dataset-design" && (
          <MenuItem onClick={exportDataset}>Export</MenuItem>
        )}
      </Menu>
      {/*End Menu Item Context Menu */}

      {/*Start container Menu Context Menu */}
      <Menu
        open={containerContextMenuOpen}
        onClose={handleContainerContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          containerContextMenuOpen
            ? {
                top: containerContextMenu.mouseY,
                left: containerContextMenu.mouseX,
              }
            : undefined
        }
      >
        <MenuItem onClick={handleRenameContainer}>Rename</MenuItem>
        {routeModule === "dataset-design" && (
          <MenuItem onClick={exportDatasets}>Export All Datasets</MenuItem>
        )}
        {routeModule === "dataset-design" && (
          <MenuItem>
            <SimpleInputWrapper
              onChange={importDataset}
              accept="json"
              multiple={false}
            >
              Import
            </SimpleInputWrapper>
          </MenuItem>
        )}
      </Menu>
      {/*End container Menu Context Menu */}

      {props.disableDrawer ? (
        content
      ) : (
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            className: classNames({ "is-dragging": isDragging }),
            sx: { zIndex: (theme) => theme.zIndex.appBar - 1 },
          }}
        >
          {content}
          <DrawerOverlay className="overlay-holder"></DrawerOverlay>
        </Drawer>
      )}
    </Box>
  );
};

export default SideNav;
