import { AccountTreeRounded, Add, CheckRounded } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  Stack,
  Typography,
  styled,
} from "@mui/material";

import React from "react";
export const IconHolder = styled(Box)(({ theme }) => ({
  width: "35px",
  height: "35px",
  position: "absolute",
  top: 10,
  right: 10,
  svg: {
    width: "100%",
    height: "100%",
  },
}));
const CardContainer = styled(Box)({
  padding: "8px 15px",
  width: "100%",
  borderRadius: "8px",
  backgroundColor: "#1E1E23",
  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.04)",
  outline: "none",
  cursor: "grab",
});

const tags = [
  {
    label: "Core",
    color: "#0080B6",
  },
  {
    label: "Monkey",
    color: "#5F8C9E",
  },
  {
    label: "Brain",
    color: "#36A52D",
  },
  {
    label: "+2",
    color: "#17BAFF",
  },
];

type ScrumCardProps = {
  title: string;
  label: string;
  onEdit?: (_: any) => void;
  card?: Dataset;
};

const ScrumCard: React.FC<ScrumCardProps> = (props) => {
  const { title, label, onEdit, card } = props;

  return (
    <CardContainer
      sx={{ position: "relative" }}
      onClick={() => {
        onEdit?.(card);
      }}
    >
      <Stack direction="column" spacing={1}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: "#A3AAB1",
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: "#fff",
          }}
        >
          {title}
        </Typography>
        {/* <IconHolder data-no-dnd="true">
          <IconButton>
            <EditOutlined />
          </IconButton>
        </IconHolder> */}
        <Stack direction="row" spacing={1}>
          <Chip
            icon={<AccountTreeRounded fontSize="small" />}
            size="small"
            label="3"
            sx={{ borderRadius: "3px" }}
          />
          <Chip
            icon={<CheckRounded fontSize="small" />}
            size="small"
            label="1/2"
            sx={{ borderRadius: "3px" }}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          {tags.map((tag, idx) => (
            <Chip
              key={`${tag.label}-${idx}`}
              label={tag.label}
              sx={{
                background: tag.color,
                borderRadius: "3px",
                height: "20px",
              }}
              size="small"
            />
          ))}
        </Stack>
        <AvatarGroup max={4} sx={{ flexDirection: "row" }}>
          <Avatar
            alt="Assignee"
            src="https://mui.com/static/images/avatar/1.jpg"
            sx={{ width: "30px", height: "30px" }}
          />
        </AvatarGroup>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ display: "inline-flex" }}
        >
          <Add sx={{ width: "16px", height: "16px", color: "#a5a5a7" }} />
          <Typography
            sx={{ fontSize: "15px", fontWeight: 600, color: "#a5a5a7" }}
          >
            Subtask
          </Typography>
        </Stack>
      </Stack>
    </CardContainer>
  );
};

export default ScrumCard;
