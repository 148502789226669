import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, Stack, useTheme } from "@mui/material";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { SortableList } from "components/SortableList";
import React, { useMemo } from "react";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";

export type FusionActionsProps = {
  actions: FusionAction[];
  title?: string;
  onAddClick(): void;
  onEditClick(action: FusionAction): void;
  onDeleteClick(action: FusionAction): void;
  onSort(actions: FusionAction[]): void;
};

const FusionActions: React.FC<FusionActionsProps> = (props) => {
  const {
    actions,
    title = "Custom Actions",
    onAddClick,
    onEditClick,
    onDeleteClick,
    onSort,
  } = props;

  const theme = useTheme();

  const DefaultListItemStyles = useMemo(
    () => ({
      background: theme.palette.background.GFRightNavForeground,
      height: 48,
      ".MuiTypography-subtitle1": {
        margin: "0 0 2px",
      },
      ".DragHandle": {
        lineHeight: "1",
        width: "20px",
        height: "20px",
        margin: "0 6px 0 -6px",
        color: theme.palette.background.GF20,

        "&:hover": {
          color: theme.palette.background.GF50,

          path: {
            fill: theme.palette.background.GF50,
          },
        },

        path: {
          fill: theme.palette.background.GF20,
          transition: "all 0.4s ease",
        },

        svg: {
          width: "100%",
          height: "auto",
          display: "block",
        },
      },

      ".card-inner-content": {
        gap: "2px",
      },
    }),
    [
      theme.palette.background.GF20,
      theme.palette.background.GF50,
      theme.palette.background.GFRightNavForeground,
    ]
  );

  return (
    <SidebarSectionWrap title={title} onRightIconClick={onAddClick}>
      <SortableList
        items={actions}
        onChange={(updatedItems) => {
          onSort(updatedItems);
        }}
        renderItem={(item) => {
          return (
            <SortableList.Item id={item.id} handle>
              <ProfileCard
                options={{
                  draggable: true,
                  switcher: false,
                }}
                rightIcon={
                  <Stack direction="row" spacing={1}>
                    <Box className="edit-icon">
                      <DeleteOutline
                        sx={{ color: "grey.500" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteClick(item);
                        }}
                      />
                    </Box>
                    <Box className="edit-icon">
                      <EditOutlined sx={{ color: "grey.500" }} />
                    </Box>
                  </Stack>
                }
                title={item.action_title}
                sx={DefaultListItemStyles}
                onClick={() => {
                  onEditClick(item);
                }}
              />
            </SortableList.Item>
          );
        }}
      />
    </SidebarSectionWrap>
  );
};

export default FusionActions;
