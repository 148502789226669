import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type State = {
  view: "grid" | "list";
  searchTags: string[];
  count: number;
  sortValue: string;
  sortOptions: LabeledValue[];
  breadcrumbs: {
    id: string;
    name: string;
    url?: string;
  }[];
};

type Actions = {
  setView: (view: "grid" | "list") => void;
  setSearchTags(tags: string[]): void;
  addSearchTag(tag: string): void;
  removeSearchTag(tag: string): void;
  setCount(count: number): void;
  setSortValue(sortValue: string): void;
  setSortOptions(sortOptions: LabeledValue[]): void;
  setBreadcrumbs(
    breadcrumbs: {
      id: string;
      name: string;
      url?: string;
    }[]
  ): void;
};

export const useListingLayoutStoreBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      view: "grid",
      searchTags: [],
      count: 0,
      sortValue: "last_updated",
      sortOptions: [
        {
          label: "Last Updated",
          value: "last_updated",
        },
      ],
      breadcrumbs: [],
      setView(view) {
        set((state) => {
          state.view = view;
        });
      },
      setSearchTags(tags) {
        set((state) => {
          state.searchTags = tags;
        });
      },
      addSearchTag(tag) {
        set((state) => {
          state.searchTags.push(tag);
        });
      },
      removeSearchTag(tag) {
        set((state) => {
          state.searchTags = state.searchTags.filter((t) => t !== tag);
        });
      },
      setCount(count) {
        set((state) => {
          state.count = count;
        });
      },
      setSortValue(sortValue) {
        set((state) => {
          state.sortValue = sortValue;
        });
      },
      setSortOptions(sortOptions) {
        set((state) => {
          state.sortOptions = sortOptions;
        });
      },
      setBreadcrumbs(breadcrumbs) {
        set((state) => {
          state.breadcrumbs = breadcrumbs;
        });
      },
    }))
  )
);

export const useListingLayout = createSelectorHooks(useListingLayoutStoreBase);
