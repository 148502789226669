import { NorthEast } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { List } from "components/List";
import Spin from "components/Spin";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import useCurrentUser from "hooks/useCurrentUser";
import InnerPageLayout from "layouts/inner-app-layout";
import useFusionWebhooks from "queries/fusion/useFusionWebhooks";
import useListFusionsByWebhook from "queries/fusion/useListFusionsByWebhook";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSystemLayoutStore from "store";
import { API_POOL } from "utils/request";

const FusionWebhooks: React.FC = () => {
  const { webhookSlug } = useParams<{ webhookSlug: string }>();
  const appNavigate = useAppNavigate();
  const accountSlug = useAccountSlug();
  const user = useCurrentUser();

  const { data: webhooks } = useFusionWebhooks("no-module", user?.slug, {
    return: "all",
  });
  const { data: fusions, isFetching } = useListFusionsByWebhook(webhookSlug);

  const setMenu = useSystemLayoutStore.useSetMenu();
  const menuItemProps = useSystemLayoutStore.useSetItemProps();
  const setDisableFolders = useSystemLayoutStore.useSetDisableFolders();
  const setShowActionButtons = useSystemLayoutStore.useSetShowActionButtons();
  const setCustomSearch = useSystemLayoutStore.useSetCustomSearch();
  const searchValue = useSystemLayoutStore.useSearchValue();
  const setShowSideNavTopSelector =
    useSystemLayoutStore.useSetShowSideNavTopSelector();

  useEffect(() => {
    setDisableFolders(true);
    setShowSideNavTopSelector(false);
    setShowActionButtons(false);
    setCustomSearch(true);
  }, [
    setDisableFolders,
    setCustomSearch,
    setShowActionButtons,
    setShowSideNavTopSelector,
  ]);

  useEffect(() => {
    menuItemProps({
      onClick: (item) => {
        appNavigate(`/fusion-webhooks-module/${item.key}?t=0`);
      },
      isActive: (item) => item.key === webhookSlug,
    });
  }, [webhookSlug, accountSlug, menuItemProps, appNavigate]);

  useEffect(() => {
    if (!webhookSlug && webhooks && webhooks?.length > 0) {
      appNavigate(`/fusion-webhooks-module/${webhooks[0].slug}?t=0`);
    }
  }, [appNavigate, setMenu, webhookSlug, webhooks]);

  const menu = useMemo(
    () =>
      webhooks?.map((webhook) => ({
        key: webhook.slug,
        title: webhook.webhook_name,
        webhook_url: `${API_POOL["gui-fusion"]}/fusion/webhook/dedicated/${webhook.account_id}?id=${webhook.slug}`,
      })) || [],
    [webhooks]
  );

  const filteredMenu = useMemo(() => {
    if (!searchValue) {
      return menu;
    }

    return menu.filter((m) => {
      if (m.title.includes(searchValue)) {
        return true;
      }

      if (m.key.includes(searchValue)) {
        return true;
      }

      if (m.webhook_url.includes(searchValue)) {
        return true;
      }

      return false;
    });
  }, [searchValue, menu]);

  useEffect(() => {
    setMenu(filteredMenu);
  }, [filteredMenu, setMenu]);

  const webhookTitle = useMemo(
    () =>
      webhooks?.find((webhook) => webhook.slug === webhookSlug)?.webhook_name,
    [webhookSlug, webhooks]
  );

  return (
    <InnerPageLayout
      title={webhookTitle}
      subtitle={`List of fusions associated with webhook "${webhookTitle}"`}
      loading={isFetching}
    >
      <Spin spinning={isFetching}>
        {!!fusions?.length ? (
          <List
            data={fusions}
            hideSearch
            keyBinding={{
              title: "fusion_title",
              createdAt: "created_at",
              updatedAt: "updated_at",
              description: "fusion_type",
              icon: "fusion_icon",
            }}
            showAddButton={false}
            showLoadMoreButton={false}
            onItemClick={(item) => {
              appNavigate(`/fusion/flow-designer/${item.slug}`);
            }}
            rowActions={() => <NorthEast />}
          />
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <Typography>No Fusions</Typography>
          </Stack>
        )}
      </Spin>
    </InnerPageLayout>
  );
};

export default FusionWebhooks;
