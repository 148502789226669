import { Box, Grid, TextField } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import FormField from "components/FormField";
import {
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from "components/stripe/StripeFields";
import React, { useCallback } from "react";

export type PaymentFormRef = {
  validate: () => boolean;
  clear: () => void;
  getToken: () => any; //TokenResult typing not working
};
const initalState = {
  cardNumberComplete: false,
  expiredComplete: false,
  cvcComplete: false,
  cardNumberError: null,
  expiredError: null,
  cvcError: null,
};
const PaymentForm = React.forwardRef<PaymentFormRef, {}>((props, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] =
    React.useState<{
      cardNumberComplete: boolean;
      expiredComplete: boolean;
      cvcComplete: boolean;
      cardNumberError: null | string;
      expiredError: null | string;
      cvcError: null | string;
      name?: string;
      nameError?: string;
    }>(initalState);

  const onElementChange =
    (field: keyof typeof state, errorField: string) =>
    ({
      complete,
      error = { message: null },
    }: {
      complete: boolean;
      error: { message: string | null };
    }) => {
      setState({ ...state, [field]: complete, [errorField]: error.message });
    };

  const hanldeStripeValidation = useCallback(() => {
    let error = false;
    const { cardNumberComplete, expiredComplete, cvcComplete, name } = state;
    if (!name) {
      setState((pre) => ({
        ...pre,
        nameError: "Your card's number  is incomplete.",
      }));
      error = true;
    }
    if (!cardNumberComplete) {
      setState((pre) => ({
        ...pre,
        cardNumberError: "Your card's number  is incomplete.",
      }));
      error = true;
    }
    if (!expiredComplete) {
      setState((pre) => ({
        ...pre,
        expiredError: "Your card's expiration date is incomplete.",
      }));

      error = true;
    }
    if (!cvcComplete) {
      setState((pre) => ({
        ...pre,
        cvcError: "Your card's security code is incomplete.",
      }));

      error = true;
    }
    return error;
  }, [state]);

  const getToken = useCallback(async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cnum = elements.getElement(CardNumberElement);
    if (!cnum) {
      return;
    }

    const result = await stripe.createToken(cnum, {
      name: state.name,
    });
    console.log({ result });

    return result;
  }, [elements, stripe, state.name]);

  const clearFields = useCallback(() => {
    elements?.getElement(CardNumberElement)?.clear();
    elements?.getElement(CardExpiryElement)?.clear();
    elements?.getElement(CardCvcElement)?.clear();
    setState(initalState);
  }, [elements]);

  React.useImperativeHandle(
    ref,
    () => ({
      validate: hanldeStripeValidation,
      clear: clearFields,
      getToken,
    }),
    [clearFields, getToken, hanldeStripeValidation]
  );

  const handleNameChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setState((pre) => ({ ...pre, name: e.target.value }));
  };

  const { cardNumberError, expiredError, cvcError, name, nameError } = state;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={24} md={24}>
          <FormField label="Name on card">
            <TextField
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Name"
              onChange={handleNameChange}
              error={Boolean(name?.length)}
              helperText={Boolean(name?.length) ? nameError : ""}
            />
          </FormField>
        </Grid>
        <Grid item xs={24} md={24}>
          <StripeTextFieldNumber
            error={Boolean(cardNumberError)}
            labelErrorMessage={cardNumberError}
            onChange={onElementChange("cardNumberComplete", "cardNumberError")}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <StripeTextFieldExpiry
            error={Boolean(expiredError)}
            labelErrorMessage={expiredError}
            onChange={onElementChange("expiredComplete", "expiredError")}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StripeTextFieldCVC
            error={Boolean(cvcError)}
            labelErrorMessage={cvcError}
            onChange={onElementChange("cvcComplete", "cvcError")}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default PaymentForm;
