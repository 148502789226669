import AddOutlined from "@mui/icons-material/AddOutlined";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GfLogo } from "assets/icons";
import Spin from "components/Spin";
import useCurrentUser from "hooks/useCurrentUser";
import useOpenClose from 'hooks/useOpenClose';
import InvitationModal from "models/Invitation";
import UserModel from "models/User";
import AddAccountModal from "module/SidebarNav/AddAccountModal";
import moment from "moment";
import { queryClient } from "queries";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";
import RightSection from "./components/RightSection";

type Props = {};

export const ListItem = ({
  title,
  created_at,
  extra,
  icon,
}: {
  title: string;
  subtitle?: string;
  created_at?: string;
  extra?: React.ReactNode;
  icon?: React.ReactNode;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 1,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#1F1F27",
        borderRadius: "5px",
        mb: 2,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            height: "40px",
            width: "40px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: theme.palette.primary.main,
            borderRadius: "5px",
            marginRight: "12px",
          }}
        >
          {icon ? icon : <GfLogo />}
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, lineHeight: "110%" }}
          >
            {title}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            <span>Invitation Sent:</span> {moment(created_at).format("LLL")}{" "}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          justifyContent: "center",
          color: "white",
          alignItems: "center",
        }}
      >
        {extra && extra}
      </Box>
    </Box>
  );
};
const CenterBox = styled(Box)(({ theme }) => ({
  flexGrow: "1",
  flexBasis: "0",
  minWidth: "0",
  height: "calc(100vh - 132px)",

  ".side-menu-active &": {
    height: "100vh",
  },
}));

const RightSideBox = styled(Box)(({ theme }) => ({
  width: "420px",
  height: "calc(100vh - 60px)",

  ".side-menu-active &": {
    height: "100vh",
  },
}));

const Home = (props: Props) => {
  const [openAddAccountModal, onAddAccountModalOpen, onAddAccountModalClose] =
    useOpenClose();
  const theme = useTheme();
  const currentUser = useCurrentUser();
  const accounts = useStore.useAccounts?.();
  const setSelectedAccount = useStore.useSetSelectedAccount();
  const setAccounts = useStore.useSetAccounts();

  const navigate = useNavigate();
  const {
    data: Invitations,
    isLoading,
    isFetchedAfterMount,
    refetch,
  } = useQuery({
    queryFn: async () => {
      const res = await InvitationModal.list({
        query: { email: currentUser.email },
      });
      return res.data;
    },
    queryKey: ["user-invitations", currentUser.email],
  });

  const { mutate: updateStatus, isLoading: isUpdatingStatus } = useMutation(
    ["update_invaite"],
    async ({
      status,
      accountSlug,
    }: {
      status: "accept" | "reject";
      accountSlug: string;
    }) => {
      const res = InvitationModal.updateStatus(accountSlug, status);
      return res;
    }
  );

  const handleOpenWorkSpace = (account: AppAccount) => {
    setSelectedAccount(account.slug);
    setTimeout(() => navigate(`/${account.slug}`), 0);
    queryClient.clear();
  };

  const handleAccept = (accountSlug: string) => {
    // Logic to accept invitation
    console.log("Invitation Accepted");
    updateStatus(
      { status: "accept", accountSlug },
      {
        onSuccess: async () => {
          refetch();
          const userRes = await UserModel.getUser();
          setAccounts(userRes.data.accounts);
          console.log("success");
        },
      }
    );
  };

  const handleReject = (accountSlug: string) => {
    // Logic to reject invitation
    console.log("Invitation Rejected");
    updateStatus(
      { status: "reject", accountSlug },
      {
        onSuccess: () => {
          refetch();
          console.log("success");
        },
      }
    );
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }} m={-5}>
        <CenterBox m={5}>
          <Typography variant="h4" pb="20px">
            Welcome {currentUser.first_name}
          </Typography>
          <Box mb={2}>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="body1"
                sx={{
                  borderBottom: "3px solid",
                  borderColor: theme.palette.primary.main,
                  fontWeight: 600,
                  marginBottom: "20px",
                }}
              >
                Workspace Invitations
              </Typography>
            </Box>
            <Spin spinning={isLoading || isUpdatingStatus}>
              <React.Fragment>
                {Invitations?.map((item) => (
                  <ListItem
                    title={item.account_title}
                    created_at={item.created_at}
                    extra={
                      <React.Fragment>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={() => handleReject(item.id)}
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="success"
                          sx={{ color: "white" }}
                          onClick={() => handleAccept(item.id)}
                        >
                          Accept
                        </Button>
                      </React.Fragment>
                    }
                  />
                ))}
                {isFetchedAfterMount && Invitations?.length === 0 && (
                  <Box
                    sx={{
                      padding: 1,
                      backgroundColor: "#1F1F27",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="body1"> No Invitation</Typography>
                  </Box>
                )}
              </React.Fragment>
            </Spin>
          </Box>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="body1"
                sx={{
                  borderBottom: "3px solid",
                  borderColor: theme.palette.primary.main,
                  fontWeight: 600,
                  marginBottom: "20px",
                }}
              >
                Your Workspaces{" "}
              </Typography>
              <Button
                sx={{ marginLeft: 1 }}
                size="small"
                onClick={onAddAccountModalOpen}
              >
                <AddOutlined />
              </Button>
            </Box>

            <React.Fragment>
              {accounts?.map((item) => (
                <ListItem
                  title={item.name}
                  created_at={item.created_at}
                  icon={<span>{item.name[0]?.toLocaleUpperCase()}</span>}
                  extra={
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      sx={{ color: "white" }}
                      onClick={() => {
                        handleOpenWorkSpace(item);
                      }}
                    >
                      Open
                    </Button>
                  }
                />
              ))}
              {accounts?.length === 0 && (
                <Box
                  sx={{
                    padding: 1,
                    backgroundColor: "#1F1F27",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body1"> No Workspace</Typography>
                </Box>
              )}
            </React.Fragment>
          </Box>
        </CenterBox>
        <RightSideBox
          sx={{
            background: theme.palette.background.GFRightNavBackground,
            padding: "20px",
          }}
        >
          <RightSection />
        </RightSideBox>
      </Box>
      <AddAccountModal
        open={openAddAccountModal}
        onClose={onAddAccountModalClose}
      />
    </React.Fragment>
  );
};

export default Home;
