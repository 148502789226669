import { DragOverlay, UniqueIdentifier } from "@dnd-kit/core";
import { Box } from "@mui/material";
import { get } from "lodash";
import React, { Fragment, useMemo } from "react";
import { createPortal } from "react-dom";
import FolderCard from "../FolderCard";
import GridCard from "../GridCard";
import GridView from "./GridView";
import ListView from "./ListView";

export type ListingItem =
  | {
      type: "folder";
      folder: Record<string, any>;
    }
  | {
      type: "grid-item";
      item: Record<string, unknown>;
    };

export type ListItemAction = {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?(id: string): void;
};

export type GridKeyMap = {
  id: string;
  imageUrl?: string;
  icon?: string;
  title?: string;
  subtitle?: string;
  link?: string;
};

export type TableColumns = {
  label: React.ReactNode;
  fieldKey: string;
  folderKey: string;
};

export type ListingProps = {
  data: ListingItem[];
  gridKeyMap?: GridKeyMap;
  actions?: ListItemAction[];
  folderActions?: {
    title?: React.ReactNode;
    icon?: React.ReactNode;
    onClick?(folder: Folder): void;
  }[];
  idKey: string;
  tableColumns: TableColumns[];
  onOpenClick?(id: string): void;
  onMediaClick?(id: string): void;
  onFolderClick?(folder: Folder): void;
  onItemDrop?(folderSlug: string, itemSlug: string): void;
  onFolderDrop?(folderSlug: string, parentFolder: string): void;
  activeKey?: UniqueIdentifier | null;
  view?: "grid" | "list";
};

const Listing: React.FC<ListingProps> = (props) => {
  const {
    data = [],
    actions = [],
    folderActions = [],
    gridKeyMap,
    idKey,
    tableColumns,
    onOpenClick,
    onMediaClick,
    onFolderClick,
    activeKey,
    view,
  } = props;

  const activeContainerData = useMemo(() => {
    if (!activeKey) return null;
    return data.find((v) => {
      const itemId = get(v, `item.${gridKeyMap?.id}`);
      return v.type === "folder"
        ? `folder-card_${v.folder.slug}` === activeKey
        : `grid-card_${itemId}` === activeKey;
    });
  }, [data, activeKey, gridKeyMap?.id]);

  return (
    <Fragment>
      <Box>
        {view === "grid" && (
          <GridView
            data={data}
            actions={actions}
            gridKeyMap={gridKeyMap}
            onFolderClick={onFolderClick}
            onOpenClick={onOpenClick}
            onMediaClick={onMediaClick}
            folderActions={folderActions}
          />
        )}
        {view === "list" && (
          <ListView
            data={data}
            idKey={idKey}
            tableColumns={tableColumns}
            actions={actions}
            folderActions={folderActions}
            onOpenClick={onOpenClick}
            onFolderClick={onFolderClick}
          />
        )}
      </Box>
      {createPortal(
        <DragOverlay
          // adjustScale={adjustScale}
          // dropAnimation={dropAnimation}
          zIndex={100000}
        >
          {activeContainerData && (
            <React.Fragment>
              {activeContainerData?.type === "folder" ? (
                <FolderCard
                  folder={activeContainerData.folder as Folder}
                  overlay={true}
                />
              ) : (
                <GridCard
                  overlay={true}
                  id={
                    get(activeContainerData, `item.${gridKeyMap?.id}`) as string
                  }
                  imageUrl={
                    get(
                      activeContainerData,
                      `item.${gridKeyMap?.imageUrl}`
                    ) as string
                  }
                  icon={
                    get(
                      activeContainerData,
                      `item.${gridKeyMap?.icon}`
                    ) as string
                  }
                  title={
                    get(
                      activeContainerData,
                      `item.${gridKeyMap?.title}`
                    ) as string
                  }
                  subtitle={
                    get(
                      activeContainerData,
                      `item.${gridKeyMap?.subtitle}`
                    ) as string
                  }
                  link={
                    get(
                      activeContainerData,
                      `item.${gridKeyMap?.link}`
                    ) as string
                  }
                />
              )}
            </React.Fragment>
          )}
        </DragOverlay>,
        document.body
      )}
    </Fragment>
  );
};

export default Listing;
