import { Box } from "@mui/material";
import React, { forwardRef } from "react";

import ColumnHeader from "views/app-view/gui-workflow-board/components/ColumnHeader";

export interface ContainerProps {
  children: React.ReactNode;
  columns?: number;
  label?: string;
  style?: React.CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  scrollable?: boolean;
  shadow?: boolean;
  placeholder?: boolean;
  unstyled?: boolean;
  status: WorkflowStatus & {
    children: Dataset[];
  };
  onClick?(): void;
  onRemove?(): void;
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      status,
      ...props
    },
    ref
  ) => {
    return (
      <Box
        {...props}
        ref={ref}
        style={style}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        <Box {...handleProps} sx={{ marginBottom: "20px" }}>
          <ColumnHeader
            title={status.label}
            count={status.children.length}
            color={status.color}
          />
        </Box>
        {children}
      </Box>
    );
  }
);

export default Container;
