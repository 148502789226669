import Model from "models";
import qs from "query-string";

class ThreePAppActionModel {
  private baseModel = new Model<ThreePAppAction>("/3p-apps-action", "dev-sett");

  async list(appSlug?: string, query?: Record<string, any>) {
    return await this.baseModel.sendRequest<ThreePAppAction[]>(
      `/list/${appSlug}?${qs.stringify(query || {})}`,
      "GET"
    );
  }

  async create(appSlug: string, data: Partial<ThreePAppAction>) {
    return await this.baseModel.sendRequest<ThreePAppAction>(
      `/${appSlug}`,
      "POST",
      data
    );
  }

  async get(
    slug: string,
    options?: { query?: Record<string, any>; path?: string }
  ) {
    return await this.baseModel.sendRequest<ThreePAppAction>(
      `${options?.path ? `/${options.path}` : ""}/${slug}${
        options?.query ? `?${qs.stringify(options.query)}` : ``
      }`,
      "GET"
    );
  }

  async update(slug: string, data: Partial<ThreePAppAction>) {
    return await this.baseModel.sendRequest<ThreePAppAction>(
      `/${slug}`,
      "PUT",
      data
    );
  }

  async delete(slug: string) {
    return await this.baseModel.sendRequest(`/${slug}`, "DELETE");
  }

  async getEpochResponse(slug: string, data?: Record<string, unknown>) {
    return await this.baseModel.sendRequest<EpochResponse[]>(
      `/${slug}/epoch`,
      "POST",
      data
    );
  }
}

export default new ThreePAppActionModel();
