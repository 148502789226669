import {
  ArrowBackOutlined,
  CheckOutlined,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
import { Box, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import AddButton from "assets/icons/AddButton";
import FormField from "components/FormField";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { SortableList } from "components/SortableList";
import useOpenClose from "hooks/useOpenClose";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";
import AddMenuItemModel from "./AddMenuItemModel";

import { useMutation } from "@tanstack/react-query";
import Spin from "components/Spin";
import UserMenuItem from "models/UserMenuItem";
import { queryClient } from "queries";
import useDeleteItem from "queries/useDeleteItem";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import { useParams } from "react-router-dom";
import { getSearchParams } from "utils";
import { confirmDelete } from "utils/confirmDelete";
import SaveAsMenuTemplate from "./SaveAsMenuTemplate";

const getInitialComponent = () => {
  return getSearchParams().get("s_name") || "main";
};
type Props = {
  onEditMenuItem: (menuItem: UserMenuItem) => void;
  goBack: () => void;
};

function MenuEditor({ goBack, onEditMenuItem }: Props) {
  const [isOpen, onOpen, onClose] = useOpenClose();
  const [isTemplateModelOpen, onTemplateModelOpen, onTemplateModelClose] =
    useOpenClose();
  const { slug: accountUserSlug } = useParams<{ slug: string }>();
  const { mutate: updateAccountUser } = useUpdateItem({
    modelName: ApiModels.AccountUser,
    mutationOptions: {
      mutationKey: [ApiModels.AccountUser],
    },
  });
  const [sortedMenu, setSortedMenu] = useState<UserMenuItem[]>();
  const { data: templates } = useListItems({
    modelName: ApiModels.UserMenuTemplate,
    requestOptions: { query: { user_slug: accountUserSlug } },
    queryKey: [ApiModels.UserMenuTemplate, accountUserSlug],
  });
  const { mutate: updateSorting } = useMutation({
    mutationFn: async (ids: number[]) => {
      return await UserMenuItem.updateSorting({ sorted_menu_ids: ids });
    },
  });

  const { mutate: onDelete } = useDeleteItem({
    modelName: ApiModels.UserMenuItem,
  });
  const { data: guis } = useListItems({ modelName: ApiModels.Gui });
  const guiMap = useMemo(() => {
    return new Map(guis?.map((item) => [item.slug, item]));
  }, [guis]);
  const theme = useTheme();
  const { data: accountUser } = useGetItem({
    modelName: ApiModels.AccountUser,
    slug: accountUserSlug,
    queryOptions: { enabled: false },
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<any>({
    mode: "onBlur",
    // resolver: zodResolver(addAssociatedFieldFormSchema),
    defaultValues: {
      template_id: "",
    },
  });
  // const initialValueSet = useRef(false);
  // const allowNetworkRequest = useRef(false);
  const templateId = watch("template_id");
  const { data: menuItem, isFetching: isMenuItemLoading } = useListItems({
    modelName: ApiModels.UserMenuItem,
    requestOptions: {
      query: { template_id: templateId },
    },
    queryKey: [templateId, ApiModels.UserMenuItem],
    queryOptions: {
      enabled: !!templateId && templateId !== "custom",
    },
  });

  const ctemplates = useMemo(() => {
    const temp = [{ label: "Custom", value: "custom" }];
    templates?.forEach((tmp) => {
      if (tmp.template_slug === `user_${accountUserSlug}`) {
        temp[0].value = tmp.id as any;
      } else {
        temp.push({ label: tmp.template_name, value: tmp.id as any });
      }
    });
    return temp;
  }, [accountUserSlug, accountUser?.subscription?.template_id, templates]);

  console.log("template_id", templateId);

  useEffect(() => {
    setSortedMenu(menuItem?.sort((a, b) => a.sort_order - b.sort_order));
  }, [menuItem]);

  const submitHandler = useCallback(
    (template_id: number | "custom") => {
      if (accountUserSlug && template_id !== "custom") {
        updateAccountUser(
          { slug: accountUserSlug, data: { template_id: template_id } as any },
          {
            onSuccess: () => {
              console.log("inner success");
            },
          }
        );
      }
    },
    [accountUserSlug, updateAccountUser]
  );

  // useEffect(() => {
  //   initialValueSet.current = false;
  // }, [accountUserSlug]);

  useEffect(() => {
    setValue("template_id", accountUser?.subscription?.template_id);
    // if (accountUser && !initialValueSet.current) {

    //   initialValueSet.current = true;
    //   setTimeout(() => {
    //     allowNetworkRequest.current = true;
    //   }, 1000);
    // }
  }, [setValue, accountUser?.subscription?.template_id]);
  const deleteHandler = useCallback(
    (id: number) => {
      confirmDelete().then((result) => {
        if (!result.isConfirmed) return;
        onDelete(
          { slug: id as unknown as string },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([
                templateId,
                ApiModels.UserMenuItem,
              ]);
            },
          }
        );
      });
    },
    [onDelete, templateId]
  );
  return (
    <Box>
      <SidebarSectionWrap
        title="Menu Settings"
        rightIcon={false}
        leftIcon={<ArrowBackOutlined />}
        onLeftIconClick={goBack}
      >
        <FormField label="Menu Template">
          <Controller
            control={control}
            name="template_id"
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  onChange={(v) => {
                    field.onChange(v);
                    submitHandler(v.target.value as any);
                  }}
                  id="title"
                  margin="dense"
                  type="number"
                  fullWidth
                  placeholder="0"
                  hiddenLabel
                  size="small"
                  variant="filled"
                  select
                >
                  {ctemplates?.map((tmp) => {
                    return (
                      <MenuItem value={tmp.value} key={tmp.value}>
                        {tmp.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              );
            }}
          />
        </FormField>
      </SidebarSectionWrap>

      <SidebarSectionWrap
        title="Custom Menu Segments"
        rightIcon={
          <Box sx={{ lineHeight: 0 }}>
            <CheckOutlined onClick={onTemplateModelOpen} />
            <AddButton onClick={onOpen} />
          </Box>
        }
      >
        {" "}
        <Spin spinning={isMenuItemLoading}>
          <SortableList
            items={sortedMenu || []}
            onChange={(updatedItems) => {
              console.log("updatedItems", updatedItems);
              const ids = updatedItems.map((i) => i.id);
              updateSorting(ids);
              setSortedMenu(updatedItems);
              queryClient.setQueryData(
                [templateId, ApiModels.UserMenuItem],
                updatedItems
              );
            }}
            renderItem={(item) => {
              return (
                <SortableList.Item id={item.id} key={item.id} handle>
                  <ProfileCard
                    options={{
                      draggable: true,
                      switcher: false,
                    }}
                    rightIcon={
                      <Stack direction="row" spacing={1}>
                        <Box className="edit-icon">
                          <DeleteOutline
                            sx={{ color: "grey.500" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              deleteHandler(item.id!);
                            }}
                          />
                        </Box>
                        <Box className="edit-icon">
                          <EditOutlined sx={{ color: "grey.500" }} />
                        </Box>
                      </Stack>
                    }
                    subTitle={guiMap.get(item.gui_to_link_id)?.name}
                    title={item.label}
                    sx={{
                      background: theme.palette.background.GFRightNavForeground,
                      height: 48,
                      ".MuiTypography-subtitle1": {
                        margin: "0 0 2px",
                      },
                      ".DragHandle": {
                        lineHeight: "1",
                        width: "20px",
                        height: "20px",
                        margin: "0 6px 0 -6px",
                        color: theme.palette.background.GF20,

                        "&:hover": {
                          color: theme.palette.background.GF50,

                          path: {
                            fill: theme.palette.background.GF50,
                          },
                        },

                        path: {
                          fill: theme.palette.background.GF20,
                          transition: "all 0.4s ease",
                        },

                        svg: {
                          width: "100%",
                          height: "auto",
                          display: "block",
                        },
                      },

                      ".card-inner-content": {
                        gap: "2px",
                      },
                    }}
                    onClick={() => onEditMenuItem?.(item)}
                  />
                </SortableList.Item>
              );
            }}
          />
        </Spin>
      </SidebarSectionWrap>

      <AddMenuItemModel
        open={isOpen}
        onClose={onClose}
        templateId={templateId}
      />
      <SaveAsMenuTemplate
        open={isTemplateModelOpen}
        onClose={onTemplateModelClose}
        templateId={templateId}
      />
    </Box>
  );
}

export default MenuEditor;
