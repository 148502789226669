import { MoreVert } from "@mui/icons-material";
import CropIcon from "@mui/icons-material/Crop";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import ActionMenu from "components/ActionMenu";
import Cropper, { CropperData } from "components/Cropper";
import { DynamicEditFormProps } from "components/Form/DynamicEditForm";
import { List } from "components/List";
import { Row } from "components/List/BaseList";
import Icon from "components/util-components/Icon";
import { DocumentElementType } from "enums/Form";
import { CropperType } from "enums/gui";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import useOpenClose from "hooks/useOpenClose";
import {
  AnimationLayoutRef,
  TransitionComponent,
} from "layouts/AnimationLayout";
import debounce from "lodash/debounce";
import DatasetModel from "models/Dataset";
import FusionModel from "models/Fusion";
import { ApiModels } from "queries/apiModelMapping";
import useDeleteItem from "queries/useDeleteItem";
import useGetItem from "queries/useGetItem";
import useListInfiniteItems from "queries/useListInfiniteItems";
import useUpdateItem from "queries/useUpdateItem";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DatasetDraft, useDatasetStore } from "store/stores/dataset";
import { getSearchParams } from "utils";
import { confirmDelete } from "utils/confirmDelete";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import { generateDatasetTransitionHistoryAndDrafts } from "..";
import AddDatasetModel from "../AddDatasetModal";
import DatasetEditDrawer from "../DatasetEditDrawer";
const TabStyle = ({ title }: { title: string }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      <Typography className="tab-text" sx={{ color: "#fff" }}>
        {title}
      </Typography>
      <Typography
        sx={{ color: theme.palette.background.GF40 }}
        className="counter"
      >
        4
      </Typography>
    </Stack>
  );
};

export const ImageComponent = (props: any) => {
  const [open, setOpen] = useState(false);

  // Toggle the open state of the lightbox
  const handleAvatarClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Avatar
        onClick={handleAvatarClick}
        src={props?.url}
        alt={props?.name}
        sx={{
          width: "100%",
          height: "100%",
          img: {
            maxWidth: 600,
            maxHeight: 600,
            objectFit: "contain",
            objectPosition: "center top",
          },
        }}
        variant="square"
      ></Avatar>
      <Lightbox
        index={0}
        plugins={[Video]}
        open={open}
        close={onClose}
        slides={[{ type: "image", src: props.url!, alt: props.name }]}
        carousel={{ finite: true }}
      />
    </Box>
  );
};

export const CropDatasetImage = ({
  datasetSlug,
  field,
  metadeta,
  dataset,
  refetch,
  onClose,
  onRestoreHandler,
  ...rest
}: Omit<React.ComponentProps<typeof Cropper>, "image"> & {
  dataset: Dataset;
  datasetSlug: string;
  field: DataField;
  metadeta: { cropper_type: CropperType; width?: number; height?: number };
  refetch?: any;
  onRestoreHandler?: any;
}) => {
  const [useOrignalImage, setUseOrignalImage] = useState(false);
  const { mutate: processImage, isLoading } = useMutation({
    mutationFn: async ({ data }: any) => {
      await DatasetModel.processImage(data);
    },
    onSuccess: (_) => {
      console.log("success");
    },
  });
  const imageUrl = (dataset?.[field?.slug] as any)?.url as string;
  const orignalUrl = (dataset?.[field?.slug] as any)?.orignal_url as string;

  const handleClose = () => {
    setUseOrignalImage(false);
    onClose?.();
  };
  const handleSave = (d: CropperData) => {
    if (datasetSlug)
      processImage(
        {
          data: {
            datasetSlug: datasetSlug,
            datasetDesignSlug: dataset.dataset_type_slug,
            field: field,
            value: {
              ...(dataset?.[field.slug] || {}),
              url: useOrignalImage ? orignalUrl : imageUrl,
            },
            ...d,
          },
        },
        {
          onSuccess: () => {
            refetch?.();
            handleClose();
          },
          onError: () => {
            //TODO:HandleError
          },
        }
      );
  };
  const handleRestore = () => {
    onRestoreHandler?.({
      [field.slug]: {
        ...(dataset?.[field.slug] || {}),
        url: orignalUrl,
      },
    });
    handleClose();
  };
  if (!dataset?.slug) return null;

  return (
    <Cropper
      {...rest}
      onClose={handleClose}
      image={useOrignalImage ? orignalUrl : imageUrl}
      onSave={handleSave}
      isLoading={isLoading}
      crop_type={metadeta?.cropper_type as any}
      width={metadeta?.width}
      height={metadeta?.height}
      extra={
        orignalUrl ? <Button onClick={handleRestore}>Restore</Button> : null
      }
    />
  );
};
const DatasetListing = ({
  onEditHandler,
  onFormEvent,
  includedFieldIds,
  // datasetSlug,
  datasetDesignSlug: slug,
  datasetQueryFilter,
  linkedField,
  tabMetadata,
  type,
  tabActions = [],
}: {
  onEditHandler: (item?: Record<string, any>) => void;
  onFormEvent?: DynamicEditFormProps["onFormEvent"];
  includedFieldIds?: string[];
  // datasetSlug?: string;
  datasetDesignSlug: string;
  datasetQueryFilter: { [key: string]: any };
  linkedField: { slug: string; value: string };
  tabMetadata: IncludeTabs;
  type?: "row" | "card" | "default";
  tabActions?: FusionAction[];
}) => {
  const [selectDatasetSlug, setSelectDatasetSlug] = useState();
  const [addDatasetModalOpen, setAddDatasetModalOpen] = useState(false);
  // const navigate = useNavigate();
  const [searchTitle, setSearchTitle] = useState("");
  const theme = useTheme();

  // Cropper Related Fields
  const [field, setField] = useState<DataField>();
  const [datasetRecord, setDatasetRecord] = useState<Dataset>();
  const [openCropper, onCroperOpen, onCroperClose] = useOpenClose();
  // End Cropper Related Fields

  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isDrawerOpen, onDrawerOpen, closeDrawer] = useOpenClose(
    xlScreen && !!selectDatasetSlug
  );

  const pushDatasetDraft = useDatasetStore.usePushDatasetDraft();
  const popDatasetDraft = useDatasetStore.usePopDatasetDraft();
  const setDatasetDrafts = useDatasetStore.useSetDatasetDrafts();

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: slug,
  });

  const { data: dataset } = useGetItem({
    modelName: ApiModels.Dataset,
    slug: selectDatasetSlug,
    queryKey: [
      ApiModels.Dataset,
      { datasetDesignSlug: slug, datasetSlug: selectDatasetSlug },
    ],
    requestOptions: { path: slug },
  });

  const { mutate: deleteDataset } = useDeleteItem({
    modelName: ApiModels.Dataset,
  });

  const queryClient = useQueryClient();

  const handleOnCrop = (f: DataField, datasetRecord: Dataset) => {
    setField(f);
    onCroperOpen();
    setDatasetRecord(datasetRecord);
  };
  const { rowsInfo, rowsInfoString } = useMemo(() => {
    if (type === "row" && includedFieldIds?.length !== 0) {
      if (datasetDesign?.fields?.fields?.length) {
        const includedGuiFields =
          datasetDesign?.fields?.fields
            .filter((f) => includedFieldIds?.includes(f.id!) && f.slug)
            .sort((a, b) => {
              const indexA = includedFieldIds?.indexOf(a.id) || 0;
              const indexB = includedFieldIds?.indexOf(b.id) || 0;
              return indexA - indexB;
            })
            .map((f) => {
              const row: Row = { key: f.slug, title: f.title };

              if (
                [
                  DocumentElementType.Image,
                  DocumentElementType.File,
                  DocumentElementType.AudioVideo,
                ].includes(f.type)
              ) {
                row.render = (
                  value: { name: string; url: string },
                  dataset
                ) => {
                  if (tabMetadata.show_full_record && value?.url) {
                    if (
                      f.type === DocumentElementType.Image &&
                      tabMetadata?.field_metadata?.[f.id]?.cropper_type &&
                      tabMetadata?.field_metadata?.[f.id]?.cropper_type !==
                        CropperType.None
                    ) {
                      return (
                        <Box>
                          <Box
                            sx={{
                              position: "absolute",
                              top: "0px",
                              right: "20px",
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOnCrop(f, dataset as Dataset);
                              }}
                            >
                              <CropIcon
                                fontSize="small"
                                sx={{
                                  color: "success.dark",
                                  width: "16px",
                                  height: "auto",
                                }}
                              />
                            </IconButton>
                          </Box>
                          <ImageComponent url={value.url} name={value.name} />
                        </Box>
                      );
                    }
                    return <ImageComponent url={value.url} name={value.name} />;
                  } else {
                    return <>{value?.name}</>;
                  }
                };
              }
              return row;
            }) || [];

        return {
          rowsInfo: includedGuiFields,
          rowsInfoString: includedGuiFields.map((i) => i.key).join(","),
        };
      }
    }
    return {};
  }, [datasetDesign?.fields?.fields, includedFieldIds, type]);

  const { mutate: updateDataset, isLoading: updateingDataset } = useUpdateItem({
    modelName: ApiModels.Dataset,
    requestOptions: { path: slug },
  });
  const {
    data: datasetResult,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetched,
    refetch,
  } = useListInfiniteItems({
    modelName: ApiModels.Dataset,
    requestOptions: {
      query: {
        dataset_type_slug: slug,
        title: searchTitle,
        ...datasetQueryFilter,
        included_fields: rowsInfoString,
        filter: { conditions: tabMetadata.filter_rules ?? [] },
      },
      path: `list/${slug}`,
    },
    queryKey: [
      ApiModels.Dataset,
      {
        dataset_type_slug: slug,
        title: searchTitle,
        rowsInfoString,
        ...datasetQueryFilter,
        filter: { conditions: tabMetadata.filter_rules ?? [] },
      },
    ],
  });

  const datasets = useMemo(() => {
    const paginatedData: Dataset[] = [];
    datasetResult?.pages.forEach((group) => {
      group?.data?.forEach((data) => {
        paginatedData.push(data);
      });
    });
    return paginatedData;
  }, [datasetResult]);
  const layoutRef = useRef<AnimationLayoutRef>(null);
  const historyIsSet = useRef(false);

  useLayoutEffect(() => {
    if (!historyIsSet.current && xlScreen) {
      setTimeout(() => {
        const c = getSearchParams().get("c");
        if (datasetDesign && layoutRef.current) {
          const transitionHistory: TransitionComponent[] = [];
          const drafts: DatasetDraft[] = [];
          if (dataset && selectDatasetSlug) {
            drafts.push({ data: dataset, fields: datasetDesign.fields.fields });
            transitionHistory.push({
              name: "edit-dataset-form",
              id: dataset.slug,
            });
            const { transitionHistory: tHistory, datasetDrafts: dDrafts } =
              generateDatasetTransitionHistoryAndDrafts(
                c,
                dataset.fields,
                datasetDesign.fields.fields || []
              );
            transitionHistory.push(...tHistory);
            drafts.push(...dDrafts);
          }
          // console.log(
          //   "🚀 ~ file: index.tsx:154 ~ useLayoutEffect ~ transitionHistory:",
          //   transitionHistory,
          //   drafts
          // );
          layoutRef.current.setTransitionHistory(transitionHistory);
          setDatasetDrafts(drafts);

          if (selectDatasetSlug && dataset) {
            historyIsSet.current = true;
          }

          if (slug && datasetDesign && !selectDatasetSlug) {
            historyIsSet.current = true;
          }
        }
      }, Infinity - 1);
    }
  }, [datasetDesign, dataset]);

  useEffect(() => {
    if (selectDatasetSlug) {
      if (xlScreen) {
        onDrawerOpen();
      }
    }
  }, []);

  const onDrawerClose = () => {
    closeDrawer();
    popDatasetDraft();
  };

  const handleEdit = (item: Record<string, any>) => {
    // navigate(`${item.slug}`);
    // console.log("navigate");
    if (item) {
      if (xlScreen) {
        pushDatasetDraft(
          {
            data: item,
            fields: datasetDesign?.fields?.fields,
          },
          false
        );
        setSelectDatasetSlug(item.slug);
        onDrawerOpen();
      } else {
        onEditHandler?.(item);
      }
    }
  };

  const handleDeleteClick = (item: Dataset) => {
    confirmDelete().then(async (result) => {
      if (result.isConfirmed) {
        deleteDataset(
          { slug: `${slug}/${item.slug}` },
          {
            onSuccess(data, variables, context) {
              queryClient.setQueryData<InfiniteData<ApiResponse<Dataset[]>>>(
                [
                  [
                    ApiModels.Dataset,
                    { dataset_type_slug: slug, title: searchTitle },
                  ],
                ],
                (prev) => {
                  if (!prev) return prev;

                  return {
                    ...prev,
                    pages: prev.pages.map((page) => ({
                      ...page,
                      data: page.data.filter((i) => i.slug !== item.slug),
                    })),
                  };
                }
              );
            },
          }
        );
      }
    });
  };

  const handleItemClick = (item: Record<string, any>) => {
    if (smScreen) handleEdit(item);
  };

  const accountSlug = useAccountSlug();
  const user = useCurrentUser();

  const tabActionsWithDefault = useMemo(() => {
    const allowDelete = tabMetadata.allow_delete_record ?? true;
    const actions = [
      { action_title: "Edit", action_icon: "EditOutlined", id: "edit" },
    ];
    if (allowDelete) {
      actions.push({
        action_title: "Delete",
        action_icon: "DeleteOutline",
        id: "delete",
      });
    }

    actions.push(...tabActions);
    return actions;
  }, [tabActions, tabMetadata.allow_delete_record]);
  const submitHandler = (fields: Record<string, any>) => {
    if (datasetRecord?.slug!)
      updateDataset(
        { slug: datasetRecord?.slug!, data: { fields: fields } },
        {
          onSuccess: () => {
            refetch?.();
          },
          onError: () => {
            //TODO:HandleError
          },
        }
      );
  };

  return (
    <React.Fragment>
      <List
        data={datasets}
        handleSearch={debounce((e) => {
          setSearchTitle(e.target.value);
        }, 300)}
        keyBinding={{
          title: "title",
          createdAt: "created_at",
          updatedAt: "updated_at",
        }}
        addButtonProps={{ onClick: () => setAddDatasetModalOpen(true) }}
        onEditHandler={handleEdit}
        showLoadMoreButton={hasNextPage}
        loading={isFetching}
        onLoadMore={fetchNextPage}
        onItemClick={handleItemClick}
        onDeleteClick={handleDeleteClick}
        type={type}
        rows={rowsInfo}
        rowActions={(item: any) => {
          return (
            <ActionMenu
              menuItems={tabActionsWithDefault.map((action) => ({
                label: action.action_title,
                value: action.id,
                icon: <Icon iconName={action.action_icon} />,
              }))}
              onItemClick={(value) => {
                if (value === "edit") {
                  handleEdit?.(item);
                } else if (value === "delete") {
                  handleDeleteClick?.(item);
                } else {
                  const action = tabActions.find(
                    (action) => action.id === value
                  );
                  if (action && item && accountSlug && user.slug) {
                    FusionModel.runFusion(action.fusion_slug, {
                      user_id: user.slug,
                      account_id: accountSlug,
                      popup_variables: {
                        popup_variables: item,
                      },
                    });
                  }
                }
              }}
              containerSx={{ display: "inline" }}
            >
              <IconButton size="small">
                <MoreVert sx={{ color: "grey.500" }} />
              </IconButton>
            </ActionMenu>
          );
        }}
      />
      <AddDatasetModel
        open={addDatasetModalOpen}
        onClose={() => setAddDatasetModalOpen(false)}
        includedFieldIds={includedFieldIds!}
        datasetDesignSlug={slug}
        linkedField={linkedField}
        tabMetadata={tabMetadata}
      />
      <DatasetEditDrawer
        onFormEvent={onFormEvent}
        open={isDrawerOpen}
        onClose={onDrawerClose}
        layoutRef={layoutRef}
        // guiSlug={guiSlug}
        datasetDesignSlug={slug}
        datasetSlug={selectDatasetSlug}
        includedFieldIds={includedFieldIds!}
        includedTabs={tabMetadata.additional_tabs || []}
        formMetadata={tabMetadata as any}
        showWidget
      />

      <CropDatasetImage
        open={openCropper}
        dataset={datasetRecord!}
        datasetSlug={datasetRecord?.slug!}
        onClose={onCroperClose}
        field={field!}
        metadeta={tabMetadata.field_metadata?.[field?.id!]}
        refetch={refetch}
        onRestoreHandler={submitHandler}
      />
    </React.Fragment>
  );
};

export default DatasetListing;
