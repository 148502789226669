import { Autocomplete } from "@mui/material";
import { styled } from "@mui/material/styles";

const GfAutoComplete = styled(Autocomplete)(({ theme }) => {
  return {
    "&.Mui-expanded": {
      ".MuiFormControl-root ": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.text.primary_shades?.["30p"],
        },
      },
    },
    ".MuiPopover-root ": {
      position: "absolute",
      left: "0",
      top: "100%",
      bottom: "auto",
    },

    ".MuiFormControl-root ": {
      ".MuiInputBase-root": {
        paddingTop: "6px",
        paddingBottom: "6px",
        paddingLeft: "6px",
        background: theme.palette.background.GF5,
        borderColor: "transparent",
        transition: "all 0.4s ease",

        "&:hover": {
          background: theme.palette.background.GF10,
        },
      },

      ".MuiAutocomplete-input": {
        padding: "2.5px 4px 2.5px 6px",
      },

      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },

    ".MuiInputBase-input": {
      // padding: "0 !important",
    },

    ".MuiPaper-root ": {
      position: "absolute",
      left: "0 !important",
      top: "0 !important",
      maxHeight: "200px !important",
    },
  };
});

export default GfAutoComplete;
