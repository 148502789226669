import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Draggable from "components/Draggable";
import Droppable from "components/Droppable";
import React, { useState } from "react";
import { ListItemAction, ListingItem, TableColumns } from "./index";

type ListViewProps = {
  data: ListingItem[];
  actions?: ListItemAction[];
  folderActions?: {
    title?: React.ReactNode;
    icon?: React.ReactNode;
    onClick?(folder: Folder): void;
  }[];
  idKey: string;
  tableColumns: TableColumns[];
  onOpenClick?(id: string): void;
  onFolderClick?(folder: Folder): void;
};

const ListView: React.FC<ListViewProps> = (props) => {
  const {
    data,
    actions = [],
    folderActions = [],
    idKey,
    tableColumns = [],
    onOpenClick,
    onFolderClick,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [folderAnchorEl, setFolderAnchorEl] =
    useState<null | HTMLElement>(null);
  const folderOpen = Boolean(folderAnchorEl);

  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);

  const handleMoreClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleFolderMoreClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    folder: Folder
  ) => {
    setFolderAnchorEl(event.currentTarget);
    setSelectedFolder(folder);
  };

  const handleFolderMenuClose = () => {
    setFolderAnchorEl(null);
    setSelectedFolder(null);
  };

  return (
    <TableContainer component={Paper} sx={{ background: "transparent" }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableColumns.map((col) => (
              <TableCell align="left">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box>{col.label}</Box>
                </Stack>
              </TableCell>
            ))}
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            if (item.type === "folder") {
              return (
                <Droppable
                  id={`folder-card_${item.folder.slug}`}
                  dataId={item.folder.slug}
                  key={item.folder.slug}
                >
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={() => {
                      onFolderClick?.(item.folder as Folder);
                    }}
                  >
                    {tableColumns.map((tc) => (
                      <TableCell align="left">
                        <>{item.folder[tc.folderKey]}</>
                      </TableCell>
                    ))}
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFolderMoreClick(e, item.folder as Folder);
                        }}
                      >
                        <MoreHoriz fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </Droppable>
              );
            }

            return (
              <Draggable
                id={`grid-card_${item.item[`${idKey}`]}`}
                dataId={item.item[`${idKey}`] as string}
                key={`${item.item[`${idKey}`]}`}
              >
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  onClick={() => {
                    onOpenClick?.(`${item.item[`${idKey}`]}`);
                  }}
                >
                  {tableColumns.map((tc) => (
                    <TableCell align="left">
                      <>{item.item[tc.fieldKey]}</>
                    </TableCell>
                  ))}
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoreClick(e, `${item.item[`${idKey}`]}`);
                      }}
                    >
                      <MoreHoriz fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </Draggable>
            );
          })}
          <Menu
            id="edit-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            {actions?.map((action) => (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  selectedRow && action.onClick?.(selectedRow);
                }}
              >
                {action.icon} {action.title}
              </MenuItem>
            ))}
          </Menu>
          <Menu
            id="folder-menu"
            anchorEl={folderAnchorEl}
            open={folderOpen}
            onClose={handleFolderMenuClose}
          >
            {folderActions?.map((action) => (
              <MenuItem
                onClick={() => {
                  handleFolderMenuClose();
                  selectedFolder && action.onClick?.(selectedFolder);
                }}
              >
                {action.icon} {action.title}
              </MenuItem>
            ))}
          </Menu>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListView;
