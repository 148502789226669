import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import FusionModel from "models/Fusion";

const fetchFusionsByConnection = async (context: QueryFunctionContext) => {
  const [, connectionSlug] = context.queryKey;
  const res = await FusionModel.listFusionByConnection(
    connectionSlug as string
  );
  return res.data;
};

const useListFusionsByConnection = (connectionSlug?: string) => {
  return useQuery(
    ["fusions-by-connection", connectionSlug],
    fetchFusionsByConnection,
    {
      enabled: !!connectionSlug,
    }
  );
};

export default useListFusionsByConnection;
