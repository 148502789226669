import { UniqueIdentifier } from "@dnd-kit/core/dist/types";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { ArrowBack } from "@mui/icons-material";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { Box, Stack } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { MultipleContainers } from "components/drag-drop/MultipleContainer";
import { DraggableItemProps } from "components/drag-drop/components/Item/Item";
import { DocumentElementType } from "enums/Form";
import { GuiType } from "enums/gui";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { CSSProperties, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import DragFieldItem from "./DragFieldItem";
const ListItemStyle = styled(Box)(() => ({
  marginBottom: 10,
}));

const FieldsWrapper = styled(Box)(({ theme }) => {
  return {
    margin: "0 0 6px",

    ".title": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
      margin: "0 0 16px",
      textTransform: "capitalize",
    },
  };
});

const FieldsWrap = styled(Stack)(({ theme }) => {
  return {
    ".draggable-handle": {
      color: theme.palette.background.GF20,

      "&:hover": {
        background: "none",
        color: theme.palette.background.GF80,
      },

      svg: {
        color: "currentColor",
        fill: "currentColor",
      },
    },
  };
});

const FieldsSelection: React.FC<{
  gui: GfGui;
  indexKey: "form_fields" | "search_fields";
  onClickBack: () => void;
  onEditFilterRules?: (fieldId: DataField) => void;
  onEditImageSetting?: (fieldId: DataField) => void;
}> = ({
  gui,
  onClickBack,
  indexKey = "search_fields",
  onEditFilterRules,
  onEditImageSetting,
}) => {
  const selectedTab = useGuiDashboardStore.useSelectedTab();

  const guiTab = useMemo(() => {
    if (selectedTab != null && gui?.tabs) {
      return gui?.tabs?.[selectedTab];
    }
  }, [gui?.tabs, selectedTab]);

  const datasetDesignSlug =
    guiTab?.tab_type === GuiType.RecordList ||
    guiTab?.tab_type === GuiType.Reviewer
      ? guiTab.dataset_design_slug
      : undefined;

  const includedFields = useMemo(() => {
    if (guiTab?.tab_type === GuiType.Reviewer) {
      return indexKey === "form_fields" ? guiTab.form_fields || [] : [];
    }

    return guiTab?.tab_type === GuiType.RecordList
      ? guiTab?.[indexKey] || []
      : [];
  }, [guiTab, indexKey]);

  const theme = useTheme();
  const {
    slug: guiSlug,
    // datasetSlug,
  } =
    useParams<{
      slug: string;
      datasetSlug: string;
    }>();
  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetDesignSlug,
    queryOptions: { enabled: false },
  });
  const { mutate: updateGui } = useUpdateItem({ modelName: ApiModels.Gui });

  const { fieldsMap, items } = useMemo(() => {
    const excludedFields: string[] = [];
    const fieldsMap = new Map<string, DataField>();
    datasetDesign?.fields?.fields?.forEach((field) => {
      fieldsMap.set(field.id, field);
      if (!includedFields.includes(field.id)) {
        excludedFields.push(field.id);
      }
    });

    return {
      items: { included: includedFields, excluded: excludedFields },
      fieldsMap,
    };
  }, [datasetDesign, includedFields]);

  const onSortEndHandler = (
    _: UniqueIdentifier[],
    items: Record<UniqueIdentifier, UniqueIdentifier[]>
  ) => {
    if (guiSlug && guiTab) {
      updateGui(
        {
          slug: guiSlug,
          data: {
            tabs:
              gui.tabs?.map((t) =>
                t.id === guiTab.id
                  ? { ...guiTab, [indexKey]: items.included }
                  : t
              ) || [],
          },
        },
        {
          onSuccess: () => {
            console.log("gui edit success");
          },
        }
      );
    }
  };

  const handleFieldEdit = (d: DataField) => {
    // if (d.type === DocumentElementType.Select) {
    // }
    if (d.type === DocumentElementType.Image) {
      onEditImageSetting?.(d);
    } else {
      onEditFilterRules?.(d);
    }
  };

  return (
    <React.Fragment>
      <SidebarSectionWrap
        title="Back to Dataset"
        rightIcon={false}
        leftIcon={<ArrowBack />}
        onLeftIconClick={onClickBack}
      >
        <MultipleContainers
          items={items}
          minimal
          vertical={true}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          style={{ padding: 0 }}
          renderContainer={(prps: any) => {
            return (
              <FieldsWrapper>
                <Box className="title">{prps.id}</Box>
                <FieldsWrap ref={prps.ref} sx={{ minHeight: 60 }}>
                  {prps.children}
                </FieldsWrap>
              </FieldsWrapper>
            );
          }}
          renderItem={(prps: DraggableItemProps & { ref: any }) => {
            const item = fieldsMap.get(prps.value as unknown as string);
            if (!item) return null;

            const {
              transition,
              handleProps,
              listeners,
              transform,
              index,
              color,
              dragging,
              style,
              ref,
              ...rest
            } = prps;

            const styles: CSSProperties = {
              opacity: dragging ? 0.4 : undefined,
              transform: CSS.Translate.toString(transform as any),
              transition: transition as any,
            };
            return (
              <ListItemStyle
                style={styles as CSSProperties}
                id={item.id}
                ref={ref}
              >
                <DragFieldItem
                  item={item}
                  listeners={listeners}
                  handleProps={handleProps}
                  rightIcon={
                    <Box
                      className="edit-icon"
                      onClick={() => handleFieldEdit?.(item)}
                    >
                      <EditOutlined sx={{ color: "grey.500" }} />
                    </Box>
                  }
                />
              </ListItemStyle>
            );
          }}
          onSortEnd={onSortEndHandler as any}
        />
      </SidebarSectionWrap>
    </React.Fragment>
  );
};

export default FieldsSelection;
