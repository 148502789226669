import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  MenuItem,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import FormField from "components/FormField";
import SidebarSection from "components/RightSidebar";
import Spin from "components/Spin";
import { GUI_PARAM_TYPE } from "constants/index";
import useQuery from "hooks/useQuery";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  type: z.string(),
  title: z.string(),
  slug: z.string().min(1, "slug is required"),
  description: z.string(),
  is_required: z.boolean(),
  is_active: z.number(),
});
const CheckboxStyled = styled(Checkbox)({
  display: "block",
  width: "fit-content",
});

export type FormType = z.infer<typeof formSchema>;

type props = {
  tab?: GuiTab;
  onBackClick(): void;
};

const EditGuiParams: React.FC<props> = (props) => {
  const { tab, onBackClick } = props;
  const { paramSlug } = useQuery<{ paramSlug: string }>();
  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    control,
  } = useForm<FormType>({
    defaultValues: {
      type: "",
      title: "",
      slug: "",
      description: "",
      is_active: 0,
      is_required: false,
    },
    resolver: zodResolver(formSchema),
  });
  const { data: guiParam, isLoading } = useGetItem({
    modelName: ApiModels.GuiParams,
    slug: paramSlug,
    queryOptions: {
      onSuccess: (value) => {},
    },
  });
  const { mutate: updateParam, isLoading: isUpdating } = useUpdateItem({
    modelName: ApiModels.GuiParams,
  });
  const onSubmit = (value: FormType) => {
    updateParam(
      {
        data: { ...value, is_active: Number(value.is_active) as any },
        slug: paramSlug,
      },
      {
        onSuccess: () => {
          queryClient.refetchQueries([ApiModels]);
          onBackClick();
        },
      }
    );
  };

  useEffect(() => {
    reset(guiParam as unknown as FormType);
  }, [guiParam, reset]);

  // console.error(errors);
  return (
    <Box sx={{ px: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Spin spinning={isLoading}>
          <SidebarSection
            title="Update Paramater"
            leftIcon={<ArrowBack />}
            rightIcon={false}
            onLeftIconClick={onBackClick}
          >
            <FormField
              label="PARAMATER TYPE"
              error={dirtyFields.type ? errors.type : undefined}
            >
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <TextField
                    {...field}
                    autoFocus
                    margin="dense"
                    id="name"
                    type="text"
                    hiddenLabel
                    size="small"
                    variant="filled"
                    fullWidth
                    select
                  >
                    {Object.keys(GUI_PARAM_TYPE).map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
            </FormField>
            <FormField
              label="PARAMATER TITLE"
              error={dirtyFields.title ? errors.title : undefined}
            >
              <TextField
                {...register("title")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                hiddenLabel
                size="small"
                variant="filled"
                fullWidth
              />
            </FormField>
            <FormField
              label="PARAMATER SLUG"
              error={dirtyFields.slug ? errors.slug : undefined}
            >
              <TextField
                {...register("slug")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                hiddenLabel
                size="small"
                variant="filled"
                fullWidth
                disabled
              />
            </FormField>
            <FormField
              label="description"
              error={dirtyFields.description ? errors.description : undefined}
            >
              <TextField
                {...register("description")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                hiddenLabel
                size="small"
                variant="filled"
                fullWidth
                multiline
              />
            </FormField>
            <FormField
              label="Required"
              error={dirtyFields.is_required ? errors.is_required : undefined}
            >
              <Controller
                control={control}
                name="is_required"
                render={({ field }) => (
                  <CheckboxStyled
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </FormField>
            <FormField
              label="Is Active"
              error={dirtyFields.is_active ? errors.is_active : undefined}
            >
              <Controller
                control={control}
                name="is_active"
                render={({ field }) => (
                  <CheckboxStyled
                    checked={Boolean(field.value)}
                    onChange={(e) => field.onChange(Number(e.target.checked))}
                  />
                )}
              />
            </FormField>
          </SidebarSection>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1.5}
            sx={{ mt: 2 }}
          >
            <LoadingButton
              variant="outlined"
              color="inherit"
              sx={{
                border: "none",
                color: "#fff",
                background: (theme) => theme.palette.background.GF10,
              }}
              onClick={onBackClick}
              fullWidth
              loading={isUpdating}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                borderColor: "#fff",
                boxShadow: "none",
              }}
              fullWidth
              loading={isUpdating}
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </Spin>
      </form>
    </Box>
  );
};

export default EditGuiParams;
