import { Add, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";

const CardContainer = styled(Box)({
  padding: "12px 16px",
  width: "100%",
  borderRadius: "8px",
  backgroundColor: "#1E1E23",
  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.04)",
  outline: "none",
  cursor: "grab",
});

type AddTaskProps = {
  onAdd(data: Partial<Dataset>): Promise<void>;
};

const AddTask: React.FC<AddTaskProps> = (props) => {
  const { onAdd } = props;

  const [openTaskPopup, setOpenTaskPopup] = useState(false);
  const [datasetTitle, setDatasetTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const onAddDataset = () => {
    setLoading(true);
    onAdd({ title: datasetTitle })
      .then(() => {
        setLoading(false);
        setOpenTaskPopup(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return openTaskPopup ? (
    <CardContainer>
      <Stack direction="column" spacing={1}>
        <TextField
          size="small"
          placeholder="Task name"
          autoFocus
          value={datasetTitle}
          onChange={(e) => setDatasetTitle(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Close
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setOpenTaskPopup(false)}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            ".MuiInputBase-root": {
              minHeight: "auto",
              padding: 0,
            },
            ".MuiSvgIcon-root": {
              height: "16px",
              width: "16px",
            },
            ".MuiInputBase-input": {
              padding: 0,
              fontSize: "14px",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              padding: 0,
            },
          }}
        />
        <Stack direction="row-reverse">
          <LoadingButton
            color="primary"
            size="small"
            variant="contained"
            sx={{
              height: "24px",
              minWidth: "auto",
            }}
            onClick={onAddDataset}
            loading={loading}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </CardContainer>
  ) : (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ display: "inline-flex", cursor: "pointer" }}
      onClick={() => setOpenTaskPopup(true)}
    >
      <Add
        sx={{
          width: "16px",
          height: "16px",
          color: "#a5a5a7",
        }}
      />
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 600,
          color: "#a5a5a7",
        }}
      >
        New Task
      </Typography>
    </Stack>
  );
};

export default AddTask;
