import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import AppConfig from "models/AppConfig";
import { useStore } from "store";
import { ApiModels } from "./apiModelMapping";

const useGetAppConfig = (
  options?: Omit<
    UseQueryOptions<
      AppConfig,
      unknown,
      AppConfig,
      typeof ApiModels.AppConfig[]
    >,
    "initialData"
  >
) => {
  const setAppConfig = useStore.useSetAppConfig();
  return useQuery({
    queryKey: [ApiModels.AppConfig],
    queryFn: async () => {
      const res = await AppConfig.get();
      return res.data;
    },
    onSuccess: (data) => {
      setAppConfig(data);
    },
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    ...options,
  });
};

export default useGetAppConfig;
