import { ArrowBack } from "@mui/icons-material";
import { MenuItem, TextField } from "@mui/material";
import FormField from "components/FormField";
import IOSSwitch from "components/IOSSwitch";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { CROPPER_TYPE } from "constants/gui";
import { cloneDeep, debounce, set } from "lodash";
import get from "lodash/get";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";

const ImageSetting: React.FC<{
  gui: GfGui;
  onClickBack: () => void;
  indexKey: "included_tabs" | "included_sidebar_widgets" | "general";
  path?: string[];
  selectedField: DataField;
}> = ({
  gui,
  onClickBack,
  indexKey = "included_tabs",
  path = [],
  selectedField,
}) => {
  const { control, reset, register, handleSubmit, watch } = useForm();

  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);

  const selectedGuiTab = useGuiDashboardStore.useSelectedTab();

  const guiTab = useMemo(() => {
    if (selectedGuiTab != null && gui?.tabs) {
      return gui?.tabs?.[selectedGuiTab];
    }
  }, [gui?.tabs, selectedGuiTab]);

  const { selectedTab } = useMemo(() => {
    if (guiTab?.tab_type === "record_list") {
      if (indexKey === "general") {
        return {
          selectedTab: guiTab,
        };
      }
      let parentTab = guiTab[indexKey];

      const selectedTab = get(parentTab, path.slice(0, -1));

      return {
        selectedTab,
        parentTab:
          path.length > 2 ? get(parentTab, path.slice(0, -3)) : undefined,
      } as { selectedTab?: IncludeTabs; parentTab?: IncludeTabs };
    } else {
      return {};
    }
  }, [guiTab, indexKey, path]);

  const { mutate: updateGui } = useUpdateItem({
    modelName: ApiModels.Gui,
  });

  useEffect(() => {
    initialValueSet.current = false;
    allowNetworkRequest.current = false;
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab) {
      reset(selectedTab?.[`field_metadata`]?.[selectedField.id]);
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 500);
    }
  }, [selectedTab]);

  const submitHandler = useCallback(
    async (data: Partial<{ conditions: Condition[] }>) => {
      if (
        gui.slug &&
        allowNetworkRequest.current &&
        guiTab?.tab_type === "record_list"
      ) {
        if (indexKey === "general") {
          await updateGui(
            {
              slug: gui.slug,
              data: {
                tabs:
                  gui.tabs?.map((t) =>
                    t.id === guiTab.id
                      ? {
                          ...t,
                          field_metadata: {
                            ...guiTab.field_metadata,
                            [selectedField.id]: data,
                          },
                        }
                      : t
                  ) || [],
              },
            },
            {
              onSuccess: () => {
                console.log("gui edit success");
              },
            }
          );

          return;
        }
        let datasetListSetting = cloneDeep(guiTab);

        const setPath = [
          indexKey,
          ...path.slice(0, -1),
          "field_metadata",
          selectedField.id,
        ];
        set(datasetListSetting, setPath, data);

        await updateGui(
          {
            slug: gui.slug,
            data: {
              tabs:
                gui.tabs?.map((t) =>
                  t.id === guiTab.id ? { ...guiTab, ...datasetListSetting } : t
                ) || [],
            },
          },
          {
            onSuccess: () => {
              console.log("gui edit success");
            },
          }
        );
      }
    },
    [gui.slug, gui.tabs, guiTab, indexKey, path, selectedField.id, updateGui]
  );

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 1200);
    const subscription = watch((_) => {
      submitDeb();
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  return (
    <SidebarSectionWrap
      title="Manage Rules"
      leftIcon={<ArrowBack />}
      onLeftIconClick={onClickBack}
    >
      <FormField label="Read Only">
        <Controller
          control={control}
          name="read_only"
          render={({ field }) => (
            <IOSSwitch
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              checked={field.value ?? false}
            />
          )}
        />
      </FormField>
      <FormField label="Cropper Type">
        <Controller
          name="cropper_type"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              id="type"
              fullWidth
              select
              hiddenLabel
              size="small"
              variant="filled"
              value={`${field.value}`}
            >
              {CROPPER_TYPE.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormField>
      <FormField label="Height">
        <TextField
          {...register("height")}
          margin="dense"
          id="type"
          fullWidth
          hiddenLabel
          size="small"
          variant="filled"
          type="number"
        />
      </FormField>
      <FormField label="Width">
        <TextField
          {...register("width")}
          margin="dense"
          id="type"
          fullWidth
          hiddenLabel
          size="small"
          variant="filled"
          type="number"
        />
      </FormField>
    </SidebarSectionWrap>
  );
};

export default ImageSetting;
