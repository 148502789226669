import { Box, DialogProps, TextField } from "@mui/material";

import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "services/Auth";
import { z } from "zod";
const formSchema = z
  .object({
    old_password: z.string().min(1, "password name is required"),
    password: z
      .string()
      .regex(
        new RegExp(
          "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"
        ),
        "Invalid password format."
      ),

    // confirm should be same as new password
    confirm: z.string(),
  })
  .refine((data) => data.password === data.confirm, {
    message: "Passwords don't match",
    path: ["confirm"],
  });

export type FormType = z.infer<typeof formSchema>;
export type Props = {} & DialogProps;

const ChangePasswordModal = ({ onClose, ...dialogProps }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<FormType>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  const submitHandler = useCallback(async (data: FormType) => {
    try {
      await Auth.changePassword(data.old_password, data.password);
      enqueueSnackbar("Password changed successfully", { variant: "success" });
      handleClose();
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  }, []);

  const handleClose = () => {
    onClose?.({}, "backdropClick");
  };

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Invite User</DialogTitle>
      <Box component="form" sx={{ padding: "40px" }}>
        <TextField
          {...register("old_password")}
          autoFocus
          name="old_password"
          margin="dense"
          id="type"
          type="password"
          fullWidth
          hiddenLabel
          size="small"
          variant="filled"
          placeholder="Old Password"
          error={!!errors.old_password}
          helperText={errors.old_password?.message}
        />
        <TextField
          {...register("password")}
          name="password"
          margin="dense"
          id="type"
          type="password"
          fullWidth
          hiddenLabel
          size="small"
          variant="filled"
          placeholder="New Password"
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextField
          {...register("confirm")}
          name="confirm"
          margin="dense"
          id="type"
          type="password"
          fullWidth
          hiddenLabel
          size="small"
          variant="filled"
          placeholder="Confirm Password"
          error={!!errors.confirm}
          helperText={errors.confirm?.message}
        />
      </Box>
      <DialogActions>
        <LoadingButton onClick={handleClose} loading={isSubmitting}>
          Cancel
        </LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler)}
          variant="contained"
          loading={isSubmitting}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
