import { Crop, Download } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/Image";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import DeleteOutline from "assets/icons/DeleteOutline";
import React from "react";
import { LinearProgressWrap, RcStack } from "./FileUploader.style";

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "6px",
    borderRadius: "6px",
    marginTop: "0 !important",
  },
  [`& .${tooltipClasses.arrow}`]: {
    display: "none",
  },
}));

interface Props {
  id: string;
  size?: string;
  name?: string;
  url?: string;
  type?: string;
  leftIcon?: React.ReactElement | React.ReactNode;
  options?: {
    edit?: boolean;
    delete?: boolean;
    progress?: boolean;
    view?: boolean;
    crop?: boolean;
  };
  onDelete?: (id: string) => void;
  onCrop?: (id: string) => void;
  progress?: number;
  status?: "complete" | "error" | "uploading";
  onView: (id?: string) => void;
}
export type UploadedItemsProps = Props;

const UploadedItems: React.FC<Props> = (props) => {
  const {
    id,
    size = "100KB",
    name = "document_file_name.pdf",
    leftIcon = <ImageIcon />,
    options,
    onDelete,
    progress,
    status,
    type,
    url,
    onView,
    onCrop,
  } = props;
  const {
    view = false,
    edit = true,
    delete: deleteAlt = true,
    progress: showProgress = false,
    crop = false,
  } = options || {};

  // const [open, setOpen] = useState(false);

  // const isImage = useMemo(
  //   () => accept({ name, type }, "image/*"),
  //   [name, type]
  // );

  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //   if (isImage && url) {
  //     setOpen(true);
  //   }
  // };

  const handleDelete = () => {
    onDelete?.(id);
  };

  return (
    // <ClickAwayListener onClickAway={handleTooltipClose}>
    <Box>
      {/* <TooltipStyled
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<img src={url} alt={name} />}
          sx={{ p: 1 }}
          placement="bottom"
        > */}
      <RcStack
        direction="row"
        alignItems="center"
        borderRadius={"4px"}
        py={1.25}
        px={2}
        className={`${status} uploaded-item`}
        // onClick={handleTooltipOpen}
      >
        <Stack direction="row" alignItems="center" flexGrow={1} flexBasis={0}>
          <Box className="icon-holder">{leftIcon}</Box>
          <Stack className="file-detail">
            <Typography
              component="div"
              variant="subtitle1"
              className="file-name"
            >
              {name}
            </Typography>
            <Typography component="div" variant="body2" className="file-size">
              {size}
            </Typography>
          </Stack>
        </Stack>
        {showProgress ? (
          <IconButton
            aria-label="upload picture"
            component="label"
            disableRipple
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            <DeleteOutline />
          </IconButton>
        ) : (
          <Box className="action-btns" gap="8px">
            {view && (
              <IconButton
                aria-label="view item"
                disableRipple
                onClick={(e) => {
                  e.stopPropagation();
                  onView?.(id);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            )}
            <IconButton
              aria-label="upload picture"
              component="a"
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={url}
              download={name}
              target="_blank"
            >
              <Download />
            </IconButton>
            {deleteAlt && (
              <IconButton
                aria-label="upload picture"
                component="label"
                disableRipple
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete();
                }}
              >
                <DeleteOutline />
              </IconButton>
            )}
            {crop && (
              <IconButton
                aria-label="upload picture"
                component="label"
                disableRipple
                onClick={onCrop?.bind(null, id)}
              >
                <Crop />
              </IconButton>
            )}
            {edit && (
              <IconButton
                aria-label="upload picture"
                component="label"
                disableRipple
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>
        )}
        {showProgress && (
          <LinearProgressWrap
            variant="determinate"
            value={progress!}
            {...props}
          />
        )}
      </RcStack>
      {/* </TooltipStyled> */}
    </Box>
    // </ClickAwayListener>
  );
};

export default UploadedItems;
