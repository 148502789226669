export enum GuiType {
  Dashboard = "dashboard",
  RecordList = "record_list",
  WorkflowBoard = "workflow_board",
  Reviewer = "reviewer",
}
export enum CropperType {
  None = "none",
  FixAll = "fix_all",
  FixWidthOnly = "fix_width_only",
  FixHeightOnly = "fix_height_only",
}
