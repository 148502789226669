import { Direction } from "@mui/material/styles";

import type {} from "@mui/x-date-pickers/themeAugmentation";
import useGetAppConfig from "queries/useGetAppConfig";
import { useMemo } from "react";
import componentsOverride from "theme/overrides";
import getThemeMode from "utils/getColorPresets";

type Props = {
  themeMode?: ThemeModes;
  themeDirection: "ltr" | "rtl";
};

const useThemeToggle = (props: Props) => {
  const { themeMode = "light", themeDirection = "ltr" } = props;
  const { data } = useGetAppConfig({ enabled: false });
  // const routeModule = useRouteModule();

  const theme = useMemo(() => {
    const theme = getThemeMode(themeMode, {
      defaultColor: data?.primary_color,
    });

    const newTheme = {
      ...theme.theme,
      direction: themeDirection as Direction,
      navStyle: data?.nav_style || "top",
    };
    newTheme.components = componentsOverride(newTheme);
    return newTheme;
  }, [themeMode, themeDirection, data?.primary_color]);
  return theme;
};

export default useThemeToggle;
