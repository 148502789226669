import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TollIcon from "@mui/icons-material/Toll";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  DrawerProps,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import LabelArrowIcon from "assets/icons/LabelArrowIcon";
import axios from "axios";
import CodeEditor from "components/CodeEditor";
import SidebarSection from "components/RightSidebar";
import RunFusion from "components/RunFusion/RunFusion";
import Scrollbar from "components/Scrollbar";
import Spin from "components/Spin";
import { S3_CLOUD_FRONT_URL } from "configs/AppConfig";
import { SessionStatus } from "enums/Fusion";
import useAccountSlug from "hooks/useAccountSlug";
import { isArray } from "lodash";
import moment from "moment";
import use3pApps from "queries/3p-app/use3pApps";
import React, { FC, memo, useEffect, useState } from "react";
import { getNameInitial } from "utils";
import { v4 } from "uuid";

const ErrorContent: React.FC = (props) => {
  const theme = useTheme();

  const [collapsed, setCollapsed] = useState(true);

  return (
    <Collapse in={!collapsed} collapsedSize={30} sx={{ mt: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <InfoOutlinedIcon sx={{ color: theme.palette.red[500] }} />
          <Typography sx={{ color: theme.palette.red[500] }}>
            Errors: Code cancelation
          </Typography>
        </Stack>
        <IconButton sx={{ p: 0 }} onClick={() => setCollapsed((prev) => !prev)}>
          <LabelArrowIcon
            sx={{ fontSize: 16, color: theme.palette.red[500] }}
          />
        </IconButton>
      </Stack>
      <Typography>
        Account verification denied. Must submit new user diagnostics for
        upload.
      </Typography>
    </Collapse>
  );
};

type OperationLogsProps = {
  title: string;
  logsUrl?: string;
  outputsUrl?: string;
};

const OperationLogs: React.FC<OperationLogsProps> = memo(
  (props) => {
    const { title, logsUrl, outputsUrl } = props;

    const theme = useTheme();

    const [collapsed, setCollapsed] = useState(true);
    const [logs, setLogs] = useState<(FusionOperatorLog & { id: string })[]>(
      []
    );
    const [output, setOutput] = useState<unknown>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (logsUrl && !collapsed && !logs.length) {
        setLoading(true);
        axios
          .get(logsUrl)
          .then(
            (res) =>
              isArray(res.data) &&
              setLogs(res.data.map((d) => ({ ...d, id: v4() })))
          )
          .catch((e) => console.error)
          .finally(() => setLoading(false));
      }
    }, [logsUrl, collapsed]);

    useEffect(() => {
      if (outputsUrl && !collapsed && !output) {
        axios
          .get(outputsUrl)
          .then((res) => setOutput(res.data))
          .catch((e) => console.error);
      }
    }, [outputsUrl, collapsed]);

    return (
      <Collapse in={!collapsed} collapsedSize={30} sx={{ mt: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <InfoOutlinedIcon />
            <Typography>{title}</Typography>
          </Stack>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => setCollapsed((prev) => !prev)}
          >
            <Spin
              spinning={loading}
              iconProps={{ size: 10, sx: { color: "#fff" } }}
              backdropSx={{ background: "transparent" }}
            >
              <LabelArrowIcon
                sx={{
                  fontSize: 16,
                  color: theme.palette.red[500],
                  transform: !collapsed ? "rotate(180deg)" : undefined,
                }}
              />
            </Spin>
          </IconButton>
        </Stack>
        {logs.map((log) => {
          return (
            <Box key={log.id}>
              <Typography>
                {log.status}: {log.message}:
              </Typography>
              {log.payload?.responseUrl ? (
                <Link href={log.payload.responseUrl} target="_blank">
                  payload
                </Link>
              ) : log.payload ? (
                <LogsCodeButton title="Payload" logs={log.payload} />
              ) : null}
            </Box>
          );
        })}
        {output ? (
          <Box>
            <Typography>Operation Output:</Typography>
            <LogsCodeButton title="Output" logs={output} />
          </Box>
        ) : null}
      </Collapse>
    );
  },
  (prev, next) =>
    prev.title === next.title &&
    prev.logsUrl === next.logsUrl &&
    prev.outputsUrl === next.outputsUrl
);

const SessionTimelineItem: React.FC<{
  operator: SessionOperator;
  isLast: boolean;
  operatorOperations: OperatorResponse["operations"];
  operationCounter?: number | null;
  sessionSlug?: string;
  accountSlug?: string;
}> = (props) => {
  const {
    operator,
    isLast,
    operatorOperations = [],
    operationCounter,
    accountSlug,
    sessionSlug,
  } = props;

  const theme = useTheme();

  const { data: apps } = use3pApps();
  const app = apps?.find((app) => {
    return app.slug === operator.app;
  });
  const color = app?.app_color || theme.palette.primary.main;
  const appLabel = operator.app === "system" ? "System" : app?.app_name;
  const avatar = operator.app === "system" ? undefined : app?.app_color_logo;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            backgroundColor: "#fff",
            // borderColor: "blue",
            m: 0,
          }}
        >
          <Avatar
            src={avatar}
            variant="circular"
            sx={{
              height: 36,
              width: 36,
              backgroundColor: color,
              color: "#fff",
            }}
          >
            {getNameInitial(operator.operator_title || "")}
          </Avatar>
        </TimelineDot>
        {!isLast && <TimelineConnector sx={{ background: color }} />}
      </TimelineSeparator>
      <TimelineContent sx={{ py: 0, px: 2, mb: 4 }}>
        <Typography variant="h6" component="span">
          {operator.operator_title}
        </Typography>
        <Typography>{operator.operator_subtitle}</Typography>
        {operationCounter != null && operationCounter > 0
          ? Array(operationCounter)
              .fill(0)
              .map((_, i) => (
                <OperationLogs
                  key={`${operator.operator_slug}-${i}`}
                  title={`Operation ${i + 1}: Success`}
                  logsUrl={`${S3_CLOUD_FRONT_URL}/${accountSlug}/fusion-sessions/${sessionSlug}/${operator.operator_slug}/${i}.logs.json`}
                  outputsUrl={`${S3_CLOUD_FRONT_URL}/${accountSlug}/fusion-sessions/${sessionSlug}/${operator.operator_slug}/${i}.json`}
                />
              ))
          : operatorOperations.map((operation, idx) => (
              <OperationLogs
                key={operation.id}
                title={`Operation ${idx + 1}: ${operation.data.status}`}
                logsUrl={operation.data.logs?.url}
                outputsUrl={
                  (operation.data.outputs?.responsePath ??
                    operation.data.outputs?.url) as string
                }
              />
            ))}
      </TimelineContent>
    </TimelineItem>
  );
};

type ErrorLogsProps = {
  logs: unknown;
  title: string;
};

const LogsCodeButton: React.FC<ErrorLogsProps> = (props) => {
  const { logs, title } = props;

  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setOpen(true)}
      >
        {title}
      </Button>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <CodeEditor
            style={{ maxHeight: "500px" }}
            value={JSON.stringify(logs, null, 2)}
            readOnly
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

type ErrorLogsButtonProps = {
  sessionSlug: string;
};

const ErrorLogsButton: FC<ErrorLogsButtonProps> = (props) => {
  const { sessionSlug } = props;

  const accountSlug = useAccountSlug();

  const [logs, setLogs] = useState<unknown>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = async () => {
    if (logs) {
      setOpen(true);
    }

    setLoading(true);

    const response = await axios
      .get(
        `${S3_CLOUD_FRONT_URL}/${accountSlug}/fusion-sessions/${sessionSlug}/error.json`
      )
      .then((res) => res.data)
      .catch((e) => console.error);

    if (response) {
      setLogs(response);
    }

    setOpen(true);

    setLoading(false);
  };

  return (
    <Box>
      <LoadingButton
        variant="contained"
        color="primary"
        size="small"
        loading={loading}
        disabled={loading}
        onClick={handleOpen}
      >
        Error Logs
      </LoadingButton>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Error Logs</DialogTitle>
        <DialogContent>
          <CodeEditor
            style={{ maxHeight: "500px" }}
            value={JSON.stringify(logs, null, 2)}
            readOnly
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

type Props = {
  session?: FusionSession | FusionFlowSession;
  fusionSlug?: string;
} & DrawerProps;

const SessionRunDrawer: React.FC<Props> = (props) => {
  const { session, fusionSlug, ...drawerProps } = props;

  const theme = useTheme();
  const accountSlug = useAccountSlug();

  const duration = moment.duration(
    moment(session?.created_at).diff(moment(session?.updated_at))
  );
  const formattedDuration = moment
    .utc(duration.asMilliseconds())
    .format("HH:mm:ss");

  const creditsUsed = session?.session_data?.total_credits_used ?? 0;
  const status =
    session?.version === "v2"
      ? session.session_status
      : session?.session_data.session_status;
  const title = status === SessionStatus.Building ? "Running Test" : status;

  const operationsCounter =
    session?.version === "v2" && session?.operations_counter != null
      ? session?.operations_counter
      : null;

  const responses =
    session?.version === "v2"
      ? session?.operator_responses
      : session?.session_data?.operator_responses;

  const operators =
    session?.version === "v2"
      ? session?.session_operators.filter((op) => {
          if (operationsCounter != null) {
            return operationsCounter[op.operator_slug]?.counter > -1;
          } else if (responses?.[op.operator_slug]?.operations) {
            return responses?.[op.operator_slug]?.operations?.length > 0;
          }

          return true;
        })
      : session?.session_data?.session_operators;

  return (
    <Drawer
      sx={{
        width: 0,
        flexShrink: 0,
        zIndex: theme.zIndex.appBar - 1,

        [`& .MuiDrawer-paper`]: {
          zIndex: theme.zIndex.appBar - 1,
          width: 450,
          boxSizing: "border-box",
          boxShadow: "none",
          background: "none",
          backgroundImage: "none",
          backgroundColor: theme.palette.background.GFRightNavBackground,

          ".MuiPaper-root": {
            boxShadow: "none",
            background: "none",
            backgroundImage: "none",
          },
        },
      }}
      anchor="right"
      hideBackdrop
      {...drawerProps}
    >
      <Toolbar />
      <ClickAwayListener
        onClickAway={(e) => drawerProps.onClose?.(e, "backdropClick")}
      >
        <Stack spacing={1} height="100%">
          <RunFusion
            actions={false}
            title={title}
            progress={status === SessionStatus.Complete ? 100 : 60}
          />
          <Box height="100%">
            <Scrollbar>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      sx={{
                        background:
                          theme.palette.background.GFRightNavForeground,
                      }}
                    >
                      <AccessTimeIcon sx={{ color: "#fff", opacity: 0.6 }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack direction="row" spacing={2}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.text.secondary,
                            opacity: 0.7,
                          }}
                        >
                          Total run time:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: theme.palette.text.primary }}
                        >
                          {formattedDuration}
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      sx={{
                        background:
                          theme.palette.background.GFRightNavForeground,
                      }}
                    >
                      <TollIcon sx={{ color: "#fff", opacity: 0.6 }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack direction="row" spacing={2}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.text.secondary,
                            opacity: 0.7,
                          }}
                        >
                          Fuse credits used:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: theme.palette.text.primary }}
                        >
                          {`${creditsUsed}`.padStart(2, "0")}
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      sx={{
                        background:
                          theme.palette.background.GFRightNavForeground,
                      }}
                    >
                      <CalendarTodayIcon sx={{ color: "#fff", opacity: 0.6 }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack direction="row" spacing={2}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.text.secondary,
                            opacity: 0.7,
                          }}
                        >
                          Last updated:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: theme.palette.text.primary }}
                        >
                          {moment(session?.updated_at).format("ll")}
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      sx={{
                        background: theme.palette.red[500],
                      }}
                    >
                      <InfoOutlinedIcon sx={{ color: "#fff" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack direction="row" spacing={2}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.text.secondary,
                            opacity: 0.7,
                          }}
                        >
                          Errors:
                        </Typography>
                        {/* <Typography
                          variant="subtitle2"
                          sx={{ color: theme.palette.text.primary }}
                        >
                          {`${errorCount}`.padStart(2, "0")}{" "}
                        </Typography> */}
                        {session?.slug && (
                          <ErrorLogsButton sessionSlug={session.slug} />
                        )}
                      </Stack>
                    }
                  />
                </ListItem>
              </List>
              <Box sx={{ px: 2 }}>
                <SidebarSection title="Todays activity" rightIcon={false}>
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    {operators?.map((op, i, arr) => (
                      <SessionTimelineItem
                        key={op.operator_slug}
                        operator={op}
                        isLast={i === arr.length - 1}
                        operatorOperations={
                          responses?.[op.operator_slug]?.operations || []
                        }
                        operationCounter={
                          (operationsCounter?.[op.operator_slug]?.counter ||
                            -1) + 1
                        }
                        sessionSlug={session?.slug}
                        accountSlug={accountSlug}
                      />
                    ))}
                  </Timeline>
                </SidebarSection>
              </Box>
            </Scrollbar>
          </Box>
        </Stack>
      </ClickAwayListener>
    </Drawer>
  );
};

export default SessionRunDrawer;
