import Model from "models";

class GuiModal extends Model<GfGui> {
  constructor() {
    super("/gf-gui", "gui-fusion");
  }

  async createFusion(data: {
    gui_slug: string;
    main_tab_id: string;
    sub_tab_id: string;
  }) {
    return this.sendRequest<Fusion>("/fusion", "POST", data);
  }

  async createReviewerAction(
    guiSlug: string,
    data: {
      action_title: string;
      action_icon?: string;
      tab_name: string;
    }
  ) {
    return this.sendRequest<GfGui>(`/reviewer-action/${guiSlug}`, "POST", data);
  }

  async createDatasetAction(
    guiSlug: string,
    data: {
      action_title: string;
      action_icon?: string;
      tab_name: string;
    }
  ) {
    return this.sendRequest<GfGui>(`/dataset-action/${guiSlug}`, "POST", data);
  }
}

const model = new GuiModal();

export default model;
