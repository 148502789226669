import Send from "@mui/icons-material/Send";
import { TextField } from "@mui/material";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import FusionModel from "models/Fusion";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";

type MessageInputFieldProps = {
  fusionSlug?: string;
  parentSlug?: string;
};

const MessageInputField: FC<MessageInputFieldProps> = (props) => {
  const { fusionSlug, parentSlug } = props;

  const accountSlug = useAccountSlug();
  const user = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (!fusionSlug) {
      enqueueSnackbar({ variant: "error", message: "Fusion not defined!" });
      return;
    }

    if (!parentSlug) {
      enqueueSnackbar({ variant: "error", message: "Parent not defined!" });
      return;
    }

    if (!message) {
      enqueueSnackbar({
        variant: "error",
        message: "Message cannot be empty!",
      });
      return;
    }

    if (!user) {
      enqueueSnackbar({ variant: "error", message: "User not defined!" });
      return;
    }

    FusionModel.runFusion(fusionSlug, {
      user_id: user.slug,
      account_id: accountSlug,
      popup_variables: {
        popup_variables: {
          sender_id: user.slug,
          sender_name: `${user.first_name} ${user.last_name}`.trim(),
          message,
          parent_id: parentSlug,
        },
      },
    });
    enqueueSnackbar({ variant: "success", message: "Message sent!" });
  };

  return (
    <TextField
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      fullWidth
      InputProps={{
        endAdornment: <Send onClick={handleSend} sx={{ cursor: "pointer" }} />,
      }}
    />
  );
};

export default MessageInputField;
