import "yet-another-react-lightbox/styles.css";

import {
  BackupOutlined,
  BrokenImageOutlined,
  DeleteOutlined,
  DriveFileRenameOutline,
  EditOutlined,
  ImageOutlined,
  SmartDisplayOutlined,
  VolumeUpOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { upload } from "api/upload";
import { File } from "assets/icons";
import AddFolder from "assets/icons/AddFolder";
import AddFolderModel from "components/AddFolderModal";
import IconButtonWithTooltip from "components/IconButtonWithTooltip";
import { ListingItem } from "components/layout-components/listing-layout/Listing";
import RenameModel from "components/share-components/RenameModel";
import { S3_CLOUD_FRONT_URL } from "configs/AppConfig";
import useAccountSlug from "hooks/useAccountSlug";
import useAppNavigate from "hooks/useAppNavigate";
import useOpenClose from "hooks/useOpenClose";
import useQuery from "hooks/useQuery";
import ListingLayout from "layouts/ListingLayout";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useDeleteItem from "queries/useDeleteItem";
import useListItems from "queries/useListItems";
import useUpdateItem from "queries/useUpdateItem";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useListingLayout } from "store/stores/listingLayout";
import { useMediaStore } from "store/stores/media-manager";
import { useSystemLayoutStore } from "store/stores/systemLayout";
import { getSearchParams, sortItems } from "utils";
import { v4 } from "uuid";
import { Lightbox, Slide } from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import DropzoneCover from "./components/DropzoneCover";

type Props = {
  onChange?: (e: SelectChangeEvent<unknown>, child: ReactNode) => void;
};

const MediaManagerContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const MediaContainer = styled(Box)(({ theme }) => ({
  flexGrow: "1",
  flexBasis: "0",
  minHeight: "0",
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  maxWidth: "1255px",
  margin: "0 auto",
  padding: "21px 20px",
  overflow: "hidden",
  width: "100%",
}));

type CardIconProps = {
  type: Media["media_type"];
};

const CardIcon: React.FC<CardIconProps> = (props) => {
  switch (props.type) {
    case "image":
      return <ImageOutlined />;
    case "video":
      return <SmartDisplayOutlined />;
    case "audio":
      return <VolumeUpOutlined />;
    case "presentation":
      return <BrokenImageOutlined />;
    default:
      return <File />;
  }
};

const MediaManager: React.FC<Props> = (props) => {
  const { folder_id: folderId, q = "" } =
    useQuery<{ folder_id: string; q: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [folderModalOpen, setFolderModalOpen] = useState(false);
  const [lightboxSlides, setLightboxSlides] = useState<Slide[]>([]);

  const [isLightBoxOpen, openLightBox, closeLightBox] = useOpenClose();
  const accountSlug = useAccountSlug();
  const navigate = useAppNavigate();

  const { mutate: createMedia } = useCreateItem({
    modelName: ApiModels.Media,
    queryKey: [ApiModels.Media, folderId ?? null],
  });
  const { mutate: updateMedia } = useUpdateItem({ modelName: ApiModels.Media });
  const {
    data: mediaData = [],
    refetch,
    isFetching,
  } = useListItems({
    modelName: ApiModels.Media,
    requestOptions: {
      query: { parent_id: folderId || null, q },
    },
    queryKey: [ApiModels.Media, folderId ?? null],
  });
  const { mutate: deleteMedia } = useDeleteItem({
    modelName: ApiModels.Media,
    dataKey: "id",
    queryKey: [ApiModels.Media, folderId || null],
  });

  const { data: folders } = useListItems({
    modelName: ApiModels.Media,
    requestOptions: {
      query: { parent_id: "all", media_type: "folder" },
    },
    queryKey: [ApiModels.Media, "all", "folder"],
  });
  const mediaList = useMemo(
    () => mediaData.filter((m) => m.media_type !== "folder"),
    [mediaData]
  );

  const onRename = useSystemLayoutStore.useOnRename?.();
  const setBreadcrumbs = useListingLayout.useSetBreadcrumbs();
  const searchTags = useListingLayout.useSearchTags();
  const sortValue = useListingLayout.useSortValue();
  const setSortValue = useListingLayout.useSetSortValue();
  const setSortOptions = useListingLayout.useSetSortOptions();
  const setCount = useListingLayout.useSetCount();
  const setSelectedFolderId = useMediaStore.useSetSelectedFolderId();
  const selectedFolderId = useMediaStore.useSelectedFolderId();
  const setPendingMediaError = useMediaStore.useSetPendingMediaError();
  const addPendingMedia = useMediaStore.useAddPendingMedia();
  const removePendingMedia = useMediaStore.useRemovePendingMedia();
  const updatePendingMediaProgress =
    useMediaStore.useUpdatePendingMediaProgress();

  useEffect(() => {
    if (!isFetching) {
      refetch();
    }
  }, [q]);

  useEffect(() => {
    setSelectedFolderId(Number(folderId) || null);
  }, [folderId]);

  useEffect(() => {
    setSortOptions([
      {
        label: "Last Updated",
        value: "last_updated",
      },
      {
        label: "Last Created",
        value: "last_created",
      },
      {
        label: "Name",
        value: "name",
      },
    ]);

    setSortValue("last_updated");
  }, []);

  useEffect(() => {
    let breadcrumbs = [];

    if (folders?.length && folderId) {
      const folder = folders.find((f) => f.id === Number(folderId));
      const parentFolder = folders.find((f) => f.id === folder?.parent_id);
      if (folder && parentFolder) {
        breadcrumbs.push({
          id: "root",
          name: "Media Manager",
          url: "/media",
        });
        breadcrumbs.push({
          id: `${parentFolder.id}`,
          name: parentFolder.title,
          url: `/media?folder_id=${parentFolder.id}`,
        });
        if (searchTags.length > 0) {
          breadcrumbs.push({
            id: `${folder.id}`,
            name: folder.title,
            url: `/media?folder_id=${folder.id}`,
          });
          breadcrumbs.push({
            id: "search_results",
            name: "Search Results",
          });
        } else {
          breadcrumbs.push({
            id: `${folder.id}`,
            name: folder.title,
          });
        }
      } else if (folder) {
        breadcrumbs.push({
          id: "root",
          name: "Media Manager",
          url: "/media",
        });
        if (searchTags.length > 0) {
          breadcrumbs.push({
            id: `${folder.id}`,
            name: folder.title,
            url: `/media?folder_id=${folder.id}`,
          });
          breadcrumbs.push({
            id: "search_results",
            name: "Search Results",
          });
        } else {
          breadcrumbs.push({
            id: `${folder.id}`,
            name: folder.title,
          });
        }
      } else {
        breadcrumbs.push({
          id: "root",
          name: "Media Manager",
        });
      }
    } else {
      breadcrumbs.push({
        id: "root",
        name: "Media Manager",
      });
      if (searchTags.length > 0) {
        breadcrumbs = [
          {
            id: "root",
            name: "Media Manager",
            url: "/media",
          },
          {
            id: "search_results",
            name: "Search Results",
          },
        ];
      }
    }
    setBreadcrumbs(breadcrumbs);
  }, [folderId, folders, searchTags.length]);

  const sortData = useCallback(
    (mediaItems: Media[], folderItems: Media[]) => {
      let sortedMediaItems = mediaItems;
      let sortedFolderItems = folderItems;
      const sortOptions = {
        key: "",
        isDateType: false,
      };
      if (sortValue === "last_updated") {
        sortOptions.key = "updated_at";
        sortOptions.isDateType = true;
      } else if (sortValue === "last_created") {
        sortOptions.key = "created_at";
        sortOptions.isDateType = true;
      } else if (sortValue === "name") {
        sortOptions.key = "title";
        sortOptions.isDateType = false;
      }

      if (sortOptions.key) {
        sortedMediaItems = sortItems(
          sortedMediaItems,
          sortOptions.key as keyof Media,
          true,
          sortOptions.isDateType
        );
        sortedFolderItems = sortItems(
          sortedFolderItems,
          sortOptions.key as keyof Media,
          true,
          sortOptions.isDateType
        );
      }

      return { mediaItems: sortedMediaItems, folderItems: sortedFolderItems };
    },
    [sortValue]
  );

  const data = useMemo(() => {
    let filtered: Media[] = [];

    if (!folderId) {
      filtered = mediaList?.filter((f) => !f.parent_id) || [];
    } else {
      const folder = folders?.find((folder) => folder.id === Number(folderId));

      filtered = mediaList?.filter((f) => f.parent_id === folder?.id) || [];
    }

    let filteredFolders =
      folders?.filter((f) =>
        folderId ? f.parent_id === Number(folderId) : f.parent_id == null
      ) || [];

    const sortedData = sortData(filtered, filteredFolders);
    filtered = sortedData.mediaItems;
    filteredFolders = sortedData.folderItems;

    let finalList: ListingItem[] =
      filtered.map((media) => ({
        type: "grid-item",
        item: {
          ...media,
          iconComp: <CardIcon type={media.media_type} />,
          imageUrl:
            media.media_type === "image"
              ? media.s3_path
              : "/assets/images/play-button.png",
          lastUpdated: moment
            .utc(media.updated_at || media.created_at)
            .fromNow(),
          titleComp: (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                variant="rounded"
                sx={{
                  width: "32px",
                  height: "32px",
                  color: "#fff",
                  background: (theme) => theme.palette.background.GF10,
                }}
              >
                <CardIcon type={media.media_type} />
              </Avatar>
              <Typography>{media.title}</Typography>
            </Stack>
          ),
        },
      })) || [];

    const folderList: ListingItem[] =
      filteredFolders.map((folder) => ({
        type: "folder",
        folder: {
          ...folder,
          name: folder.title,
          id: `${folder.id}`,
          slug: `${folder.id}`,
          titleComp: (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                variant="rounded"
                sx={{
                  width: "32px",
                  height: "32px",
                  color: "#fff",
                  background: (theme) => theme.palette.background.GF10,
                }}
              >
                <CardIcon type="folder" />
              </Avatar>
              <Typography>{folder.title}</Typography>
            </Stack>
          ),
          lastUpdated: moment
            .utc(folder.updated_at || folder.created_at)
            .fromNow(),
        },
      })) || [];

    finalList.unshift(...folderList);

    if (searchTags.length > 0) {
      finalList = finalList.filter((item) => {
        if (item.type === "folder") {
          return searchTags.some(
            (keyword) =>
              (item.folder?.title as string)
                ?.toLowerCase()
                .indexOf(keyword.toLowerCase()) > -1
          );
        } else if (item.type === "grid-item") {
          return searchTags.some(
            (keyword) =>
              (item.item?.title as string)
                ?.toLowerCase()
                ?.indexOf(keyword.toLowerCase()) > -1
          );
        }

        return false;
      });
    }

    return finalList;
  }, [folderId, folders, sortData, searchTags, mediaList]);

  useEffect(() => {
    setCount(data.length);
  }, [data]);

  const handleFolderClick = (folder: Folder) => {
    const searchParams = getSearchParams();
    searchParams.set("folder_id", `${folder.slug}`);
    navigate({ search: `?${searchParams.toString()}` });
  };

  const handleCardDropOnFolder = (folderSlug: string, itemSlug: string) => {
    updateMedia({ slug: itemSlug, data: { parent_id: Number(folderSlug) } });
  };

  const handleFileSelect = async (file: File, parentId?: number) => {
    const nameChunks = file.name.split(".");
    const extension = nameChunks.pop();
    const name = nameChunks.join(".");
    const key = `${name}-${v4()}.${extension}`;
    const url = `${S3_CLOUD_FRONT_URL}/${accountSlug}/uploads/${key}`;
    const mediaTemplate = {
      title: file.name,
      media_type: file.type.split("/")[0],
      s3_path: url,
      parent_id: parentId != null ? parentId : selectedFolderId || undefined,
    };
    const pendingId = v4();
    addPendingMedia({
      ...mediaTemplate,
      media_type: file.type.split("/")[0] as Media["media_type"],
      pendingId,
      created_at: moment.utc().format(),
      updated_at: moment.utc().format(),
      id: 0,
    });
    await upload(
      { file, filename: key },
      {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
          );
          updatePendingMediaProgress(pendingId, percentCompleted);
        },
      }
    )
      .catch(() => {
        setPendingMediaError(pendingId, "Upload failed");
      })
      .then(() => {
        createMedia(mediaTemplate as Media, {
          onSuccess() {
            removePendingMedia(pendingId);
          },
          onError() {
            setPendingMediaError(pendingId, "Create media failed");
          },
        });
      });
  };

  const handleFolderDrop = (acceptedFiles: File[], parentId: number) => {
    acceptedFiles.forEach((file) => handleFileSelect(file, parentId));
  };

  const handleDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => handleFileSelect(file));
  };

  const handleError = (fileRejections: FileRejection[]) => {
    const errors = fileRejections.reduce<{ code: string; message: string }[]>(
      (acc, cur) => {
        cur.errors.forEach((err) => {
          if (!acc.find((a) => a.code === err.code)) {
            acc.push({ code: err.code, message: err.message });
          }
        });

        return acc;
      },
      []
    );

    errors.forEach((err) => {
      enqueueSnackbar({ message: err.message, variant: "error" });
    });
  };

  const handleOpenMedia = (media: Media) => {
    if (media.media_type === "image") {
      setLightboxSlides([
        {
          type: "image",
          src: media.s3_path,
          alt: media.title,
        },
      ]);
      openLightBox();
    } else if (media.media_type === "video") {
      setLightboxSlides([
        {
          type: "video",
          sources: [
            {
              src: media.s3_path,
              type: `video/${media.s3_path.split(".").pop()}`,
            },
          ],
        },
      ]);
      openLightBox();
    } else {
      window.open(media.s3_path, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <MediaManagerContainer sx={{ background: "#1A1D21" }}>
      <DropzoneCover
        onDrop={handleDrop}
        onError={handleError}
        accept={{
          "image/*": [],
        }}
        disabled={false}
      >
        <ListingLayout
          data={data}
          idKey="id"
          tableColumns={[
            {
              label: "Media Name",
              fieldKey: "titleComp",
              folderKey: "titleComp",
            },
            {
              label: "Last Updated",
              fieldKey: "lastUpdated",
              folderKey: "lastUpdated",
            },
          ]}
          topBarProps={{
            extra: (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  size="small"
                  variant="contained"
                  component="label"
                  sx={{
                    border: "none",
                    borderRadius: "6px",
                    height: "30px",
                    color: "#fff",
                    background: (theme) => theme.palette.background.GF10,
                    padding: "5px",
                    minWidth: 0,
                    width: "auto",

                    "&:hover": {
                      background: (theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  <BackupOutlined />
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      if (e.target.files?.[0]) {
                        handleFileSelect(e.target.files[0]);
                      }
                    }}
                  />
                </Button>
                <IconButtonWithTooltip
                  size="small"
                  tooltipProps={{ title: "Add Folder" }}
                  onClick={() => setFolderModalOpen(true)}
                >
                  <AddFolder />
                </IconButtonWithTooltip>
              </Stack>
            ),
          }}
          actions={[
            {
              title: "Open",
              icon: <EditOutlined />,
              onClick(id) {
                // const gui = guis?.find((gui) => gui.slug === id);
                // if (gui) {
                //   navigate(`/gui-module/${gui.slug}`);
                // }
              },
            },
            {
              title: "Rename",
              icon: <DriveFileRenameOutline />,
              onClick(id) {
                const media = mediaList?.find(
                  (media) => media.id === Number(id)
                );
                if (media) {
                  onRename?.({
                    title: media.title,
                    key: `${media.id}`,
                    model: ApiModels.Media,
                  });
                }
              },
            },
            {
              title: "Delete",
              icon: <DeleteOutlined />,
              onClick(id) {
                deleteMedia({ slug: id });
              },
            },
          ]}
          folderActions={[
            {
              title: "Open",
              icon: <EditOutlined />,
              onClick(folder) {
                handleFolderClick(folder);
              },
            },
            {
              title: "Rename",
              icon: <DriveFileRenameOutline />,
              onClick(folder) {
                onRename?.({
                  title: (folder as unknown as Media).title,
                  key: `${folder.id}`,
                  model: ApiModels.Media,
                });
              },
            },
            {
              title: "Delete",
              icon: <DeleteOutlined />,
              onClick(folder) {
                deleteMedia({ slug: folder.id });
              },
            },
          ]}
          gridKeyMap={{
            id: "id",
            icon: "iconComp",
            imageUrl: "imageUrl",
            title: "title",
            subtitle: "lastUpdated",
            link: "link",
          }}
          onFolderClick={handleFolderClick}
          onOpenClick={(id) => {
            const media = mediaList?.find((media) => media.id === Number(id));
            if (media) {
              handleOpenMedia(media);
            }
          }}
          onMediaClick={(id) => {
            const media = mediaList?.find((media) => media.id === Number(id));
            if (media) {
              handleOpenMedia(media);
            }
          }}
          onFolderDrop={handleCardDropOnFolder}
        />
      </DropzoneCover>
      <AddFolderModel
        open={folderModalOpen}
        parentFolderId={folderId}
        onClose={() => setFolderModalOpen(false)}
      />
      <RenameModel module={ApiModels.Folder} title="Rename" />
      <Lightbox
        plugins={[Video]}
        open={isLightBoxOpen}
        close={() => {
          closeLightBox();
          setLightboxSlides([]);
        }}
        slides={lightboxSlides}
      />
    </MediaManagerContainer>
  );
};

export default MediaManager;
