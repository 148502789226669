import Model from "models";

class AppConfigNodel extends Model<AppConfig> {
  constructor() {
    super("/app-config", "public-1");
  }

  async get<ReturnType = AppConfig>(): Promise<ApiResponse<ReturnType>> {
    // You might need to adjust the code inside to correctly use the slug and options parameters
    return await this.sendRequest<ReturnType>("", "GET", undefined, {
      public: true,
    });
  }
}

export default new AppConfigNodel();
