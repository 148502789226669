import { Navigate, Outlet, useLocation } from "react-router-dom";

import useRouteModule from "hooks/useRouteModule";
import useAuthenticate from "queries/auth/useAuthenticate";
import { useStore } from "store";
import { hasAccessToModule } from "utils/gaurd";
import isAccountOwner from "utils/isAccountOwner";

const WorkspaceGaurd = ({ allowedRoles }: { allowedRoles?: string[] }) => {
  const location = useLocation();
  const account = useStore.useSelectedAccount?.();
  const routeModule = useRouteModule();
  const { status } = useAuthenticate();

  if (status === "success") {
    const path = location.pathname;
    const cardPath = "/account-setup/add-card";
    const firstSlashIndex = path.indexOf("/", 2);
    const extractedPath = path.slice(firstSlashIndex); // Extract the path after the first slash

    const onCardView = extractedPath === cardPath;

    // check if user has access to that route or is owner
    const isOwner = isAccountOwner(account?.subscription?.account_user_type);
    const hasAccess = hasAccessToModule(
      routeModule!,
      account?.subscription?.permissions!
    );

    if (account?.slug && !account?.has_card && !onCardView && isOwner) {
      return (
        <Navigate
          to={`/${account?.slug}/account-setup/add-card`}
          state={{ from: location }}
          replace
        />
      );
    }

    if (!account?.has_card)
      // if the account doens't have card then show unauthorized page to all the user except owner
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;

    if (isOwner || (!isOwner && hasAccess)) return <Outlet />;

    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  if (status === "error")
    return <Navigate to="/login" state={{ from: location }} replace />;

  return <div>...loading</div>;
};

export default WorkspaceGaurd;
