import Model from "models";

class MediaModel extends Model<Media> {
  constructor() {
    super("/media", "public-1");
  }
}

const model = new MediaModel();
export default model;
