import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Box, IconButton, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { SOCKET_URL } from "configs/AppConfig";
import { SocketState } from "enums";
import useSocket from "hooks/useSocket";
import SystemAppBar from "module/Appbar";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSocketStore } from "store/stores/socket";
import { useSystemLayoutStore } from "store/stores/systemLayout";

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",
    marginTop: "60px",
  };
});

const AppLayout = () => {
  const { connect, disconnect, subscribe, unsubscribe } = useSocket();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const socketState = useSocketStore.useState();
  const appBarProps = useSystemLayoutStore.useAppBarProps();

  useEffect(() => {
    if (socketState !== SocketState.Open) {
      connect(SOCKET_URL!);
      return () => {
        disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (socketState === SocketState.Open) {
      subscribe(
        "fusion_send_notification",
        "fusion_send_notification",
        (response: SocketResponse) => {
          const data = response.data as { message?: string };
          if (data.message) {
            enqueueSnackbar({
              variant: "info",
              message: data.message,
              action(key) {
                return (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      closeSnackbar(key);
                    }}
                  >
                    <CloseOutlined fontSize="small" />
                  </IconButton>
                );
              },
            });
          }
        }
      );

      return () => {
        unsubscribe("fusion_send_notification", "fusion_send_notification");
      };
    }
  }, [enqueueSnackbar, socketState, subscribe, unsubscribe]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {/* <SideNav /> */}
        <Box sx={{ flexGrow: 1 }}>
          <SystemAppBar
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            {...appBarProps}
          />
          <ScrollbarParent>
            <Scrollbar>
              <Box component="main" sx={{ p: 4 }}>
                <Outlet />
              </Box>
            </Scrollbar>
          </ScrollbarParent>
        </Box>
      </Box>
    </>
  );
};

export default AppLayout;
