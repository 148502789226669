import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import GfDrawer from "components/GfDrawer";
import Scrollbar from "components/Scrollbar";
import useQuery from "hooks/useQuery";
import AnimationLayout, {
  AnimationLayoutRef,
  Config,
} from "layouts/AnimationLayout";
import { cloneDeep, get, set } from "lodash";
import FusionModel from "models/Fusion";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import useListItems from "queries/useListItems";
import useUpdateItem from "queries/useUpdateItem";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import { getSearchParams } from "utils";
import { v4 } from "uuid";
import FusionActionEditor, {
  FusionActionForm,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/FusionActionEditor";
import FieldFilterRules from "./FieldFilterRules";
import FieldsSelection from "./FieldsSelection";
import ImageSetting from "./ImageSetting";
import TabCreator from "./TabCreator";
import GuiSidenavMain from "./WidgetMain";
import GuiSideRules from "./WidgetRules";

const DrawerBox = styled(GfDrawer)(({ theme }) => ({
  ".MuiDrawer-paper ": {
    ".MuiPaper-root ": {
      background: `${theme.palette.background.GFTopNav} !important`,
      boxShadow: "none",

      ".MuiCard-root": {
        background: `${theme.palette.background.GFRightNavForeground} !important`,
        transition: "all 0.4s ease",

        "&:hover": {
          background: `${theme.palette.background.GF20} !important`,
        },
      },
    },

    ".drawer-head": {
      background: theme.palette.background.GFTopNav,
      padding: "15px 20px",
      borderBottom: `1px solid ${theme.palette.other?.divider}`,

      ".drawer-icon-holder": {
        width: "auto",
        height: "auto",
        background: "none",
      },

      ".drawer-title-holder": {
        padding: "0 0 0 11px",
      },
    },

    ".draggable-handle": {
      padding: "0",
    },
  },
}));

const DatasetSidebarContainerWrap = styled(Box)(({ theme }) => {
  return {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    marginBottom: 20,

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",
  };
});

const getInitialComponent = () => {
  return getSearchParams().get("s_name") || "main";
};

type GuiRightSideNavProps = {
  gui: GfGui;
  open: boolean;
  onClose(): void;
};

const GuiRightSideNav: React.FC<GuiRightSideNavProps> = (props) => {
  const { open, onClose, gui } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const layoutRef = useRef<AnimationLayoutRef>(null);

  const [initialComponent, setInitialComponent] = useState<string>("");
  const [additionalTabPath, setAdditionalTabPath] = useState<string[]>([]);
  const [selectedField, setSelectedField] = useState<DataField>();
  const [selectedFusionAction, setSelectedFusionAction] =
    useState<FusionAction>();

  const { data: datasetDesigns } = useListItems({
    modelName: ApiModels.DatasetDesign,
    queryOptions: { enabled: false },
  });

  const selectedTab = useGuiDashboardStore.useSelectedTab();

  const guiTab = useMemo(() => {
    if (selectedTab != null && gui?.tabs) {
      return gui?.tabs?.[selectedTab];
    }
  }, [gui?.tabs, selectedTab]);

  const datasetDesignSlug =
    guiTab?.tab_type === "record_list" ? guiTab.dataset_design_slug : undefined;

  const datasetDesignsMap = useMemo(() => {
    const datasetMap = new Map<string, DatasetDesign>();
    datasetDesigns?.forEach((dd) => {
      datasetMap.set(dd.slug, dd);
    });
    return datasetMap;
  }, [datasetDesigns]);

  const { mutate: updateGui, isLoading } = useUpdateItem({
    modelName: ApiModels.Gui,
  });

  const { slug: guiSlug } =
    useParams<{
      slug: string;
    }>();
  const { selectedTabId } = useQuery<{ selectedTabId: string }>();

  const handleFusionActionSubmit = useCallback(
    async (
      values: FusionActionForm,
      {
        indexKey,
        path,
      }: {
        indexKey: string;
        path: string[];
      }
    ) => {
      if (guiSlug && selectedTabId) {
        const tab = cloneDeep(guiTab) ?? {};
        let parentTab = (tab as any)[indexKey as keyof typeof tab];
        const selectedTab = get(parentTab, path.slice(0, -1)) as IncludeTabs;
        if (!selectedTab) {
          return;
        }
        if (selectedFusionAction) {
          set(tab, [indexKey, ...path.slice(0, -1)], {
            ...selectedTab,
            tab_actions:
              selectedTab.tab_actions?.map((action) =>
                action.id === selectedFusionAction.id
                  ? { ...action, ...values }
                  : action
              ) ?? [],
          });
        } else {
          const fusionSlug = v4();
          await FusionModel.create({
            fusion_title: values.action_title,
            fusion_icon: values.action_icon,
            fusion_type: "fusion_action",
            meta_data: {
              gui_slug: guiSlug,
              selected_tab_id: selectedTabId,
              index_key: indexKey,
              path,
              tab_id: guiTab?.id,
            },
            fusion_slug: fusionSlug,
          });
          set(tab, [indexKey, ...path.slice(0, -1)], {
            ...selectedTab,
            tab_actions: [
              ...(selectedTab.tab_actions ?? []),
              { ...values, id: v4(), fusion_slug: fusionSlug },
            ],
          });
        }

        await new Promise<void>((resolve, reject) => {
          updateGui(
            {
              slug: guiSlug,
              data: {
                tabs:
                  gui.tabs?.map((t) =>
                    t.id === guiTab?.id ? { ...guiTab, ...tab } : t
                  ) ?? [],
              },
            },
            {
              onSuccess: () => {
                console.log("gui edit success");
                resolve();
              },
            }
          );
        });
      }
    },
    [gui.tabs, guiSlug, guiTab, selectedFusionAction, selectedTabId, updateGui]
  );

  const getComponentMiddleComponent: Config["getComponents"] =
    React.useCallback(
      (gotoComponent, goBack) => {
        return {
          main: (
            <GuiSidenavMain
              gui={gui}
              onEditRules={() => {
                gotoComponent({ name: "rules", id: "rules" });
              }}
              onEditSeachFields={() => {
                gotoComponent({
                  name: "field-selection-search",
                  id: "field-selection-search",
                });
              }}
              onEditFieldToInclude={() => {
                gotoComponent({
                  name: "field-selection-form",
                  id: "field-selection-form",
                });
              }}
              onNewTabAddition={() => {
                searchParams.set("selectedTabId", "new-tab");
                setSearchParams(searchParams);
                setAdditionalTabPath([]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs",
                    id: "additional-tabs",
                  });
                }, 0);
              }}
              onEditAddition={(item: IncludeTabs, index: number) => {
                searchParams.set("selectedTabId", item.id);
                setSearchParams(searchParams);
                setAdditionalTabPath([index?.toString(), "additional_tabs"]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs",
                    id: "additional-tabs",
                  });
                }, 0);
              }}
              onNewWidget={() => {
                searchParams.set("selectedTabId", "new-tab");
                setSearchParams(searchParams);
                setAdditionalTabPath([]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget",
                    id: "additional-widget",
                  });
                }, 0);
              }}
              onEditWidget={(item: IncludeTabs, index: number) => {
                searchParams.set("selectedTabId", item.id);
                setSearchParams(searchParams);
                setAdditionalTabPath([index?.toString(), "additional_tabs"]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget",
                    id: "additional-widget",
                  });
                }, 0);
              }}
            />
          ),
          "field-selection-search": (
            <FieldsSelection
              gui={gui}
              indexKey={"search_fields"}
              onClickBack={() => goBack()}
            />
          ),
          "field-selection-form": (
            <FieldsSelection
              gui={gui}
              indexKey={"form_fields"}
              onClickBack={() => goBack()}
              onEditFilterRules={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "general-field-filters",
                    id: "general-field-filters",
                  });
                }, 0);
              }}
              onEditImageSetting={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "image-setting-general",
                    id: "image-setting-general",
                  });
                }, 0);
              }}
            />
          ),
          "additional-tabs": (
            <TabCreator
              gui={gui}
              type="middle_tab"
              onClickBack={() => {
                const currentId = searchParams.get("selectedTabId");
                if (additionalTabPath.length > 0) {
                  if (guiTab?.tab_type === "record_list") {
                    const parentTab = get(
                      guiTab.included_tabs,
                      additionalTabPath.slice(0, -1)
                    ) as IncludeTabs;
                    searchParams.set("selectedTabId", parentTab.id);
                    setSearchParams(searchParams);
                    goBack();
                    if (currentId !== "new-tab") {
                      setAdditionalTabPath((pa) => {
                        const newpath = pa.slice(0, -2);
                        return newpath;
                      });
                    }
                  }
                } else {
                  searchParams.delete("selectedTabId");
                  setSearchParams(searchParams);
                  setTimeout(goBack, 0);
                  setAdditionalTabPath([]);
                }
              }}
              indexKey="included_tabs"
              datasetDesigns={datasetDesigns}
              datasetDesignsMap={datasetDesignsMap}
              path={additionalTabPath}
              onEditFilterRules={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs-field-filters",
                    id: "additional-tabs-field-filters",
                  });
                }, 0);
              }}
              allowAdditionalTabs
              onAddWidget={() => {
                searchParams.set("selectedTabId", "new-tab");
                setSearchParams(searchParams);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs",
                    id: v4(),
                  });
                }, 0);
              }}
              onEditWidget={(item: IncludeTabs, index: number) => {
                searchParams.set("selectedTabId", item.id);
                setSearchParams(searchParams);
                setAdditionalTabPath((path) => [
                  ...path,
                  index?.toString(),
                  "additional_tabs",
                ]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-tabs",
                    id: item.id,
                  });
                }, 0);
              }}
              allowTabActions
              onAddActionClick={() => {
                setSelectedFusionAction(undefined);
                gotoComponent({
                  name: "fusion-action-editor",
                  id: "fusion-action-editor",
                });
              }}
              onEditActionClick={(action) => {
                setSelectedFusionAction(action);
                gotoComponent({
                  id: action.id,
                  name: "fusion-action-editor",
                });
              }}
              onEditRules={() => {
                gotoComponent({ name: "tab-rules", id: "tab-rules" });
              }}
              onEditImageSetting={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "image-setting-tabs",
                    id: "image-setting-tabs",
                  });
                }, 0);
              }}
            />
          ),
          "fusion-action-editor": (
            <FusionActionEditor
              action={selectedFusionAction}
              onSubmit={async (values) => {
                await handleFusionActionSubmit(values, {
                  path: additionalTabPath,
                  indexKey: "included_tabs",
                }).then(() => {
                  goBack();
                  setSelectedFusionAction(undefined);
                });
              }}
              onCancel={() => {
                goBack();
                setSelectedFusionAction(undefined);
              }}
            />
          ),
          "additional-widget": (
            <TabCreator
              gui={gui}
              onClickBack={() => {
                const currectId = searchParams.get("selectedTabId");
                if (additionalTabPath.length > 0) {
                  if (guiTab?.tab_type === "record_list") {
                    const parentTab = get(
                      guiTab.included_sidebar_widgets,
                      additionalTabPath.slice(0, -1)
                    ) as IncludeTabs;
                    searchParams.set("selectedTabId", parentTab.id);
                    setSearchParams(searchParams);
                    setTimeout(goBack, 0);
                    if (currectId !== "new-tab") {
                      setAdditionalTabPath((pa) => {
                        const newpath = pa.slice(0, -2);
                        return newpath;
                      });
                    }
                  }
                } else {
                  searchParams.delete("selectedTabId");
                  setSearchParams(searchParams);
                  setTimeout(goBack, 0);
                  setAdditionalTabPath([]);
                }
              }}
              indexKey={"included_sidebar_widgets"}
              onAddWidget={() => {
                searchParams.set("selectedTabId", "new-tab");
                setSearchParams(searchParams);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget",
                    id: v4(),
                  });
                }, 0);
              }}
              onEditWidget={(item: IncludeTabs, index: number) => {
                searchParams.set("selectedTabId", item.id);
                setSearchParams(searchParams);
                setAdditionalTabPath((path) => [
                  ...path,
                  index?.toString(),
                  "additional_tabs",
                ]);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget",
                    id: item.id,
                  });
                }, 0);
              }}
              allowAdditionalTabs={true}
              path={additionalTabPath}
              datasetDesigns={datasetDesigns}
              datasetDesignsMap={datasetDesignsMap}
              onEditFilterRules={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "additional-widget-field-filters",
                    id: "additional-widget-field-filters",
                  });
                }, 0);
              }}
              onEditImageSetting={(field) => {
                setSelectedField(field);
                setTimeout(() => {
                  gotoComponent({
                    name: "image-setting-widget",
                    id: "image-setting-widget",
                  });
                }, 0);
              }}
              onEditRules={() => {
                gotoComponent({ name: "widget-rules", id: "widget-rules" });
              }}
            />
          ),
          rules: (
            <GuiSideRules
              gui={gui}
              guiTab={guiTab!}
              onClickBack={() => goBack()}
            />
          ),
          "tab-rules": (
            <GuiSideRules
              gui={gui}
              guiTab={guiTab!}
              onClickBack={() => goBack()}
              indexKey="included_tabs"
              path={additionalTabPath}
            />
          ),
          "widget-rules": (
            <GuiSideRules
              gui={gui}
              guiTab={guiTab!}
              onClickBack={() => goBack()}
              indexKey={"included_sidebar_widgets"}
              path={additionalTabPath}
            />
          ),
          "general-field-filters": (
            <FieldFilterRules
              gui={gui}
              onClickBack={() => goBack()}
              indexKey="general"
              selectedField={selectedField!}
            />
          ),
          "additional-tabs-field-filters": (
            <FieldFilterRules
              gui={gui}
              onClickBack={() => goBack()}
              indexKey={"included_tabs"}
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
          "additional-widget-field-filters": (
            <FieldFilterRules
              gui={gui}
              onClickBack={() => goBack()}
              indexKey={"included_sidebar_widgets"}
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
          "image-setting-general": (
            <ImageSetting
              gui={gui}
              onClickBack={() => goBack()}
              indexKey="general"
              selectedField={selectedField!}
            />
          ),
          "image-setting-tabs": (
            <ImageSetting
              gui={gui}
              onClickBack={() => goBack()}
              indexKey={"included_tabs"}
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
          "image-setting-widget": (
            <ImageSetting
              gui={gui}
              onClickBack={() => goBack()}
              indexKey={"included_sidebar_widgets"}
              path={additionalTabPath}
              selectedField={selectedField!}
            />
          ),
        };
      },
      [
        additionalTabPath,
        datasetDesigns,
        datasetDesignsMap,
        gui,
        guiTab,
        handleFusionActionSubmit,
        searchParams,
        selectedField,
        selectedFusionAction,
        setSearchParams,
      ]
    );

  useEffect(() => {
    setInitialComponent(getInitialComponent());
  }, []);

  const handleClose = () => {
    queryClient.refetchQueries([ApiModels.Dataset, datasetDesignSlug]);
    onClose();
  };
  return (
    <DrawerBox
      anchor={"right"}
      open={open}
      width="420px"
      title="Widget Editor"
      icon={<GridViewOutlinedIcon />}
      onClose={handleClose}
    >
      <ScrollbarParent>
        <Scrollbar>
          <DatasetSidebarContainerWrap>
            {initialComponent && (
              <AnimationLayout
                ref={layoutRef}
                config={{
                  getComponents: getComponentMiddleComponent,
                  initialComponent,
                }}
              />
            )}
          </DatasetSidebarContainerWrap>
        </Scrollbar>
      </ScrollbarParent>
    </DrawerBox>
  );
};

export default GuiRightSideNav;
