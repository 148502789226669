import { Settings } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import GfDrawer from "components/GfDrawer";
import Scrollbar from "components/Scrollbar";
import React from "react";
import FusionRightBar from "./FusionRightBar";

const DrawerBox = styled(GfDrawer)(({ theme }) => ({
  ".MuiDrawer-paper ": {
    ".MuiPaper-root ": {
      background: `${theme.palette.background.GFTopNav} !important`,
      boxShadow: "none",

      ".MuiCard-root": {
        background: `${theme.palette.background.GFRightNavForeground} !important`,
        transition: "all 0.4s ease",

        "&:hover": {
          background: `${theme.palette.background.GF20} !important`,
        },
      },
    },

    ".drawer-head": {
      background: theme.palette.background.GFTopNav,
      padding: "15px 20px",
      borderBottom: `1px solid ${theme.palette.other?.divider}`,

      ".drawer-icon-holder": {
        width: "24px",
        height: "24px",
        background: "none",
      },

      ".drawer-title-holder": {
        padding: "0 0 0 11px",
      },
    },

    ".draggable-handle": {
      padding: "0",
    },
  },
}));

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",
  };
});

type FusionEditDrawerProps = {
  open: boolean;
  type?: FusionPageType;
  onClose(): void;
};

const FusionEditDrawer: React.FC<FusionEditDrawerProps> = (props) => {
  const { open, type, onClose } = props;

  return (
    <DrawerBox
      anchor={"right"}
      open={open}
      width="420px"
      title="Settings"
      icon={<Settings />}
      onClose={onClose}
      closeIcon={false}
    >
      <ScrollbarParent>
        <Scrollbar>
          <FusionRightBar onClose={onClose} type={type} />
        </Scrollbar>
      </ScrollbarParent>
    </DrawerBox>
  );
};

export default FusionEditDrawer;
