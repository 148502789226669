import { zodResolver } from "@hookform/resolvers/zod";
import { DeleteOutline } from "@mui/icons-material";
import EditOutlined from "@mui/icons-material/EditOutlined";
import LocalOfferOutlined from "@mui/icons-material/LocalOfferOutlined";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import FieldIcon from "components/Form/FieldIcon";
import FormField from "components/FormField";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { debounce } from "lodash";
import clone from "lodash/clone";
import set from "lodash/set";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useListItems from "queries/useListItems";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import InfoList from "stories/CompoundComponent/AccountInfoCard/AccountInfoCard/AccountInfoCard";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";
import { confirmDelete } from "utils/confirmDelete";
import { removeArrayItem } from "utils/removeArrayItem";
import { z } from "zod";

const InfoListWrap = styled(InfoList)(({ theme }) => {
  return {
    ".MuiList-root": {
      padding: "12px 0 8px",
    },
  };
});

const formSchema = z.object({
  hide_general_tab: z.boolean().optional(),
  selected_importer: z.string().optional(),
});

type FromType = z.infer<typeof formSchema>;

const GuiSidenavMain: React.FC<{
  gui: GfGui;
  onEditSeachFields: () => void;
  onEditFieldToInclude: () => void;
  onEditRules: () => void;
  onNewTabAddition: () => void;
  onEditAddition: (_: IncludeTabs, index: number) => void;
  onNewWidget: () => void;
  onEditWidget: (_: IncludeTabs, index: number) => void;
}> = ({
  gui,
  onEditSeachFields,
  onEditRules,
  onNewTabAddition,
  onEditFieldToInclude,
  onEditAddition,
  onNewWidget,
  onEditWidget,
}) => {
  const { data: uploadDesigns } = useListItems({
    modelName: ApiModels.UploadDesign,
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    reset,
    watch,
    setValue,
  } = useForm<FromType>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: { hide_general_tab: false, selected_importer: "" },
  });
  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);

  const selectedTab = useGuiDashboardStore.useSelectedTab();
  const guiTab = useMemo(() => {
    if (selectedTab != null && gui?.tabs) {
      return gui?.tabs?.[selectedTab];
    }
  }, [gui?.tabs, selectedTab]);

  const isGuiRecordList = guiTab?.tab_type === "record_list";
  const datasetDesignSlug = isGuiRecordList
    ? guiTab.dataset_design_slug
    : undefined;

  const view_filters = gui?.filter_settings?.view_filters?.[guiTab!.id] || [];
  const searchFieldsIds = isGuiRecordList ? guiTab.search_fields : undefined;
  const formFieldsIds = isGuiRecordList ? guiTab.form_fields : undefined;
  const includedTabs = isGuiRecordList ? guiTab.included_tabs : undefined;
  const includedWdigets = isGuiRecordList
    ? guiTab.included_sidebar_widgets
    : undefined;

  const {
    slug: guiSlug,
    // datasetSlug,
  } =
    useParams<{
      slug: string;
      datasetSlug: string;
    }>();
  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetDesignSlug,
    queryOptions: { enabled: false },
  });

  const { data: datasetDesigns } = useListItems({
    modelName: ApiModels.DatasetDesign,
    queryOptions: { enabled: false },
  });
  const theme = useTheme();

  const { mutate: updateGui } = useUpdateItem({
    modelName: ApiModels.Gui,
  });

  const searchFields = useMemo(() => {
    return datasetDesign?.fields?.fields?.filter((f) =>
      searchFieldsIds?.includes(f.id)
    );
  }, [searchFieldsIds, datasetDesign?.fields?.fields]);

  const formFields = useMemo(() => {
    return datasetDesign?.fields?.fields?.filter((f) =>
      formFieldsIds?.includes(f.id)
    );
  }, [formFieldsIds, datasetDesign?.fields?.fields]);

  const datasetDesignsMap = useMemo(() => {
    const datasetMap = new Map<string, DatasetDesign>();
    datasetDesigns?.find((dd) => {
      datasetMap.set(dd.slug, dd);
    });
    return datasetMap;
  }, [datasetDesigns]);

  useEffect(() => {
    initialValueSet.current = false;
  }, [guiTab?.id]);

  useEffect(() => {
    if (isGuiRecordList && !initialValueSet.current) {
      setValue("hide_general_tab", !!guiTab?.hide_general_tab);
      setValue("selected_importer", guiTab?.selected_importer || "");

      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 1000);
    }
  }, [
    guiTab?.hide_general_tab,
    guiTab?.selected_importer,
    isGuiRecordList,
    setValue,
  ]);

  const submitHandler = useCallback(
    (value: Partial<GuiTab>) => {
      if (!guiTab?.id) return;
      let updatedGuiTab: any = clone(guiTab);

      updatedGuiTab = { ...updatedGuiTab, ...value };
      updateGui(
        {
          slug: guiSlug!,
          data: {
            tabs:
              gui.tabs?.map((t) => (t.id === guiTab.id ? updatedGuiTab : t)) ||
              [],
          },
        },
        {
          onSuccess: () => {
            console.log("AccountUser edit success");
          },
        }
      );
    },
    [gui.tabs, guiSlug, guiTab, updateGui]
  );

  const handleDeleteTab = async (
    tabIndex: number,
    indexKey: "included_tabs" | "included_sidebar_widgets"
  ) => {
    if (guiTab?.tab_type !== "record_list") {
      return;
    }
    confirmDelete().then(async (result) => {
      if (!result.isConfirmed || !guiTab?.[indexKey]?.length) return;
      const datasetListSetting = clone(guiTab);
      const newTabs = removeArrayItem(
        clone(datasetListSetting?.[indexKey] || []),
        tabIndex
      );
      set(datasetListSetting, indexKey, newTabs);
      updateGui(
        {
          slug: guiSlug!,
          data: {
            tabs:
              gui.tabs?.map((t) =>
                t.id === guiTab.id ? { ...guiTab, ...datasetListSetting } : t
              ) || [],
          },
        },
        {
          onSuccess: () => {
            console.log("AccountUser edit success");
          },
        }
      );
    });
  };

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 600);
    const subscription = watch((_) => {
      submitDeb();
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  const uploadDesignsMap = useMemo(() => {
    const uploadDesignsMap = new Map<string, UploadDesign>();
    uploadDesigns?.find((dd) => {
      uploadDesignsMap.set(dd.slug, dd);
    });
    return uploadDesignsMap;
  }, [uploadDesigns]);
  return (
    <React.Fragment>
      {isGuiRecordList && (
        <SidebarSectionWrap title="General Settion" rightIcon={false}>
          <Box mb={1}>
            <FormControlLabel
              control={<Checkbox {...register("hide_general_tab")} />}
              label={"Hide General Table"}
            />
          </Box>
          <Box>
            <FormField label="Selected Importer">
              <Controller
                name="selected_importer"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    value={
                      field?.value
                        ? ({
                            title: uploadDesignsMap.get(field.value)?.title,
                            slug: field.value,
                          } as any)
                        : null
                    }
                    options={uploadDesigns || []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.title || ""}
                    onChange={(e, option) => field.onChange(option?.slug)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        type="text"
                        fullWidth
                        hiddenLabel
                        size="small"
                        variant="filled"
                      />
                    )}
                  />
                )}
              />
            </FormField>
          </Box>
        </SidebarSectionWrap>
      )}
      <SidebarSectionWrap title="Data View Filters" rightIcon={false}>
        <ProfileCard
          options={{
            draggable: false,
            switcher: false,
          }}
          rightIcon={
            <Stack direction="row" spacing={1}>
              <Box className="edit-icon">
                <DeleteOutline
                  sx={{ color: "grey.500" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    // Delete logic
                  }}
                />
              </Box>
              <Box className="edit-icon">
                <EditOutlined sx={{ color: "grey.500" }} />
              </Box>
            </Stack>
          }
          subTitle={`${view_filters?.length} Inactive`}
          title={`${view_filters?.length} Active Rules`}
          sx={{
            background: theme.palette.background.GFRightNavForeground,
            height: 48,
            ".MuiTypography-subtitle1": {
              margin: "0 0 2px",
            },

            ".card-inner-content": {
              gap: "2px",
            },
          }}
          onClick={() => onEditRules?.()}
        />
      </SidebarSectionWrap>
      <SidebarSectionWrap title="Data Search Fields" rightIcon={false}>
        <InfoListWrap
          data={searchFields?.map((f) => {
            const Icon =
              FieldIcon[f.type as keyof typeof FieldIcon] || LocalOfferOutlined;
            return {
              title: f.title,
              icon: <Icon />,
            };
          })}
          headerRightIcon={
            <Box className="edit-icon">
              <EditOutlined
                onClick={onEditSeachFields}
                sx={{ color: "grey.500" }}
              />
            </Box>
          }
          title={"Included Fields"}
          sx={{
            "&:hover": {
              background: theme.palette.background.GFRightNavForeground,
            },
          }}
        />
      </SidebarSectionWrap>

      <SidebarSectionWrap title="Fields to Include" rightIcon={false}>
        <InfoListWrap
          data={formFields?.map((f) => {
            const Icon =
              FieldIcon[f.type as keyof typeof FieldIcon] || LocalOfferOutlined;
            return {
              title: f.title,
              icon: <Icon />,
            };
          })}
          headerRightIcon={
            <Box className="edit-icon">
              <EditOutlined
                onClick={onEditFieldToInclude}
                sx={{ color: "grey.500" }}
              />
            </Box>
          }
          title={"Included Fields"}
          sx={{
            "&:hover": {
              background: theme.palette.background.GFRightNavForeground,
            },
          }}
        />
      </SidebarSectionWrap>

      <SidebarSectionWrap
        title="Additional Tabs"
        onRightIconClick={onNewTabAddition}
      >
        {includedTabs?.map((tab, index) => {
          const inforList = [
            {
              title: tab.name,
              icon: "Name :",
            },
            {
              title: datasetDesignsMap.get(tab.dataset_to_include)?.name,
              icon: "Included Dataset :",
            },
            {
              title: tab.association_type,
              icon: "Association Type :",
            },
          ];
          if (tab.association_type !== "parent") {
            inforList.push({
              title: tab.record_type,
              icon: "Record Type :",
            });
          }
          return (
            <InfoListWrap
              key={tab.id}
              data={inforList}
              headerRightIcon={
                <Box>
                  <IconButton>
                    <DeleteOutline
                      onClick={() => handleDeleteTab(index, "included_tabs")}
                    />
                  </IconButton>
                  <IconButton>
                    <EditOutlined
                      onClick={() => onEditAddition(tab, index)}
                      sx={{ color: "grey.500" }}
                    />
                  </IconButton>
                </Box>
              }
              title={"Included Fields"}
              sx={{
                marginBottom: "10px",
                "&:hover": {
                  background: theme.palette.background.GFRightNavForeground,
                },
              }}
            />
          );
        })}
      </SidebarSectionWrap>
      <SidebarSectionWrap title="Sidebar Widget" onRightIconClick={onNewWidget}>
        {includedWdigets?.map((tab, index) => {
          const inforList = [
            {
              title: tab.name,
              icon: "Name :",
            },
            {
              title: datasetDesignsMap.get(tab.dataset_to_include)?.name,
              icon: "Included Dataset :",
            },
            {
              title: tab.association_type,
              icon: "Association Type :",
            },
          ];
          if (tab.association_type !== "parent") {
            inforList.push({
              title: tab.record_type,
              icon: "Record Type :",
            });
          }
          return (
            <InfoListWrap
              key={tab.id}
              data={inforList}
              headerRightIcon={
                <Box>
                  <IconButton>
                    <DeleteOutline
                      onClick={() =>
                        handleDeleteTab(index, "included_sidebar_widgets")
                      }
                    />
                  </IconButton>
                  <IconButton>
                    <EditOutlined
                      onClick={() => onEditWidget(tab, index)}
                      sx={{ color: "grey.500" }}
                    />
                  </IconButton>
                </Box>
              }
              title={"Included Fields"}
              sx={{
                marginBottom: "10px",
                "&:hover": {
                  background: theme.palette.background.GFRightNavForeground,
                },
              }}
            />
          );
        })}
      </SidebarSectionWrap>
    </React.Fragment>
  );
};

export default GuiSidenavMain;
