import { styled } from "@mui/material";
import AppBarComp, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { ReactNode } from "react";

export type SystemAppBarProps = AppBarProps & {
  title?: ReactNode;
  DropDown?: React.ReactNode | React.ReactElement;
  leftIcon?: React.ReactNode | React.ReactElement;
  centeredComponent?: React.ReactNode | React.ReactElement;
  onLeftIconClick?: React.MouseEventHandler<HTMLButtonElement>;
  rightComponent?: React.ReactNode;
  leftMenuClasses?: string;
  navMenu?: boolean;
};

const AppBarWrap = styled(AppBarComp)(({ theme }) => ({
  ".MuiToolbar-root": {
    minHeight: "60px !important",
  },

  ".arrow-back": {
    "&:hover": {
      opacity: "0.6",
    },
  },

  ".full-width": {
    width: "100%",
  },

  ".menu-left": {
    ".right-column": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
      marginLeft: "0",

      ".MuiStack-root": {
        width: "100%",
        justifyContent: "space-between",
      },
    },
  },
}));

const SystemAppBar = (props: SystemAppBarProps) => {
  const {
    title = "syler.ai",
    DropDown,
    leftIcon,
    //  = (
    //   <ArrowBack
    //     sx={{
    //       width: "24px",
    //       height: "24px",
    //       padding: "4px",
    //       marginRight: "12px",
    //     }}
    //   />
    // ),
    centeredComponent,
    onLeftIconClick,
    rightComponent,
    leftMenuClasses,
    navMenu = true,
    ...rest
  } = props;

  return (
    <AppBarWrap position="fixed" {...rest}>
      <Toolbar></Toolbar>
    </AppBarWrap>
  );
};

export default SystemAppBar;
