import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export const stripePromise = loadStripe(
  "pk_test_51LwYZtBpvm0C4vU7qfSvU4V55U5qwut3Vtp1IXPIYvzzylFnJZhpb0LxGaFvVx43R2e9trKl7zcPAQXAYlXq9zdc00jlzZuERG"
);

const StripeHoc: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeHoc;
