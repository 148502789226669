import { Settings } from "@mui/icons-material";
import {
  Box,
  Popover,
  popoverClasses,
  PopoverProps,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import GfDrawer from "components/GfDrawer";
import Scrollbar from "components/Scrollbar";
import useOpenClose from "hooks/useOpenClose";
import React, { useCallback, useImperativeHandle } from "react";
import { cloneElement } from "utils";
import FlowPopoverContainer, {
  FlowPopoverContainerProps,
} from "./FlowPopoverContainer";

export const NodeEditorTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`.${tooltipClasses.popper}`]: {
    position: "fixed",
    color: "white",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    width: 450,
    padding: 0,
    maxWidth: "none",
    maxHeight: "800px",
    backgroundColor: theme.palette.background.GFRightNavBackground,
    // overflowY: "auto",
    overflow: "hidden",
  },
}));

export const NodeEditorPopover = styled(
  ({ className, ...props }: PopoverProps) => (
    <Popover {...props} classes={{ root: className }} />
  )
)(({ theme }) => ({
  // [`.${tooltipClasses.popper}`]: {
  //   position: "fixed",
  //   color: "white",
  // },
  [`& .${popoverClasses.paper}`]: {
    width: 450,
    padding: 0,
    maxWidth: "none",
    maxHeight: "800px",
    backgroundColor: theme.palette.background.GFRightNavBackground,
    // overflowY: "auto",
    overflow: "hidden",
  },
}));

const DrawerBox = styled(GfDrawer)(({ theme }) => ({
  ".MuiDrawer-paper ": {
    ".MuiPaper-root ": {
      background: `${theme.palette.background.GFTopNav} !important`,
      boxShadow: "none",

      ".MuiCard-root": {
        background: `${theme.palette.background.GFRightNavForeground} !important`,
        transition: "all 0.4s ease",

        "&:hover": {
          background: `${theme.palette.background.GF20} !important`,
        },
      },
    },

    ".drawer-head": {
      background: theme.palette.background.GFTopNav,
      padding: "15px 20px",
      borderBottom: `1px solid ${theme.palette.other?.divider}`,

      ".drawer-icon-holder": {
        width: "24px",
        height: "24px",
        background: "none",

        ".MuiAvatar-root": {
          background: "transparent",

          ".MuiSvgIcon-root": {
            width: "100%",
            height: "100%",
          },
        },
      },

      ".drawer-title-holder": {
        padding: "0 0 0 11px",
      },
    },

    ".draggable-handle": {
      padding: "0",
    },
  },
}));

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",
  };
});

const ContentContainer = styled(Box)(({ theme }) => {
  return {
    // paddingLeft: 20,
    // paddingRight: 20,
    // paddingBottom: 20,
    // marginBottom: 20,
    // [`${theme.breakpoints.down("sm")}`]: {
    //   paddingLeft: 0,
    //   paddingRight: 0,
    // },
  };
});

type Props = {
  content?: React.ReactNode;
  containerProps?: FlowPopoverContainerProps & { icon?: React.ReactElement };
  delayDuration?: number;
  onSaveClick?(data: { onClose(): void }): void;
  onOpen?(): void;
  onClose?(): void;
} & Partial<Omit<PopoverProps, "content">>;

export type FlowPopoverRef = {
  close(): void;
  open(el: Element | SVGGElement): void;
};

const FlowPopover = React.forwardRef<FlowPopoverRef | undefined, Props>(
  (props, ref) => {
    const {
      content,
      onOpen,
      onClose,
      children,
      containerProps,
      onSaveClick,
      delayDuration = 0,
      ...popoverProps
    } = props;

    // const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const [isOpen, open, close] = useOpenClose();

    const handleClick = (event: React.MouseEvent<Element>) => {
      event.stopPropagation();
      // const ct = event.currentTarget;
      // setTimeout(() => {
      //   setAnchorEl(ct);
      // }, delayDuration);
      open();
      onOpen?.();
    };

    const handleClose = useCallback(() => {
      // setAnchorEl(null);
      close();
      onClose?.();
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        close: handleClose,
        open: (el: Element) => {
          // setAnchorEl(el);
          open();
        },
      }),
      [handleClose]
    );

    // const open = Boolean(anchorEl);

    return (
      <Box>
        <DrawerBox
          anchor={"right"}
          open={isOpen}
          width="420px"
          title={containerProps?.title}
          icon={containerProps?.icon || <Settings />}
          onClose={handleClose}
          closeIcon={false}
        >
          <ScrollbarParent>
            <Scrollbar>
              <ContentContainer sx={{ p: 2 }}>
                <FlowPopoverContainer
                  {...containerProps}
                  onSaveClick={() => onSaveClick?.({ onClose: handleClose })}
                  onCloseClick={handleClose}
                >
                  {cloneElement(content, { onClose: handleClose })}
                </FlowPopoverContainer>
              </ContentContainer>
            </Scrollbar>
          </ScrollbarParent>
        </DrawerBox>
        <Box sx={{ height: "100%", width: "100%" }} onClick={handleClick}>
          {children}
        </Box>
      </Box>
    );

    // return (
    //   // <ClickAwayListener
    //   //   onClickAway={(e) => {
    //   //     console.log(e.currentTarget);
    //   //   }}
    //   // >
    //   <Box sx={{ height: "100%", width: "100%" }}>
    //     <NodeEditorPopover
    //       open={open}
    //       onClose={handleClose}
    //       anchorOrigin={{
    //         vertical: "center",
    //         horizontal: "right",
    //       }}
    //       transformOrigin={{
    //         vertical: "center",
    //         horizontal: -15,
    //       }}
    //       anchorEl={anchorEl}
    //       id="flow-popover"
    //       className="flow-popover"
    //       {...popoverProps}
    //     >
    //       <FlowPopoverContainer
    //         {...containerProps}
    //         onSaveClick={() => onSaveClick?.({ onClose: handleClose })}
    //         onCloseClick={handleClose}
    //       >
    //         {cloneElement(content, { onClose: handleClose })}
    //       </FlowPopoverContainer>
    //     </NodeEditorPopover>
    //     <Box sx={{ height: "100%", width: "100%" }} onClick={handleClick}>
    //       {children}
    //     </Box>
    //   </Box>
    //   // </ClickAwayListener>
    // );
  }
);

export default FlowPopover;
