import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Link as MuiLink,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";

const GridCardIconButton = styled(IconButton)(({ theme }) => ({
  border: "none",
  borderRadius: "6px",
  height: "30px",
  color: "#fff",
  background: theme.palette.background.GF10,

  "&:hover": {
    background: theme.palette.primary.main,
  },
}));

const GridCardButton = styled(Button)(({ theme }) => ({
  border: "none",
  color: "#fff",
  background: theme.palette.background.GF10,
}));

const GridCardContainer = styled(Card)(({ theme }) => ({
  background: theme.palette.background.GF10,
  border: `1px solid ${theme.palette.background.GF10}`,
  borderRadius: "5px",
  overflow: "hidden",
  position: "relative",
  height: "230px",

  ".image-holder": {
    // padding: "12px",
    background: "#000",
    height: "174px",

    img: {
      height: "174px",
      display: "block",
      margin: "0 auto",
      objectFit: "contain",
    },
  },

  ".MuiCardContent-root": {
    padding: "8px 8px 9px 17px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "56px",
    borderTop: `1px solid ${theme.palette.background.GF5}`,
  },

  ".MuiCardActions-root": {
    height: "56px",
    display: "none",
  },

  ".icon": {
    width: "22px",

    "svg, img": {
      width: "100%",
      height: "auto",
      display: "block",
    },
  },

  ".MuiTypography-h5": {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "500",
    margin: "0",
  },

  ".MuiTypography-body2": {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "300",
  },

  ".img-star": {
    width: "15px",
    minWidth: "15px",
    color: theme.palette.background.cardsBorder,

    "svg, img": {
      width: "100%",
      height: "auto",
      display: "block",
      color: "currentColor",
    },
  },

  "&:hover": {
    ".MuiCardActions-root": {
      display: "block",
    },
    ".MuiCardContent-root": {
      display: "none",
    },
  },
}));

type ActionBarProps = Pick<GridCardProps, "actions" | "onOpenClick" | "id">;

const ActionBar: React.FC<ActionBarProps> = memo(
  (props) => {
    const { actions = [], onOpenClick, id } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const menuItems = useMemo(
      () => actions.map((action) => ({ ...action, key: v4() })),
      [actions]
    );

    return (
      <CardActions
        className="hover-content"
        sx={{
          background: "#303337",
          borderTop: "1px solid rgba(255, 255, 255, 0.05)",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%", height: "100%" }}
        >
          <GridCardButton
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onOpenClick?.(id);
            }}
          >
            Open
          </GridCardButton>
          <Stack direction="row" alignItems="center" spacing={1}>
            <GridCardIconButton
              onClick={(e) => {
                handleMoreClick(e);
              }}
            >
              <MoreHoriz fontSize="small" />
            </GridCardIconButton>
          </Stack>
        </Stack>
        {menuItems.length > 0 ? (
          <Menu
            id="edit-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            disablePortal
          >
            {menuItems.map((action) => (
              <MenuItem
                key={action.key}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAnchorEl(null);
                  action.onClick?.(id);
                }}
              >
                {action.icon} {action.title}
              </MenuItem>
            ))}
          </Menu>
        ) : null}
      </CardActions>
    );
  },
  (prevProps, nextProps) =>
    prevProps.actions === nextProps.actions &&
    prevProps.onOpenClick === nextProps.onOpenClick &&
    prevProps.id === nextProps.id
);

type ContentProps = Pick<GridCardProps, "title" | "subtitle" | "icon">;

const Content: React.FC<ContentProps> = memo(
  (props) => {
    const { title, subtitle, icon } = props;
    return (
      <CardContent className="card-content">
        <Stack
          alignItems="center"
          direction={"row"}
          gap="20px"
          sx={{ width: "100%", height: "100%" }}
        >
          <Box className="icon">{icon}</Box>
          <Stack gap="5px" className="text-holder" sx={{ width: "100%" }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                width: "90%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    );
  },
  (prevProps, nextProps) =>
    prevProps.title === nextProps.title &&
    prevProps.subtitle === nextProps.subtitle &&
    prevProps.icon === nextProps.icon
);

type MediaProps = Pick<GridCardProps, "imageUrl" | "onMediaClick" | "id">;

const Media: React.FC<MediaProps> = memo(
  (props) => {
    const { imageUrl, onMediaClick, id } = props;

    return (
      <Box className="image-holder">
        <CardMedia
          component="img"
          src={imageUrl}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onMediaClick?.(id);
          }}
        />
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.imageUrl === nextProps.imageUrl &&
    prevProps.onMediaClick === nextProps.onMediaClick &&
    prevProps.id === nextProps.id
);

type GridCardProps = {
  id: string;
  imageUrl?: string;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  link?: string;
  actions?: {
    title?: React.ReactNode;
    icon?: React.ReactNode;
    onClick?(id: string): void;
  }[];
  onOpenClick?(id: string): void;
  onMediaClick?(id: string): void;
  overlay?: boolean;
};

const GridCard: React.FC<GridCardProps> = (props) => {
  const {
    id,
    link = "",
    imageUrl,
    icon,
    title,
    subtitle,
    actions = [],
    onOpenClick,
    onMediaClick,
    overlay,
  } = props;

  console.log("🚀 ~ link:", link);
  const { attributes, listeners, setNodeRef, isDragging, transform } =
    useDraggable({
      id: `grid-card_${id}`,
      data: { id },
    });

  const CardContent = (
    <>
      <Media onMediaClick={onMediaClick} id={id} imageUrl={imageUrl} />
      <Content title={title} subtitle={subtitle} icon={icon} />
      <ActionBar onOpenClick={onOpenClick} id={id} actions={actions} />
    </>
  );

  // console.log("-isDragging", isDragging);
  return (
    <GridCardContainer
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        zIndex: overlay ? 100 : undefined,
        opacity: isDragging ? 0 : overlay ? 0.3 : 1,
        // display: isDragging ? "none" : "block",
      }}
      {...attributes}
      {...listeners}
    >
      {link ? (
        <MuiLink component={Link} to={link} color="inherit" underline="none">
          {CardContent}
        </MuiLink>
      ) : (
        CardContent
      )}
    </GridCardContainer>
  );
};

export default GridCard;
