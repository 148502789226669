import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import FusionModel from "models/Fusion";

const fetchFusionConnections = async (context: QueryFunctionContext) => {
  const [, fusionSlug, appSlug, query] = context.queryKey;
  const res = await FusionModel.getConnections(
    fusionSlug as string,
    appSlug as string,
    query as Record<string, string>
  );
  return res.data;
};

const useFusionConnections = (
  fusionSlug?: string,
  appSlug?: string,
  query: Record<string, string> = {}
) => {
  return useQuery(
    ["fusion-connections", fusionSlug, appSlug, query],
    fetchFusionConnections,
    {
      enabled: !!fusionSlug && !!appSlug,
    }
  );
};

export default useFusionConnections;
