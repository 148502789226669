import { ArrowBack } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { cloneDeep, debounce, get, set } from "lodash";
import { ApiModels } from "queries/apiModelMapping";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import ConditionSets from "./ConditionSets";

const RulesCard = styled(Card)(({ theme }) => {
  return {
    padding: "13px",
    transition: "all 0.4s ease",
    background: theme.palette.background.GFOutlineNav,

    ".MuiCardHeader-root": {
      padding: "0 0 9px",

      ".MuiTypography-root": {
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.text.primary,
        fontWeight: "400",
      },

      ".MuiCardHeader-action ": {
        marginRight: "0",
        ".MuiButtonBase-root": {
          padding: "0",
          width: "20px",
          height: "20px",
          color: theme.palette.text.primary,

          svg: {
            width: "100%",
            height: "auto",
            display: "block",
          },
        },
      },
    },

    ".MuiCardContent-root ": {
      padding: "0",
      display: "flex",
      flexDirection: "column",
      gap: "11px",

      "&:last-child": {
        padding: "0",
      },
    },

    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },

    ".MuiInputBase-root": {
      background: theme.palette.background.GFTopNav,
    },
  };
});

const name = "view_filters";
const RecordFilterRules: React.FC<{
  gui: GfGui;
  guiTab: GuiTab;
  indexKey?: string;
  path?: string[];
  onClickBack: () => void;
}> = ({ gui, guiTab, onClickBack, indexKey, path }) => {
  const { control, register, handleSubmit, watch, setValue, ...rest } =
    useForm();
  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);
  const { mutate: updateGui } = useUpdateItem({ modelName: ApiModels.Gui });

  const guiSlug = useMemo(() => gui.slug, [gui.slug]);
  const { fields, remove, replace, insert } = useFieldArray({
    control,
    name,
  });

  // useEffect(() => {
  //   if (!fields.length) {
  //     replace([{ condition_set: [{ a: "", b: "", o: "=" }] }]);
  //   }
  // }, [fields]);

  useEffect(() => {
    initialValueSet.current = false;
    allowNetworkRequest.current = false;
  }, [guiSlug]);

  useEffect(() => {
    if (guiSlug && !initialValueSet.current) {
      let filters = gui?.filter_settings?.view_filters?.[guiTab.id!];
      if (indexKey && path) {
        let parentTab = guiTab[indexKey as keyof typeof guiTab];
        const selectedTab = get(parentTab, path.slice(0, -1)) as IncludeTabs;
        filters = selectedTab.filter_rules ?? [];
      }
      setValue(name, filters);
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 500);
    }
  }, [guiSlug, gui?.filter_settings, setValue, guiTab, indexKey, path]);

  const submitHandler = useCallback(
    (data: Partial<{ view_filters: Condition[] }>) => {
      if (guiSlug && guiTab.id && allowNetworkRequest.current) {
        if (indexKey && path) {
          let parentTab = guiTab[indexKey as keyof typeof guiTab];
          const selectedTab = get(parentTab, path.slice(0, -1)) as IncludeTabs;
          const tab = cloneDeep(guiTab);
          set(tab, [indexKey, ...path.slice(0, -1)], {
            ...selectedTab,
            filter_rules: data.view_filters,
          });
          updateGui(
            {
              slug: guiSlug,
              data: {
                tabs:
                  gui.tabs?.map((t) =>
                    t.id === guiTab.id ? { ...guiTab, ...tab } : t
                  ) ?? [],
              },
            },
            {
              onSuccess: () => {
                console.log("AccountUser edit success");
              },
            }
          );
        } else {
          const reqData = set(
            cloneDeep(gui?.filter_settings),
            `view_filters.${guiTab.id}`,
            data.view_filters
          );
          updateGui(
            {
              slug: guiSlug,
              data: { filter_settings: reqData },
            },
            {
              onSuccess: () => {
                console.log("AccountUser edit success");
              },
            }
          );
        }
      }
    },
    [gui?.filter_settings, gui.tabs, guiSlug, guiTab, indexKey, path, updateGui]
  );

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 600);
    const subscription = watch((_) => {
      submitDeb();
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  return (
    <SidebarSectionWrap
      title="Manage Rules"
      onRightIconClick={() => {
        insert(fields.length + 1, {
          condition_set: [{ a: "", b: "", o: "=" }],
        });
      }}
      leftIcon={<ArrowBack />}
      onLeftIconClick={onClickBack}
    >
      {fields.map((field, fieldIdx) => {
        return (
          <Box key={field.id} id={field.id}>
            <Stack direction="column" spacing={1} key={field.id}>
              {fieldIdx > 0 && (
                <Divider
                  sx={{
                    "&:before, &:after": {
                      borderColor: (theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  or
                </Divider>
              )}
            </Stack>
            <RulesCard>
              <CardHeader
                title="INCLUDE IF "
                action={
                  <IconButton
                    aria-label="cross"
                    onClick={() => remove(fieldIdx)}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              />
              <CardContent sx={{ padding: "0px 8px" }}>
                <ConditionSets
                  control={control}
                  register={register}
                  parentNamePath={`${name}[${fieldIdx}]`}
                  conditionsLength={fields.length}
                  indexKey={indexKey}
                  path={path}
                  // field={{}}
                  // field={paramField}
                  {...rest}
                />
              </CardContent>
            </RulesCard>
          </Box>
        );
      })}
    </SidebarSectionWrap>
  );
};

export default RecordFilterRules;
