import React, { useRef } from "react";

const SimpleInputWrapper: React.FC<
  React.PropsWithChildren<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > & { presist?: boolean }
  >
> = ({ children, onChange, presist, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const hanldeClick = () => {
    (inputRef as any)?.current?.click();
  };
  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange?.(e);

    if (!presist && inputRef?.current?.value) {
      inputRef.current.value = "";
    }
  };
  return (
    <React.Fragment>
      <input
        type="file"
        style={{ opacity: 0, visibility: "hidden", position: "absolute" }}
        ref={inputRef}
        {...rest}
        onChange={onChangeHandler}
      />
      <div onClick={hanldeClick}>{children}</div>
    </React.Fragment>
  );
};
export default SimpleInputWrapper;
