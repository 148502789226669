import Model from "models";
import request from "utils/request";

class UserModel extends Model<User> {
  constructor() {
    super("/user", "user-mng");
  }

  async getUser() {
    return await this.sendRequest<{
      user: User;
      accounts: AppAccount[];
    }>("", "GET");
  }

  async updateUser(data: Partial<User>) {
    return await this.sendRequest("", "PUT", data);
  }
  async createAccount(data: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    // company_name: string;
    // account_package_id: string;
  }) {
    const res = await request("/account/create", {
      method: "POST",
      data,
      public: true,
    });
  }
}

export default new UserModel();
