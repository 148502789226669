import { Box, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import Scrollbar from "components/Scrollbar";
import useOpenClose from "hooks/useOpenClose";
import { AnimationLayoutRef } from "layouts/AnimationLayout";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import useListItems from "queries/useListItems";
import React, { useMemo, useRef, useState } from "react";
import MultipleContainers from "views/app-view/gui-workflow-board/components/MultipleContainers";
import DatasetEditDrawer from "../gui-dataset/DatasetEditDrawer";

const WorkflowBoardContainer = styled(Box)({});

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 120px)`,
    overflow: "hidden",

    ".side-menu-active &": {
      height: "calc(100vh - 60px)",
    },
  };
});

type WorkflowBoardProps = {
  datasetDesignSlug: string;
  statuses: WorkflowStatus[];
  statusField: string;
};

const WorkflowBoard: React.FC<WorkflowBoardProps> = (props) => {
  const { datasetDesignSlug, statuses, statusField } = props;
  const layoutRef = useRef<AnimationLayoutRef>(null);
  const queryClient = useQueryClient();
  const [isDrawerOpen, onDrawerOpen, closeDrawer] = useOpenClose();
  const [data, setData] =
    useState<{ datasetSlug: string; datasetDesignSlug: string }>();
  const { data: datasets } = useListItems({
    modelName: ApiModels.Dataset,
    requestOptions: {
      query: {
        dataset_type_slug: datasetDesignSlug,
        included_fields: "all",
      },
      path: `list/${datasetDesignSlug}`,
    },
    queryKey: [
      ApiModels.Dataset,
      {
        dataset_type_slug: datasetDesignSlug,
        included_fields: "all",
      },
    ],
  });

  const { mutate: createDataset } = useCreateItem({
    modelName: ApiModels.Dataset,
  });

  const boardStatuses = useMemo(() => {
    return statuses.map((status) => ({
      ...status,
      children:
        datasets?.filter(
          (dataset) => dataset[statusField as keyof Dataset] === status.status
        ) || [],
    }));
  }, [statuses, datasets]);

  const addDataset = (
    dataset: Partial<Dataset>,
    status: WorkflowStatus
  ): Promise<void> => {
    const data = {
      ...dataset,
      dataset_type_slug: datasetDesignSlug,
      fields: {
        [statusField]: status.status,
      },
    };
    return new Promise((resolve, reject) => {
      createDataset(data, {
        onSuccess(res) {
          queryClient.setQueryData<Dataset[]>(
            [
              ApiModels.Dataset,
              {
                dataset_type_slug: datasetDesignSlug,
                included_fields: "all",
              },
            ],
            (list) => {
              if (list) {
                return [...list, { ...res, ...data.fields }];
              }

              return [{ ...res, ...data.fields }];
            }
          );
          resolve();
        },
        onError() {
          reject();
        },
      });
    });
  };

  const onEditHanlder = (data: Dataset) => {
    onDrawerOpen();
    setData({
      datasetSlug: data.slug,
      datasetDesignSlug: data.dataset_type_slug,
    });
    console.log("clickedt");
  };
  const hanldeClose = () => {
    setData(undefined);
    closeDrawer();
  };
  return (
    <WorkflowBoardContainer>
      <ScrollbarParent>
        <Scrollbar>
          <MultipleContainers
            statuses={boardStatuses}
            onAdd={addDataset}
            onEdit={onEditHanlder}
          />
        </Scrollbar>
      </ScrollbarParent>
      <DatasetEditDrawer
        open={isDrawerOpen}
        onClose={hanldeClose}
        layoutRef={layoutRef}
        datasetDesignSlug={data?.datasetDesignSlug!}
        datasetSlug={data?.datasetSlug!}
        includeAll={true}
      />
    </WorkflowBoardContainer>
  );
};

export default WorkflowBoard;
