import { Box, Button, Stack, styled } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import Icon from "components/util-components/Icon";
import React from "react";

const DatasetSidebarContainerWrap = styled(Box)(({ theme }) => {
  return {
    padding: 20,
    marginBottom: 20,

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 120px)`,
    overflow: "hidden",

    ".side-menu-active &": {
      height: "calc(100vh - 60px)",
    },
  };
});

type RightSidebarProps = {
  actions?: FusionAction[];
  onNextClick(): void;
  onPreviousClick(): void;
  onActionClick(action: FusionAction): void;
};

const RightSidebar: React.FC<RightSidebarProps> = (props) => {
  const { actions, onActionClick, onNextClick, onPreviousClick } = props;

  return (
    <Box>
      <ScrollbarParent>
        <Scrollbar>
          <DatasetSidebarContainerWrap>
            <Stack direction="column" spacing="20px">
              <Stack
                direction="row"
                spacing={2}
                sx={{ p: 2, background: "#2F2F36", borderRadius: "6px" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onPreviousClick}
                >
                  Previous
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onNextClick}
                >
                  Next
                </Button>
              </Stack>
              {!!actions?.length && (
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{ p: 2, background: "#2F2F36", borderRadius: "6px" }}
                >
                  {actions?.map((action) => (
                    <Button
                      key={action.id}
                      fullWidth
                      variant="contained"
                      color="primary"
                      startIcon={<Icon iconName={action.action_icon} />}
                      onClick={() => onActionClick(action)}
                    >
                      {action.action_title}
                    </Button>
                  ))}
                </Stack>
              )}
            </Stack>
          </DatasetSidebarContainerWrap>
        </Scrollbar>
      </ScrollbarParent>
    </Box>
  );
};

export default RightSidebar;
