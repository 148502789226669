import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type State = {
  view: "grid" | "list";
  selectedFolderId: number | null;
  pendingMediaList: Media[];
  pendingMediaUploadProgress: Map<string, number>;
};

type Actions = {
  setView: (view: State["view"]) => void;
  setSelectedFolderId: (selectedFolderId: number | null) => void;
  addPendingMedia: (media: Media) => void;
  removePendingMedia: (mediaId: string) => void;
  updatePendingMediaProgress: (mediaId: string, progress: number) => void;
  setPendingMediaError: (mediaId: string, error: string) => void;
};

export const useMediaStoreBase = create(
  devtools(
    immer<State & Actions>((set) => ({
      view: "grid",
      selectedFolderId: null,
      pendingMediaList: [],
      pendingMediaUploadProgress: new Map(),
      addPendingMedia(media) {
        set((state) => {
          state.pendingMediaList.push(media);
          state.pendingMediaUploadProgress.set(`${media.pendingId}`, 0);
        });
      },
      removePendingMedia(mediaId) {
        set((state) => {
          state.pendingMediaList = state.pendingMediaList.filter(
            (m) => m.pendingId !== mediaId
          );
          state.pendingMediaUploadProgress.delete(`${mediaId}`);
        });
      },
      updatePendingMediaProgress(mediaId, progress) {
        set((state) => {
          if (state.pendingMediaUploadProgress.has(mediaId)) {
            state.pendingMediaUploadProgress.set(`${mediaId}`, progress);
          }
        });
      },
      setPendingMediaError(mediaId, error) {
        set((state) => {
          state.pendingMediaList = state.pendingMediaList.filter((m) => {
            if (m.pendingId === mediaId) {
              m.error = error;
            }

            return m;
          });
        });
      },
      setSelectedFolderId(selectedFolderId) {
        set((state) => {
          state.selectedFolderId = selectedFolderId;
        });
      },
      setView(view) {
        set((state) => {
          state.view = view;
        });
      },
    }))
  )
);

export const useMediaStore = createSelectorHooks(useMediaStoreBase);
