import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import IconPickerField from "components/IconPicker";
import { NativeMaterialIconNames } from "constants/index";
import { queryClient } from "queries";
import useListItems from "queries/useListItems";
import { useParams } from "react-router-dom";
import { z } from "zod";

const icons = NativeMaterialIconNames.map((icon, idx) => ({
  id: "string",
  slug: icon,
  title: icon,
  svg: "string",
  native_ref: icon,
  tags: [],
  icon_type: "native",
  category_name: `native`,
  created_by: "string",
  created_at: "string",
  updated_at: "string",
  is_deleted: 0,
}));

const formSchema = z.object({
  label: z.string().min(1, "title is required"),
  icon: z.string(),
  gui_to_link_id: z.string().min(1, "gui is required"),
});

type AddAccountUserFormType = z.infer<typeof formSchema>;

type Props = { templateId: string } & Omit<DialogProps, "onSubmit">;

const AddMenuItemModel: React.FC<Props> = (props) => {
  const { slug: accountUserSlug } = useParams<{ slug: string }>();

  const { onClose, templateId, ...dialogProps } = props;
  const { data: guis } = useListItems({
    modelName: ApiModels.Gui,
    queryOptions: { enabled: false },
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, dirtyFields },
    reset,
  } = useForm<AddAccountUserFormType>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const { mutate: createUserMeun, isLoading } = useCreateItem({
    modelName: ApiModels.UserMenuItem,
  });

  const submitHandler = (data: AddAccountUserFormType) => {
    createUserMeun(
      { template_id: templateId, user_slug: accountUserSlug, ...data } as any,
      {
        onSuccess: () => {
          console.log("inner success");
          queryClient.refetchQueries([templateId, ApiModels.UserMenuItem]);
          queryClient.refetchQueries([ApiModels.AccountUser, accountUserSlug]);

          (onClose as any)?.();
          reset();
        },
      }
    );
  };

  return (
    <Dialog
      onClose={onClose}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add Menu Item</DialogTitle>
      <Scrollbar className="form-scroller">
        <DialogContent>
          <Box component="form">
            <FormField
              label="Title"
              error={dirtyFields.label ? errors.label : undefined}
            >
              <TextField
                {...register("label")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
              />
            </FormField>
            <FormField
              label="Dashboard Item"
              error={
                dirtyFields.gui_to_link_id ? errors.gui_to_link_id : undefined
              }
            >
              <TextField
                {...register("gui_to_link_id")}
                margin="dense"
                type="text"
                fullWidth
                select
                onChange={
                  ((e: any, { props }: any) => {
                    setValue("icon", props?.itemProp);
                  }) as any
                }
              >
                {guis?.map((gui) => {
                  return (
                    <MenuItem
                      value={gui.slug}
                      key={gui.slug}
                      itemProp={gui.icon}
                    >
                      {gui.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormField>
            <FormField label="Icon" error={errors.icon}>
              <Controller
                name="icon"
                control={control}
                render={({ field }) => (
                  <IconPickerField {...field} icons={icons} compact={true} />
                )}
              />
            </FormField>
          </Box>
        </DialogContent>
      </Scrollbar>
      <DialogActions>
        <LoadingButton onClick={onClose as any}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler)}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddMenuItemModel;
