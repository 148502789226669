import { GuiType } from "enums/gui";
import React from "react";
import RecordListFields, {
  RecordListFieldsProps,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/ExtendedFields/RecordListFields";
import ReviewerFields, {
  ReviewerFieldsProps,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/ExtendedFields/ReviewerFields";
import WorkflowBoardFields, {
  WorkflowBoardFieldsProps,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/ExtendedFields/WorkflowBoardFields";

type ExtendedFieldsProps = {
  tab: GuiTab;
} & WorkflowBoardFieldsProps &
  RecordListFieldsProps &
  Omit<ReviewerFieldsProps, "formFieldsIds">;

const ExtendedFields: React.FC<ExtendedFieldsProps> = (props) => {
  const {
    tab,
    onDatalistSettingsClick,
    onEditFieldToInclude,
    datasetDesignSlug,
    onAddActionClick,
    onEditActionClick,
    onDeleteActionClick,
    onSortAction,
    reviewerActions,
    datasetActions,
    viewFilters,
    onEditFilters,
    ...workflowFieldProps
  } = props;

  switch (tab.tab_type) {
    case GuiType.RecordList:
      return (
        <RecordListFields
          datasetActions={datasetActions}
          onAddActionClick={onAddActionClick}
          onEditActionClick={onEditActionClick}
          onDeleteActionClick={onDeleteActionClick}
          onSortAction={onSortAction}
          onDatalistSettingsClick={onDatalistSettingsClick}
        />
      );
    case GuiType.WorkflowBoard:
      return <WorkflowBoardFields {...workflowFieldProps} />;
    case GuiType.Reviewer: {
      return (
        <ReviewerFields
          datasetDesignSlug={datasetDesignSlug}
          formFieldsIds={tab.form_fields ?? []}
          reviewerActions={reviewerActions}
          viewFilters={viewFilters}
          onEditFilters={onEditFilters}
          onEditFieldToInclude={onEditFieldToInclude}
          onAddActionClick={onAddActionClick}
          onEditActionClick={onEditActionClick}
          onDeleteActionClick={onDeleteActionClick}
          onSortAction={onSortAction}
        />
      );
    }
    default:
      return null;
  }
};

export default ExtendedFields;
