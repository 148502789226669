import { DeleteOutline, MoreHoriz } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import ActionMenu from "components/ActionMenu";
import React from "react";

const ColumnHeaderContainer = styled(Stack)<{ color: string }>(({ color }) => ({
  width: "268px",
  height: "50px",
  borderRadius: "8px",
  backgroundColor: "#1f1f27",
  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.04);",
  paddingTop: "14px",
  paddingBottom: "12px",
  paddingLeft: "15px",
  paddingRight: "15px",
  fontSize: "15px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  position: "relative",
  color: "#fff",
  overflow: "hidden",

  "&:before": {
    content: "''",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "3px",
    backgroundColor: color,
  },
}));

const CountTag = styled(Box)({
  height: "24px",
  padding: "0px 7px",
  display: "inline-flex",
  alignItems: "center",
  gap: "10px",
  flexShrink: 0,
  borderRadius: "3px",
  backgroundColor: "#7CA935",
  color: "#fff",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "18px",
});

type ColumnHeaderProps = {
  title: string;
  color: string;
  count: number;
};

const ColumnHeader: React.FC<ColumnHeaderProps> = (props) => {
  const { title, color, count } = props;
  return (
    <ColumnHeaderContainer
      direction="row"
      justifyContent="space-between"
      color={color}
    >
      <Stack direction="row" spacing={2}>
        <Typography fontWeight={600}>{title}</Typography>
        <CountTag>{count}</CountTag>
      </Stack>
      <ActionMenu
        menuItems={[
          {
            label: "Delete",
            value: "delete",
            icon: <DeleteOutline fontSize="small" />,
            iconContainerProps: { sx: { height: "20px" } },
          },
        ]}
      >
        <IconButton sx={{ p: 0 }}>
          <MoreHoriz />
        </IconButton>
      </ActionMenu>
    </ColumnHeaderContainer>
  );
};

export default ColumnHeader;
