import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "@tanstack/react-query";
import StripeHoc from "components/stripe/StripeHoc";
import Account from "models/Account";
import { queryClient } from "queries";
import { ApiModels } from "queries/apiModelMapping";
import React, { useRef, useState } from "react";
type InnerType = {
  creditType: PackageCreditSetting;
  packageId: string;
  onCancel?: React.MouseEventHandler<HTMLButtonElement | undefined>;
};
type Props = {
  onSubmit?: (data: any) => void;
  onClose?: () => void;
} & Omit<DialogProps, "onSubmit"> &
  InnerType;

const useHoldButton = (callback: () => void) => {
  const intervalRef = useRef<any>();
  const handleMouseDown = () => {
    intervalRef.current = setInterval(() => {
      callback();
    }, 100); // Increment every 100ms, adjust as needed
  };

  const handleMouseUp = () => {
    clearInterval(intervalRef.current);
  };
  return [handleMouseDown, handleMouseUp];
};
const DialogContentWrapper = ({
  onCancel,
  creditType,
  packageId,
}: InnerType) => {
  const [loading, setLoading] = useState(false);
  const [requestedCredit, setReqestCredit] = useState(0);
  const stripe = useStripe();
  const { mutate: requestCredit } = useMutation({
    mutationFn: async (data: {
      credit_type_id: string;
      requested_credit: number;
    }) => {
      return await Account?.requestCredit(data);
    },
    onSuccess: () => {
      console.log("success requested credit");
    },
  });
  const { mutate: requestCreditConfim } = useMutation({
    mutationFn: async (data: {
      credit_type_id: string;
      requested_credit: number;
      payment_intent_id: string;
      package_id: string;
    }) => {
      await Account?.requestCreditConfirm(data);
    },
    onSuccess: () => {
      console.log("success requested credit confirm");
    },
  });

  const increamentCredit = () => {
    setReqestCredit((credit) => credit + creditType.additional_increment);
  };
  const decrementCredit = () => {
    setReqestCredit((credit) => {
      if (credit < 1) return credit;
      return credit - creditType.additional_increment;
    });
  };
  const [incrementHandleMouseDown, incrementHandleMouseUp] =
    useHoldButton(increamentCredit);

  const [decrementHandleMouseDown, decrementHandleMouseUp] =
    useHoldButton(decrementCredit);

  const handleSubmit = () => {
    const credit = creditType;
    if (packageId && credit.slug) {
      setLoading(true);
      requestCredit(
        {
          credit_type_id: credit.slug,
          requested_credit: requestedCredit,
        },
        {
          onSuccess: async (data) => {
            const paymentIntent = data.data.payment_intent;
            await stripe
              ?.confirmCardPayment(paymentIntent.client_secret)
              .then(async () => {
                await requestCreditConfim(
                  {
                    credit_type_id: credit.slug,
                    requested_credit: requestedCredit,
                    payment_intent_id: paymentIntent.id,
                    package_id: packageId,
                  },
                  {
                    onSuccess: () => {
                      console.log("sucess");
                      setLoading(false);
                      queryClient.invalidateQueries([
                        ApiModels.TransationHistory,
                      ]);
                      queryClient.invalidateQueries([ApiModels.CreditType]);
                      (onCancel as any)?.();
                    },
                    onError: (e) => {
                      console.log(e);
                      setLoading(false);
                    },
                  }
                );
              });
          },
          onError: (e) => {
            console.log(e);
            setLoading(false);
          },
        }
      );
    }
  };

  if (!creditType?.slug) return null;
  return (
    <React.Fragment>
      <DialogContent>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Additional Credit"
            autoFocus
            value={requestedCredit}
          />

          <Stack gap={2}>
            <Typography>
              <Typography>
                Additional Increment: {creditType.additional_increment}
              </Typography>
              <Typography>
                Cost per Increment: {creditType.additional_cost}
              </Typography>
              <Typography>
                Total Amount:{" "}
                {(requestedCredit / creditType.additional_increment) *
                  creditType.additional_cost}
              </Typography>
            </Typography>
            <Stack direction="row" gap={1}>
              <Button
                variant="outlined"
                startIcon={<RemoveIcon />}
                onMouseDown={decrementHandleMouseDown}
                onMouseUp={decrementHandleMouseUp}
                onMouseLeave={decrementHandleMouseUp}
                onClick={decrementCredit}
              >
                Decrease
              </Button>
              <Button
                variant="contained"
                endIcon={<AddIcon />}
                onMouseDown={incrementHandleMouseDown}
                onMouseUp={incrementHandleMouseUp}
                onMouseLeave={incrementHandleMouseUp}
                onClick={increamentCredit}
              >
                Increase
              </Button>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton onClick={onCancel} loading={loading} disabled={loading}>
          Cancel
        </LoadingButton>
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          type={"submit"}
          loading={loading}
          disabled={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </React.Fragment>
  );
};

const RequestCreditModal: React.FC<Props> = (props) => {
  const { onClose, onSubmit, creditType, packageId, ...dialogProps } = props;

  return (
    <Dialog
      onClose={(e, r) => {
        console.log(e, r);
      }}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add New Card</DialogTitle>
      <StripeHoc>
        <DialogContentWrapper
          onCancel={onClose}
          creditType={creditType}
          packageId={packageId}
        />
      </StripeHoc>
    </Dialog>
  );
};

export default RequestCreditModal;
