import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import PaymentForm, { PaymentFormRef } from "components/stripe/PaymentForm";
import StripeHoc from "components/stripe/StripeHoc";
import useAppNavigate from "hooks/useAppNavigate";
import { Copyright } from "layouts/SplashLayout";

import { ApiModels } from "queries/apiModelMapping";
import useCreateItem from "queries/useCreateItem";
import { RefObject, useRef, useState } from "react";
import { useStore } from "store";

function StripeForm() {
  const appNavigate = useAppNavigate();
  const [loading, setLoading] = useState(false);
  const paymentFormRef = useRef() as RefObject<PaymentFormRef>;
  const { mutate: createCard } = useCreateItem({ modelName: ApiModels.Card });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setLoading(true);
    if (paymentFormRef?.current?.validate()) {
      setLoading(false);
      return;
    }
    try {
      /// type TokenResult but not working
      const result: any = await paymentFormRef?.current?.getToken();

      createCard({ card_token: result.token.id } as Partial<Card>, {
        onSuccess: (res) => {
          console.log(res);
          useStore.setState((s: any) => ({
            ...s,
            selectedAccount: {
              ...s.selectedAccount,
              has_card: true,
            },
          }));
          appNavigate("/app");
          //   paymentFormRef?.current?.clear();
        },
      });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <PaymentForm ref={paymentFormRef} />
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={loading}
      >
        Add Card
      </LoadingButton>
      <Copyright sx={{ mt: 5 }} />
    </Box>
  );
}

export default function Index() {
  return (
    <Box>
      <Box>
        <Typography component="h1" variant="h5" textAlign={"center"}>
          Let's add a card for you!
        </Typography>
        <StripeHoc>
          <StripeForm />
        </StripeHoc>
      </Box>
    </Box>
  );
}
