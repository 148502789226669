import { FC, IframeHTMLAttributes } from "react";

type IFrameProps = {
  url: string;
} & IframeHTMLAttributes<Element>;

const IFrame: FC<IFrameProps> = (props) => {
  const { url, ...rest } = props;
  return (
    <iframe
      style={{ width: "100%", height: "100%", border: "none" }}
      title={url}
      src={url}
      {...rest}
    />
  );
};

export default IFrame;
