import { useQuery } from "@tanstack/react-query";
import UserModel from "models/User";
import { Auth } from "services/Auth";
import { useStore } from "store";
import { setLocalStorage } from "utils";

export default function useAuthenticate() {
  const setAccounts = useStore.useSetAccounts();
  const setUser = useStore.useSetUser();

  return useQuery(
    ["auth"],
    async () => {
      await Auth.authenticate();
      const userRes = (await UserModel.getUser()) as unknown as {
        user: User;
        accounts: AppAccount[];
      };
      //Data struture from api is wrong should be inside data key.
      setLocalStorage("auth", userRes);
      if (userRes.user) {
        setAccounts(userRes.accounts || []);
        setUser(userRes.user);
      } else {
        throw new Error("User and Account was empty");
      }
      return userRes;
    },
    {
      enabled: true,

      // initialData: () => {
      //   const cacheUserData: { user: User; accounts: AppAccount[] } =
      //     getLocalStorage("auth");
      //   return cacheUserData;
      // },
      //select: (data) => data.data.user,
      retry: 1,
      onSuccess: (data) => {},

      onError: (e) => {
        console.log("handle error on useAuthenticate", e);
      },
    }
  );
}
