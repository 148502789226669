import { EditOutlined, LocalOfferOutlined } from "@mui/icons-material";
import { Box, styled, useTheme } from "@mui/material";
import FieldIcon from "components/Form/FieldIcon";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import React, { useMemo } from "react";
import InfoList from "stories/CompoundComponent/AccountInfoCard/AccountInfoCard/AccountInfoCard";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";
import FusionActions, {
  FusionActionsProps,
} from "views/app-view/gui-workspace/components/TabEditorDrawer/ExtendedFields/FusionActions";

const DatasetSidebarContainerWrap = styled(Box)({
  marginBottom: 20,

  ".gf-header-list-item": {
    paddingTop: 0,
  },
});

const InfoListWrap = styled(InfoList)(({ theme }) => {
  return {
    ".MuiList-root": {
      padding: "12px 0 8px",
    },
  };
});

export type ReviewerFieldsProps = {
  datasetDesignSlug: string;
  formFieldsIds: string[];
  reviewerActions: FusionActionsProps["actions"];
  viewFilters: Condition[];
  onEditFilters(): void;
  onEditFieldToInclude(): void;
  onAddActionClick: FusionActionsProps["onAddClick"];
  onEditActionClick: FusionActionsProps["onEditClick"];
  onDeleteActionClick: FusionActionsProps["onDeleteClick"];
  onSortAction: FusionActionsProps["onSort"];
};

const ReviewerFields: React.FC<ReviewerFieldsProps> = (props) => {
  const {
    datasetDesignSlug,
    formFieldsIds,
    reviewerActions,
    viewFilters,
    onEditFilters,
    onEditFieldToInclude,
    onAddActionClick,
    onEditActionClick,
    onDeleteActionClick,
    onSortAction,
  } = props;

  const theme = useTheme();

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: datasetDesignSlug,
  });

  const formFields = useMemo(() => {
    return datasetDesign?.fields?.fields?.filter((f) =>
      formFieldsIds?.includes(f.id)
    );
  }, [formFieldsIds, datasetDesign?.fields?.fields]);

  return (
    <Box>
      <DatasetSidebarContainerWrap>
        <SidebarSectionWrap title="Record Filter" rightIcon={false}>
          <ProfileCard
            options={{
              draggable: false,
              switcher: false,
            }}
            rightIcon={<></>}
            subTitle={`${viewFilters?.length} Inactive`}
            title={`${viewFilters?.length} Active Rules`}
            sx={{
              background: theme.palette.background.GFRightNavForeground,
              height: 48,
              ".MuiTypography-subtitle1": {
                margin: "0 0 2px",
              },

              ".card-inner-content": {
                gap: "2px",
              },
            }}
            onClick={() => onEditFilters?.()}
          />
        </SidebarSectionWrap>
        <Box sx={{ mt: 2 }}>
          <SidebarSectionWrap title="Display Fields" rightIcon={false}>
            <InfoListWrap
              data={formFields?.map((f) => {
                const Icon =
                  FieldIcon[f.type as keyof typeof FieldIcon] ||
                  LocalOfferOutlined;
                return {
                  title: f.title,
                  icon: <Icon />,
                };
              })}
              headerRightIcon={
                <Box className="edit-icon">
                  <EditOutlined
                    onClick={onEditFieldToInclude}
                    sx={{ color: "grey.500" }}
                  />
                </Box>
              }
              title={"Included Fields"}
              sx={{
                "&:hover": {
                  background: theme.palette.background.GFRightNavForeground,
                },
              }}
            />
          </SidebarSectionWrap>
        </Box>
        <Box sx={{ mt: 2 }}>
          <FusionActions
            actions={reviewerActions}
            onAddClick={onAddActionClick}
            onEditClick={onEditActionClick}
            onDeleteClick={onDeleteActionClick}
            onSort={onSortAction}
          />
        </Box>
      </DatasetSidebarContainerWrap>
    </Box>
  );
};

export default ReviewerFields;
