import { Box, TabProps } from "@mui/material";
import useAppNavigate from "hooks/useAppNavigate";
import useHasAccessToModule from "hooks/useHasAccessToModule";
import { cloneDeep } from "lodash";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import { v4 } from "uuid";
import { AddGuiTabFormType } from "./components/AddGuiTabModal";
import GuiTabContent from "./components/GuiTabContent";
import { RenameGuiTabFormType } from "./components/RenameGuiTabModal";
import TabBar from "./components/TabBar";

type GuiWorkspaceProps = {
  publicAccess?: boolean;
};

const GuiWorkspace: React.FC<GuiWorkspaceProps> = (props) => {
  const { publicAccess = false } = props;

  const { slug, tab } = useParams<{ slug: string; tab: string }>();

  const navigate = useAppNavigate();

  const selectedTab = useGuiDashboardStore.useSelectedTab();
  const setSelectedTab = useGuiDashboardStore.useSetSelectedTab();
  // const setEditModeEnabled = useGuiDashboardStore.useSetEditModeEnabled();
  const setGuiDraft = useGuiDashboardStore.useSetGuiDraft();

  const { data: gui } = useGetItem({ modelName: ApiModels.Gui, slug });
  const { mutate: updateGui } = useUpdateItem({ modelName: ApiModels.Gui });

  const guiTab = selectedTab != null ? gui?.tabs?.[selectedTab] : undefined;

  useEffect(() => {
    // setEditModeEnabled(false);
  }, []);

  useEffect(() => {
    if (gui) {
      setGuiDraft(gui);
    }
  }, [gui]);

  const handleAddTab = useCallback(
    (data: AddGuiTabFormType) => {
      if (slug) {
        let guiTabs = cloneDeep(gui?.tabs || []);
        if (guiTabs.some((t) => t.tab_name === data.tab_name)) {
          return Promise.reject();
        }
        const id = v4();
        guiTabs.push({
          id,
          ...data,
          ...(data.tab_type === "dashboard"
            ? {
                tab_rows: [],
              }
            : {}),
        } as GuiTab);
        return new Promise<void>((resolve, reject) => {
          updateGui(
            { slug, data: { tabs: guiTabs } },
            {
              onSuccess() {
                resolve();
              },
              onError() {
                reject();
              },
            }
          );
        });
      } else {
        return Promise.reject();
      }
    },
    [gui, slug]
  );

  const handleDeleteTab = useCallback(
    (tab: TabProps) => {
      if (slug) {
        const guiTabs =
          gui?.tabs?.filter((t) => t.tab_name !== tab.value) || [];

        return new Promise<void>((resolve, reject) => {
          updateGui(
            { slug, data: { tabs: guiTabs } },
            {
              onSuccess() {
                resolve();
              },
              onError() {
                reject();
              },
            }
          );
        });
      } else {
        return Promise.reject();
      }
    },
    [gui, slug]
  );

  const handleRenameTab = useCallback(
    (tab: TabProps, data: RenameGuiTabFormType) => {
      if (slug) {
        const name = data.tab_name;
        const guiTabs =
          gui?.tabs?.map((t) => {
            if (t.tab_name === tab.value) {
              return {
                ...t,
                tab_name: name,
              };
            }

            return t;
          }) || [];

        return new Promise<void>((resolve, reject) => {
          updateGui(
            { slug, data: { tabs: guiTabs } },
            {
              onSuccess() {
                resolve();
              },
              onError() {
                reject();
              },
            }
          );
        });
      } else {
        return Promise.reject();
      }
    },
    [gui, slug]
  );

  const handleEditTab = useCallback(
    async (tabName: string, data: Partial<GuiTab>) => {
      if (slug) {
        const guiTabs =
          gui?.tabs?.map((t) => {
            if (t.tab_name === tabName) {
              return {
                ...t,
                ...data,
                tab_type: t.tab_type,
              };
            }

            return t;
          }) || [];

        return new Promise<void>((resolve, reject) => {
          updateGui(
            { slug, data: { tabs: guiTabs as GuiTab[] } },
            {
              onSuccess() {
                resolve();
              },
              onError() {
                reject();
              },
            }
          );
        });
      } else {
        return Promise.reject();
      }
    },
    [gui, slug]
  );

  useEffect(() => {
    if (tab) {
      setSelectedTab(Number(tab));
    } else {
      setSelectedTab(0);
    }
  }, [tab]);

  const moduleName = publicAccess ? "gui-module-public" : "gui-module";
  const hasAccessToGui = useHasAccessToModule({ moduleName: "gui-module" });

  return (
    <Box className="workspace">
      <TabBar
        tabs={gui?.tabs || []}
        title={gui?.name || ""}
        selected={selectedTab}
        onChange={(tab) => {
          setSelectedTab(tab);
          navigate(`/${moduleName}/${slug}/${tab}`);
        }}
        showEditableSwitch={!publicAccess || hasAccessToGui}
        publicAccess={publicAccess}
        onAddTab={handleAddTab}
        onRenameTab={handleRenameTab}
        onDeleteTab={handleDeleteTab}
        onEditTab={handleEditTab}
        gui={gui}
      />
      <Box>
        {guiTab && <GuiTabContent tab={guiTab} publicAccess={publicAccess} />}
      </Box>
    </Box>
  );
};

export default GuiWorkspace;
