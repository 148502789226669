/* eslint-disable eqeqeq */
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Autocomplete, InputAdornment, TooltipProps } from "@mui/material";
import TextField from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";
import { FormFieldProps } from "components/FormField";
import GfAutoComplete from "components/FormField/GfAutoComplete";
import { debounce, isArray } from "lodash";
import React from "react";
import FormFieldTooltipWrapper from "../FormFieldTooltipWrapper";
type Option = { label: string; value: string };
type Props = {
  name: string;
  options?: Option[];
  tooltipProps?: Partial<TooltipProps>;
  baseElementProps?: React.ComponentProps<typeof TooltipBaseElement>;
  tooltipInlineElementProps?: Partial<
    React.ComponentProps<typeof Autocomplete>
  >;
  fetchNextPage?: () => void;
  onSearchHandler?: (value: string) => void;
  loading?: boolean;
  [key: string]: any;
} & FormFieldProps;

export const TooltipBaseElement = styled(TextField)<{
  isTooltipOpen?: boolean;
}>(({ isTooltipOpen, theme }) => {
  return {
    ...(isTooltipOpen && {
      ".MuiInputBase-root": {
        border: `2px solid ${alpha(theme.palette.text.primary, 0.3)}`,
        background: `${theme.palette.background.GF10} !important`,
      },
    }),
  };
});

export const AutoCompleteWrapper = styled(Autocomplete)(() => {
  return {
    "MuiInputBase-root ": {
      display: "none",
    },

    ".MuiPopover-root ": {
      position: "absolute",
      left: "0",
      top: "100%",
      bottom: "auto",
    },

    ".MuiPaper-root ": {
      position: "absolute",
      left: "0 !important",
      top: "0 !important",
      maxHeight: "200px !important",
    },
  };
});

const getOptionsArrayValue = (value: any, options: Record<string, Option>) => {
  if (!isArray(value)) {
    return value ? [options[value]] : [];
  }

  return value.map((item) => options[item]).filter(Boolean);
};

const ToolTipAutoComplete = ({
  name,
  options = [],
  label,
  tooltipProps,
  baseElementProps,
  tooltipInlineElementProps,
  fetchNextPage,
  onSearchHandler,
  loading,
}: Props) => {
  // Create an options lookup object for constant time access
  const optionsLookup = React.useMemo(() => {
    return options.reduce((acc: { [key: string]: Option }, option) => {
      acc[option.value] = option;
      return acc;
    }, {});
  }, [options]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollHandler = React.useCallback(
    debounce((event: any) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target;

      if (scrollHeight - scrollTop <= clientHeight + 10 && !loading) {
        // Near the bottom, load more

        fetchNextPage?.();
      }
    }, 1000),
    [fetchNextPage, loading]
  ); // debounce of 1 second
  return (
    <FormFieldTooltipWrapper
      name={name}
      label={label}
      tooltipProps={tooltipProps}
      render={({ field, fieldRef, isTooltipOpen, isBaseElement }) => {
        let value = tooltipInlineElementProps?.multiple
          ? getOptionsArrayValue(field.value, optionsLookup)
          : optionsLookup[field.value] || "";

        if (isBaseElement)
          return (
            <TooltipBaseElement
              value={
                isArray(value)
                  ? value.map((item) => item.label).join(",")
                  : value.label || ""
              }
              isTooltipOpen={isTooltipOpen}
              variant="filled"
              size="small"
              disabled
              hiddenLabel
              {...baseElementProps}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ExpandMoreOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          );

        if (isTooltipOpen) {
          return (
            <GfAutoComplete
              {...field}
              value={value}
              size="small"
              // variant="filled"
              // id="test"
              // defaultOpen
              sx={{
                minHeight: "40px",
              }}
              disablePortal
              ref={fieldRef}
              {...tooltipInlineElementProps}
              options={options}
              renderInput={(params) => <TextField {...params} />}
              isOptionEqualToValue={(option: any, value) => {
                const match = option.value?.toString() === value?.toString();
                return match;
              }}
              onChange={(e, option: any) => {
                field.onChange(
                  isArray(option)
                    ? option.map((o) => o.value)
                    : option?.value || ""
                );
              }}
              filterOptions={(options, { inputValue }) => {
                if (onSearchHandler) onSearchHandler(inputValue);
                return options;
              }}
              renderOption={(props, option: any) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              loading={loading}
              ListboxProps={{
                onScroll: scrollHandler,
              }}
            />
          );
        }
        return <React.Fragment />;
      }}
    />
  );
};

export default ToolTipAutoComplete;
