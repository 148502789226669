import { ImportExport } from "@mui/icons-material";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import AssistantDirectionOutlinedIcon from "@mui/icons-material/AssistantDirectionOutlined";
import CommitOutlinedIcon from "@mui/icons-material/CommitOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { Avatar, Box, IconButton, Menu, MenuItem, styled } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import * as React from "react";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
type AccountSelectorProps = {};

const AvatarDropDownWrapper = styled("div")((theme) => ({
  ".MuiButtonBase-root": {
    padding: "0",
  },

  ".MuiAvatar-root ": {
    width: "32px",
    height: "32px",
    borderRadius: "4px",
    overflow: "hidden",
  },
}));

const StyledMenu = styled(Menu)((theme) => ({
  ".MuiMenu-paper": {
    background: "#eee",
    color: "#000",
    width: "258px",
  },

  ".MuiMenu-list": {
    paddingTop: "6px",
    paddingBottom: "0",
  },

  ".menus": {
    padding: "0",
    margin: "0",
    listStyle: "none",
    display: "block",

    ".list-item": {
      padding: "9px 16px",
      fontWeight: "500",
      flexWrap: "wrap",
      width: "100%",

      a: {
        margin: "0",
        padding: "0",
        width: "100%",
        color: "#000",
        background: "none !important",
      },

      path: {
        fill: "#000",
      },
    },
  },

  ".multilevel": {
    position: "static",
    width: "100%",
    padding: "10px 0 0",
    background: "none",
    borderRadius: "0",
    boxShadow: "none",
  },

  // ".MuiMenuItem-root": {
  //   padding: "9px 16px",
  //   fontWeight: "500",
  // },

  ".MuiMenuItem-root": {
    // padding: "9px 16px",
    padding: "0",

    a: {
      display: "block",
      color: "#000",
      textDecoration: "none",
      width: "100%",
      transition: "all 0.4s ease",

      "&:hover": {
        background: `rgba(0,0,0,0.05)`,
      },

      ".MuiBox-root": {
        padding: "9px 16px",
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
      },
    },
  },

  ".counter": {
    opacity: "0.6",
    padding: "0 0 0 8px",
  },

  ".MuiListItemIcon-root": {
    color: "inherit",
    width: "24px",
    height: "24px",

    ".MuiSvgIcon-root ": {
      width: "24px",
      height: "auto",
    },
  },

  ".MuiDivider-root ": {
    borderColor: "rgba(0, 0, 0, 0.10)",
    margin: "8px 0",
  },
}));

const ProfileBox = styled(Box)((theme) => ({
  padding: "13px 16px 6px",

  ".MuiAvatar-root ": {
    width: "50px",
    height: "50px",
    borderRadius: "4px",
    overflow: "hidden",
    margin: "0 0 16px",
  },

  ".profile_name": {
    display: "flex",
    alignItems: "center",
    gap: "9px",
    fontSize: "15px",
    lineHeight: "18px",
    fontWeight: "600",
    letterSpacing: "0.4px",
    margin: "0 0 2px",

    ".verified": {
      width: "14px",
      display: "block",
    },

    svg: {
      display: "block",
      width: "100%",
      height: "auto",
    },
  },

  ".user_name": {
    display: "block",
    fontSize: "14px",
    lineHeight: "18px",
  },
}));

const navMenu = [
  {
    path: "/account-user-module",
    title: "My profile",
    Icon: <AccountBoxOutlinedIcon fontSize="small" />,
  },
  {
    path: "/chat",
    title: "Co-Pilot",
    Icon: <AssistantDirectionOutlinedIcon fontSize="small" />,
  },
  {
    path: "#",
    title: "Jobs",
    Icon: <WorkOutlineOutlinedIcon fontSize="small" />,
    divider: true,
  },
  {
    path: "/dataset-design",
    title: "Datasets",
    Icon: <StorageOutlinedIcon fontSize="small" />,
  },
  {
    path: "/fusion",
    title: "Workflows",
    Icon: <LayersOutlinedIcon fontSize="small" />,
  },
  { title: "Import Templates", path: "/import-module", Icon: <ImportExport /> },
  {
    path: "/gui-module",
    title: "Dashboards",
    Icon: <GridViewOutlinedIcon fontSize="small" />,
  },
  {
    path: "/media",
    title: "Media",
    Icon: <PlayCircleOutlineOutlinedIcon fontSize="small" />,
    divider: true,
  },
  { title: "Icons", path: "/icons", Icon: <AddReactionIcon /> },
  {
    path: "/account-user-module",
    title: "Users",
    Icon: <PersonOutlineOutlinedIcon fontSize="small" />,
  },
  {
    path: "/chat",
    title: "Sylar Credits",
    Icon: <PaidOutlinedIcon fontSize="small" />,
  },
  {
    path: "/billing-module",
    title: "Account",
    Icon: <SettingsOutlinedIcon fontSize="small" />,
  },
  {
    path: "/billing-module",
    title: "Billing",
    Icon: <CreditCardOutlinedIcon fontSize="small" />,
    divider: true,
  },
  {
    path: "/fusion-action-module",
    title: "Apps",
    Icon: <AppsOutlinedIcon fontSize="small" />,
  },
  {
    path: "/vector-knowledgebase-module",
    title: "Vector Embeds",
    Icon: <CommitOutlinedIcon fontSize="small" />,
  },
  {
    path: "/finetune-knowledgebase-module",
    title: "Fine Tune Models",
    Icon: <TuneOutlinedIcon fontSize="small" />,
  },
  {
    path: "/skill-design-module",
    title: "Skills",
    Icon: <ViewInArIcon fontSize="small" />,
  },
  {
    path: "/adventure-module",
    title: "Adventure",
    Icon: <ViewInArIcon fontSize="small" />,
  },
  {
    path: "/double-talk-module",
    title: "Double Talk",
    Icon: <ViewInArIcon fontSize="small" />,
  },
  {
    path: "/saline-chat-module",
    title: "Saline Chat",
    Icon: <ViewInArIcon fontSize="small" />,
  },
  {
    path: "/fusion-connections-module",
    title: "Fusion Connections",
    Icon: <ViewInArIcon fontSize="small" />,
  },
  {
    path: "/fusion-webhooks-module",
    title: "Fusion Webhooks",
    Icon: <ViewInArIcon fontSize="small" />,
  },
];
const AvatarDropDown: React.FC<AccountSelectorProps> = ({}) => {
  const accountSlug = useAccountSlug();
  const currentUser = useCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AvatarDropDownWrapper>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? "user-account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar>{currentUser?.first_name?.[0]}</Avatar>
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        id="user-account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <ProfileBox>
          <Avatar sx={{ borderRadius: "6px" }} />
          <strong className="profile_name">
            {currentUser?.first_name}
            <span className="verified">
              <VerifiedOutlinedIcon />
            </span>
          </strong>
          <span className="user_name">{currentUser?.email}</span>
        </ProfileBox>
        <Divider />
        {navMenu.map((element) => {
          return (
            <Fragment key={element.path}>
              <MenuItem>
                <Link to={`/${accountSlug}${element.path}`}>
                  <Box>
                    <ListItemIcon>{element.Icon}</ListItemIcon>
                    {element.title}
                  </Box>
                </Link>
              </MenuItem>
              {element.divider && <Divider />}
            </Fragment>
          );
        })}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountBoxOutlinedIcon fontSize="small" />
          </ListItemIcon>
          My profile
        </MenuItem> */}
        {/* <SubNavMenu routes={navItems} /> */}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AssistantDirectionOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Co-Pilot
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <WorkOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Jobs
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <StorageOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Datasets
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <LayersOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Workflows
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <GridViewOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Dashboards
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PlayCircleOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Media
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Users
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PaidOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Sylar Credits <span className="counter">(143) </span>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <SettingsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <CreditCardOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Billing
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AppsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Apps
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <CommitOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Vector Embeds
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <TuneOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Fine Tune Models
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ViewInArIcon fontSize="small" />
          </ListItemIcon>
          Skills
        </MenuItem> */}
      </StyledMenu>
    </AvatarDropDownWrapper>
  );
};

export default AvatarDropDown;
