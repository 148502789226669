export enum SystemModuleType {
  Create = "create",
  Update = "update",
  Read = "read",
  ReadOne = "read_one",
  Delete = "delete",
  Webhook = "webhook",
  WebhookResponse = "webhook_response",
  Import = "import",
  AddTag = "add_tag",
  RemoveTag = "remove_tag",
  DocumentDelete = "document_delete",
  DocumentEdit = "document_edit",
  DocumentCreate = "document_create",
  ChargePayment = "charge_payment",
  SendMessage = "send_message",
  AddSubscriber = "add_subscriber",
  RemoveSubscriber = "remove_subscriber",
  CloseThread = "close_thread",
  ChatStart = "chat_start_operator",
  RestClient = "rest_api",
  GraphQLClient = "graphql_api",
  SetVariable = "set_variable",
  GetVariable = "get_variable",
  SetMultipleVariables = "set_multiple_variables",
  GetMultipleVariables = "get_multiple_variables",
  ArrayIterator = "array_iterator",
  ArrayAggregator = "array_aggregator",
  Event = "event",
  MapChartData = "map_chart_data",
  SocialMediaAutomation = "social_media_automation",
  GetNextTask = "get_next_task",
  CompleteTask = "complete_task",
  UpdateInputVariables = "update_input_variables",
  ChartNode = "chart-node",
  Loop = "loop",
  LoopEnd = "loop_end",
  TriggerFusion = "trigger_fusion",
  AskQuestion = "ask_question",
  CollectSlots = "collect_slots",
  UpdateDisplay = "update_display",
  ChargeCredit = "charge_credit",
  Search3PList = "search_3p_list",
  AuthenticationRequest3P = "authentication_request_3p",
  UpdateSkillUser = "update_skill_user",
  UpdateSkillSession = "update_skill_session",
  GetTemporaryS3Link = "get_temporary_s3_link",
  SplitAudio = "split_audio",
  TranscriptionJobTrigger = "transcription_job_trigger",
  CreateTranscriptionJob = "create_transcription_job",
  DeleteTranscriptionJob = "delete_transcription_job",
  GetTranscriptionJob = "get_transcription_job",
  ListTranscriptionJobs = "list_transcription_jobs",
  RunPodExtractFaces = "run_pod_extract_faces",
  ActivateDisplay = "activate_display",
  CreateOperator = "create_operator",
  GetAllOperators = "get_all_operators",
  GetOperatorSlots = "get_operator_slots",
  DeleteOperator = "delete_operator",
  UpdateOperatorSlots = "update_operator_slots",
  GetAllPopupVariables = "get_all_popup_variables",
  CreateTable = "create_table",
  AlterTable = "alter_table",
  AddColumn = "add_column",
  AlterColumn = "alter_column",
  DetectIntent = "detect_intent",
  CreateJob = "create_job",
  ChangeSelectedDisplay = "change_selected_display",
  ExitSkill = "exit_skill",
  ZipS3FilesFromDatasets = "zip_s3_files_from_datasets",
  LoopWhile = "loop_while",
  Sleep = "sleep",
  SendNotification = "send_notification",
}

export enum MappableTagType {
  Function = "function",
  Variable = "variable",
  Separator = "separator",
  ClosingBracket = "closing_bracket",
}

export enum SessionStatus {
  Building = "Building",
  Running = "Running",
  Paused = "Paused",
  Complete = "Complete",
  UserCancelled = "UserCancel",
}

export enum OperatorStatus {
  Pending = "Pending",
  Processing = "Processing",
  Failed = "Failed",
  Complete = "Complete",
}

export enum OperatorLogStatus {
  Warning = "Warning",
  Failed = "Failed",
  Success = "Success",
}

export enum ScheduleType {
  Indefinitely = "indefinitely",
  Once = "once",
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Yearly = "yearly",
}

export enum FusionType {
  Core = "core",
  Skills = "skills",
  Import = "import",
  Adventure = "adventure",
  DoubleTalk = "double_talk",
  SalineChat = "saline_chat",
  DataListWidgetCreateActionFormSubmit = "data-list-widget-create-action-form-submit",
  DataListWidgetEditActionFormSubmit = "data-list-widget-edit-action-form-submit",
  DataListWidgetCreateActionFormPopulate = "data-list-widget-create-action-form-populate",
  DataListWidgetEditActionFormPopulate = "data-list-widget-edit-action-form-populate",
  DataListGuiCreateFormSubmit = "data-list-gui-create-form-submit",
  DataList = "data-list",
  FusionAction = "fusion_action",
}
