import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, TextField } from "@mui/material";
import FormField from "components/FormField";
import FusionLinkButton from "components/FusionLinkButton";
import IconPickerField from "components/IconPicker";
import SidebarSection from "components/RightSidebar";
import { AllIconPickerIcons } from "constants/index";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

type FusionActionEditorProps = {
  action?: FusionAction;
  onCancel(): void;
  onSubmit(action: FusionActionForm): Promise<void>;
};

const formSchema = z.object({
  action_title: z.string(),
  action_icon: z.string().optional(),
});

export type FusionActionForm = z.infer<typeof formSchema>;

const FusionActionEditor: React.FC<FusionActionEditorProps> = (props) => {
  const { action, onCancel } = props;

  const [loading, setLoading] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = useForm<FusionActionForm>({
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    if (action) {
      reset({
        action_title: action.action_title,
        action_icon: action.action_icon,
      });
    }
  }, [action, reset]);

  const onSubmit = async (data: FusionActionForm) => {
    setLoading(true);
    try {
      await props.onSubmit(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ px: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SidebarSection
          title="Add Action"
          leftIcon={<ArrowBack />}
          rightIcon={false}
          onLeftIconClick={onCancel}
        >
          <FormField
            label="Action Title"
            error={dirtyFields.action_title ? errors.action_title : undefined}
          >
            <TextField
              {...register("action_title")}
              autoFocus
              margin="dense"
              id="action_title"
              type="text"
              hiddenLabel
              size="small"
              variant="filled"
              fullWidth
            />
          </FormField>
          <FormField
            label="Action Icon"
            error={dirtyFields.action_icon ? errors.action_icon : undefined}
          >
            <Controller
              name="action_icon"
              control={control}
              render={({ field }) => (
                <IconPickerField
                  {...field}
                  icons={AllIconPickerIcons}
                  compact={true}
                  placement="left"
                />
              )}
            />
          </FormField>
          {action?.fusion_slug && (
            <Box sx={{ mt: 2 }}>
              <FusionLinkButton
                title="Edit Fusion Flow"
                fusionSlug={action.fusion_slug}
              />
            </Box>
          )}
        </SidebarSection>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
          sx={{ mt: 2 }}
        >
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              border: "none",
              color: "#fff",
              background: (theme) => theme.palette.background.GF10,
            }}
            onClick={onCancel}
            fullWidth
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              borderColor: "#fff",
              boxShadow: "none",
            }}
            fullWidth
            loading={loading}
          >
            Save Changes
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default FusionActionEditor;
