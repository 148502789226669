import Model from "models";

class AccountUser extends Model<User> {
  private model = new Model<AppAccount>("/user", "user-mng");
  constructor() {
    super("/account-user", "user-mng");
  }

  async updateMe(user: User) {
    return await this.model.sendRequest("", "PUT", user);
  }
}

export default new AccountUser();
