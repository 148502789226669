import { ArrowBack } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import Scrollbar from "components/Scrollbar";
import AnimationLayout, {
  AnimationLayoutRef,
  Config,
} from "layouts/AnimationLayout";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDatasetStore } from "store/stores/dataset";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import { getSearchParams } from "utils";
import { DatasetEditDrawerForm } from "../DatasetEditDrawer";
import RightSidebarMain from "./RightSidebarMain";

const DatasetSidebarContainerWrap = styled(Box)(({ theme }) => {
  return {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    marginBottom: 20,

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 120px)`,
    overflow: "hidden",

    ".side-menu-active &": {
      height: "calc(100vh - 60px)",
    },
  };
});

const getInitialComponent = () => {
  return getSearchParams().get("s_name") || "main";
};

const GuiRightSidenav: React.FC<{
  gui: GfGui;
  actions?: FusionAction[];
  onNextClick(): void;
  onPreviousClick(): void;
  onActionClick(action: FusionAction): void;
}> = ({ gui, actions, onActionClick, onNextClick, onPreviousClick }) => {
  const layoutRef = useRef<AnimationLayoutRef>(null);
  const editFormlayoutRef = useRef<AnimationLayoutRef>(null);
  const [selectedTabData, setSelectedTabData] = useState<
    { dataset: Dataset; widget: IncludeTabs }[]
  >([]);
  const [initialComponent, setInitialComponent] = useState<string>("");

  const selectedTab = useGuiDashboardStore.useSelectedTab();
  const pushDatasetDraft = useDatasetStore.usePushDatasetDraft();
  const mergeDatasetDraftTail = useDatasetStore.useMergeDatasetDraftTail();
  const deleteDatasetDraftAtIndex =
    useDatasetStore.useDeleteDatasetDraftAtIndex();

  const { datasetSlug } =
    useParams<{
      datasetSlug: string;
    }>();

  const guiTab = useMemo(() => {
    if (selectedTab != null && gui?.tabs) {
      return gui?.tabs?.[selectedTab];
    }
  }, [gui?.tabs, selectedTab]);

  const datasetDesignSlug =
    guiTab?.tab_type === "record_list" ? guiTab.dataset_design_slug : undefined;

  const lastSelectedElement = useMemo(
    () => selectedTabData?.[(selectedTabData?.length || 0) - 1],
    [selectedTabData]
  );

  const sidebarWidgets = useMemo(() => {
    return guiTab?.tab_type === "record_list"
      ? guiTab.included_sidebar_widgets || []
      : [];
  }, [guiTab]);

  const getComponentMiddleComponent: Config["getComponents"] =
    React.useCallback(
      (gotoComponent, goBack) => {
        return {
          main: (
            <RightSidebarMain
              includedTabs={sidebarWidgets}
              onEditDataset={(dataset, widget) => {
                pushDatasetDraft({ data: dataset, fields: [] });
                setSelectedTabData([{ dataset, widget }]);
                setTimeout(() => {
                  gotoComponent({
                    name: "edit-dataset-form",
                    id: "edit-dataset-form",
                  });
                });
              }}
              datasetSlug={datasetSlug!}
              datasetDesignSlug={datasetDesignSlug!}
              actions={actions}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              onActionClick={onActionClick}
              selectedImport={guiTab?.selected_importer}
              showActions
            />
          ),
          "edit-dataset-form": (
            <SidebarSectionWrap
              title={"Go back"}
              onLeftIconClick={() => {
                goBack();
                setSelectedTabData((data) => {
                  return data.slice(0, -1);
                });
              }}
              rightIcon={false}
              leftIcon={<ArrowBack />}
            >
              <div>
                <div id="form-container">
                  <DatasetEditDrawerForm
                    layoutRef={editFormlayoutRef}
                    onFormEvent={(event) => {
                      if (event.name === "table-delete") {
                        deleteDatasetDraftAtIndex(
                          event.data.index,
                          event.field
                        );
                      } else if (event.name === "table-add") {
                      } else if (event.name === "table-edit") {
                        pushDatasetDraft({
                          field: event.field,
                          data: event.data.data as Record<string, unknown>,
                        });
                        gotoComponent({
                          name: "table-fields-form",
                          id: event.data.data._id,
                        });
                      } else if (event.name === "table-add-complete") {
                        pushDatasetDraft({
                          field: event.field,
                          data: event.data.data,
                        });
                        mergeDatasetDraftTail();
                      } else if (event.name === "table-edit-complete") {
                        pushDatasetDraft({
                          field: event.field,
                          data: event.data.data,
                        });
                        mergeDatasetDraftTail();
                      }
                    }}
                    datasetDesignSlug={
                      lastSelectedElement?.dataset?.dataset_type_slug
                    }
                    datasetSlug={lastSelectedElement?.dataset?.slug}
                    includedFieldIds={
                      lastSelectedElement?.widget?.included_fields || []
                    }
                    includedTabs={lastSelectedElement?.widget?.additional_tabs}
                    onWidgetDatasetEdit={(dataset, widget) => {
                      setSelectedTabData((prev) => {
                        return [...prev, { dataset, widget }];
                      });
                      setTimeout(() => {
                        gotoComponent({
                          name: "edit-dataset-form",
                          id: widget.id,
                        });
                      });
                    }}
                    showWidget={true}
                    formMetadata={lastSelectedElement?.widget}
                  />
                </div>
              </div>
            </SidebarSectionWrap>
          ),
        };
      },
      [
        sidebarWidgets,
        datasetSlug,
        datasetDesignSlug,
        actions,
        onNextClick,
        onPreviousClick,
        onActionClick,
        guiTab?.selected_importer,
        lastSelectedElement?.dataset?.dataset_type_slug,
        lastSelectedElement?.dataset?.slug,
        lastSelectedElement?.widget,
        pushDatasetDraft,
        deleteDatasetDraftAtIndex,
        mergeDatasetDraftTail,
      ]
    );

  useEffect(() => {
    setInitialComponent(getInitialComponent());
  }, []);

  return (
    <Box>
      <ScrollbarParent>
        <Scrollbar>
          <DatasetSidebarContainerWrap>
            {initialComponent && (
              <AnimationLayout
                ref={layoutRef}
                config={{
                  getComponents: getComponentMiddleComponent,
                  initialComponent,
                }}
              />
            )}
          </DatasetSidebarContainerWrap>
        </Scrollbar>
      </ScrollbarParent>
    </Box>
  );
};

export default GuiRightSidenav;
