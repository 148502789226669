import VerifiedIcon from "@mui/icons-material/Verified";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useQuery from "hooks/useQuery";
import { Copyright } from "layouts/SplashLayout";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "services/Auth";

const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
const regex = new RegExp(
  "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"
);
export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [error, setError] = useState<string>();
  const { email, code } = useQuery<{ email: string; code: string }>();
  const [reset, setReset] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const validatePassword = () => {
    if (!regex.test(password)) {
      setPasswordValid(false);
    } else {
      setPasswordValid(true);
    }
  };

  const checkPasswordsMatch = () => {
    if (password !== confirmPassword) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isValidEmail(email) || !code) {
      setError("Invalid email or code");
      enqueueSnackbar("Invalid email or code", { variant: "error" });
      return;
    }
    if (!passwordError && passwordValid) {
      // Add your submit logic here, such as sending the password to an API.

      try {
        await Auth.forgotPassword(email, code.toString(), password);
        setReset(true);
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: "error" });
        setError(e.message);
      }
    }
  };

  if (reset)
    return (
      <Box textAlign={"center"}>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main", marginLeft: "45%" }}>
          <VerifiedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password Reset SuccessFully
        </Typography>

        <Box component="form" sx={{ mt: 1 }}>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
            onClick={() => navigate("/login")}
          >
            Login
          </LoadingButton>
          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Box>
    );
  return (
    <Box textAlign={"center"}>
      <Avatar sx={{ m: 1, bgcolor: "secondary.main", marginLeft: "45%" }}>
        <VerifiedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Forget Password
      </Typography>
      <Typography textAlign={"center"}>
        {`Please enter you email and we'll sent you one time code and reset your password.`}
      </Typography>

      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={validatePassword}
          error={!passwordValid}
          helperText={!passwordValid ? "Invalid password format" : ""}
        />

        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          margin="normal"
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onBlur={checkPasswordsMatch}
          error={passwordError}
          helperText={passwordError ? "Passwords must match" : ""}
        />

        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
          onClick={handleSubmit}
        >
          Reset Password
        </LoadingButton>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Box>
  );
}
