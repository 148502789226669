import AddIcon from "@mui/icons-material/Add";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Avatar, Box, ClickAwayListener, Divider, styled } from "@mui/material";
import AppBarComp, { AppBarProps } from "@mui/material/AppBar";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import GfLogo from "assets/icons/GfLogo";
import Scrollbar from "components/Scrollbar";
import GenericIcon from "components/util-components/Icon";
import useAccountSlug from "hooks/useAccountSlug";
import useCurrentUser from "hooks/useCurrentUser";
import useCurrentUserMenu from "hooks/useCurrentUserMenu";
import { useStore } from "store";
// import ArrowBack from "assets/icons/ArrowBack";
import classNames from "classnames";
import useOpenClose from "hooks/useOpenClose";
import { queryClient } from "queries";
import useSignOut from "queries/auth/useSignOut";
import { Fragment, ReactNode, useMemo, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { hasAccessToModule, pathToModule } from "utils/gaurd";
import isAccountOwner from "utils/isAccountOwner";
import AddAccountModal from "./AddAccountModal";

export type SystemAppBarProps = AppBarProps & {
  title?: ReactNode;
  DropDown?: React.ReactNode | React.ReactElement;
  leftIcon?: React.ReactNode | React.ReactElement;
  centeredComponent?: React.ReactNode | React.ReactElement;
  onLeftIconClick?: React.MouseEventHandler<HTMLButtonElement>;
  rightComponent?: React.ReactNode;
  leftMenuClasses?: string;
  navMenu?: boolean;
};

const AppBarWrap = styled(AppBarComp)(({ theme }) => ({
  ".MuiToolbar-root": {
    minHeight: "60px !important",
  },

  ".arrow-back": {
    "&:hover": {
      opacity: "0.6",
    },
  },

  ".full-width": {
    width: "100%",
  },

  ".menu-left": {
    ".right-column": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
      marginLeft: "0",

      ".MuiStack-root": {
        width: "100%",
        justifyContent: "space-between",
      },
    },
  },
}));

const AppSideBar = styled(Stack)(({ theme }) => ({
  position: "fixed",
  left: "0",
  top: "0",
  bottom: "0",
  width: "48px",
  background: theme.palette.primary.main,
  zIndex: "1100",
  overflow: "auto",

  ".app-sidebar_logo": {
    width: "28px",
    margin: "0 auto 6px",

    svg: {
      display: "block",
      height: "auto",
      width: "100%",
    },
  },

  ".app-sidebar-scrollWrap": {
    minHeight: "100%",
    justifyContent: "space-between",
    padding: "11px 7px 7px",
  },
}));

const MenuListing = styled("ul")(({ theme }) => ({
  display: "flex",
  gap: "7px",
  flexDirection: "column",

  ".app-sidebar_menu-item": {
    width: "34px",
    height: "34px",
    borderRadius: "4px",
    background: "rgba(0, 0, 0 ,0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all 0.4s ease",

    "&:hover": {
      background: "rgba(255, 255, 255 ,0.2)",
    },

    "&.active": {
      background: "rgba(0, 0, 0 ,0.6)",
    },

    svg: {
      width: "20px",
      height: "auto",
      display: "block",
    },
  },
}));

const BottomArea = styled(Box)(({ theme }) => ({
  ".MuiDivider-root": {
    background: "rgba(255, 255, 255, 0.33)",
    margin: "10px 0 21px",
  },
}));

const BottomListing = styled("ul")(({ theme }) => ({
  display: "flex",
  gap: "9px",
  flexDirection: "column",
  justifyContent: "center",

  ".avatar-area": {
    width: "32px",
    height: "32px",
    margin: "0 auto",
    cursor: "pointer",

    ".MuiAvatar-circular": {
      width: "100%",
      height: "100%",
    },
  },

  ".app-bottom_menu-item": {
    width: "34px",
    height: "34px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",

    svg: {
      width: "24px",
      height: "auto",
      display: "block",
    },
  },
}));

const CustomDesignTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#fff",
    padding: "10px 20px",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "600",
    color: "#000",
    cursor: "pointer",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.25)",

    ".MuiTooltip-arrow": {
      color: "#fff",
    },
  },
});

const ProfileDropDown = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: "26px",
  bottom: "20px",
  zIndex: "5",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "6px",
  background: theme.palette.gfGrey[200],
  width: "258px",
  color: "rgba(0, 0, 0, 0.8)",
  overflow: "hidden",
  border: `1px solid ${theme.palette.primary.main}`,
  // opacity: "0",
  // visibility: "hidden",

  ".profile-dropmenu-head": {
    padding: "10px 14px",
    gap: "8px",
    fontSize: "13px",
    lineHeight: "30px",
    background: theme.palette.primary.main,
    fontWeight: "600",
    color: "#fff",
    borderBotom: "1px solid rgba(0, 0, 0, 0.1)",

    ".MuiAvatar-root": {
      width: "24px",
      height: "24px",
      fontSize: "12px",
      lineHeight: "24px",
    },
  },

  ".profile-dropmenu-listing": {
    width: "48px",
    borderRight: "1px solid rgba(215, 215, 215, 1)",
  },

  ".profile-dropmenu-listing_wrap": {
    padding: "7px 10px",

    ".MuiAvatar-root": {
      width: "30px",
      height: "30px",
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: "600",
      color: "#fff",
      cursor: "pointer",
      position: "relative",
      overflow: "visible",

      "&.active": {
        "&:before": {
          opacity: "1",
          visibility: "visible",
        },
      },

      "&:before": {
        border: "1px solid rgba(112, 111, 114, 1)",
        inset: "-4px",
        content: `""`,
        position: "absolute",
        borderRadius: "100%",
        opacity: "0",
        visibility: "hidden",
      },
    },

    ".add-profile": {
      width: "30px",
      height: "30px",
      border: "1px solid rgba(0, 0, 0, 0.31)",
      color: "rgba(28, 27, 31, 0.6)",
      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",

      svg: {
        width: "16px",
        height: "auto",
      },
    },
  },

  ".profile-guis-detail": {
    flexGrow: "1",
    flexBasis: "0",
    minWidth: "0",
  },

  ".profile-guis-detail-wrap": {
    padding: "7px 10px",
  },

  ".profile-guis-head": {
    alignItems: "center",
    fontSize: "12px",
    lineHeight: "15px",
    color: theme.palette.common.blackshades["100p"],
    margin: "0 0 16px",

    ".MuiAvatar-root": {
      width: "30px",
      height: "30px",
    },
  },
}));

const ProfileLinksListing = styled("ul")(({ theme }) => ({
  padding: "0 0 0 10px",
  margin: "0",
  listStyle: "none",

  li: {
    cursor: "pointer",
    margin: "0 0 1px",
  },

  a: {
    display: "block",
    fontSize: "13px",
    lineHeight: "30px",
    color: "rgba(0, 0, 0, 0.8)",
    textDecoration: "none",

    "&:hover": {
      color: "#000",
    },
  },

  ".profile-listing-counter": {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "11px",
  },
}));

const navMenuList = [
  {
    path: "/account-user-module",
    title: "My profile",
  },
  {
    path: "/chat",
    title: "Co-Pilot",
  },
  {
    path: "#",
    title: "Jobs",
  },
  {
    path: "/dataset-design",
    title: "Datasets",
  },
  {
    path: "/fusion",
    title: "Workflows",
  },
  { title: "Import Templates", path: "/import-module" },
  {
    path: "/gui-module",
    title: "Dashboards",
  },
  {
    path: "/media",
    title: "Media",
  },
  { title: "Icons", path: "/icons" },
  {
    path: "/account-user-module",
    title: "Users",
  },
  {
    path: "/chat",
    title: "Sylar Credits",
  },
  {
    path: "/billing-module",
    title: "Account",
  },
  {
    path: "/billing-module",
    title: "Billing",
  },
  {
    path: "/fusion-action-module",
    title: "Apps",
  },
  {
    path: "/vector-knowledgebase-module",
    title: "Vector Embeds",
  },
  {
    path: "/finetune-knowledgebase-module",
    title: "Fine Tune Models",
  },
  {
    path: "/skill-design-module",
    title: "Skills",
  },
  {
    path: "/adventure-module",
    title: "Adventure",
  },
  {
    path: "/double-talk-module",
    title: "Double Talk",
  },
  {
    path: "/saline-chat-module",
    title: "Saline Chat",
  },
  {
    path: "/fusion-connections-module",
    title: "Fusion Connections",
  },
  {
    path: "/fusion-webhooks-module",
    title: "Fusion Webhooks",
  },
];

const SidebarNav = (props: SystemAppBarProps) => {
  // const {} = props;
  const userMenu = useCurrentUserMenu();
  const account = useStore.useSelectedAccount?.();
  const isOwner = isAccountOwner(account?.subscription?.account_user_type);
  const [open, onOpen, onClose] = useOpenClose();
  const userMenuList = useMemo(() => {
    return (
      userMenu
        ?.sort((a, b) => a.sort_order - b.sort_order)
        ?.map((item) => ({
          title: item.label,
          path: `gui-module-public/${item.gui_to_link_id}`,
          icon: item.icon,
        })) || []
    );
  }, [userMenu]);

  const userAllowedModules = useMemo(() => {
    return navMenuList?.filter((item) => {
      if (!item.path) return false;
      const rmodule = pathToModule(`/accountId${item.path}`);
      const hasAccess = hasAccessToModule(
        rmodule!,
        account?.subscription?.permissions!,
        false
      );

      return isOwner || hasAccess;
    });
  }, [account?.subscription?.permissions, isOwner]);

  const navigate = useNavigate();
  const { mutate: handleSignOut, isLoading } = useSignOut();

  const selectAccount = useStore.useSelectedAccount?.();
  const accounts = useStore.useAccounts?.();
  const setSelectedAccount = useStore.useSetSelectedAccount();
  const location = useLocation();
  const [dropDown, setDropDown] = useState(false);
  const accountSlug = useAccountSlug();
  const currentUser = useCurrentUser();

  const handleOnSelect = (acct: AppAccount) => {
    if (acct.slug === accountSlug) return;
    const pathAfterAccount = location.pathname.split("/").slice(2, 3).join("/");
    setSelectedAccount(acct.slug);
    queryClient.clear();
    setTimeout(() => navigate(`/${acct.slug}/${pathAfterAccount}`), 0);
  };
  const handleLogout: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    handleSignOut();
  };
  return (
    <AppSideBar className="app-sidebar">
      <Scrollbars>
        <Stack direction="column" className="app-sidebar-scrollWrap">
          <Stack gap="7px">
            <Box className="app-sidebar_logo">
              <GfLogo sx={{ fontSize: "28px" }} />
            </Box>
            <MenuListing>
              {userMenuList.map((route) => {
                return (
                  <li key={route.path}>
                    <Link
                      component={RouterLink}
                      to={`/${accountSlug}/${route.path}`}
                      sx={{ color: "currentcolor" }}
                    >
                      <CustomDesignTooltip
                        title={route.title}
                        arrow={true}
                        placement="right-end"
                      >
                        <Stack className="app-sidebar_menu-item">
                          <GenericIcon
                            iconName={route.icon || "HomeOutlined"}
                          />
                        </Stack>
                      </CustomDesignTooltip>
                    </Link>
                  </li>
                );
              })}
            </MenuListing>
          </Stack>
          <BottomArea>
            <Divider />
            <BottomListing>
              <li>
                <Stack className="app-bottom_menu-item">
                  <NotificationsNoneOutlinedIcon />
                </Stack>
              </li>
              <li>
                <Stack className="app-bottom_menu-item">
                  <ModeCommentOutlinedIcon />
                </Stack>
              </li>
              <li>
                <Box className="avatar-area" onClick={() => setDropDown(true)}>
                  <Avatar />
                </Box>
                {dropDown && (
                  <ClickAwayListener onClickAway={() => setDropDown(false)}>
                    <ProfileDropDown className="profile-dropmenu">
                      <Stack
                        alignItems={"center"}
                        direction="row"
                        className="profile-dropmenu-head"
                      >
                        {/* <GfLogo sx={{ fontSize: "24px" }} /> */}
                        <Avatar
                          sx={{
                            backgroundColor: "rgba(107, 32, 19, 1)",
                            color: "white",
                          }}
                        >
                          {selectAccount?.name?.[0]?.toLocaleUpperCase()}
                        </Avatar>
                        {selectAccount?.name}
                      </Stack>
                      <Stack className="profile-dropmenu-row" direction="row">
                        <Box className="profile-dropmenu-listing">
                          <Stack
                            gap={"8px"}
                            className="profile-dropmenu-listing_wrap"
                          >
                            {accounts?.map((act) => {
                              return (
                                <Avatar
                                  className={classNames({
                                    active: act.slug === accountSlug,
                                  })}
                                  sx={{
                                    backgroundColor: "rgba(107, 32, 19, 1)",
                                  }}
                                  onClick={() => handleOnSelect(act)}
                                >
                                  {act.name?.[0]?.toLocaleUpperCase()}
                                </Avatar>
                              );
                            })}

                            <Box className="add-profile" onClick={onOpen}>
                              <AddIcon />
                            </Box>
                          </Stack>
                        </Box>
                        <Box className="profile-guis-detail">
                          <Scrollbar autoHeight autoHeightMax="498px">
                            <Box className="profile-guis-detail-wrap">
                              <Stack
                                direction="row"
                                className="profile-guis-head"
                                gap="8px"
                              >
                                <Avatar
                                  src={
                                    currentUser.image?.url ||
                                    "/static/images/avatar/1.jpg"
                                  }
                                />
                                <Box className="profile-user-name">
                                  {currentUser?.first_name}
                                </Box>
                              </Stack>
                              <ProfileLinksListing>
                                {userAllowedModules.map((element) => {
                                  return (
                                    <Fragment key={element.path}>
                                      <li>
                                        <Link
                                          to={`/${accountSlug}${element.path}`}
                                          component={RouterLink}
                                        >
                                          {element.title}
                                        </Link>
                                      </li>
                                    </Fragment>
                                  );
                                })}
                                <Fragment key="logout">
                                  <Link onClick={handleLogout}>
                                    <li>Logout</li>
                                  </Link>
                                </Fragment>
                              </ProfileLinksListing>
                            </Box>
                          </Scrollbar>
                        </Box>
                      </Stack>
                    </ProfileDropDown>
                  </ClickAwayListener>
                )}
              </li>
            </BottomListing>
          </BottomArea>
        </Stack>
      </Scrollbars>
      <AddAccountModal open={open} onClose={onClose} />
    </AppSideBar>
  );
};

export default SidebarNav;
