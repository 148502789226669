import Model from "models";

class DatasetDesignModel extends Model<DatasetDesign> {
  constructor() {
    super("/dataset-design", "data-mng");
  }
  async import(data: { data: DatasetDesign; folder: string }) {
    return await this.sendRequest(`/import`, "POST", data);
  }
  async exportFolderDatasetDesign(folderSlug: string) {
    return await this.sendRequest<DatasetDesign[]>(
      `/export/folder/${folderSlug}`,
      "GET"
    );
  }
  async importBatch(data: { data: DatasetDesign[]; folder: string }) {
    return await this.sendRequest<DatasetDesign[]>(`/import/folder`, "POST", {
      folder: data.folder,
      data: data.data,
    });
  }
}

const model = new DatasetDesignModel();
export default model;
