import { useQuery } from "@tanstack/react-query";
import FusionModel from "models/Fusion";
import { ApiModels } from "queries/apiModelMapping";

type UseFusionsParams = {
  type: FusionPageType;
  folderId?: string;
};

const getAllFusions = async (
  type: FusionPageType,
  folderId: string = "root"
) => {
  const fusions: Fusion[] = [];
  let cursor: Record<string, string> | undefined;

  do {
    const response = await FusionModel.list({
      query: { type, cursor, folder: folderId },
    });
    fusions.push(...response.data);
    cursor = response.cursor as Record<string, string> | undefined;
  } while (cursor);

  return fusions;
};

const useFusions = (params: UseFusionsParams) => {
  const { data } = useQuery<Fusion[]>(
    [ApiModels.Fusion, params.type, params.folderId || "root"],
    () => getAllFusions(params.type, params.folderId)
  );

  return data;
};

export default useFusions;
