import Model from "models";
import qs from "query-string";

class FusionModel extends Model<Fusion> {
  constructor() {
    super("/fusion", "gui-fusion");
  }

  async updateFlow(
    slug: string,
    data: { operator_operations: OperatorOperation[]; flow: Fusion["flow"] }
  ) {
    return await this.sendRequest<void>(`/flow/${slug}`, "POST", data);
  }

  async createConnection(connection: Partial<FusionConnection>) {
    return await this.sendRequest<FusionConnection>(
      "/connection",
      "POST",
      connection
    );
  }

  async getConnections(
    userSlug: string,
    appSlug: string,
    query?: Record<string, any>
  ) {
    return await this.sendRequest<FusionConnection[]>(
      `/connection/${appSlug}${query ? `?${qs.stringify(query)}` : ""}`,
      "GET"
    );
  }

  async updateConnection(slug: string, data: Partial<FusionConnection>) {
    return await this.sendRequest<FusionConnection>(
      `/connection/${slug}`,
      "PUT",
      data
    );
  }

  async getHistory(slug: string) {
    return await this.sendRequest<Fusion[]>(`/${slug}/history`, "GET");
  }

  async getHistoryDetails(slug: string) {
    return await this.sendRequest<Fusion>(`/history/${slug}`, "GET");
  }

  async getSessions(slug: string, query?: Record<string, any>) {
    return await this.sendRequest<FusionSession[]>(
      `/${slug}/session${query ? `?${qs.stringify(query)}` : ""}`,
      "GET"
    );
  }

  async runTest(
    fusion: Partial<Fusion>,
    userSlug: string,
    query?: Record<string, any>
  ) {
    return await this.sendRequest<{ sessionSlug: string }>(
      `/test${query ? `?${qs.stringify(query)}` : ""}`,
      "POST",
      {
        fusion,
        userSlug,
      }
    );
  }

  async createWebhook(webhook: Partial<FusionWebhook>) {
    return await this.sendRequest<FusionWebhook>("/webhook", "POST", webhook);
  }

  async getWebhooks(
    moduleSlug: string,
    userSlug: string,
    query: Record<string, string> = {}
  ) {
    return await this.sendRequest<FusionWebhook[]>(
      `/webhook/${moduleSlug}${query ? `?${qs.stringify(query)}` : ""}`,
      "GET"
    );
  }

  async runWidgetFusion(
    accountId: string,
    userId: string,
    fusionSlug: string,
    chartInputs: Record<string, any>
  ) {
    return await this.sendRequest(`/widget/${fusionSlug}`, "POST", {
      accountId,
      userId,
      chart_inputs: chartInputs,
    });
  }

  async runFusion(fusionSlug: string, data: Record<string, unknown>) {
    return await this.sendRequest(`/run/${fusionSlug}`, "POST", data);
  }

  async importFusion(data: FusionExportData, folderId?: string) {
    return await this.sendRequest(
      `/import?${folderId ? `folderId=${folderId}` : ""}`,
      "POST",
      {
        data: data,
      }
    );
  }
  async importFolderFusions(data: Fusion, folderId?: string) {
    return await this.sendRequest(`/import/folder`, "POST", {
      data: data,
      folder: folderId,
    });
  }

  async export<ReturnType = FusionExportData>(slug: string) {
    return await this.sendRequest<ReturnType>(`/export/${slug}`, "GET");
  }

  async exportFusions(folderId?: string) {
    return await this.sendRequest(`/export/folder/${folderId}`, "GET");
  }

  async listFusionByWebhook(webhookSlug: string) {
    return await this.sendRequest<Fusion[]>(
      `/list/webhook/${webhookSlug}`,
      "GET"
    );
  }

  async listFusionByConnection(connectionSlug: string) {
    return await this.sendRequest<Fusion[]>(
      `/list/connection/${connectionSlug}`,
      "GET"
    );
  }

  async purgeSessions(fusionSlug: string) {
    const url = fusionSlug
      ? `/session/purge?fusionSlug=${fusionSlug}`
      : "/session/purge";

    return await this.sendRequest(url, "DELETE");
  }

  async migrateToV2(fusionSlug: string) {
    return await this.sendRequest(`/migrate-to-v2/${fusionSlug}`, "GET");
  }

  async getSession(sessionSlug: string) {
    return await this.sendRequest<FusionFlowSession>(
      `/session/${sessionSlug}`,
      "GET"
    );
  }
}
const model = new FusionModel();

export default model;
