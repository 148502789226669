import { useStore } from "store";
import { hasAccessToModule } from "utils/gaurd";
import isAccountOwner from "utils/isAccountOwner";
import useRouteModule from "./useRouteModule";

const useHasAccessToModule = ({
  moduleName,
}: {
  moduleName?: ReturnType<typeof useRouteModule>;
}) => {
  const account = useStore.useSelectedAccount?.();
  const routeModule = useRouteModule();
  const isOwner = isAccountOwner(account?.subscription?.account_user_type);
  const hasAccess = hasAccessToModule(
    moduleName || routeModule!,
    account?.subscription?.permissions!
  );

  return isOwner || hasAccess;
};

export default useHasAccessToModule;
