import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import FieldIcon from "components/Form/FieldIcon";
import { Handle } from "components/drag-drop/components";
import { DocumentElementType } from "enums";
import React from "react";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";

const BoxIcon = styled(Box)(({ theme }) => {
  return {
    width: "20px",
    color: theme.palette.background.GF70,
    marginLeft: "10px",
    svg: {
      maxWidth: "100%",
      height: "20px",
      width: "auto",
      display: "block",
      margin: "auto",

      path: {
        fill: "currentColor",
      },
    },
  };
});
const DragFieldItem = ({
  item,
  rightIcon,
  handleProps,
  listeners,
}: {
  item: DataField;
  rightIcon?:
    | React.ReactNode
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  [key: string]: any;
}) => {
  const theme = useTheme();
  const Icon =
    FieldIcon[
      (item.type as keyof typeof FieldIcon) || DocumentElementType.TextField
    ];
  return (
    <ProfileCard
      options={{
        draggable: true,
        switcher: false,
      }}
      subTitle={item.slug}
      title={item.title}
      rightIcon={rightIcon}
      AvatarImage={
        <BoxIcon>
          <Icon />
        </BoxIcon>
      }
      DragHandle={
        <Handle
          // className="DragHandle"
          {...handleProps}
          {...listeners}
        >
          <DragIndicatorIcon
            sx={{
              verticalAlign: "middle",
              color: theme.palette.background.GF20,
              cursor: "grab",
            }}
          />
        </Handle>
      }
      sx={{
        background: theme.palette.background.GFRightNavForeground,
        height: 48,
        ".MuiTypography-subtitle1": {
          margin: "0 0 2px",
        },
        ".card-inner-content": {
          gap: "2px",
        },
      }}
    />
  );
};

export default DragFieldItem;
