import { UseDroppableArguments, useDroppable } from "@dnd-kit/core";
import React, { PropsWithChildren } from "react";
import { cloneElement } from "utils";

type DroppableProps = {
  id: string;
  dataId?: string;
  droppableOptions?: UseDroppableArguments;
};

export type DroppableContext = {
  isOver: boolean;
};

const Droppable: React.FC<PropsWithChildren<DroppableProps>> = (props) => {
  const { id, children, dataId, droppableOptions = {}, ...rest } = props;

  const { setNodeRef, isOver } = useDroppable({
    id: id,
    data: {
      id: dataId ?? id,
    },
    ...droppableOptions,
  });

  return cloneElement(children, {
    ...rest,
    ref: setNodeRef,
    droppableContext: { isOver },
  });
};

export default Droppable;
