import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, TextField } from "@mui/material";
import FormField from "components/FormField";
import SidebarSection from "components/RightSidebar";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  label: z.string(),
});

export type AddFilterRuleFormType = z.infer<typeof formSchema>;

type FilterRuleEditorProps = {
  rule?: FilterRule;
  onBackClick(): void;
  onSubmit(data: AddFilterRuleFormType): void;
};

const FilterRuleEditor: React.FC<FilterRuleEditorProps> = (props) => {
  const { rule, onSubmit, onBackClick } = props;

  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
  } = useForm<AddFilterRuleFormType>({
    defaultValues: {},
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    if (rule) {
      reset({
        label: rule.label,
      });
    }

    return () => {
      reset();
    };
  }, []);

  return (
    <Box sx={{ px: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SidebarSection
          title="Add Filter Rule"
          leftIcon={<ArrowBack />}
          rightIcon={false}
          onLeftIconClick={onBackClick}
        >
          <FormField
            label="Label"
            error={dirtyFields.label ? errors.label : undefined}
          >
            <TextField
              {...register("label")}
              autoFocus
              margin="dense"
              id="name"
              type="text"
              hiddenLabel
              size="small"
              variant="filled"
              fullWidth
            />
          </FormField>
        </SidebarSection>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
          sx={{ mt: 2 }}
        >
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              border: "none",
              color: "#fff",
              background: (theme) => theme.palette.background.GF10,
            }}
            onClick={onBackClick}
            fullWidth
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              borderColor: "#fff",
              boxShadow: "none",
            }}
            fullWidth
          >
            Save Changes
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default FilterRuleEditor;
