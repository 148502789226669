import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import FormField from "components/FormField";
import Scrollbar from "components/Scrollbar";
import AccountModel from "models/Account";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const formSchema = z.object({
  company_name: z.string().min(1, { message: "Company Name is required" }),
  account_package_id: z
    .string()
    .min(1, { message: "Account Package Name is required" }),
  pass_phrase: z.string().optional(),
});

export type AddGuiTabFormType = z.infer<typeof formSchema>;

type Props = {
  onClose: () => void;
} & Omit<DialogProps, "onSubmit">;

const AddAccountModal: React.FC<Props> = (props) => {
  const { onClose, ...dialogProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { mutate: createAccount } = useMutation({
    mutationFn: async (data: AddGuiTabFormType) => {
      const res = await AccountModel.create(data);
      return res.data;
    },
    onSuccess: (_) => {
      console.log("success");
    },
  });

  const form = useForm<AddGuiTabFormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    watch,
  } = form;

  const submitHandler = (data: AddGuiTabFormType) => {
    setIsLoading(true);
    createAccount(data, {
      onSuccess: (res) => {
        navigate(`/${res.account_slug}/account-setup/add-card`);
        reset();
        onClose();
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  const handleClose = () => {
    onClose?.();
    reset();
  };

  return (
    <Dialog
      onClose={handleClose}
      disableEscapeKeyDown
      scroll="body"
      {...dialogProps}
    >
      <DialogTitle>Add New Account</DialogTitle>

      <Scrollbar className="form-scroller">
        <DialogContent>
          <Stack gap="10px" component="form">
            <FormField
              label="Company Name"
              error={dirtyFields.company_name ? errors.company_name : undefined}
            >
              <TextField
                {...register("company_name")}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                hiddenLabel
                size="small"
                variant="filled"
              />
            </FormField>
            <FormField
              label="Account Package Id"
              error={
                dirtyFields.account_package_id
                  ? errors.account_package_id
                  : undefined
              }
            >
              <TextField
                {...register("account_package_id")}
                margin="dense"
                id="name"
                type="text"
                fullWidth
                hiddenLabel
                size="small"
                variant="filled"
              />
            </FormField>
            <FormField
              label="Pass Phrase"
              error={dirtyFields.pass_phrase ? errors.pass_phrase : undefined}
            >
              <TextField
                {...register("pass_phrase")}
                margin="dense"
                id="name"
                type="text"
                fullWidth
                hiddenLabel
                size="small"
                variant="filled"
              />
            </FormField>
          </Stack>
        </DialogContent>
      </Scrollbar>

      <DialogActions>
        <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
        <LoadingButton
          onClick={handleSubmit(submitHandler)}
          variant="contained"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAccountModal;
