import type { DraggableSyntheticListeners } from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
import { CSS } from "@dnd-kit/utilities";
import { Box, styled } from "@mui/material";
import React from "react";
import ScrumCard from "views/app-view/gui-workflow-board/components/ScrumCard";

const ItemContainer = styled(Box)({
  display: "flex",
  color: "#fff",
}) as typeof Box;

export interface Props {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  card?: Dataset;
  onRemove?(): void;
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props["transform"];
    transition: Props["transition"];
    value: Props["value"];
  }): React.ReactElement;
  status?: WorkflowStatus;
  onEdit(_: Dataset): void;
}

const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        card,
        status,
        onEdit,
        ...props
      },
      ref
    ) => {
      return (
        <ItemContainer
          component="li"
          style={{
            transition: [transition].filter(Boolean).join(", "),
            transform: CSS.Translate.toString(transform || null),
            outline: "none",
          }}
          ref={ref}
        >
          <Box
            data-cypress="draggable-item"
            {...listeners}
            {...props}
            sx={{ width: "100%" }}
          >
            <ScrumCard
              label={status?.label || ""}
              title={card?.title || ""}
              onEdit={onEdit}
              card={card}
            />
          </Box>
        </ItemContainer>
      );
    }
  )
);

export default Item;
