import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import Scrollbar from "components/Scrollbar";
import AnimationLayout, {
  AnimationLayoutRef,
  Config,
} from "layouts/AnimationLayout";
import React, { useEffect, useRef, useState } from "react";
import { getSearchParams } from "utils";
import AccountUserSetting from "./AccountUserSetting";
import MenuEditor from "./MenuEditor";
import MenuItemEditor from "./MenuItemEdit";

const getInitialComponent = () => {
  return getSearchParams().get("s_name") || "main";
};
const AccountUserSidebarContainer = styled(Box)(({ theme }) => {
  return {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    height: "100%",
    // width: "420px",

    [`${theme.breakpoints.down("sm")}`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});
const ScrollbarParent = styled(Box)(({ theme }) => {
  return {
    height: `calc(100vh - 60px)`,
    overflow: "hidden",

    ".side-menu-active &": {
      height: "100vh",
    },
  };
});
const SidebarSetting = ({
  disableScrollbar,
}: {
  disableScrollbar?: boolean;
}) => {
  const layoutRef = useRef<AnimationLayoutRef>(null);
  const [initialComponent, setInitialComponent] = useState<string>("");
  const [selectedMenu, setSelectedMenu] = useState<UserMenuItem>();
  const getComponent: Config["getComponents"] = React.useCallback(
    (gotoComponent, goBack) => {
      return {
        main: (
          <AccountUserSetting
            onMenuEdit={() => {
              gotoComponent({ name: "menu-editor", id: "menu-editor" });
            }}
          />
        ),
        "menu-editor": (
          <MenuEditor
            goBack={goBack}
            onEditMenuItem={(item) => {
              setSelectedMenu(item);
              setTimeout(() => {
                gotoComponent({
                  name: "menu-editor-item",
                  id: "menu-editor-item",
                });
              }, 100);
            }}
          />
        ),
        "menu-editor-item": (
          <MenuItemEditor goBack={goBack} menuItem={selectedMenu} />
        ),
      };
    },
    [selectedMenu]
  );
  useEffect(() => {
    setInitialComponent(getInitialComponent());
  }, []);
  return (
    <AccountUserSidebarContainer>
      <ScrollbarParent>
        <Scrollbar>
          {initialComponent && (
            <AnimationLayout
              ref={layoutRef}
              config={{
                getComponents: getComponent,
                initialComponent,
              }}
            />
          )}
        </Scrollbar>
      </ScrollbarParent>
    </AccountUserSidebarContainer>
  );
};

export default SidebarSetting;
