import { ApiModels } from "queries/apiModelMapping";
import useListInfiniteItems from "queries/useListInfiniteItems";
import useListItems from "queries/useListItems";
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";

const defaultKeyBinding = { label: "title", value: "id" };
const DatasetDataProvider: React.FC<
  PropsWithChildren<{
    datasetDesingSlug: string;
    keyBinding?: { label?: string; value?: string };
    filter?: any;
    fetchSelectedValueInList?: boolean;
    name?: string;
  }>
> = ({
  name,
  fetchSelectedValueInList,
  children,
  datasetDesingSlug,
  filter,
  keyBinding = defaultKeyBinding,
}) => {
  const { watch } = useFormContext();
  const value = watch(name!);
  const {
    data: datasetResult,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetched,
  } = useListInfiniteItems({
    modelName: ApiModels.Dataset,
    requestOptions: {
      query: {
        dataset_type_slug: datasetDesingSlug,
        filter,
      },
      path: `list/${datasetDesingSlug}`,
    },
    queryKey: [
      ApiModels.Dataset,
      {
        dataset_type_slug: datasetDesingSlug,
        filter,
      },
    ],
  });
  const [search, setSearch] = useState("");
  const allowFetch = useRef(true);
  const { data: selectedData } = useListItems({
    modelName: ApiModels.Dataset,
    requestOptions: {
      query: {
        dataset_type_slug: datasetDesingSlug,
        title: search,
        ids: value,
      },
      path: `list/${datasetDesingSlug}`,
    },
    queryKey: [
      ApiModels.Dataset,
      {
        value,
        dataset_type_slug: datasetDesingSlug,
        title: search,
      },
    ],
    queryOptions: {
      enabled: Boolean(value && fetchSelectedValueInList && allowFetch.current),
    },
  });

  useEffect(() => {
    setTimeout(() => {
      allowFetch.current = false;
    }, 2000);
  });

  const options = useMemo(() => {
    const paginatedData: { label: string; value: string }[] = [];
    selectedData?.forEach((data: any) => {
      const value = data[keyBinding.value || defaultKeyBinding.value];
      const label = data[keyBinding.label || defaultKeyBinding.label] || value;
      paginatedData.push({
        label,
        value,
      });
    });
    datasetResult?.pages.forEach((group) => {
      group?.data?.forEach((data: any) => {
        const value = data[keyBinding.value || defaultKeyBinding.value];
        const label =
          data[keyBinding.label || defaultKeyBinding.label] || value;
        paginatedData.push({
          label,
          value,
        });
      });
    });
    return paginatedData;
  }, [datasetResult?.pages, keyBinding.label, keyBinding.value, selectedData]);

  const optionsWithLoading = useMemo(() => {
    if (isFetching) {
      return [...options, { label: "Loading...", value: "__data-loading__" }];
    } else {
      return options.filter((op) => op.value !== "__data-loading__");
    }
  }, [isFetching, options]);

  const onSearchHandler = (search: string) => {
    // setSearch(search);
  };
  if (React.isValidElement(children)) {
    return (
      <React.Fragment>
        {React.cloneElement(children, {
          ...children.props,
          onSearchHandler,
          fetchNextPage,
          loading: isFetching,
          options: optionsWithLoading,
          ListboxProps: {
            onScroll: (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
              const element = e.currentTarget;

              if (
                element.scrollTop + element.offsetHeight >=
                element.scrollHeight
              ) {
                if (hasNextPage) {
                  void fetchNextPage();
                }
              }
            },
          },
        })}
      </React.Fragment>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};
export default DatasetDataProvider;
