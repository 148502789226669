import Model from "models";

class UserMenuModel extends Model<UserMenuItem> {
  constructor() {
    super("/user-menu", "user-mng");
  }
  async updateSorting(data: { sorted_menu_ids: number[] }) {
    return await this.sendRequest<AppAccount>(`/sort`, "PUT", data);
  }
  async getUseMenu() {
    return await this.sendRequest<(UserMenuItem & UserMenuTemplate)[]>(
      `/current`,
      "GET"
    );
  }
}

export default new UserMenuModel();
