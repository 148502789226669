import useGetAccountSeats from "queries/useGetAccountSeats";
import React, { PropsWithChildren, useMemo } from "react";

const AccountSeatDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data: seatTypes, isLoading: isSeatLoading } = useGetAccountSeats();
  const options = useMemo(() => {
    return seatTypes?.map((type) => ({
      title: type.name,
      id: type.slug,
      subtitle: type.slug,
    }));
  }, [seatTypes]);

  if (React.isValidElement(children)) {
    return (
      <React.Fragment>
        {React.cloneElement(children, {
          ...children.props,
          options,
        })}
      </React.Fragment>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};
export default AccountSeatDataProvider;
