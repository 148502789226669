import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, Stack, useTheme } from "@mui/material";
import SidebarSection from "components/RightSidebar";
import { SortableList } from "components/SortableList";
import { useMemo } from "react";
import ProfileCard from "stories/CompoundComponent/ProfileCard/ProfileCard";

export type WorkflowBoardFieldsProps = {
  filterRules: FilterRule[];
  statusesToInclude: WorkflowStatus[];
  onAddStatusClick(): void;
  setStatusesToInclude(
    updater:
      | WorkflowStatus[]
      | ((statuses: WorkflowStatus[]) => WorkflowStatus[])
  ): void;
  onEditStatusClick(status: WorkflowStatus): void;
  onAddFilterRuleClick(): void;
  onEditFilterRuleClick(status: FilterRule): void;
  setFilterRules(
    filterRules: FilterRule[] | ((filterRules: FilterRule[]) => void)
  ): void;
};

const WorkflowBoardFields: React.FC<WorkflowBoardFieldsProps> = (props) => {
  const {
    statusesToInclude,
    setStatusesToInclude,
    filterRules,
    setFilterRules,
    onAddFilterRuleClick,
    onAddStatusClick,
    onEditFilterRuleClick,
    onEditStatusClick,
  } = props;

  const theme = useTheme();

  const DefaultListItemStyles = useMemo(
    () => ({
      background: theme.palette.background.GFRightNavForeground,
      height: 48,
      ".MuiTypography-subtitle1": {
        margin: "0 0 2px",
      },
      ".DragHandle": {
        lineHeight: "1",
        width: "20px",
        height: "20px",
        margin: "0 6px 0 -6px",
        color: theme.palette.background.GF20,

        "&:hover": {
          color: theme.palette.background.GF50,

          path: {
            fill: theme.palette.background.GF50,
          },
        },

        path: {
          fill: theme.palette.background.GF20,
          transition: "all 0.4s ease",
        },

        svg: {
          width: "100%",
          height: "auto",
          display: "block",
        },
      },

      ".card-inner-content": {
        gap: "2px",
      },
    }),
    [
      theme.palette.background.GF20,
      theme.palette.background.GF50,
      theme.palette.background.GFRightNavForeground,
    ]
  );

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <SidebarSection
          title="Statuses to include"
          onRightIconClick={onAddStatusClick}
        >
          <SortableList
            items={statusesToInclude}
            onChange={(updatedItems) => {
              setStatusesToInclude(updatedItems);
            }}
            renderItem={(item) => {
              return (
                <SortableList.Item id={item.id} handle>
                  <ProfileCard
                    options={{
                      draggable: true,
                      switcher: false,
                    }}
                    rightIcon={
                      <Stack direction="row" spacing={1}>
                        <Box className="edit-icon">
                          <DeleteOutline
                            sx={{ color: "grey.500" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setStatusesToInclude((statuses) =>
                                statuses.filter((s) => s.id !== item.id)
                              );
                            }}
                          />
                        </Box>
                        <Box className="edit-icon">
                          <EditOutlined sx={{ color: "grey.500" }} />
                        </Box>
                      </Stack>
                    }
                    subTitle={item.status}
                    title={item.label}
                    sx={DefaultListItemStyles}
                    onClick={() => {
                      onEditStatusClick(item);
                    }}
                  />
                </SortableList.Item>
              );
            }}
          />
        </SidebarSection>
      </Box>
      <Box sx={{ mb: 2 }}>
        <SidebarSection
          title="Filter Rules"
          onRightIconClick={onAddFilterRuleClick}
        >
          <SortableList
            items={filterRules}
            onChange={(updatedItems) => {
              setFilterRules(updatedItems);
            }}
            renderItem={(item) => {
              return (
                <SortableList.Item id={item.id} handle>
                  <ProfileCard
                    options={{
                      draggable: true,
                      switcher: false,
                    }}
                    rightIcon={
                      <Stack direction="row" spacing={1}>
                        <Box className="edit-icon">
                          <DeleteOutline
                            sx={{ color: "grey.500" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setFilterRules((rules) =>
                                rules.filter((s) => s.id !== item.id)
                              );
                            }}
                          />
                        </Box>
                        <Box className="edit-icon">
                          <EditOutlined sx={{ color: "grey.500" }} />
                        </Box>
                      </Stack>
                    }
                    title={item.label}
                    sx={DefaultListItemStyles}
                    onClick={() => {
                      onEditFilterRuleClick(item);
                    }}
                  />
                </SortableList.Item>
              );
            }}
          />
        </SidebarSection>
      </Box>
    </Box>
  );
};

export default WorkflowBoardFields;
