import LoadingButton from "@mui/lab/LoadingButton";
import { Box, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import InvitationModal from "models/Invitation";
import UserModel from "models/User";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from 'store';

const InvitationCard = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const theme = useTheme();

  const { accountSlug } = useParams<{ accountSlug: string }>();
  const setAccounts = useStore.useSetAccounts();

  const navigate = useNavigate();
  const { mutate: updateStatus, isLoading } = useMutation(
    ["update_invaite"],
    async ({ status }: { status: "accept" | "reject" }) => {
      const res = InvitationModal.updateStatus(accountSlug!, status);
      return res;
    }
  );

  const handleAccept = () => {
    // Logic to accept invitation
    console.log("Invitation Accepted");
    updateStatus(
      { status: "accept" },
      {
        onSuccess: async () => {
          const userRes = await UserModel.getUser();
          setAccounts(userRes.data.accounts);
          navigate("/");
          console.log("success");
        },
      }
    );
  };

  const handleReject = () => {
    // Logic to reject invitation
    console.log("Invitation Rejected");
    updateStatus(
      { status: "reject" },
      {
        onSuccess: () => {
          navigate("/");
          console.log("success");
        },
      }
    );
  };

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">{description}</Typography>
        <Box
          sx={{
            height: "72px",
            width: "72px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: theme.palette.primary.main,
            borderRadius: "5px",
            marginRight: "12px",
            fontSize: "32px",
            fontWeight: "bold",
          }}
        >
          {title[0]?.toLocaleUpperCase()}
        </Box>
        <Typography variant="h4">{title}</Typography>
      </CardContent>
      <CardActions
        sx={{
          marginTop: "40px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          padding: 1,
        }}
      >
        <Box sx={{ borderTop: "3px solid #2E2E2E", padding: "20px 10px" }}>
          <LoadingButton
            sx={{
              backgroundColor: "#2E2E2E",
              color: "white",
              mr: 1,
            }}
            onClick={handleReject}
            loading={isLoading}
          >
            Reject Invitation
          </LoadingButton>
          <LoadingButton
            sx={{ backgroundColor: "#A84A06", color: "white" }}
            onClick={handleAccept}
            loading={isLoading}
          >
             Accept Invitation
          </LoadingButton>
        </Box>
      </CardActions>
    </Card>
  );
};

export default InvitationCard;
