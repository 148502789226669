import { matchPath } from "react-router-dom";
export const hasAccessToModule = (
  routeModule: string,
  permissions: Partial<GFPermissions> = {},
  defaultV: boolean = true
) => {
  switch (routeModule) {
    case "fusion-action-module":
      return permissions.workflow_access;

    case "dataset-design-module":
      return permissions.dataset_access;

    case "vector-knowledgebase-module":
      return permissions.vector_embeds_access;

    case "import-module":
      return permissions.import_template_access;

    case "fusion-module":
      return permissions.workflow_access;
    case "skill-module":
    case "skill-design-module":
      return permissions.skills_access;

    case "gui-dashboard-module":
    case "gui-module":
      return permissions.dashboards_access;
    // case "presentation-module":
    case "icon-module":
      return permissions.icon_access;

    case "media-module":
      return permissions.media_access;
    case "account-user-module":
    case "user-type-module":
      return permissions.user_access;

    case "sylar-module":
      return permissions.sylar_access || permissions.co_pilot_access;

    case "billing-module":
      return permissions.billing_module;

    case "finetune-knowledgebase-module":
      return permissions.fine_tune_models_access;
      
    case "gui-module-public":
      return true;

    default:
      return defaultV;
  }
};

export const pathToModule = (path: string) => {
  if (matchPath("/:accountSlug/dataset-design/*", path))
    return "dataset-design-module";

  if (matchPath("/:accountSlug/vector-knowledgebase-module/*", path))
    return "vector-knowledgebase-module";

  if (matchPath("/:accountSlug/finetune-knowledgebase-module/*", path))
    return "finetune-knowledgebase-module";

  if (matchPath("/:accountSlug/import-module/*", path)) return "import-module";

  if (matchPath("/:accountSlug/gui-module/:slug/dashboard/*", path))
    return "gui-dashboard-module";

  if (matchPath("/:accountSlug/gui-module/*", path)) return "gui-module";

  if (matchPath("/:accountSlug/gui-module-public/*", path)) return "gui-module-public";

  if (matchPath("/:accountSlug/fusion/*", path)) return "fusion-module";

  if (matchPath("/:accountSlug/skill-design-module/*", path))
    return "fusion-module";

  if (matchPath("/:accountSlug/adventure-module/*", path))
    return "fusion-module";

  if (matchPath("/:accountSlug/double-talk-module/*", path))
    return "fusion-module";

  if (matchPath("/:accountSlug/saline-chat-module/*", path))
    return "fusion-module";

  if (matchPath("/:accountSlug/datacard-design-module/*", path))
    return "datacard-design-module";

  if (matchPath("/:accountSlug/presentation/*", path))
    return "presentation-module";

  if (matchPath("/:accountSlug/icons/*", path)) return "icon-module";

  if (matchPath("/:accountSlug/fusion-action-module/*", path))
    return "fusion-action-module";

  if (matchPath("/:accountSlug/account-user-module/*", path))
    return "account-user-module";

  if (matchPath("/:accountSlug/user-type-module/*", path))
    return "user-type-module";

  if (matchPath("/:accountSlug/skills/*", path)) {
    return "skill-module";
  }

  if (matchPath("/:accountSlug/media/*", path)) return "media-module";

  if (matchPath("/:accountSlug/billing-module/*", path))
    return "billing-module";

  if (
    matchPath("/:accountSlug/settings/*", path) ||
    matchPath("/:accountSlug/chat/*", path)
  )
    return "sylar-module";
};
