import { Box, Divider, Grid, Stack, TextField, styled } from "@mui/material";
import Label from "components/Form/Label";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { FormType } from "./UpdateUserModal";

type Props = {
  register: UseFormRegister<Partial<FormType>>;
  errors: FieldErrors<Partial<FormType>>;
  control: Control<Partial<FormType>, any>;
};

export const LabelHolder = styled(Grid)(({ theme }) => ({
  ".MuiFormLabel-root": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    color: theme.palette.text.primary,
  },

  [`${theme.breakpoints.down("sm")}`]: {
    marginBottom: "20px",
  },
}));

export const RDivider = styled(Divider)(({ theme }) => ({
  margin: "30px 0",

  [`${theme.breakpoints.down("sm")}`]: {
    margin: "20px 0",
  },
}));

export const StackHolder = styled(Stack)(({ theme }) => ({
  // maxWidth: "400px",
  padding: "0 0 0 10px",

  ".MuiFormLabel-root ": {
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.text.primary,
    margin: "0 0 8px",
  },
}));

const BasicComponent = ({ register, errors, control }: Props) => {
  return (
    <Box>
      <Grid container>
        <LabelHolder item xs={12} sm={5}>
          <Label label="General" />
        </LabelHolder>
        <Grid item sm={7} xs={12}>
          <StackHolder spacing={2.5}>
            <TextField
              {...register("first_name")}
              autoFocus
              name="first_name"
              margin="dense"
              id="type"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="First Name"
            />
            <TextField
              {...register("last_name")}
              name="last_name"
              margin="dense"
              id="type"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Last Name"
            />
            {/* <TextField
              {...register("email")}
              name="email"
              margin="dense"
              id="type"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Email"
            /> */}
          </StackHolder>
        </Grid>
      </Grid>
      <RDivider />
      <Grid container>
        <LabelHolder item xs={12} sm={5}>
          <Label label="Phone Number" />
        </LabelHolder>
        <Grid item sm={7} xs={12}>
          <StackHolder spacing={2.5}>
            <TextField
              {...register("phone")}
              name="phone"
              margin="dense"
              id="type"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
            />
          </StackHolder>
        </Grid>
      </Grid>
      <RDivider />
      <Grid container>
        <LabelHolder item xs={12} sm={5}>
          <Label label="Mailing address" />
        </LabelHolder>
        <Grid item sm={7} xs={12}>
          <StackHolder spacing={2.5}>
            <TextField
              {...register("mailing_address.address1")}
              name="mailing_address.address1"
              margin="dense"
              id="type"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Address line 1"
            />
            <TextField
              {...register("mailing_address.address2")}
              name="mailing_address.address2"
              margin="dense"
              id="type"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Address line 2"
            />
            <TextField
              {...register("mailing_address.city")}
              name="mailing_address.city"
              margin="dense"
              id="type"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="City"
            />
            <TextField
              {...register("mailing_address.state")}
              name="mailing_address.state"
              margin="dense"
              id="type"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="State"
            />
            <TextField
              {...register("mailing_address.zip")}
              name="mailing_address.zip"
              margin="dense"
              id="type"
              type="text"
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Zip"
            />
          </StackHolder>
        </Grid>
      </Grid>
      <RDivider />
    </Box>
  );
};

export default BasicComponent;
