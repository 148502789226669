import { useDroppable } from "@dnd-kit/core";
import {
  Breadcrumbs,
  Link,
  LinkProps,
  Toolbar,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import useAppNavigate from "hooks/useAppNavigate";
import React, { useMemo } from "react";
import { useListingLayout } from "store/stores/listingLayout";

type TopBarProps = {
  extra?: React.ReactNode;
};

const ToolbarWrap = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  background: alpha(theme.palette.common.black, 0.07),
  borderBottom: "1px solid #28262B",
  minHeight: "60px !important",

  ".MuiIconButton-sizeSmall": {
    svg: {
      display: "block",
      width: "20px",
      height: "auto",
      margin: "0 auto",
      opacity: "0.8",
    },
  },

  ".MuiBreadcrumbs-ol": {
    fontSize: "14px",
    lineHeight: "18px",

    ".MuiBreadcrumbs-separator": {
      margin: "0 16px",
      width: "16px",
      height: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "18px",
      lineHeight: "22px",
    },

    ".MuiTypography-root": {
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
}));

export const StyleLink = styled(Link)<{
  isDraggingOver?: boolean;
}>(({ isDraggingOver, theme }) => {
  return {
    ...(isDraggingOver && {
      border: "2px solid",
      padding: "4px",
      borderColor: theme.palette.primary.main,
      borderRadius: "5px",
    }),
  };
});

const DirectoryLink: React.FC<LinkProps> = (props) => {
  // const routeModule = useRouteToApiModel();
  // const isMutating = useIsMutating({
  //   mutationKey: [ApiModels.Folder, routeModule],
  //   predicate: (mutation) => {
  //     return mutation.options?.variables?.slug === folder.slug;
  //   },
  // });

  const { setNodeRef, isOver } = useDroppable({
    id: `folder-card_${props.id}`,
    data: {
      id: `${props.id}`,
    },
  });

  return <StyleLink ref={setNodeRef} {...props} isDraggingOver={isOver} />;
};
const TopBar: React.FC<TopBarProps> = (props) => {
  const { extra } = props;

  const navigate = useAppNavigate();

  const breadcrumbs = useListingLayout.useBreadcrumbs();

  const breadcrumbComponents = useMemo(() => {
    return breadcrumbs.map((bc, idx) => {
      if (bc.url) {
        return (
          <DirectoryLink
            underline="hover"
            key={`${bc.name}:${idx}`}
            id={bc.id}
            color="inherit"
            onClick={() => {
              navigate(bc.url!);
            }}
            sx={{ cursor: "pointer" }}
          >
            {bc.name}
          </DirectoryLink>
        );
      }

      return (
        <Typography key={`${bc.name}:${idx}`} color="text.primary">
          {bc.name}
        </Typography>
      );
    });
  }, [breadcrumbs, navigate]);

  return (
    <ToolbarWrap>
      <Breadcrumbs separator="›">{breadcrumbComponents}</Breadcrumbs>
      {extra}
    </ToolbarWrap>
  );
};

export default TopBar;
