import { BackupOutlined } from "@mui/icons-material";
import { Box, Stack, Typography, styled } from "@mui/material";
import classNames from "classnames";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { Accept, DropEvent, FileRejection, useDropzone } from "react-dropzone";

const DropzoneBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "60px",
  left: 0,
  width: "100%",
  height: "calc(100% - 60px)",
  display: "block",
  // flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  // padding: theme.spacing(2),
  cursor: "pointer",

  ".side-menu-active  &": {
    left: "48px",
    width: "auto",
    right: "0",
    top: "0",
    height: "100vh",
  },

  ".disabled": {
    // backgroundColor: theme.palette.grey[200],
    cursor: "not-allowed",
  },
}));

const DropzoneBoxCover = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  cursor: "pointer",

  ".disabled": {
    backgroundColor: theme.palette.grey[200],
    cursor: "not-allowed",
  },
}));

interface DropzoneCoverProps {
  onDrop: (acceptedFiles: File[], e: DropEvent) => void;
  onError?: (fileRejections: FileRejection[], e: DropEvent) => void;
  accept?: Accept;
  disabled?: boolean;
}

const DropzoneCover: React.FC<PropsWithChildren<DropzoneCoverProps>> = (
  props
) => {
  const { onDrop, onError, accept, disabled, children } = props;

  const [isOpen, setIsOpen] = useState(false);
  // const [dragCounter, setDragCounter] = useState(0);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDragEnter: () => {
      // console.log("onDragEnter");
      setIsOpen(true);
      // setDragCounter((prevCounter) => prevCounter + 1);
    },
    onDragOver: () => {
      // console.log("onDragOver");
      setIsOpen(true);
      // setDragCounter((prevCounter) => prevCounter + 1);
    },
    onDrop: (acceptedFiles, _, e) => {
      setIsOpen(false);
      onDrop(acceptedFiles, e);
      // setDragCounter(0);
    },
    onDropRejected: (fileRejections, e) => {
      setIsOpen(false);
      onError && onError(fileRejections, e);
      // setDragCounter((prevCounter) => prevCounter - 1);
    },
    onDragLeave: () => {
      // console.log("parent: onDragLeave");
      setIsOpen(false);
      // setDragCounter((prevCounter) => prevCounter - 1);
    },
    // onDragExit: () => setIsOpen(false),
    accept,
    multiple: true,
    disabled,
  });

  useEffect(() => {
    if (document) {
      document.addEventListener("dragleave", () => {
        // console.log("document parent: onDragLeave");
        setIsOpen(false);
      });
    }
  }, []);

  return (
    // <Modal open={isOpen} onClose={() => setIsOpen(false)}>
    <DropzoneBox
      {...getRootProps()}
      onDragEnd={(e) => {
        setIsOpen(false);
      }}
      onDragExit={(e) => {
        setIsOpen(false);
      }}
      className={classNames({ disabled, abc: true })}
      // sx={{ opacity: isOpen ? 1 : 0 }}
    >
      <DropzoneBoxCover
        sx={{
          // opacity: isOpen ? 1 : 0,
          display: isOpen ? "flex" : "none",
          // display: "flex",
          zIndex: 1,
          pointerEvents: "none",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ color: (theme) => theme.palette.background.cardsBorder }}
        >
          <BackupOutlined sx={{ fontSize: "48px" }} />
          <Typography
            sx={{ color: "inherit", fontSize: "28px" }}
            variant="body1"
          >
            Drop files to upload to
          </Typography>
        </Stack>
      </DropzoneBoxCover>
      <input {...getInputProps()} />
      <Box sx={{ height: "100%" }}>
        {/* <Toolbar /> */}
        {children}
      </Box>
    </DropzoneBox>
    // </Modal>
  );
};

export default DropzoneCover;
