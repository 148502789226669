import {
  AddOutlined,
  DeleteOutline,
  EditOutlined,
  MoreHoriz,
  SettingsOutlined,
} from "@mui/icons-material";
import { Box, Button, Stack, Tab, TabProps, Tabs, styled } from "@mui/material";
import ActionMenu from "components/ActionMenu";
import IOSSwitch from "components/IOSSwitch";
import IconButtonWithTooltip from "components/IconButtonWithTooltip";
import TabStyled from "components/TabStyled";
import useOpenClose from "hooks/useOpenClose";
import React, { useMemo, useState } from "react";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import GuiRightSideNav from "../../gui-dataset/components/GuiRightSideNav";
import AddGuiTabModal, { AddGuiTabFormType } from "./AddGuiTabModal";
import GuiSettingsDrawer from "./GuiSettingsDrawer";
import RenameGuiTabModal, { RenameGuiTabFormType } from "./RenameGuiTabModal";
import TabEditDrawer from "./TabEditorDrawer";

const TopBarButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.secondary.shades?.["30p"],
  background: theme.palette.background.GF36,
  color: theme.palette.text.secondary,
  padding: "6px 14px 6px 9px",
  position: "relative",
  overflow: "hidden",

  "&:hover:not(.item-edited)": {
    borderColor: theme.palette.secondary.shades?.["30p"],
    background: theme.palette.background.GF36,
  },

  "&.item-edited": {
    background: theme.palette.background.GF10,
    color: theme.palette.text.primary,
    borderColor: "transparent",

    ".MuiSwitch-root": {
      ".MuiSwitch-thumb": {
        background: theme.palette.text.primary,
      },

      ".MuiSwitch-track": {
        background: theme.palette.background.GF10,
      },
    },
  },

  ".MuiSwitch-root": {
    overflow: "visible",

    "&:before": {
      position: "absolute",
      inset: "-80px",
      content: `""`,
    },

    ".MuiSwitch-track": {
      background: theme.palette.background.GF10,
    },

    ".MuiSwitch-thumb": {
      boxShadow: "none",
      background: theme.palette.background.GF60,
    },
  },
}));

const SettingsButton = styled(IconButtonWithTooltip)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.shades?.["30p"]}`,
  background: theme.palette.background.GF36,
  padding: "4px",
  height: "36px",
  width: "36px",

  "&:hover": {
    background: theme.palette.background.GF10,
    color: theme.palette.text.primary,
    borderColor: "transparent",
  },
}));

const TabActionIconButton = styled(Box)(({ theme }) => ({
  border: "none",
  borderRadius: "4px",
  height: "18px",
  width: "18px",
  dispaly: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.4s ease",

  svg: {
    width: "100%",
    height: "auto",
    display: "block",
  },

  "&:hover": {
    background: theme.palette.background.GFOutlineNav,
    color: theme.palette.text.primary,
  },
}));

const StyledGuiTab = styled(Tab)(({ theme }) => ({
  ".action-menu": {
    ".MuiPopover-paper": {
      boxShadow: "none",
      background: theme.palette.background.GFOutlineNav,
      borderRadius: "6px",
      overflow: "hidden",
    },

    "&__icon": {
      svg: {
        maxWidth: "18px",
        height: "auto",
        display: "block",
      },
    },

    "&__label": {
      color: theme.palette.text.primary,
      fontSize: "14px",
      lineHeight: "22px",
    },

    ".MuiList-root": {
      padding: "0",
      minWidth: "123px",

      ".MuiButtonBase-root": {
        height: "auto",
        padding: "9px 13px",
      },
    },
  },
}));

const TopBarWrapper = styled(Stack)(({ theme }) => ({
  padding: "0 23px",
  height: "60px",
  borderBottom: "1px solid #28262B",

  ".MuiTabs-root ": {
    ".MuiTabs-flexContainer": {
      // gap: "48px",
    },

    ".MuiButtonBase-root": {
      padding: "8px 22px",
      height: "40px",
      minHeight: "40px",
      color: theme.palette.text.secondary,
      position: "relative",
      minWidth: "inherit",
      gap: "7px",
      borderRadius: "4px",

      "&:hover": {
        ".MuiTypography-root": {
          color: theme.palette.text.primary,
        },
      },

      "&:after": {
        position: "absolute",
        left: "22px",
        right: "22px",
        bottom: "0",
        content: `""`,
        background: theme.palette.primary.main,
        height: "2px",
        opacity: "0",
        visibility: "hidden",
        transition: "all 0.4s ease",
      },

      "&.Mui-selected": {
        // background: theme.palette.background.GF10,

        ".MuiTypography-root": {
          color: theme.palette.text.primary,
        },

        "&:after": {
          opacity: "1",
          visibility: "visible",
        },
      },

      ".action-menu": {
        margin: "0 -10px 0 0",
      },

      ".MuiTypography-root": {
        color: theme.palette.text.secondary,
        fontWeight: "600",
      },
    },

    ".MuiTabs-indicator": {
      display: "none !important",
    },
  },

  ".edit-button-holder": {
    ".MuiIconButton-sizeSmall": {
      width: "28px",
      height: "28px",
      border: "1px solid #28262B",
      borderRadius: "4px",
      background: "none",
    },
  },
}));

const GuiName = styled(Stack)(({ theme }) => ({
  fontSize: "26px",
  lineHeight: "32px",
  fontWeight: "600",
}));

type GuiTabProps = {
  label: React.ReactNode;
  value?: string | number;
  disableActions: boolean;
  onRenameTab(): void;
  onDeleteTab(): void;
  onSettingsClick(): void;
};

const GuiTab: React.FC<GuiTabProps> = (props) => {
  const {
    label,
    value,
    disableActions = false,
    onRenameTab,
    onDeleteTab,
    onSettingsClick,
    ...rest
  } = props;

  return (
    <StyledGuiTab
      label={label}
      value={value}
      disableRipple
      icon={
        disableActions ? (
          <></>
        ) : (
          <ActionMenu
            menuItems={[
              {
                label: "Rename",
                icon: <EditOutlined />,
                value: "rename",
              },
              {
                label: "Settings",
                icon: <SettingsOutlined />,
                value: "settings",
              },
              {
                label: "Delete",
                icon: <DeleteOutline />,
                value: "delete",
              },
            ]}
            disablePortal
            onItemClick={(key) => {
              if (key === "rename") {
                onRenameTab();
              } else if (key === "delete") {
                onDeleteTab();
              } else if (key === "settings") {
                onSettingsClick();
              }
            }}
          >
            <TabActionIconButton>
              <MoreHoriz fontSize="small" />
            </TabActionIconButton>
          </ActionMenu>
        )
      }
      iconPosition="end"
      {...rest}
    />
  );
};

type TabBarProps = {
  tabs: GuiTab[];
  selected: number | null;
  showEditableSwitch?: boolean;
  gui?: GfGui;
  publicAccess?: boolean;
  onChange: (value: number) => void;
  onAddTab(data: AddGuiTabFormType): Promise<void>;
  onRenameTab(tab: TabProps, data: RenameGuiTabFormType): void;
  onDeleteTab(tab: TabProps): void;
  onEditTab(tab: string, updates: Partial<GuiTab>): Promise<void>;
  title?: string;
};

const TabBar: React.FC<TabBarProps> = (props) => {
  const {
    gui,
    tabs: guiTabs,
    selected,
    showEditableSwitch = true,
    onChange,
    onAddTab,
    onRenameTab,
    onDeleteTab,
    onEditTab,
    title,
    publicAccess = false,
  } = props;

  const [isDatalistSettingsOpen, openDatalistSettings, closeDataListSettings] =
    useOpenClose();
  const [
    isGuiSettingsDrawerOpen,
    openGuiSettingsDrawer,
    closeGuiSettingsDrawer,
  ] = useOpenClose();
  const [isAddModalOpen, openAddModal, closeAddModal] = useOpenClose();
  const [isRenameModalOpen, openRenameModal, closeRenameModal] = useOpenClose();
  const [isEditTabDrawerOpen, openEditTabDrawer, closeEditTabDrawer] =
    useOpenClose();

  const editModeEnabled = useGuiDashboardStore.useEditModeEnabled();
  const setEditModeEnabled = useGuiDashboardStore.useSetEditModeEnabled();

  const [selectedTab, setSelectedTab] = useState<TabProps | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };

  const tabs = useMemo(() => {
    const allTabs: TabProps[] = [];

    guiTabs.forEach((tab) => {
      allTabs.push({
        label: <TabStyled title={tab.tab_name} />,
        value: tab.tab_name,
      });
    });

    return allTabs;
  }, [guiTabs]);

  const guiTab = useMemo(() => {
    return guiTabs.find((tab) => tab.tab_name === selectedTab?.value);
  }, [guiTabs, selectedTab?.value]);

  const handleEditDatalistSettings = () => {
    openDatalistSettings();
  };

  return (
    <TopBarWrapper
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <Stack direction="row" alignItems="center" sx={{ flex: 1 }} gap={"24px"}>
        <Tabs
          value={selected ?? 0}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{ flex: editModeEnabled ? undefined : 1 }}
        >
          {tabs.map((tab) => (
            <GuiTab
              key={tab.value}
              label={tab.label}
              disableActions={!editModeEnabled}
              onRenameTab={() => {
                setSelectedTab(tab);
                openRenameModal();
              }}
              onDeleteTab={() => {
                onDeleteTab(tab);
              }}
              onSettingsClick={() => {
                setSelectedTab(tab);
                openEditTabDrawer();
              }}
            />
          ))}
        </Tabs>
        {editModeEnabled && (
          <Box className="edit-button-holder">
            <IconButtonWithTooltip
              variant="outlined"
              size="small"
              tooltipProps={{ title: "Add Tab" }}
              onClick={(e) => {
                e.stopPropagation();
                openAddModal();
              }}
            >
              <AddOutlined fontSize="small" />
            </IconButtonWithTooltip>
          </Box>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1.5}>
        {showEditableSwitch && (
          <TopBarButton
            variant="outlined"
            size="medium"
            disableElevation
            className={`${editModeEnabled ? "item-edited" : ""}`}
            startIcon={
              <IOSSwitch
                checked={editModeEnabled ?? false}
                onChange={(e) => setEditModeEnabled(e.target.checked)}
                size="small"
                color="primary"
                sx={{
                  ".MuiSwitch-track": {
                    background: (theme) => theme.palette.background.GF20,
                  },
                }}
              />
            }
          >
            EDIT
          </TopBarButton>
        )}
        <Box>
          {editModeEnabled && (
            <SettingsButton
              size="medium"
              tooltipProps={{ title: "Gui Settings" }}
              onClick={() => {
                openGuiSettingsDrawer();
              }}
            >
              <SettingsOutlined fontSize="small" />
            </SettingsButton>
          )}
        </Box>
        <GuiName className="gui-name">{title}</GuiName>
      </Stack>
      <AddGuiTabModal
        open={isAddModalOpen}
        onClose={() => closeAddModal()}
        onSubmit={onAddTab}
      />
      <RenameGuiTabModal
        open={isRenameModalOpen}
        value={{ tab_name: selectedTab?.value }}
        onClose={() => {
          setSelectedTab(null);
          closeRenameModal();
        }}
        onSubmit={async (data) => {
          if (selectedTab) {
            onRenameTab(selectedTab, data);
          }
        }}
      />
      <TabEditDrawer
        open={isEditTabDrawerOpen}
        tab={guiTab}
        gui={gui}
        onClose={() => {
          closeEditTabDrawer();
          setSelectedTab(null);
        }}
        onSubmit={async (updates) => {
          if (selectedTab) {
            await onEditTab(selectedTab.value, updates);
          }
        }}
        onEditDatalistSettings={handleEditDatalistSettings}
      />
      <GuiSettingsDrawer
        open={isGuiSettingsDrawerOpen}
        onClose={closeGuiSettingsDrawer}
      />
      {gui && (
        <GuiRightSideNav
          gui={gui}
          open={isDatalistSettingsOpen}
          onClose={closeDataListSettings}
        />
      )}
    </TopBarWrapper>
  );
};

export default TabBar;
