import { MenuItem, TextField } from "@mui/material";
import FormField from "components/FormField";
import { FC } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";

type ChatViewFieldsProps = {
  control: Control<Partial<IncludeTabs>, any>;
  errors: FieldErrors<Partial<IncludeTabs>>;
  linkFields: DataField[];
};

const ChatViewFields: FC<ChatViewFieldsProps> = (props) => {
  const { control, errors, linkFields } = props;
  return (
    <>
      <FormField label="Sender ID Field" error={errors.linking_field}>
        <Controller
          name="sender_id_field"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value ?? ""}
              type="text"
              fullWidth
              variant="filled"
              size="small"
              select
            >
              {linkFields?.map((f) => (
                <MenuItem key={f.id} value={f.slug}>
                  {f.title}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormField>
      <FormField label="Message Field" error={errors.linking_field}>
        <Controller
          name="message_field"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value ?? ""}
              type="text"
              fullWidth
              variant="filled"
              size="small"
              select
            >
              {linkFields?.map((f) => (
                <MenuItem key={f.id} value={f.slug}>
                  {f.title}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormField>
      <FormField label="Sender Name Field" error={errors.linking_field}>
        <Controller
          name="sender_name_field"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value ?? ""}
              type="text"
              fullWidth
              variant="filled"
              size="small"
              select
            >
              {linkFields?.map((f) => (
                <MenuItem key={f.id} value={f.slug}>
                  {f.title}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormField>
    </>
  );
};

export default ChatViewFields;
