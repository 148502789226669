import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import ManagementSettings from "components/ManagementSettings";
import Spin from "components/Spin";
import useAccountSlug from "hooks/useAccountSlug";
import useOpenClose from "hooks/useOpenClose";
import InvitationModal from "models/Invitation";
import React from "react";
import { confirmDelete } from "utils/confirmDelete";
import { ListItem } from "views/dashboard/Home";
import InviteUserModal from "./account-user/InviteUser";

const Home = (props: {}) => {
  const theme = useTheme();
  const [openInviteModal, onInviteModalOpen, onInviteModalClose] =
    useOpenClose();
  const accountslug = useAccountSlug();
  const {
    data: Invitations,
    isLoading,
    isFetchedAfterMount,
    refetch,
  } = useQuery({
    queryFn: async () => {
      const res = await InvitationModal.list({
        query: { account_slug: accountslug! },
      });
      return res.data;
    },
    queryKey: ["user-invitations", accountslug],
  });

  const { mutate: handleDelete, isLoading: isUpdatingStatus } = useMutation(
    ["update_invaite", accountslug],
    async ({ slug }: { slug: string }) => {
      const res = InvitationModal.delete(slug);
      return res;
    }
  );

  const handleCancel = (item: Invitation) => {
    // Logic to accept invitation
    console.log("Invitation Accepted");
    confirmDelete().then((result) => {
      if (result) {
        handleDelete(
          { slug: item.slug },
          {
            onSuccess: async () => {
              refetch();
              console.log("success");
            },
          }
        );
      }
    });
  };

  const handleClose = () => {
    refetch();
    onInviteModalClose();
  };
  return (
    <React.Fragment>
      <Box p={5}>
        <Typography variant="h4" pb="20px">
          Welcome
        </Typography>
        <Box mb={2}>
          <Box sx={{ display: "flex" }}>
            <Typography
              variant="body1"
              sx={{
                borderBottom: "3px solid",
                borderColor: theme.palette.primary.main,
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Pending Invitations
            </Typography>
            <Button
              sx={{ marginLeft: 1 }}
              size="small"
              onClick={onInviteModalOpen}
              color="error"
            >
              <AddOutlined />
            </Button>
          </Box>
          <Spin spinning={isLoading || isUpdatingStatus}>
            <React.Fragment>
              {Invitations?.map((item) => (
                <ListItem
                  title={item.account_title}
                  created_at={item.created_at}
                  extra={
                    <React.Fragment>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        sx={{ color: "white" }}
                        onClick={() => handleCancel(item)}
                      >
                        Cancel
                      </Button>
                    </React.Fragment>
                  }
                />
              ))}
              {isFetchedAfterMount && Invitations?.length === 0 && (
                <Box
                  sx={{
                    padding: 1,
                    backgroundColor: "#1F1F27",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body1"> No Invitation</Typography>
                </Box>
              )}
            </React.Fragment>
          </Spin>
        </Box>
        <ManagementSettings />
      </Box>
      <InviteUserModal open={openInviteModal} onClose={handleClose} />
    </React.Fragment>
  );
};
export default Home;
