import { ArrowBack } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IOSSwitch from "components/IOSSwitch";
import { SidebarSectionWrap } from "components/RightSidebar/SidebarSection";
import { DocumentElementType } from "enums";
import { cloneDeep, debounce, set } from "lodash";
import get from "lodash/get";
import { ApiModels } from "queries/apiModelMapping";
import useGetItem from "queries/useGetItem";
import useUpdateItem from "queries/useUpdateItem";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useGuiDashboardStore } from "store/stores/gui-dashboard-widget";
import DatasetDesignRules from "./DatasetDesignRules";

const RulesCard = styled(Card)(({ theme }) => {
  return {
    padding: "13px",
    transition: "all 0.4s ease",
    background: theme.palette.background.GFOutlineNav,

    ".MuiCardHeader-root": {
      padding: "0 0 9px",

      ".MuiTypography-root": {
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.text.primary,
        fontWeight: "400",
      },

      ".MuiCardHeader-action ": {
        marginRight: "0",
        ".MuiButtonBase-root": {
          padding: "0",
          width: "20px",
          height: "20px",
          color: theme.palette.text.primary,

          svg: {
            width: "100%",
            height: "auto",
            display: "block",
          },
        },
      },
    },

    ".MuiCardContent-root ": {
      padding: "0",
      display: "flex",
      flexDirection: "column",
      gap: "11px",

      "&:last-child": {
        padding: "0",
      },
    },

    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },

    ".MuiInputBase-root": {
      background: theme.palette.background.GFTopNav,
    },
  };
});

const name = "conditions";
const defaultFields: DataField[] = [
  {
    id: "id",
    slug: "id",
    title: "id",
    type: DocumentElementType.TextField,
  },
];
const FieldFilterRules: React.FC<{
  gui: GfGui;
  onClickBack: () => void;
  indexKey: "included_tabs" | "included_sidebar_widgets" | "general";
  path?: string[];
  selectedField: DataField;
}> = ({
  gui,
  onClickBack,
  indexKey = "included_tabs",
  path = [],
  selectedField,
}) => {
  const { control, register, handleSubmit, watch, setValue, ...rest } =
    useForm();
  const { fields, remove, replace, insert } = useFieldArray({
    control,
    name,
  });
  const initialValueSet = useRef(false);
  const allowNetworkRequest = useRef(false);

  const selectedGuiTab = useGuiDashboardStore.useSelectedTab();

  const guiTab = useMemo(() => {
    if (selectedGuiTab != null && gui?.tabs) {
      return gui?.tabs?.[selectedGuiTab];
    }
  }, [gui?.tabs, selectedGuiTab]);

  const { selectedTab } = useMemo(() => {
    if (guiTab?.tab_type === "record_list") {
      if (indexKey === "general") {
        return {
          selectedTab: guiTab,
        };
      }
      let parentTab = guiTab[indexKey];

      const selectedTab = get(parentTab, path.slice(0, -1));

      return {
        selectedTab,
        parentTab:
          path.length > 2 ? get(parentTab, path.slice(0, -3)) : undefined,
      } as { selectedTab?: IncludeTabs; parentTab?: IncludeTabs };
    } else {
      return {};
    }
  }, [guiTab, indexKey, path]);

  const { mutate: updateGui, isLoading } = useUpdateItem({
    modelName: ApiModels.Gui,
  });

  const { data: datasetDesign } = useGetItem({
    modelName: ApiModels.DatasetDesign,
    slug: selectedField.associated_document,
    queryOptions: { enabled: !!selectedField.associated_document },
  });

  useEffect(() => {
    initialValueSet.current = false;
    allowNetworkRequest.current = false;
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab) {
      setValue(
        name,
        selectedTab?.[`field_metadata`]?.[selectedField.id]?.[name]
      );
      setValue(
        "read_only",
        selectedTab?.[`field_metadata`]?.[selectedField.id]?.["read_only"]
      );
      initialValueSet.current = true;
      setTimeout(() => {
        allowNetworkRequest.current = true;
      }, 500);
    }
  }, [selectedTab]);

  const submitHandler = useCallback(
    async (data: Partial<{ conditions: Condition[] }>) => {
      if (
        gui.slug &&
        allowNetworkRequest.current &&
        guiTab?.tab_type === "record_list"
      ) {
        if (indexKey === "general") {
          await updateGui(
            {
              slug: gui.slug,
              data: {
                tabs:
                  gui.tabs?.map((t) =>
                    t.id === guiTab.id
                      ? {
                          ...t,
                          field_metadata: {
                            ...guiTab.field_metadata,
                            [selectedField.id]: data,
                          },
                        }
                      : t
                  ) || [],
              },
            },
            {
              onSuccess: () => {
                console.log("gui edit success");
              },
            }
          );

          return;
        }
        let datasetListSetting = cloneDeep(guiTab);

        const setPath = [
          indexKey,
          ...path.slice(0, -1),
          "field_metadata",
          selectedField.id,
        ];
        set(datasetListSetting, setPath, data);

        await updateGui(
          {
            slug: gui.slug,
            data: {
              tabs:
                gui.tabs?.map((t) =>
                  t.id === guiTab.id ? { ...guiTab, ...datasetListSetting } : t
                ) || [],
            },
          },
          {
            onSuccess: () => {
              console.log("gui edit success");
            },
          }
        );
      }
    },
    [gui.slug, gui.tabs, guiTab, indexKey, path, selectedField.id, updateGui]
  );

  useEffect(() => {
    const submitDeb = debounce(() => {
      handleSubmit(submitHandler)();
    }, 1200);
    const subscription = watch((_) => {
      submitDeb();
    });
    return () => subscription.unsubscribe();
  }, [watch, submitHandler, handleSubmit]);

  const fieldList = useMemo(() => {
    return [...defaultFields, ...(datasetDesign?.fields?.fields ?? [])];
  }, [datasetDesign?.fields?.fields]);
  return (
    <SidebarSectionWrap
      title="Field Metadata"
      leftIcon={<ArrowBack />}
      rightIcon={<></>}
      onLeftIconClick={onClickBack}
    >
      <Controller
        control={control}
        name="read_only"
        render={({ field }) => (
          <FormControlLabel
            control={
              <IOSSwitch
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
                checked={field.value ?? false}
              />
            }
            sx={{
              ".MuiFormControlLabel-label": { mr: 1, ml: 2 },
            }}
            label="Read Only"
            labelPlacement="start"
          />
        )}
      />
      <SidebarSectionWrap
        title="Manage Rules"
        onRightIconClick={() => {
          insert(fields.length + 1, {
            condition_set: [{ a: "", b: "", o: "=" }],
          });
        }}
      >
        {fields.map((field, fieldIdx) => {
          return (
            <Box key={field.id} id={field.id}>
              <Stack direction="column" spacing={1} key={field.id}>
                {fieldIdx > 0 && (
                  <Divider
                    sx={{
                      "&:before, &:after": {
                        borderColor: (theme) => theme.palette.primary.main,
                      },
                    }}
                  >
                    or
                  </Divider>
                )}
              </Stack>
              <RulesCard>
                <CardHeader
                  title="INCLUDE IF "
                  action={
                    <IconButton
                      aria-label="cross"
                      onClick={() => remove(fieldIdx)}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                />
                <CardContent sx={{ padding: "0px 8px" }}>
                  <DatasetDesignRules
                    control={control}
                    register={register}
                    parentNamePath={`${name}[${fieldIdx}]`}
                    conditionsLength={fields.length}
                    fields={fieldList}
                    {...rest}
                  />
                </CardContent>
              </RulesCard>
            </Box>
          );
        })}
      </SidebarSectionWrap>
    </SidebarSectionWrap>
  );
};

export default FieldFilterRules;
